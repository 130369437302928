import React from 'react'
import ReactDOMServer from 'react-dom/server'
import { Link } from "react-router-dom"

/*********************************************************
 * @prop title    : Sets the title of the dialog display
 * @prop content  : The body text of the dialog (can be html if msgFormat)
 * @prop msgFormat: If 'html' interpolates content string as html
 * @prop label1   : Label for button #1
 * @prop action1  : action for button #1 and the Close (x) button
 * @prop label2   : Label for button #2 [optional]
 * @prop action2  : action for button #2 [optional]
 * @prop custom1  : used to turn first button into an external link with
 *                  href (used to navigate to company pricing page)
 * @prop isDanger : show as an error level dialog
 * @prop noPadding : if true removes padding on the main content column
 *********************************************************/
export default function DMDialog(props) {
  let CONTENT = null
  let paddingClass = ""
  if( props.msgFormat === 'html' ) {
    if( Array.isArray(props.content) ) {
      CONTENT = <div dangerouslySetInnerHTML={{__html: props.content.map(ReactDOMServer.renderToStaticMarkup).join("")}} />
    }
    else {
      CONTENT = <div dangerouslySetInnerHTML={{__html: props.content}} />
    }
    paddingClass = "p-0"
  }
  else {
    CONTENT = <div key='dialog-content' className="subtitle is-5">{props.content}</div>
  }
  // Build custom modal based on passed props
  return (
    <div id="modal-ter" className="modal is-active" >
      <div className="modal-background"></div>
      <div className="modal-card" id="anim-fadein" >
        <header className="modal-card-head m-0">
          <p className="modal-card-title">{props.title}</p>
          <button className="delete" onClick={() => props.action1()} aria-label="close"></button>
        </header>
        <section className={`modal-card-body m-0 ${paddingClass}`} style={{overflowX:'hidden'}}>
          <div className="content">
            {
              props.isDanger
              ?
              <div className="notification is-danger is-light">
                <div className="columns">
                  <div className="column is-1 p-1 m-1">
                    <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
                  </div>
                  <div className="column p-1 m-1">
                    {CONTENT}
                  </div>
                </div>
              </div>
              :
              <div className="columns m-0">
                <div className={`column ${props.noPadding ? "p-0" : ""}`}>
                  {CONTENT}
                </div>
              </div>
            }
          </div>
        </section>
        <footer className="modal-card-foot m-0">
          <div className="columns m-0 fullwidth">
            <div className="column p-0">
              <div className="buttons is-right">
                { /* Check for optional button #2 values */
                  (props.action2 && props.label2)
                  ?
                  <React.Fragment>
                    {
                      props.custom1 === "showUpgradeButton"
                      ?
                      <a href="https://www.deepmotion.com/pricing" target="_blank" rel="noopener noreferrer" className="button action-btn" tabIndex="0" onClick={()=>props.action1()}>{props.label1}</a>
                      :
                      <button className="button is-white dm-brand-font" tabIndex="0" onClick={() => props.action1()}>{props.label1}</button>
                    }
                    <button className="button action-btn-dark" tabIndex="1" onClick={() => props.action2()}>{props.label2}</button>
                  </React.Fragment>
                  :
                    <button className="button action-btn-dark" tabIndex="0" onClick={() => props.action1()}>{props.label1}</button>
                }
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}