// product names and descriptions for main dashboard page
export const productInfo = Object.freeze({
  HOME: 0,  // default means no product currently selected in UI
  DMBT_Cloud: {'id': 1, name: 'Animate 3D', descr: 'Generate 3D Animations and Images from Video'},
  DMBT_SDK: {'id': 2, name: 'Body Tracking SDK', descr: 'Real time body tracking for mobile and PC'},
  VR_SDK: {'id': 3, name: 'VR Tracking SDK', descr: 'Full body VR tracking using three or more trackers'},
  APE_SDK: {'id': 4, name: 'Physics Engine SDK', descr: 'A powerful and fast physics articulation engine'},
  numProducts: 4
})

// Product app routes
export const routes = Object.freeze({
  SignIn:             '/',
  LoginCallback:      '/login/callback',
  Dash:               '/dashboard',
  Anim3d:             '/dashboard/animate-3d',
  Anim3dGuidedFTE:    '/dashboard/animate-3d/new',
  Anim3dCreate:       '/dashboard/animate-3d/create',
  Anim3dJobTypeSelect:'/dashboard/animate-3d/select-job-type',
  Anim3dModelSelect:  '/dashboard/animate-3d/select-model',
  Anim3dModelUpload:  '/dashboard/animate-3d/upload-model',
  Anim3dModelManage:  '/dashboard/animate-3d/custom-models',
  Anim3dLibrary:      '/dashboard/animate-3d/library',
  Anim3dPreview:      '/dashboard/animate-3d/library/preview',
  Anim3dProfilePage:  '/dashboard/animate-3d/profile',
  DMBTSdk:            '/dashboard/body-tracking-sdk',
  VRSdk:              '/dashboard/vr-tracking-sdk',
  APESdk:             '/dashboard/physics-sdk',
  ActivityPage:       '/dashboard/activity',
  Admin:              '/dashboard/admin',
  Contact:            '/dashboard/contact',
  SupportPage:        '/dashboard/contact/support',
  FeedbackPage:       '/dashboard/contact/feedback',
  ForgotPwdPage:      '/forgot',
  Logout:             '/logout',
  UserForums:         '/oauth-api/forum',
  CreateAccount:      '/animate-3d/sign-up',
  ClosedAccount:      '/account-closed'
});

export const featureLockNames = Object.freeze({
  slowMotion: 'slowMotion',
  physicsFilter: 'physicsFilter',
  faceTracking: 'faceTracking',
  peSmoothness: 'peSmoothness',
  footLocking: 'footLocking',
  maxDuration: 'maxDuration',
  maxFps: 'maxFps',
  maxResolution: 'maxResolution'
})

// returns feature availability info based on user's subscription plan
export const featureLocksData = Object.freeze({
  slowMotion: [false, false, true, true, true],
  physicsFilter: [true, true, true, true, true],
  faceTracking: [true, true, true, true, true],
  peSmoothness: [false, false, false, true, true],
  footLocking: [
    [true, false, false, false],
    [true, false, false, false],
    [true, true, false, false],
    [true, true, true, true],
    [true, true, true, true]
  ],
  maxDuration: [10.0, 20.0, 30.0, 120.0, 240.0],
  maxFps: [30.0, 30.0, 60.0, 120.0, 120.0],
  maxResolution: [{w: 1920.0, h: 1080.0}, {w: 1920.0, h: 1080.0}, {w: 1920.0, h: 1080.0}, {w: 4096.0, h: 2160.0}, {w: 8192.0, h: 4320.0}]
})

// Metadata for generic character models used in Previewer
export const characterModels = Object.freeze({
  "1" : {fileName: 'female-normal', uiName: 'Adult Female'},
  "2" : {fileName: 'female-thin', uiName: 'Adult Female Alt'},
  "3" : {fileName: 'male-normal', uiName: 'Adult Male'},
  "4" : {fileName: 'male-fat', uiName: 'Adult Male Alt'},
  "5" : {fileName: 'male-young', uiName: 'Young Male'},
  "6" : {fileName: 'child', uiName: 'Child'},
})

export const animFileType = Object.freeze({
  bvh: "bvh",
  fbx: "fbx",
  gif: "gif",
  mp4: "mp4",
  glb: "glb",
  gltf: "gltf",
  dmpe: "dmpe",
  jpg: "jpg",
  png: "png",
  // TODO: Remove uppercase versions and add 'is-uppercase' Bulma CSS class where needed
  BVH: "BVH",
  FBX: "FBX",
  GIF: "GIF",
  MP4: "MP4",
  GLB: "GLB",
  GLTF: "GLTF",
  DMPE: "DMPE",
  JPG: "JPG",
  PNG: "PNG"
})

export const acceptedVideoFormats = Object.freeze([
  "mp4",
  "mov",
  "avi"
])

export const acceptedStaticPoseFormats = Object.freeze([
  "mp4",
  "mov",
  "avi",
  "gif",
  "jpg",
  "jpeg",
  "png",
  "bmp"
])

export const menuItemState = Object.freeze({
  "enabled": "item-enabled",
  "disabled": "item-disabled"
})

/********************************************************** 
 * Pagination constants:
 **********************************************************/ 
// max page buttons in library
export const MAX_PAGE_BUTTONS = 7

// rows per page
export const ROWS_PER_PAGE = [5, 10, 20, 50, 100]

// max input filename length
export const MAX_FILENAME_LENGTH = 50

// key name for temp local storage of app state
export const localStorageStateId = 'dm-app-state'
export const localStorageOktaId = 'dm-app-id'

// returns true if non-ascii characters found in input
export function containsNonAsciiCharacters(input) {
  return /[^\u0000-\u007F]/.test(input)
}

// public method for encoding an Uint8Array to base64
export function encodeUint8ArrayToBase64 (input) {
  var keyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/="
  var output = ""
  var chr1, chr2, chr3, enc1, enc2, enc3, enc4
  var i = 0

  while (i < input.length) {
    chr1 = input[i++]
    chr2 = i < input.length ? input[i++] : Number.NaN // Not sure if the index 
    chr3 = i < input.length ? input[i++] : Number.NaN // checks are needed here

    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63

    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output += keyStr.charAt(enc1) + keyStr.charAt(enc2) +
              keyStr.charAt(enc3) + keyStr.charAt(enc4)
  }
  return output
}

// removes illegal or reserved key words
export function removeIllegalOrReservedCharacters(input) {

  // special check against reserved filesystem paths (also fixes
  // potential security hole). Also filename cannot start with "."
  if( input === "." || input === ".." || input[0] === "." ) {
    return ""
  }
  const illegalRe = /[\/\?\[\]#%<>\\:\*\|"]/g
  const controlRe = /[\x00-\x1f\x80-\x9f]/g
  const reservedRe = /^\.+$/
  const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i
  const windowsTrailingRe = /[\. ]+$/
  const replacement = ''

  let sanitized = input
    .replace(illegalRe, replacement)
    .replace(controlRe, replacement)
    .replace(reservedRe, replacement)
    .replace(windowsReservedRe, replacement)
    .replace(windowsTrailingRe, replacement)

  // return sanitized.slice(0, MAX_FILENAME_LENGTH)
  // now handling max length exceeded at caller level
  return sanitized
}

/********************************************************** 
 * formats raw bytes count into user friendly string
 **********************************************************/ 
export function formatSizeUnits(bytes, numDec){
  if      (bytes >= 1073741824) { bytes = (bytes / 1073741824).toFixed( numDec !== null ? numDec : 2 ) + " GB"; }
  else if (bytes >= 1048576)    { bytes = (bytes / 1048576).toFixed( numDec !== null ? numDec : 2 ) + " MB"; }
  else if (bytes >= 1024)       { bytes = (bytes / 1024).toFixed( numDec !== null ? numDec : 2 ) + " KB"; }
  else if (bytes > 1)           { bytes = bytes + " bytes"; }
  else if (bytes == 1)          { bytes = bytes + " byte"; }
  else                          { bytes = "0 bytes"; }
  return bytes;
}

/********************************************************** 
 * formats seconds into user friendly string 
 **********************************************************/ 
export function secondsToHms(d, fullWords) {
  d = Number(d);
  let h = Math.floor(d / 3600);
  let m = Math.floor(d % 3600 / 60);
  let s = Math.floor(d % 3600 % 60);
  let minText = fullWords ? " minute " : " min "
  let secText = fullWords ? " second " : " sec "
  let minsText = fullWords ? " minutes " : " mins "
  let secsText = fullWords ? " seconds " : " sec "
  let hDisplay = h > 0 ? h + (h == 1 ? " hour " : " hours ") : "";
  let mDisplay = m > 0 ? m + (m == 1 ? minText : minsText) : "";
  let sDisplay = s > 0 ? s + (s == 1 ? secText : secsText) : "";
  return hDisplay + mDisplay + sDisplay; 
}

export function secondsToTime(unix_timestamp) {
  let time = new Date(unix_timestamp * 1000).toISOString().substr(11, 8)
  return time
}

/********************************************************** 
 * formats date to human readable string
 **********************************************************/ 
export function dateConverter(UNIX_timestamp, includeYear){
  const a = new Date(UNIX_timestamp * 1000)
  const months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
  const month = months[a.getMonth()]
  const date = a.getDate()
  const year = a.getFullYear()
  let time = month + ' ' + date
  if( includeYear ) {
    time += ', ' + year
  }
  return time
}

//////////////////////////////////////////////////////////////
export function convertUnixDateToStandardFormat(unix_timestamp) {
  const d = new Date(unix_timestamp)
  // convert date to human readable format
  const formattedDate = [
    d.getMonth()+1,
    d.getDate(),
    d.getFullYear()].join('/')+' '+
  [ addZero(d.getHours()),
    addZero(d.getMinutes()),
    addZero(d.getSeconds())].join(':')
  return formattedDate
}

export function countDecimals(value) {
  if(Math.floor(value) === value) return 0
  return value.toString().split(".")[1].length || 0
}

/********************************************************** 
 * Get user's first preferred browser language. Used for
 * formatting dates to user's proper locale
 **********************************************************/
export function getNavigatorLanguage() {
  if (navigator.languages && navigator.languages.length) {
    return navigator.languages[0];
  } else {
    return navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-US';
  }
}

/********************************************************** 
 * get file extension of pass in string
 **********************************************************/ 
export function getFileExtension(fileName) {
  return fileName.substr(fileName.lastIndexOf('.') + 1);
}
export function isFileExtensionVideoFormat(fileName) {
  const fileExt = getFileExtension(fileName.toLowerCase())
  return( acceptedVideoFormats.includes(fileExt) )
}
/********************************************************** 
 * helper formatting function for date strings
 **********************************************************/ 
export function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

/********************************************************** 
 * helper function for sorting objects by key
 **********************************************************/ 
export function sortObject(obj) {
  return Object.keys(obj).sort().reduce(function (result, key) {
    result[key] = obj[key];
    return result;
  }, {});
}

/********************************************************** 
 * helper function for adding to sorted characters array 
 * by most recent date
 **********************************************************/ 
export function addCharacterToSortedArray(element, array) {
  array.push(element)
  // sort characters by creation time
  array.sort(function(a, b) {
    return b.cTime - a.cTime
  })
  return array
}

// IDs for specific/custom Modals across the app
export const modalId = Object.freeze({
  // client side errors 
  None:                   null
});

// IDs for error/warning/info dialogs across the app
export const confirmDialog = Object.freeze({
  none:                   null, 
  inputFileTooBig:          1,
  inputFileCorrupt:         2,
  inputFileTooLong:         3,
  inputFileNameInvalid:     4,
  inputFileTypeWrong:       5, 
  cancelInProgressJob:      6,
  changeJobModel:           7, 
  removeJobModel:           8,
  removeCustChar:           9,
  customModelExists1:       10,
  customModelExists2:       11,
  standardDownload:         12,
  customDownload:           13,
  exitApplication:          14,
  closeUserAccount:         15,
  libraryJobSettings:       16,
  reRunJobConfig:           17,
  customModelSelect:        18,
  confirmNewAnimJob:        19,
  confirmLoseUploadData:    20,
  confirmInputMediaRemoval: 21,
  inputMaxLengthExceeded:   22,
  inputInvalidOrCorrupt:    23,

  // Base 100: Enforcement
  MinutesBalanceTooLow:     101,
  MaxResolutionExceeded:    102,
  MaxFPSExceeded:           103,
  MaxDurationExceeded:      104,

  // Extra Fallback/Custom Handling:
  Video2AnimFailure:        150,
  VideoValidationFailed:    151,

  // Base 200: Asset Pre-Processing
  VideoOrModelCopyError:    201,
  InvalidVideoCodecError:   202,

  // Base 300: CLI Pipeline
  InternalCliPipelineError: 300,

  // Base 500: DMBT
  FailedToParseArgsError:   503,
  FailedToLoadDataError: 504,
  ExplosionDetectedError: 505,
  FailedToCreatePoseEstimatorError: 506,
  FailedToCreateBodyTrackerError: 507,
  PoseEstimationTrackingError: 508,
  FailedToLoadConfigError: 509,
  FailedToOpenFileForWritingError: 510,
  InterruptedError: 511,

  // Base 700: DMFT
  InternalFaceTrackingError: 701,

  // Base 900: Vector CLI
  LoadMeshFailedError: 901,
  LoadBvhFailedError: 902,
  CopyAnimFailedError: 903,
  ExportFailedError: 904,
  MeshNotProvidedError: 905,
  BlendShapesLessThanHalfError: 906,
  LoadFaceDefinitionFailedError: 907,
  LoadDMFTDataFailedError: 908,
  LoadHumanoidMapError: 909,

  // Base 1100: Render CLI
  RenderCliError: 1101,
  InvalidInputParameterError: 1102,
  FailedToLoadOrPlayInputVideoError: 1103,
  FailedToLoadInputBvhError: 1104,
  FailedToLoadInputCharacterError: 1105,
  FailedToAttachAnimToCharacterError: 1106,
  FailedToConfigureBackDropError: 1107,
  FailedToCreateGifError: 1108
})

// STRINGS for error/warning/info dialogs across the app
export const confirmDialogMsgs = Object.freeze({
  inputFileTooBig:          "TODO:",
  inputFileCorrupt:         "TODO:",
  inputFileTooLong:         "TODO:",
  inputFileNameInvalid:     "TODO:",
  inputFileTypeWrong:       "TODO:", 
  cancelInProgressJob:      "TODO:",
  changeJobModel:           "TODO:", 
  removeJobModel:           "TODO:",
  removeCustChar:           "TODO:",
  customModelExists1:       "TODO:",
  customModelExists2:       "TODO:",
  standardDownload:         "TODO:",
  customDownload:           "TODO:",
  exitApplication:          "TODO:",
  closeUserAccount:         "TODO:",
  libraryJobSettings:       "TODO:",
  reRunJobConfig:           "TODO:",
  customModelSelect:        "TODO:",
  confirmNewAnimJob:        "TODO:",
  confirmLoseUploadData:    "TODO:",
  confirmInputMediaRemoval: "TODO:",

  // Base 100: Enforcement
  MinutesBalanceTooLow:     "TODO:",
  MaxResolutionExceeded:    "TODO:",
  MaxFPSExceeded:           "TODO:",
  MaxDurationExceeded:      "TODO:",

  // Extra Fallback Handling:
  Video2AnimFailure:        "TODO:",

  // Base 200: Asset Pre-Processing
  VideoOrModelCopyError:    "TODO:",
  InvalidVideoCodecError:   "TODO:",

  // Base 300: CLI Pipeline
  InternalCliPipelineError: "Sorry, we had an internal error with our processing pipeline. If the problem continues please contact Support for help.",

  // Base 500: DMBT
  FailedToParseArgsError:   "Sorry, we had an internal error processing the tracking parameters. If the problem continues please contact Support for help.",
  FailedToLoadDataError: "Sorry, we had an internal error loading character assets. Please contact Support for help.",
  ExplosionDetectedError: "Sorry, Physics Filter is incompatible with your custom character. Please turn off Physics Filter from your animation settings or try a different character.",
  FailedToCreatePoseEstimatorError: "Sorry, we had an internal error creating your pose estimation. Please contact Support for help.",
  FailedToCreateBodyTrackerError: "Sorry, we had an internal error while performing body tracking for your input. Please contact Support for help.",
  PoseEstimationTrackingError: "Sorry, your input video or image doesn’t meet our requirements to generate animations of good quality.<br/><br/>Please review our <a href='https://bit.ly/Capture_Guidelines' target='_blank' rel='noopener noreferrer'> video capture guidelines </a> and submit a new video that meets the guidelines. If the problem continues please send your video or image to Support.",
  FailedToLoadConfigError: "Sorry, we had an internal error loading the job configuration. If the problem continues please contact Support for help.",
  FailedToOpenFileForWritingError: "Sorry, we had an internal error trying to generate animation results. Please contact Support for help.",
  InterruptedError: "Sorry, we had an internal error and body tracking was interrupted. If the problem continues please contact Support for help.",

  // Base 700: DMFT
  InternalFaceTrackingError: "Sorry, we had an error during face tracking. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or try a new custom character model that supports ARKit Blendshapes.",

  // Base 900: Vector CLI
  LoadMeshFailedError: "Sorry, we had an error loading the mesh of your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  LoadBvhFailedError: "Sorry, we had an error while loading the BVH custom character file. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  CopyAnimFailedError: "Sorry, we had an error copying animations onto your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  ExportFailedError: "Sorry, we had an error exporting animations for your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character model. If the problem continues please send your video, custom character model, and this error code to Support for help.",
  MeshNotProvidedError: "Sorry, your custom character doesn’t include skinned mesh information. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or upload a rigged custom character model with skinned mesh in it.",
  BlendShapesLessThanHalfError: "Sorry, your character is missing more than half of the required blendshapes for Face Tracking. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a>, or upload a new custom character with the complete set of blendshapes.",
  LoadFaceDefinitionFailedError: "Sorry, we had an internal error loading facial definition for your custom character. Please make sure your facial rig meets <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or upload a custom character with the complete set of blendshapes. If the problem persists please send your video, custom character model, and this error to Support for help.",
  LoadDMFTDataFailedError: "Sorry, we had an internal error loading facial tracking data. Please make sure your facial rig meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2021/07/02/animate3d-facetracking-faq'> requirements </a> or upload a new custom character with the complete set of blendshapes. If the problem continues please send your video, custom character model, and this error to Support for help.",
  LoadHumanoidMapError: "Sorry, we had an internal error loading the metadata of your custom character. Please make sure your custom character meets the <a target='_blank' rel='noopener noreferrer' href='https://blog.deepmotion.com/2020/11/19/animate-3d-custom-characters'> requirements </a> or try a new custom character.  If the problem continues please send your video, custom character model, and this error to Support for help.",

  // Base 1100: Render CLI
  RenderCliError: "Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  InvalidInputParameterError: "Sorry, we had an internal error while rendering the result into a video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadOrPlayInputVideoError: "Sorry, we had an internal error while loading your input video or image. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadInputBvhError: "Sorry, we had an internal error while loading the BVH file for video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToLoadInputCharacterError: "Sorry, we had an internal error while loading your character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToAttachAnimToCharacterError: "Sorry, we had an internal error attaching your animation to the character for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToConfigureBackDropError: "Sorry, we had an internal error while configuring the backdrop for the video/image rendering. Please send your video, custom character model (if any), and this error to Support for help.",
  FailedToCreateGifError: "Sorry, we had an internal error while rendering GIF output. Please send your video, custom character model (if any), and this error to Support for help."
})

export function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

export function createMarkup(content) {
  return {__html: content};
}

export const jobMenu = Object.freeze({
  animSettings:     0,
  videoSettings:    1
})

export const browserCookieIds = Object.freeze({ 
  1: 'ln',          
  2: 'connect.sid',
  3: '_dm_acc'
})

// Checking against some common HTTP error codes...
export const eCodes = Object.freeze({
  // client side errors 
  BadRequest:               400,          
  Unauthorized:             401,            
  Forbidden:                403,
  NotFound:                 404, 
  RequestTimeout:           408,
  ClosedNoResponse:         444,
  ClientClosedRequest:      499,
  // server side errors
  InternalServerError:      500,
  BadGateway:               502,
  ServiceUnavailable:       503,
  GatewayTimeout:           504,
  InsufficientStorage:      507,

  OtherError:               1000
})

// OKTA API error codes...
export const oktaErrorCodes = Object.freeze({
  // client side errors 
  None:           '',
  AccountExists:  'E0000001',
  UnsupportedOp:  'E0000060'
});

// Custom Error Codes
export const customErrors = Object.freeze({
  FBXImporterInitializeFailure: "FBX File Error",
  FBXImportFileFailure: "Model Import Failure",
  FBXSceneIntegrityVerificationFailure: "FBX Scene Integrity Failure",
  FBXJointNameHasSpace: "Model Joint Names Have Spaces",
  FBXJointNameNotUnique: "Model Joint Names Are Not Unique",
  FBXAssetBelowGround: "Model Below Ground",
  FBXMultipleJointRoot: "Multiple Joint Roots Found",
  FBXNoRiggedMesh: "No Mesh Found in Model",
  AutoMappingMandatoryJointsNotMapped: "Required Joints Missing or Invalid",
  AutoMappingJointsNotUnique: "Model Joint Mappings Not Unique",
  CurrentPoseNotTPose: "Model's Default Pose Must Be T-Pose",
  TemplateSceneInvalid: "Problem With FBX Scene",
  TemplateTgtMapInvalid: "Model Template Map Invalid",
  TemplateTgtMapNotMatchingMultibody: "Model Template Map Error",
  VideoToAnimProcessingError : "Error processing video2anim",
  GCPVideoCopyError : "Error in copying video file from GCS"
});

// Tool tips for various job settings & options:
export const toolTipShadow = "Renders shadows in the MP4 output when enabled, otherwise no shadows will be included in the output."
export const toolTipIncludeVid = "Shows the input motion clip (or image for static pose jobs) in the background when turned on (enabling this option disables the Custom Background option)."
export const toolTipIncludeAud = "When enabled includes the audio of the original input video in the generated animation. Disabling this option disables audio in output animation."
export const toolTipMp4 = "Enable this option to create MP4 output files for your animations. Enabling this option significantly increases animation processing time (unavailable if FBX Output is disabled)."
export const toolTipFbx = "Enable this option to create FBX output files for your animations (automatically enabled for jobs that use a customer character, have Face Tracking enabled, or have MP4 output enabled)."
export const toolTipJpg = "Enable this option to create a JPG output file for your static pose."
export const toolTipPng = "Enable this option to create a PNG output file for your static pose."
export const toolTipGlb = "GLB output files are automatically generated for jobs that use a custom character and disabled for jobs that use the default character set."
export const toolTipFBXKeyFrame = "Lowers key frame frequency and converts the result into a file format that supports interpolation while also reducing file size (may not work for all inputs)."
export const toolTipPhysSim = "Reinforces stronger joint limits and attempts to remove self-collisions and clipping."
export const toolTipFaceTrack = "Turn on to track basic facial expressions. Compatible with default characters, custom characters created through Animate 3D and uploaded character rigs that contain ARKit blendshapes. Enabling this option increases processing time."
export const toolTipChangeVideo = "Remove video"
export const toolTipSmoothness = 'Applies an advanced AI filter that helps remove jitter and produce smoother animations though may result in lower animation accuracy for certain frames or sequences.<br /><span class="dm-brand-font"> [Available for Professional or higher plans]</span>'
export const toolTipVideSpeedMult = 'For input videos that have been slowed down enabling this option can help improve the resulting animation quality. <br/><br/>For example, if your input video speed moves at 1/2 speed then set the speed multiplier to 2x to improve animation quality. <span class="dm-brand-font"> [Available for Innovator or higher plans]</span>'
export const toolTipRootJoint = "Place a root joint at the origin for the output character. This is helpful in some cases, for example, for UE4 retargeting. (Only available for animations that use default characters)."
export const toolTipMp4BackType = '<strong>Off</strong>: Uses online previewer scene for the MP4 background<br/><strong>Solid</strong>: Sets MP4 background to single color (ie "green-screen")<br/><strong>Studio</strong>: Sets MP4 background to a 3D Studio environment<br/>(unavailable if <strong>Include Original Video</strong> enabled)'
export const toolTipMp4BackColor = 'Sets MP4 background color when <strong>Custom Background</strong><br/>option is set to either <strong>Solid</strong> or <strong>Studio</strong>'
export const tooltipFootLocking = '<strong>Auto</strong>: Automatic switching between locking and gliding modes of the foot (recommended for general cases).<br/><br/><strong>Never</strong>: Force foot locking off all the time. Recommended for motions that are completely in the air or in water. <span class="dm-brand-font">[Available for Innovator or higher plans]</span><br/><br/><strong>Always</strong>: Force foot locking on all the time. Recommended when <em>Auto</em> mode cannot remove all foot gliding issues. <span class="dm-brand-font">[Available for Professional or higher plans]</span><br/><br/><strong>Grounding</strong>: Turns foot locking off however character grounding is still enforced. Recommended when foot gliding is desired or when <em>Auto</em> mode locks the feet for too long during fast leg movements. <span class="dm-brand-font">[Available for Professional or higher plans]</span>'
export const tooltipMp4Camera = '<strong>Cinematic</strong>: The character is kept in the center of the frame.<br/><strong>Fixed</strong>: Camera will stay fixed relative to the background.<br/><strong>Face</strong>: Camera keeps the torso and face in the center of frame.'
export const tooltipReRun = 'Re-run job with different settings to potentially improve your animation results.<div class="notification is-info is-light mt-3"><span>Re-running an animation does <strong class="has-text-info">not</strong> deduct any additional time from your account and is intended to help improve your existing animations.</span></div>'

export const mp4EnableSplitTitle = "Include Original Video"
export const mp4EnableSplitTitlePose = "Include Original Image"

// breakpoints for mobile and tablet devices (supported through useWindowSize.js hook)
export const mobileWidth = 767
export const tabletWidth = 1023
export const fullHDWidth = 1407
export const largeDisplayWidth = 2560

// minimum form field length requirements
export const minNameLength = 2
export const minCompanyLength = 3
export const minOtherLength = 5
export const minPwdLength = 5

// css classes for ok or missing required fields and icon spans
export const normalInputClass = "input"
export const missingInputClass = "input is-danger"
export const normalSelectClass = "select"
export const missingSelectClass = "select is-danger"
export const normalTextAreaClass = "textarea"
export const missingTextAreaClass = "textarea is-danger"
export const inputSpanHide = "icon is-small is-right hide"
export const inputSpanShow = "icon is-small is-right show"
export const inputSpanShowSuccess = "icon is-small is-right show success-icon"

// minimum character length for feedback form title and description
export const minInputLength = 3

// minimum 
export const minJobIdLength = 19

// reg expression used for email input validation 
// Note: eslint comment below is used to disable compiler warnings!
//eslint-disable-next-line
export const validEmailRegex = RegExp(/^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i)
export const validNameRegex = RegExp(/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,30}$/u)
// number of mins before timeout due to inactivity
export const idleTimeoutInMins = 180

//************************************************************ 
// Returns a new form field state object in following format:
//
//  {
//    "value": "<value>",   // any valid JS object
//    "isValid": <boolean>, // is field ready for submit?
//    "span": "<string>",   // controls form icon display
//    "iClass": "<string>"  // controls form css class
//  }
//
//************************************************************/
export const buildStateObj = ( value, isValid, span, iClass ) => {
  const newStateObj = {
    "value": value,
    "isValid": isValid,
    "span": span,
    "iClass": iClass
  }
  return newStateObj 
}

//************************************************************ 
// Performs a deep compare of two state objects defined above
//************************************************************/
export const compareStates = ( obj1, obj2 ) => {
  if(
    obj1.value === obj2.value &&
    obj1.isValid === obj2.isValid &&
    obj1.span === obj2.span &&
    obj1.iClass === obj2.iClass
  ) {
    return true
  }
  else {
    return false
  }
}

// different states for the feedback submission form
export const FORM_STATE = Object.freeze({
  "ready":0, 
  "inProgress":1, 
  "success":2, 
  "failure":3
})

export const pageState = Object.freeze({
  "mount":                  0,
  "init":                   1, 
  "ready":                  2,
  "apiInProgress":          3,
  "renameModelDialog":      4,
  "savingModel":            5,
  "modelCreatedDialog":     6,
  "modelUploadedDialog":    7,
  "modelDeletedDialog":     8,
  "rerunAnimSettings":      9,
  "rerunVideoSettings":    10,
  "rerunInputInfo":        11
})

// Enum for library column names
export const libraryColName = Object.freeze([
  "name",
  "length",
  "size",
  "date"
])

// breadcrumb names for standard anim job
export const createAnimBreadCrumbs = Object.freeze([
  "Character Type",
  "3D Model",
  "Motion Video",
  "Job Settings"
])

// breadcrumb names for custom anim job
export const libraryBreadCrumbs = Object.freeze([
  "Library",
  "Preview"
])

// various ui states for creating a new job
export const uiStates = Object.freeze({
  "initial":                0, 
  "fileSelected":           1,
  "jobInProgress":          2,
  "jobQueued":              3
})

export const interestOptions = [
  "Game Development",
  "Social Media Content Creation",
  "For use in Film or TV",
  "Streaming / Live Broadcast",
  "Other"
]

export const hearFromOptions = [
  '',
  'VRWorldTech',
  'Venture Beat',
  'Intel DevMesh',
  'Web Search',
  'Twitter',
  'LinkedIn',
  'Facebook',
  'Instagram',
  'YouTube',
  'Reddit',
  'Medium',
  'Word of mouth',
  'Other'
]

export const accountPlansInfo = Object.freeze([
{
  name:"Freemium",
  price:"Free",
  priceId:"",   // n/a
  productId:"", // n/a
  callToAction:"SIGN UP",
  info:"no credit card required",
  mins:"30 animation seconds / month, for free!",
  minsInt:.5,
  fps:"Max 30 FPS input videos",
  resolution:"Max HD (720p) input videos",
  models:"Store up to 3 Custom Characters",
  modelsInt:3,
  uploadLimitInBytes: 50000000,
  maxVideoDurationInSec: 10,
  storage:"No Storage Limits for Animations",
  output:"Export FBX, BVH, GLB, and MP4",
  vidOutput:"DeepMotion Branded MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Personal Use",
  url:"https://portal.deepmotion.com/animate-3d/sign-up",
  tagClass:"tag is-light is-medium",
  skuColor:"rgb(250, 176, 60)"
},
{
  name:"Starter",
  price:"$9",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_STARTER_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_STARTER_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"180 animation seconds / month",
  minsInt:3,
  fps:"Max 30 FPS input videos",
  resolution:"Max Full-HD (1080p) input videos",
  models:"Store up to 5 Custom Characters",
  modelsInt:5,
  uploadLimitInBytes: 100000000,
  maxVideoDurationInSec: 20,
  storage:"No Storage Limits",
  output:"Export FBX, BVH, GLB, and MP4",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-success is-medium",
  skuColor:"rgb(72, 199, 142)"
},
{
  name:"Innovator",
  price:"$20",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_INNOVATOR_ID,
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_INNOVATOR_ID,
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"480 animation seconds / month",
  minsInt:8,
  fps:"Max 60 FPS input videos",
  resolution:"Max Full-HD (1080p) input videos",
  models:"Store up to 10 Custom Characters",
  modelsInt:10,
  uploadLimitInBytes: 200000000,
  maxVideoDurationInSec: 30,
  storage:"No Storage Limits",
  output:"Export FBX, BVH, GLB, and MP4",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-primary is-medium",
  skuColor:"rgb(0, 209, 178)"
},
{
  name:"Professional",
  price:"$55",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_PROFESSIONAL_ID, 
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_PROFESSIONAL_ID, 
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"1500 animation seconds / month",
  minsInt:25,
  fps:"Max 120 FPS input videos",
  resolution:"Max 4K (2160p) input videos",
  models:"Store up to 20 Custom Characters",
  modelsInt:20,
  uploadLimitInBytes: 400000000,
  maxVideoDurationInSec: 120,
  storage:"No Storage Limits",
  output:"Export FBX, BVH, GLB, and MP4",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-info is-medium",
  skuColor:"rgb(32, 156, 238)"
},
{
  name:"Studio",
  price:"$180",
  productId: process.env.REACT_APP_PRODUCT_ANIMATION3D_STUDIO_ID, 
  priceId: process.env.REACT_APP_PRICE_ANIMATION3D_STUDIO_ID, 
  callToAction:"BUY NOW",
  info:"per user, per month",
  mins:"7200 animation seconds / month",
  minsInt:120,
  fps:"Max 120 FPS input videos",
  resolution:"Max 4K (2160p) input videos",
  models:"Store up to 50 Custom Characters",
  modelsInt:50,
  uploadLimitInBytes: 400000000,
  maxVideoDurationInSec: 240,
  storage:"No Storage Limits",
  output:"Export FBX, BVH, GLB, and MP4",
  vidOutput:"MP4 video output",
  privacy:"Private uploads",
  rights:"Licensed for Commercial Use",
  url:"/checkout",
  tagClass:"tag is-link is-medium",
  skuColor:"rgb(50, 115, 220)"
},
{
  name:"Enterprise",
  tagClass:"tag is-link is-medium",
  skuColor:"rgb(50, 115, 220)"
}

])

export function sec2time(timeInSeconds) {
    var pad = function(num, size) { return ('000' + num).slice(size * -1); },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60),
    milliseconds = time.slice(-3);

    return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
}

export function capitalizeFirstLetter(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/********************************************************** 
 * A3D Admin Tool values:
 **********************************************************/ 

export const currentDatabase = Object.freeze({
  development:    0,
  production:     1
})

export const activeTab = Object.freeze({
  apiUser:        "API Users",
  customPack:     "Custom Packs",
  portalUser:     "Portal Users",
  listJobs:       "List Jobs",
  listModels:     "List Models",
  bulkOperations: "Bulk Operations",
  generalSupport: "General Support"
})

export const packTableType = Object.freeze({
  minutePack:         "Minute Packs",
  featurePack:        "Feature Packs"
})

// Active modal Enum for API User Management Tab
export const activeModal = Object.freeze({
  none:                   null,
  createCredential:       1,
  updatePaymentPlan:      2,
  addMinutePack:          3,
  setFeaturePack:         4,
  requestBillData:        5,
  viewBillData:           6,
  viewApiUserSecret:      7,
  createMinutePack:       8,
  createFeaturePack:      9,
  packDetailView:         10,
  successModal:           11,
  portalPayStartDate:     12,
  modifyPortalPlan:       13,
  portalAddMinutePack:    14,
  portalSetFeaturePack:   15,
  portalTempMinute:       16,
  packConfirmation:       17,
  dateConfirmation:       18,
  tempMinConfirmation:    19,
  credConfirmation:       20,
  packCreateConfirmation: 21,
  success:                22,
  confirmBulkDeactivate:  23,
  loadingModal:           24,
  failureModal:           25
})

export const FTESteps = Object.freeze({
  begin:              0,
  reviewGuidelines:   1,
  addMotionClip:      2,
  selectModel:        3, 
  choseJobSettings:   4
})

export const cameraMotionSettings = Object.freeze([
  'cinematic',
  'fixed',
  'face'
])

export const footLockMode = Object.freeze({
  auto:      'auto',
  never:     'never',
  always:    'always',
  grounding: 'grounding'
})

export const mp4BkgdOption = Object.freeze({
  off:       'off',
  solid:     'solid',
  studio:    'studio'
})

export const dropDownLabels = Object.freeze({
  footLockMode: 'Foot Locking',
  mp4BkgdOption: 'Custom Background',
  cameraMotion: 'Camera Mode',
  motionSmoothing: 'Motion Smoothing',
  videoSpeedMultiplier: 'Speed Multiplier'
})

export const jobTypes = Object.freeze({
  animation: 0,
  staticPose: 1,
  animationText: "3D Animation",
  staticPoseText: "3D Pose"
})

// default anim job state object
export const defaultGSColor = [0,177,64,1]
export const robloxModelId = "roblox_r15_block"
export const customModelObj = Object.freeze({
  id: null,
  name: 'Default Characters',
  size: null,
  modelUrl: null,
  thumbUrl: null,
  thumbImg: null
})
export const JOB_SETTINGS_TEMPLATE = Object.freeze(
  {
    backdrop: false,
    bgColor: defaultGSColor,
    camMode: cameraMotionSettings[0],
    customModelInfo: JSON.parse(JSON.stringify(customModelObj)),
    includeAudio: true,
    faceDataType: 0,
    filtering: true,
    footLockMode: footLockMode.auto,
    formats: {  // output animation format options
      'bvh':true,           
      'fbx':true,
      'gif':false,
      'mp4':false,
      'jpg':false,
      'png':false,
      'dmpe':false
    },
    greenScreen: false,
    jobId: null,
    jobType: jobTypes.animation,
    keyframeReducer: false,
    physicsSim: false,
    rootJointAtOrigin: 0,
    sbs: true,
    shadow: true,
    poseFilteringStrength: 0.0,
    trackFace: 0,
    videoSpeedMultiplier: 1.0
  }
)

export const textAcceptedClipTypes = "Accepted file types: .mp4, .mov, .avi"
export const textAcceptedImgTypes = "Accepted file types: .jpg, .png, .gif, .bmp"

export const tooltipVideoMaxSizeLimits = `Max single video length (i.e. duration) for all plans: <div class="notification is-info is-light mt-3"><span><ul><li>${accountPlansInfo[0].name} : ${(accountPlansInfo[0].uploadLimitInBytes/1000000).toString() + " MB"}</li><li>${accountPlansInfo[1].name} : ${(accountPlansInfo[1].uploadLimitInBytes/1000000).toString() + " MB"}</li><li>${accountPlansInfo[2].name} : ${(accountPlansInfo[2].uploadLimitInBytes/1000000).toString() + " MB"}</li><li>${accountPlansInfo[3].name} : ${(accountPlansInfo[3].uploadLimitInBytes/1000000).toString() + " MB"}</li><li>${accountPlansInfo[4].name} : ${(accountPlansInfo[4].uploadLimitInBytes/1000000).toString() + " MB"}</li></ul></div>`
export const tooltipVideoDurationLimits = `Max single video upload sizes for all plans: <div class="notification is-info is-light mt-3"><span><ul><li>${accountPlansInfo[0].name} : ${(accountPlansInfo[0].maxVideoDurationInSec).toString() + " seconds"}</li><li>${accountPlansInfo[1].name} : ${(accountPlansInfo[1].maxVideoDurationInSec).toString() + " seconds"}</li><li>${accountPlansInfo[2].name} : ${(accountPlansInfo[2].maxVideoDurationInSec).toString() + " seconds"}</li><li>${accountPlansInfo[3].name} : ${(accountPlansInfo[3].maxVideoDurationInSec).toString() + " seconds"}</li><li>${accountPlansInfo[4].name} : ${(accountPlansInfo[4].maxVideoDurationInSec).toString() + " seconds"}</li></ul></div>`

// list of tips to display during video upload
export const tipList = Object.freeze([
  { 
    title: "Model Default Pose",
    content: "The default pose must be a T-pose and it must be exported in a T-pose to be used in Animate3D." 
  },
  {
    title: "Model Joint Names",
    content: "Names must be unique and without any spaces."
  },
  {
    title: "Physics Filter Usage",
    content: "Your character must be sized to typical human proportions with a height of 1 to 2 meters." 
  },
  {
    title: "Model File Requirements",
    content: "Only include the character mesh and rigging information."
  },
  {
    title: "Model Foot Placement",
    content: "The feet of the character must be placed on the origin plane." 
  },
  {
    title: "Joint Advice",
    content: "Avoid using scale on the joints; if you need to scale up the whole skeleton apply it at a root node." 
  },
  {
    title: "Model Requirements",
    content: "Only models with one (1) root joint are supported." 
  },
  {
    title: "Model File Requiements",
    content: "Only one (1) character model must be in the custom character file."
  },
  {
    title: "Camera Placement for Footage",
    content: "The camera should be stationary and parallel to your subject."
  },
  {
    title: "Recording the Subject",
    content: "The entire body or the upper body (head to waist) should be visible and located 2-6 meters (6-20 feet) from the camera."
  },
  {
    title: "Subject Lighting",
    content: "Neutral lighting with high contrast between the subject and the background is recommended."
  },
  {
    title: "Recording the Subject",
    content: "The single subject should not be occluded by any objects in the motion clip."
  },
  {
    title: "Before Recording",
    content: "Do not wear loose clothing or clothing that obscures key joints like knees and elbows."
  },
  {
    title: "Face Tracking",
    content: "Face Tracking is supported for full body and half body tracking modes; for better results half body is recommended."
  }
])