import React from 'react'

const title = "Queued Job Found"
const mainText = "We found a job currently processing or queued, please wait..."

/*================================================== 
  [FUNCTIONAL COMPONENT]
  Displays queued UI for currently queued jobs
 ===================================================*/
export default function JobQueuedUI(props) {
  return (
    <div className="section pt-0 m-0">
      <div className="columns m-0">
        <div className="column bShadow mt-2">
          <div className="columns">
            <div className="column rounded-corners-top has-text-centered dm-brand bottom-border">
              <h1 className="title is_1 has-text-white">{title}</h1>
            </div>
          </div>
          <div className="columns rounded-corners-bottom dm-brand ">

            <div className="column has-text-centered">
              <p className="subtitle is-4 has-text-white" ><span className="icon icon-pulse has-text-white"><i className="fas fa-info-circle fa-md mr-3"></i></span> {mainText}</p>
            </div>
          </div>
        </div>  
      </div>
    </div>
  )
} 