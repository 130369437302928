import React, { useContext, useEffect } from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { Link } from 'react-router-dom'
import { AppStateContext } from '../../../AppStateContext'
import * as Enums from '../../common/enums'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import LoadingScreen from '../../common/LoadingScreen'
import HelmetPageData from '../../common/HelmetPageData'

const docTitle = "3D Previewer | DEEPMOTION"
const metaDesc = "Preview your generated animations using our online 3d previewer."

/******************************************************************
   Previewer - used to preview selected Task result
 ******************************************************************/
export default function Previewer(props) {

  const [previewSource, setPreviewSource] = React.useState(null)
  const [eventListenersAttached, setEventListenersAttached] = useStateWithCallbackLazy(false)
  const appStateContext = useContext(AppStateContext)

  /////////////////////////////////////////////////////////////////////
  function addLocalEventListeners() {
    if( !eventListenersAttached ) {
      window.addEventListener("load", onLoad)
      window.addEventListener("beforeunload", onUnload)
      setEventListenersAttached(true, ()=>{ return })
    }
  }
  /////////////////////////////////////////////////////////////////////
  function removeLocalEventListeners() {
    if( eventListenersAttached ) {
      window.removeEventListener("load", onLoad)
      window.removeEventListener("beforeunload", onUnload)
      setEventListenersAttached(false, ()=>{ return })
    }
  }

  /////////////////////////////////////////////////////////////////////
  // React function mount/unmount
  /////////////////////////////////////////////////////////////////////
  function mountComponent() {
    addLocalEventListeners()
    if( !appStateContext.state.animJobLinks && (!appStateContext.state.animJobId || appStateContext.state.animJobId === "") ) {
      props.openAnimationPreviewer(appStateContext.state.animJobId)
    }
    else {
      let jobLinks = appStateContext.state.animJobLinks.previewLinks   
      jobLinks.frameMaps = appStateContext.state.animJobLinks.frameMaps
      setPreviewSource(`${process.env.REACT_APP_PREVIEWER_URL}?src=${JSON.stringify(jobLinks)}`)
    }
  }
  /////////////////////////////////////////////////////////////////////
  function unmountComponent() {
    removeLocalEventListeners()
    // reset src links and preview data before un-mounting
    setPreviewSource(null)
    if(previewSource === null ) {
      appStateContext.dispatch({displayPreview: false, animJobLinks: null})
    }
  }
  /////////////////////////////////////////////////////////////////////
  // Save current animJobId to state on page/browser refresh
  /////////////////////////////////////////////////////////////////////
  function saveStateToLocalStorage(val, cb) {
    // to maintain state across page refresh we temporarily save all
    // state data to local storage, allow page refresh to happen,
    // then read back from storage and remove once complete
    let dataStr = JSON.stringify(val)
    localStorage.setItem(Enums.localStorageStateId, dataStr)
    if( cb ) {
      cb()
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Restores state from browser's local storage if key found
  /////////////////////////////////////////////////////////////////////
  function checkAndRestoreStateIfCached() {
    const localStorageData = localStorage.getItem(Enums.localStorageStateId)
    if( localStorageData !== null ) {
      const tmpData = JSON.parse(localStorageData)
      localStorage.removeItem(Enums.localStorageStateId)
      appStateContext.dispatch(tmpData) 
    }
  }

  function openDownloadModal() {
    if( props.jobContainsGLBDownloadLinks() ) {
      appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.customDownload})
    }
    else {
      appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.standardDownload})
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for page un-load in progress
  /////////////////////////////////////////////////////////////////////
  function onUnload(e) {
    // only backup state to local storage if user on an authenticated route
    // (ie. includes '/dashboard' in the path)
    saveStateToLocalStorage( {animJobId: appStateContext.state.animJobId}, () => {
      // don't return until saving to local storage complete
      return
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for re-loading this component from page refresh
  /////////////////////////////////////////////////////////////////////
  function onLoad(e) {
    checkAndRestoreStateIfCached()
  }

  // custom react hook that handles regular mounting of this component and
  // also handles browser refresh in conjunction saving to localstorage usage
  useEffect(() => {
    if( appStateContext.state.anim3dInitialized && 
        appStateContext.state.accountDataRetrieved && 
        appStateContext.state.animJobLinks && 
        Object.keys(appStateContext.state.animJobLinks).length !== 0 &&
        appStateContext.state.animJobId && appStateContext.state.animJobId !== ""
    ) { 
      mountComponent()
    }
    else if( appStateContext.state.anim3dInitialized && 
        appStateContext.state.accountDataRetrieved && 
        (appStateContext.state.animJobLinks === null || 
        Object.keys(appStateContext.state.animJobLinks).length === 0) &&
        appStateContext.state.animJobId && appStateContext.state.animJobId !== "" ) {
      props.openAnimationPreviewer(appStateContext.state.animJobId)
    }
  },[ appStateContext.state.animJobLinks, 
      appStateContext.state.anim3dInitialized, 
      appStateContext.state.accountDataRetrieved,
      appStateContext.state.animJobId ])

  // ensure loading bar does not display during preview
  useEffect(() => {
    if( previewSource ) {
      props.setLOADING({...props.LOADING, ...{show: false}})
    }
  },[previewSource])

  // hook for un-mount
  useEffect(() => () =>
    unmountComponent()
  ,[])

  // special hook that helps handle browser refresh on Preview route
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      addLocalEventListeners()
      props.initializeA3DService()
    }
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  /***************************************************************
   *** build page
   ***************************************************************/
  function buildPreviewerPage() {
    if( !appStateContext.state.animJobLinks || !Object.keys(appStateContext.state.animJobLinks).length ) {
      return <div />
    }
    return (
      <div id="anim-fadein" className="column">
        <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
        <AnimVersionPanel pageTitle={props.pageTitle}/>
        {/********************* Unity Previewer ********************/}
        <div className="container">
            <div className="columns is-paddingless">
              <div className="column has-text-centered">
                {/*****************************************/}
                {/*** Main Previewer rendered here...   ***/}
                {/*****************************************/}
                <iframe width="1100" height="700" src={previewSource} allowFullScreen>
                  {/* default message in case browser does not support iFrames */}
                  <p>Your browser does not support iframes.</p>
                </iframe>
              </div>
            </div>
        </div>
        {/**********************************************************/}
        <div className="columns has-text-centered m-0 p-1">
          <div className="column m-0 p-0">
            <h2 className="title is-4">{appStateContext.state.animJobLinks.downloadLinks.fileName}</h2>
            <div className="buttons is-block">
              <button className="button download-btn is-normal" onClick={()=>openDownloadModal()}><span>Download</span><span className="icon is-small"><i className="fas fa-file-download"></i></span></button>
              <button className="button download-btn is-normal" onClick={()=>props.displayReRunModal(appStateContext.state.animJobId)}><span>Rerun</span><span className="icon is-small"><i className="fas fa-redo-alt"></i></span></button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  /***************************************************************
   *** return() method
   ***************************************************************/
  return(
    <React.Fragment key="previewer-page">
      {
        props.LOADING.show
        ?
        <LoadingScreen />
        :
        buildPreviewerPage()
      }
    </React.Fragment>
  )
}