////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// Backend API requests (using axios)
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { oktaAuthConfig, oktaSignInConfig } from '../../config'
import axios from 'axios'
import * as Enums from '../common/enums'

const oktaAuth = new OktaAuth(oktaAuthConfig)
const useCredentials = (process.env.REACT_APP_NODE_ENV === 'production' || process.env.REACT_APP_NODE_ENV === 'staging' )
// axios interceptor to catch potential CORS/networking failures (since those)
// do not return any http response code
axios.interceptors.response.use((response) => response, (error) => {
  if (typeof error.response === 'undefined') {
    //TODO: Show branded dialog instead of native browser alert dialog?
    alert('A network error occurred. '
        + 'This could be a dropped internet connection or a potential CORS issue.')
  }
  return Promise.reject(error)
})

////////////////////////////////////////////////////////////////////////////////////
// retrieve user's account plan / subscription info
////////////////////////////////////////////////////////////////////////////////////
export const retrieveUserProfileInfo = () => {
  return new Promise((resolve, reject) => {
    oktaAuth.getUser()
    .then( async data => {
      if( !data.email_verified ) {
        // email must be verified
        console.error(`Problem with account encountered, email address has not been verified!`)
        reject('Error - Account email has not been verfied.')
      }
      else {
        try {
          const token = await oktaAuth.tokenManager.get('idToken')
          const token2 = await oktaAuth.tokenManager.get('accessToken')
          const reqBody = {idToken: token.idToken, accessToken: token2.accessToken}
          axios.post(`${process.env.REACT_APP_API_URL}/accountPlanInfo`, reqBody, { 
            withCredentials: useCredentials
          }).then(data => {
              // success
              resolve(data)
            }).catch( (error) => {
              console.error(`Could not retrieve user info -> ${error}`)
              reject(error)
          })
          .catch( (error) => {
            console.error(`Error retrieving account plan info -> ${error}`)
            reject(error)
          })
        }
        catch( error ) {
          console.error(`Problem reading auth token -> ${error}`)
          reject(error)
        }
      }
    })
  })
}

/////////////////////////////////////////////////////////////////////
// Retrieves a session cookie to be used in subsequent API
// requests to the backend pose estimation service
/////////////////////////////////////////////////////////////////////
export const getNewPEServiceToken = () => {
  return new Promise(async (resolve, reject) => {
    // read access token from browser's local storage...
    const accessToken = await oktaAuth.tokenManager.get('accessToken')
    if( accessToken && !oktaAuth.tokenManager.hasExpired(accessToken) ) {
      // Use access token retrieved by okta auth SDK to authenticate against the 
      // PE (Pose Estimation) backend service. Upon success a session cookie is 
      // created which is used to authenticate subsequent requests to the service
      axios.get(`${process.env.REACT_APP_PE_URL}/session/okta`, {
        headers: { 'Authorization': "Bearer " + accessToken.accessToken },
        withCredentials: true
      }).then(res => {
          // success
          resolve('ok')
      }).catch( (error) => {
        console.error('Error -> unable to retrieve a new PE service token.')
        reject('Error -> unable to retrieve a new PE service token.')
      })
    } else {
      // Token has been removed due to expiration or error while renewing
      console.error(`Access token expired or could not be renewed.`)
      const signout = await oktaAuth.signOut()
      return
    }
  })
}

/////////////////////////////////////////////////////////////////////
// Retrieves the account plan data included current mins
/////////////////////////////////////////////////////////////////////
export const getUserPlanData = (retry) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/account/getUserData`, {  
      withCredentials: true
    })
    .then((res) => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(getUserPlanData(false)))
        }
        else {
          console.error(`Problem getting user plan data -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem getting user plan data -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

/////////////////////////////////////////////////////////////////////
// Retrieves list of account jobs for the signed in user
/////////////////////////////////////////////////////////////////////
export const getJobsDataForAccount = (retry) => {
  return new Promise(async (resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/emojis/SUCCESS`, {
      withCredentials: true
    })
    .then((res) => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(getJobsDataForAccount(false)))
        }
        else {
          console.error(`Problem retrieving jobs data for account -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem retrieving jobs data for account -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// API request to retrieve custom characters info for the account
////////////////////////////////////////////////////////////////////
export const getAccountCustomCharacters = (retry) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/character/listModels`, {
      withCredentials: true
    })
    .then(res => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(getAccountCustomCharacters(false)))
        }
        else {
          console.error(`Problem retrieving account characters data -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem retrieving account characters data -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// downloads model images (thumbs) from passed in url
////////////////////////////////////////////////////////////////////
export const downloadModelThumbnails = (data) => {
  return new Promise(async (resolve, reject) => {
    const modelsList = data.charactersList
    let thumbnailBlobsArray = []
    for( let i = 0; i < modelsList.length; i++ ) {
      // wait for async download request to finish in each loop iteration
      const thumbnailUrl = decodeURI(modelsList[i].thumb)
      await axios.get(thumbnailUrl, {
        responseType: 'blob' // download PNG image modelsList as blob
      }).then(res => {
        thumbnailBlobsArray.push(res.data)
      }).catch( (error) => {
        // possible no thumbnail exists for certain cases such as older accounts
        // created before thumbnail feature, hence don't reject the Promise
        thumbnailBlobsArray.push(null)
      })
    }
    let returnObj = data
    // add downloaded image data for each character to account models list
    for( let i = 0; i < returnObj.charactersList.length; i++ ) {
      returnObj.charactersList[i].thumbImg = thumbnailBlobsArray[i]
    }
    resolve({accountTotals: returnObj})
  })
}

////////////////////////////////////////////////////////////////////
// API request to retrieve custom characters info for the account
////////////////////////////////////////////////////////////////////
export const getLinksForJob = (rid, retry) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/download/${rid}`, {
      withCredentials: true
    })
    .then(res => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(getLinksForJob(rid, false)))
        }
        else {
          console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// Retrieves a signed URL for model upload
////////////////////////////////////////////////////////////////////
export const getModelUploadUrl = (fileName, retry) => {
  return new Promise((resolve, reject) => {
    let reqData = {
      name: fileName,
      modelExt: "fbx" // default
    }
    // set model file extension param for custom glb and gltf uploads
    if( Enums.getFileExtension(fileName).toLowerCase() === Enums.animFileType.glb /* || 
        Enums.getFileExtension(fileName).toLowerCase() === Enums.animFileType.gltf */ ) {
      reqData.modelExt = Enums.getFileExtension(fileName)
    }
    axios.get(`${process.env.REACT_APP_PE_URL}/character/getModelUploadUrl?name=${reqData.name}&modelExt=${reqData.modelExt}`, { 
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    }).then(res => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(getModelUploadUrl(fileName, false)))
        }
        else {
          console.error(`Problem retrieving model upload url for ${fileName} -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Could not retrieve download links for job ${rid} -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// Store custom model (character) assets paths into backend DB
////////////////////////////////////////////////////////////////////
export const storeCharacterAssetsInDB = (modelUrl, thumbUrl, modelName, retry) => {
  return new Promise((resolve, reject) => {
    // construct the request body for the request
    const requestBody = JSON.stringify({
      modelUrl: modelUrl,
      //TODO: Add ability for user to upload valid thumbnail in future, otherwise
      //by not passing thumbUrl backend will generate a thumbnail image
      // thumbUrl: thumbUrl,
      modelName: modelName
    })
    //+++ Make the POST request to the backend:
    axios.post(`${process.env.REACT_APP_PE_URL}/character/storeModel`, requestBody, { 
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    })
    .then(res => { 
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve( storeCharacterAssetsInDB(modelUrl, thumbUrl, modelName, false) ))
        }
        else {
          console.error(`Problem saving character assets to the cloud -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem saving character assets to the cloud -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// Calls several backend APIs to add newly created custom characters
// generated from ReadyPlayerMe to user's account
//
// @param retry : if we should retry on auth error encountered
////////////////////////////////////////////////////////////////////
export const addNewModelToAccount = (rpmCharacterUrl, modelName, retry) => {
  return new Promise((resolve, reject) => {
    // STEP 1: Retrieve signed URL for model upload
    // GET {host}/character/getModelUploadUrl
    axios.get(`${process.env.REACT_APP_PE_URL}/character/getModelUploadUrl`, {
      withCredentials: true
    }).then(res => {
      const modelUrl = res.data.modelUrl
      const thumbUrl = res.data.thumbUrl
      delete axios.defaults.headers.post["Content-Type"];
      axios.post(modelUrl, null, {
        headers: {
          "x-goog-resumable": "start"
        }
      })
      .then(res => {
        storeCharacterAssetsInDB(rpmCharacterUrl, thumbUrl, modelName, true )
        .then( res => {
          if( Enums.getFileExtension(modelUrl) === 'glb' ) {
            // if adding a new custom glb model refresh the account's 
            // character list after once the model is added
            getAccountCustomCharacters(true)
            .then( res => {
              // resolve the promise
              resolve(res.data)
            })
            .catch( error => {
              reject(error)
            })
          }
          else {
            // update modelId state in parent for potential use in the
            // next animation job the user creates

            // let tmpStateObj = STATE.animJobSettings.customModelInfo
            let tmpStateObj = {customModelInfo: {}}
            tmpStateObj.customModelInfo.id = res.data.modelId
            tmpStateObj.customModelInfo.modelUrl = modelUrl
            tmpStateObj.customModelInfo.thumbUrl = res.data.thumbUrl
            getAccountCustomCharacters(true)
            .then( res => {
              resolve(res.data)
            })
          }
        })
        .catch( (error) => {
          console.error(`Problem saving character assets to the backend - ${error}`)
          reject(error)
        })
      }).catch( (error) => {
        console.error(`Problem retrieving signed model URI for uploading - ${error}`)
        reject(error)
      })
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve( addNewModelToAccount(rpmCharacterUrl, modelName, false) ))
        }
        else {
          console.error(`Problem saving character assets to the cloud -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem saving character assets to the cloud -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// API request to remove a given job from user's account permanently
////////////////////////////////////////////////////////////////////
export const removeCustomModelFromAccount = (modelId, retry) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_PE_URL}/character/deleteModel/${modelId}`, {
      withCredentials: true
    }).then(res => {
      resolve( res )
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(removeCustomModelFromAccount(modelId, false)))
        }
        else {
          console.error(`Problem removing model ${modelId} from account -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Problem removing model ${modelId} from account -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}



////////////////////////////////////////////////////////////////////
// Uploads a custom 3d model (provided by the user)
//
// @param url : the signed gcp URL to upload the model to
// @param file : the model asset file
////////////////////////////////////////////////////////////////////
export const uploadCustomModel = (url, file, retry) => {
  return new Promise((resolve, reject) => {
    delete axios.defaults.headers.post["Content-Type"]
    axios.put( url, file, {
      processData: false,
      contentType: false
    })
    .then(res => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve( uploadCustomModel(url, file, retry) ))
        }
        else {
          console.error(`Error encountered uploading custom model -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered uploading custom model -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// Retrieves a signed upload url for input videos/images
//
// @param fileName : the input video or image file
////////////////////////////////////////////////////////////////////
export const uploadJobDataToBackend = (fileName, fileData, retry) => {
  return new Promise((resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/upload?name=${fileName}`, {
      withCredentials: true
    })
    .then(res => {
      const signedUploadUrl = res.data.url
      delete axios.defaults.headers.post["Content-Type"];
      axios.post( signedUploadUrl, null, { 
        headers: {
          "x-goog-resumable": "start"
        }
      })
      .then(res => { // then print response status
        const newUrl = res.headers.location
        delete axios.defaults.headers.post["Content-Type"];
        axios.put( newUrl, fileData, {
          processData: false,
          contentType: false
        })
        .then(res => { 
          // success
          let responseData = res
          responseData.videoStorageUrl = signedUploadUrl
          resolve(responseData)
        })
      })
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve( uploadJobDataToBackend(fileName, fileData, false) ))
        }
        else {
          console.error(`Error encountered uploading job input data -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered uploading job input data -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

/////////////////////////////////////////////////////////////////////
// Starts processing a new animation/state job
/////////////////////////////////////////////////////////////////////
export const startNewAnimOrPoseJob = (requestData, retry) => {
  return new Promise(async (resolve, reject) => {
    axios.post(`${process.env.REACT_APP_PE_URL}/process`, JSON.stringify(requestData), { 
      headers: {
        "Content-Type": "application/json"
      },
      withCredentials: true
    })
    .then(res => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(startNewAnimOrPoseJob(requestData, false)))
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered trying to start new job -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

/////////////////////////////////////////////////////////////////////
// Attempts to stop an in progress job
/////////////////////////////////////////////////////////////////////
export const stopInProgressJob = (animJobId, retry) => {
  return new Promise(async (resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/cancel/${animJobId}`, {
      withCredentials: true
    }).then(res => {
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(stopInProgressJob(animJobId, false)))
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered trying to start new job -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// API request to retrieve custom characters info for the account
////////////////////////////////////////////////////////////////////
export const analyzeVideoInputData = (videoStorageUrl, retry) => {
  return new Promise((resolve, reject) => {
    const data = {url: videoStorageUrl}
    axios.post(`${process.env.REACT_APP_PE_URL}/videoInfo`, data, {
      withCredentials: true
    }).then(res => {
      resolve( res )
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(analyzeVideoInputData(videoStorageUrl, false)))
        }
        else {
          console.error(`Error encountered trying to start new job -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered trying to start new job -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// API request to remove a given job from user's account permanently
////////////////////////////////////////////////////////////////////
export const removeJobFromAccount = (rid, retry) => {
  return new Promise((resolve, reject) => {
    axios.delete(`${process.env.REACT_APP_PE_URL}/emojis/${rid}`, {
      withCredentials: true
    }).then(res => {
      resolve( res )
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(removeJobFromAccount(rid, false)))
        }
        else {
          console.error(`Error encountered trying to delete job ${rid} from account -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered trying to delete job ${rid} from account -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

/////////////////////////////////////////////////////////////////////
// Checks for jobs that are either currently in progress or queued
/////////////////////////////////////////////////////////////////////
export const checkJobStatus = (animJobId, retry) => {
  return new Promise(async (resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/status/${animJobId}`, {
      withCredentials: true
    })
    .then((res) => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(checkJobStatus(animJobId, false)))
        }
        else {
          console.error(`Error encountered checking job status for ${animJobId} -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered checking job status for ${animJobId} -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}


/////////////////////////////////////////////////////////////////////
// Checks for jobs that are either currently in progress or queued
/////////////////////////////////////////////////////////////////////
export const checkForInProgressOrQueuedJobs = (retry) => {
  return new Promise(async (resolve, reject) => {
    axios.get(`${process.env.REACT_APP_PE_URL}/emojis/PROGRESS`, {
      withCredentials: true
    })
    .then((res) => {
      // success
      resolve(res)
    })
    .catch((error) => {
      if( error.response && error.response.status === Enums.eCodes.Unauthorized ) {
        if( retry ) {
          console.log(`Warning: PE service token has expired, attempting to renew...`)
          getNewPEServiceToken()
          .then( res => resolve(checkForInProgressOrQueuedJobs(false)))
        }
        else {
          console.error(`Error encountered checking for in-progress or queued jobs -> ${error}`)
          reject(error)
        }
      }
      else {
        console.error(`Error encountered checking for in-progress or queued jobs -> ${error}`)
        if( error.response && error.response.data ) {
          console.error(`${error.response.data.error} : ${error.response.data.message}`)
        }
        reject(error)
      }
    })
  })
}

////////////////////////////////////////////////////////////////////
// API request to get customers latest subscription info
////////////////////////////////////////////////////////////////////
export const listSubscriptions = (customerId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {idToken: token.idToken, customerId: customerId}
      axios.post(`${process.env.REACT_APP_API_URL}/list-subscriptions`, reqBody, { 
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not retrieve subscription info -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}

////////////////////////////////////////////////////////////////////////
// Generates url and redirects user to their respective Customer Portal
////////////////////////////////////////////////////////////////////////
export const redirectToCustomerPortal = (userInfo) => {
  return new Promise(async (resolve, reject) => {
    const token = await oktaAuth.tokenManager.get('idToken')
    const reqBody = {
      idToken: token.idToken,
      email: userInfo.email,
      customerId: userInfo.customerId
    }
    axios.post(`${process.env.REACT_APP_API_URL}/profileCheckoutCallback`, reqBody, {
      withCredentials: useCredentials
    })
    .then((res) => {
      resolve(res)
      // ==> Upon success user will be redirected to their customer portal page
      // window.location.href = res.data
    })
    .catch((error) => {
      console.error(`Error attempting to redirect to customer portal -> ${error}`)
      reject(error)
    })
  })
}


////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
// +++++++++ Admin APIs +++++++++
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////
export const listApps = () => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {idToken: token}
      axios.post(`${process.env.REACT_APP_API_URL}/admin/listApps`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not list apps -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const listUserData = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {idToken: token, userData: data}
      axios.post(`${process.env.REACT_APP_API_URL}/admin/listUserData`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not list user data -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const listUsers = (data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {idToken: token, clientId: data}
      axios.post(`${process.env.REACT_APP_API_URL}/admin/listUsers`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could list users -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const createApp = (appName) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        uid: token.claims.sub,
        appName: appName
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/createApp`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not create app -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const setBillingStartDate = (cid, uid, startDate) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        "users": [{
          "clientId": cid,
          "uid": uid,
          "billingStartDate": startDate
        }]
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/setBillingStartDate`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not set billing start date -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const addMinutesPack = (cid, uid, packId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        clientId: cid,
        uid: uid,
        packId: packId
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/addMinutesPack`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not add minutes pack -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const addFeaturesPack = (cid, uid, packId) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        clientId: cid,
        uid: uid,
        packId: packId
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/addFeaturesPack`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not add features pack -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const getBillingData = (cid, cycle, duration, details) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        clientId: cid,
        cycle: cycle,
        duration: duration,
        details: details
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/getBillingData`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not get billing data -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}
////////////////////////////////////////////////////////////////////
export const setMinuteBalance = (cid, uid, mins, addTo) => {
  return new Promise(async (resolve, reject) => {
    try {
      const token = await oktaAuth.tokenManager.get('idToken')
      const reqBody = {
        idToken: token,
        clientId: cid,
        uid: uid,
        minutes: mins,
        addToExisting: addTo
      }
      axios.post(`${process.env.REACT_APP_API_URL}/admin/setMinuteBalance`, reqBody, {
        withCredentials: useCredentials
      }).then(data => {
          // success
          resolve(data)
        }).catch( (error) => {
          console.error(`Could not set minutes balance -> ${error}`)
          reject(error)
      })
    }
    catch( error ) {
      console.error(`Problem reading auth token -> ${error}`)
      reject(error)
    }
  })
}