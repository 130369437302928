import React, { useRef, useContext, useEffect } from 'react'
import { Link, useHistory } from "react-router-dom"
import {Helmet} from "react-helmet"
import { AppStateContext } from '../../AppStateContext'
import axios from 'axios'
import AnimVersionPanel from '../common/AnimVersionPanel'
import LoadingScreen from '../common/LoadingScreen'
import * as Enums from './enums'

export default function Support(props) {

  // browser history
  let history = useHistory()
  const appStateContext = useContext(AppStateContext)

  //************************************************************ 
  // State variables for support form entries: 
  //************************************************************
  const [supportTitle, setSupportTitle] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [supportDesc, setSupportDesc] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const [missingFieldsMessage, setMissingFieldsMessage] = React.useState("")
  const [submitStatus, setSubmitStatus] = React.useState(Enums.FORM_STATE.ready)

  // SEO 
  const docTitle = "Support | DEEPMOTION"
  const metaDesc = "Submit any support requests you may have here. Thanks for your interest in DeepMotion!"

  // form strings
  const submitButton    = "Submit"
  const tryAgainButton  = "Try Again"
  const backButton      = "Home"
  const goToDash        = "Back To Dashboard"
  const formTitle       = "Product Support" 
  const formText        = "Please include any important information and details regarding your support request" 
  const field_1_Title   = 'Title'
  const field_2_Title   = 'Details'
  const inProgressTitle = "Submitting request..."
  const successTitle    = "Thank you for your request!"
  const successSub1     = "We've received your request and will review it soon..."
  const successSub2     = "-The DeepMotion Team"
  const errorTitle      = "Sorry, something went wrong..."
  const errorSubtitle   = "We encountered an error submitting your request, unable to connect to server."
  const errorSubtitle2  = "If the problem continues please contact DeepMotion Support via e-mail."

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  function submitSupportForm() {
    if (isFormReadyForSubmission()) {
      setSubmitStatus(Enums.FORM_STATE.inProgress)

      // code to send info to backend to create email
      let reqBody = {
        uid: props.uid,
        email: props.email,
        title: supportTitle.value,
        content: supportDesc.value,
        subInfo: props.subscriptionInfo
      }
      axios.put(`${process.env.REACT_APP_API_URL}/support`, reqBody, {
        withCredentials: true
      })
      .then((res) => {
        console.log(`Support ticket has been sent.\n${res}`)
        setSubmitStatus(Enums.FORM_STATE.success)
        return
      })
      .catch((error) => {
        console.error(error)
        setSubmitStatus(Enums.FORM_STATE.error)
      })
    }
  }

  /*
   * onClick() validation event for Support Title field
   */
  const validateSupportTitle = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass
    )

    // sanitize value
    tmpStateObj.value = Enums.removeIllegalOrReservedCharacters(tmpStateObj.value)

    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minInputLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //*** finally we update the state with latest data
    if( !Enums.compareStates(supportTitle,tmpStateObj) ) {
      setSupportTitle(tmpStateObj)
    }
  }

  /*
   * onClick() validation event for support description 
   */
  const validateSupportDescription = event => { 
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass
    )

    // sanitize value
    tmpStateObj.value = Enums.removeIllegalOrReservedCharacters(tmpStateObj.value)

    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minInputLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setSupportDesc(tmpStateObj)
  }

  /*
   * highlight for support title field when field loses focus
   * through the onBlur() event
   */
  const highlightSupportTitleError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      supportTitle.value,
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setSupportTitle(tmpStateObj)
  }

  /*
   * highlight for support description field when field loses focus
   * through the onBlur() event
   */
  const highlightSupportDescriptionError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      supportDesc.value,
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    setSupportDesc(tmpStateObj)
  }

  /* 
   * Returns true if all form field values are ready for submission,
   * false otherwise 
   */
  function isFormReadyForSubmission() {
    if( !supportTitle.value || !supportDesc.value ) {
      return false
    }
    // check state variables for validating each form field
    let formFields = [
      { "name": "Support Title", "value": supportTitle.isValid },
      { "name": "Support Text", "value": supportDesc.isValid }
    ]
    let formStatus = true
    let printString = "Required form fields missing/invalid:\n"

    formFields.forEach(function (aItem) {
      if( !aItem.value ) {
        formStatus = false
        printString += ("\t- " + aItem.name + "\n")
      }
    });

    ////////////////////////////////////////////////////
    if( !formStatus ) {
      // form not ready 
      // console.log(printString)
      setMissingFieldsMessage(printString)
      return false
    }
    ////////////////////////////////////////////////////
    else {
      // console.log(`Form is ready`)
      return true
    }
    ////////////////////////////////////////////////////
  }

  /* 
   * Reset form and all state variables after form submission
   */
  function resetForm() {
    // console.log(`Resetting support form...`)
    // reset all form field values to null/default:
    setSupportTitle( 
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass) 
    )
    setSupportDesc(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
    )
    setMissingFieldsMessage("")
    setSubmitStatus(Enums.FORM_STATE.ready)
  }

  /* 
   * Builds the main signup for Animate 3D which is designed
   * to be mobile responsive:
   */
  function buildSupportForm() {

    /*********************************************
     * Render the Support Form:
     *********************************************/
    return (
      <div className="" style={{paddingTop:'15px'}}>

        {/*** Move title above form for mobile ***/}
        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>
        <h1 className="subtitle is-5 animate-header" style={{marginBottom:'35px'}}>{formText}</h1>

        {/*** DISPLAY MISSING FIELDS MESSAGE ***/}
        {supportTitle.iClass === Enums.missingInputClass || supportDesc.iClass === Enums.missingTextAreaClass
        ?
        <div className="field">
          <p className="help is-danger">{missingFieldsMessage}</p>
        </div>
        :
        <div className="field"></div>
        }

        {/*** SUPPORT TITLE ***/}
        <div className="field">
          <label className="label">{field_1_Title}</label>
          <div className="control has-icons-right">
            <input className={supportTitle.iClass} onChange={validateSupportTitle} onBlur={highlightSupportTitleError} type="text" placeholder="Title" />
            <span className={supportTitle.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>
      

        {/*** SUPPORT TEXT AREA ***/}
        <div className="field">
          <label className="label">{field_2_Title}</label>
          <div className="control has-icons-right">
            <textarea className={supportDesc.iClass} onChange={validateSupportDescription} onBlur={highlightSupportDescriptionError} type="textarea" placeholder="Description"/>
            <span className={supportDesc.span}>
              <i className="fas fa-check"></i>
            </span>
          </div>
        </div>

        {/*** [SUBMIT] button ***/}
        <div className="field mgTop-20">
          <div className="control">
            <button onClick={submitSupportForm} className="button is-medium preview-btn">{submitButton}</button>
          </div>
        </div>

        {/* back button */}
        <div className="field">
          <div className="control">
            <Link to={Enums.routes.Dash}> Back </Link>
          </div>
        </div>
      </div>
    )
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  /* 
   * Builds the progress bar column for when a submission is in-progress
   */
  function buildProgressDisplay() {
    return (
      <div className="Absolute-Center" >
        <div className="column has-text-centered vcenter-flex" style={{minHeight:'50vh',marginBottom:'0'}}>

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="subtitle is-4 has-text-black mgTop-50">{inProgressTitle}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column hcenter is-half">
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
  }

  /* 
   * Builds the submission successful display
   */
  function buildSubmitSuccessDisplay() {
    return (

      <div className="" style={{paddingTop:'15px'}}>

        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>

        <div className="column ">
          <div className="">
            <h1 className="title is-4 has-text-black mgTop-50">{successTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <span className="icon has-text-success">
                  <i className="fas fa-check fa-5x"></i>
                </span>
              </div>
            </div>    
            <h2 className="subtitle is-5 has-text-black"> {successSub1} </h2>
            <h2 className="subtitle is-5 has-text-black"> {successSub2} </h2>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{goToDash}</button>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  // 
  // Builds the submission error display
  //
  function buildSubmitErrorDisplay() {
    return (
      <div className="Absolute-Center" style={{paddingTop:'20px'}}>
        <div className="column" >
          <div className="">
            <h1 className="title is-3 has-text-black mgTop-50">{errorTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <span className="icon">
                  <i className="fas fa-exclamation-triangle fa-3x" style={{color:'orange'}}></i>
                </span>
              </div>
            </div>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle}</h2>
            <h2 className="subtitle is-4 has-text-black" >{errorSubtitle2}</h2>
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <div className="field is-grouped">
                  <div className="control">
                    <button onClick={()=>resetForm()} className="button preview-btn">{tryAgainButton}</button>
                  </div>
                  <div className="control">
                    <button onClick={()=>history.push(Enums.routes.Dash)} className="button preview-btn">{backButton}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  ///********************************************************************
  ///Builds the entire screen for both desktop and mobile
  ///********************************************************************/
  function BUILD_SCREEN() {
    switch( submitStatus ) {
      case Enums.FORM_STATE.ready:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildSupportForm()}

              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.inProgress:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildProgressDisplay()}
              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.success:
        // TODO: Copied code from company website form, need to refactor! 
        return (
          <div className="columns mgTop-20 has-text-centered form-col" style={{marginBottom:'0'}}>
            {buildSubmitSuccessDisplay()}
          </div>
        )
      
      case Enums.FORM_STATE.failure:
        return (

          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the support form: ***/}
                {buildSubmitErrorDisplay()}

              </div>
            </div>
          </div>  
        )
      
      default:
        return (<div></div>)
    }
  }

  React.useEffect(() => {
    let tmpStateObj = null
    //**************************************************
    //------ Validate Support Title Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      supportTitle.value,
      supportTitle.isValid,
      supportTitle.span,
      supportTitle.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(supportTitle,tmpStateObj) ) {
      setSupportTitle(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Support Description Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      supportDesc.value,
      supportDesc.isValid,
      supportDesc.span,
      supportDesc.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingTextAreaClass ) {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(supportDesc,tmpStateObj) ) {
      setSupportDesc(tmpStateObj)
    }
  },[
    supportTitle,
    supportDesc,
    missingFieldsMessage,
    submitStatus
  ])

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  return (
    <div id="anim-fadein" className="column mt-0">
        {helmetData}
        <AnimVersionPanel />
        {
          props.LOADING.show
          ?
          <LoadingScreen />
          :
          BUILD_SCREEN()
        }
      </div>
)
}