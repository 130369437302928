import * as Enums from './enums'
export const initialAppState = Object.freeze({
  accessList: [false,false,false,false],
  accountTotals: {
    size: 0,
    time: 0,
    maxTimeInSeconds: 0,
    remainingTimeInSeconds: 0,
    currCycleMinsExpired: false,
    rerun_count: 0,
    max_rerun: 3,
    numCharacters: 0,
    characterLimit: 0,
    charactersList: null
  },
  accountDataRetrieved: false,
  anim3dInitialized: false,
  animationJobProgress: 0,
  animJobId: "",
  animJobLinks: null,
  animJobSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
  confirmDialogId: null,
  currDownloadModel: Enums.characterModels['1'].fileName,
  currDownloadLinks: {},
  currFTEStep: Enums.FTESteps.begin,
  currentBillCycleInfo: {
    usedRounded: 0,
    remainingRounded: 0,
    usedMonthlyTime: 0,
    remainingMonthlyTime: 0,
    usagePercent: 0,
    currCycleStartDate: "",
    currCycleEndDate: "",
    totalCurrCycleMins: 0
  },
  currPage: 1,
  currProductSelection: 0,
  currSortField: 'date',
  currSortDirection: 'down',
  currWorkflowStep: Enums.uiStates.initial,
  dialogInfo: {},
  displayPreview: null,
  email: null,
  errorDialogInfo: {
    show: false, 
    id: null,
    title: "",
    msg: ""
  },
  groups: [],
  inputVideoData: {
    selectedFile: null,
    fileName: null,
    fileLength: null,
    fileSize: null,
    videoRes: null,
    fps: null,
    codec: null
  },
  isFTEMode: false,
  isFTEFaceTrackingFirstEnable: false,
  isJobInProgress: false,
  jobsData: null,
  libraryInitialized: false,
  loading: false,
  firstName: null,
  lastName: null,
  numPages: 0,
  openFirstJob: false,
  rowsPerPage: 10,
  pageState_CharacterManage: Enums.pageState.mount,
  progressMsg: "",
  rerunSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
  sideMenuCollapsed: false,
  silentUploadInProgress: false,
  subscriptionInfo: {
    status: "",
    cid: null,
    name: "",
    current_period_start: "",
    current_period_end: "",
    cancel_at_period_end: false,
    cancel_at: "",
    isEnterpriseUser: false,
    plan_expiary_date: "",
    featurePacks: null
  },
  uid: null,
  videoFileName: null,
  videoStorageUrl: null
})