import React, {useState, useContext, useEffect} from 'react'
import { Link, useHistory } from 'react-router-dom'
import { AppStateContext } from '../../../AppStateContext'
import paginate from 'jw-paginate'
import ReactTooltip from 'react-tooltip'
import '@fortawesome/fontawesome-free/css/all.min.css'
import Previewer from './Previewer'
import DMDialog from '../../ui/DMDialog'
import DMToolTip from '../../ui/DMToolTip'
import * as Enums from '../../common/enums'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import HelmetPageData from '../../common/HelmetPageData'
// import ParticlesBackground from '../../common/ParticlesBackground'
import useWindowSize from '../../common/useWindowSize'
import imgLibraryHeader from '../../../images/dashboard/animate-3d.jpg'

// Page title, meta description, and strings
const docTitle = "Library | DEEPMOTION"
const metaDesc = "Preview and download previously created animations including FBX and MP4 formats."
const textMainMessage = "Find, preview, download, and rerun your Animate 3D account animations"
const textMainPageTitle = "Library"

const libraryHeroStyle = {
  backgroundImage: `url(${imgLibraryHeader})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`
}

const iconTypeAnim = 'fas fa-film'
const iconTypePose = 'far fa-image'

/***************************************************************
 * Library page which lists each users previously created anims
 ***************************************************************/
export default function Library( props ){

  const history = useHistory()
  const appStateContext = useContext(AppStateContext)
  let windowSize = useWindowSize()

  // handle component mount
  useEffect(() => {
    window.scrollTo(0, 0)
    if( !appStateContext.state.libraryInitialized || !appStateContext.state.jobsData ) {
      props.initializeLibrary()
    }
  }, [appStateContext.state.libraryInitialized, appStateContext.state.jobsData])

  // Updates the current page of the library
  function setLibraryPage(newPageId) {
    if( newPageId < 1 || newPageId > appStateContext.state.numPages) {
      return
    }
    appStateContext.dispatch({currPage: newPageId})
  }

  // updates number of rows per page to display
  const setDisplayRowsPerPage = event => {
    // target value from onChange() function will be a string so 
    // make sure to convert to integer before assigning state
    const newNumPages = Math.ceil(appStateContext.state.jobsData.length / parseInt(event.target.value))
    
    appStateContext.dispatch({
      // make sure current page is not beyond new page range...
      currPage: appStateContext.state.currPage > newNumPages ? newNumPages : appStateContext.state.currPage,
      numPages: newNumPages,
      rowsPerPage: parseInt(event.target.value)
    })
  }

  // Update the direction/field of the column sorting
  function updateColumnSort(dir, field) {
    // we only update state if it has changed since updating state is an async
    // call we want to avoid when un-neccessary for performance
    if( dir !== appStateContext.state.currSortDirection && field !== appStateContext.state.currSortField) {
      appStateContext.dispatch({currSortDirection: dir, currSortField: field})
    }
    else if( dir !== appStateContext.state.currSortDirection ) {
      appStateContext.dispatch({currSortDirection: dir})
    }
    else if( field !== appStateContext.state.currSortField ) {
      appStateContext.dispatch({currSortField: field})
    }
    else {/* nothing to update */ }
  }

  /********************************************************************* 
   * Updates library based on number of rows per page to display
   *********************************************************************/
  function buildRowsPerPageDropDown() {
    const rowsPerPage = [5,10,20,50,100] // 5 different rows per page values
    let options = []                     // holds the select's options 
    // loop through all entries in the rows array and dynamically 
    // build the select's options...
    rowsPerPage.forEach( numRows => {
      if( numRows === appStateContext.state.rowsPerPage ) {
        options.push(
          <option value={(appStateContext.state.rowsPerPage).toString()} key={numRows}>{appStateContext.state.rowsPerPage}</option>
        )
      }
      else {
        options.push(
          <option value={numRows.toString()} key={numRows}>{numRows}</option>
        )
      }
    })

    // return the newly created select UI component for rendering
    return (
      <span>
        <div className="select is-small" >
          <select value={(appStateContext.state.rowsPerPage).toString()} onChange={setDisplayRowsPerPage}>
            {options}
          </select>
        </div>
      </span>
    )
  }

  /********************************************************************* 
   * Builds the preview button for each job row
   *********************************************************************/
  function buildPreviewButton(rid) {
    return (
      <button className="button glow-on-hover btn-shadow" onClick={()=>props.openAnimationPreviewer(rid)}>
        <span className="icon-text">
          <span className="icon is-medium"><i className="far fa-play-circle fa-lg dm-brand-font"></i></span>
          {
            windowSize.width >= Enums.fullHDWidth
            &&
            <span className="title is-6 dm-brand-font"> Preview </span>
          }
        </span>
      </button>
    )
  }

  /********************************************************************* 
   * Columns component for the job table
   *********************************************************************/
  function buildJobTableColumn(columnClass, iconClass, label, content) {
    return(
      <div className={columnClass}>
        <nav className="level">
          <div className="level-item has-text-right">
            <div>
              <span className="icon is-large"><i className={iconClass}></i></span>
            </div>
          </div>
          <div className="level-item has-text-left">
            <div>
              <p className="heading">{label}</p>
              <p className="title is-4 dm-brand-font">{content}</p>
            </div>
          </div>
        </nav>
      </div>
    )
  }

  /********************************************************************* 
   * Builds the table of jobs including pagination etc 
   *********************************************************************/
  function buildJobsTable() {
    if( !appStateContext.state.jobsData ) {
      return <h1 className="subtitle is-4 dm-brand-font">Error loading jobs data!</h1>
    }

    let jobsForCurrentPage = []
    let index = 0
    let titleRowSortIcons = []
    let sortHoverClasses = []

    let titleSizeClass = "is-5"
    if( windowSize.width < Enums.fullHDWidth ) {
      titleSizeClass = "is-6"
    }

    // By default all columns are sortable in the up direction except
    // for the currently selected column which will be up or down
    // based on state
    let clickDirs = ['up','up','up','up']
    let fieldNames = ['name','length','size','date']

    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    // ==> Loop through jobs data and find jobs info to display based
    // on the currently selected page and number of rows per page
    //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
    for( let i = 0; i < appStateContext.state.rowsPerPage; i++) {
      index = i + ((appStateContext.state.currPage-1)*appStateContext.state.rowsPerPage)
      if( index > appStateContext.state.jobsData.length-1 ) {
        // break loop if we reach the last job in the account
        break
      }

      let actionsObj = {}
      actionsObj.jobId = appStateContext.state.jobsData[ index ].rid
      actionsObj.jobType = appStateContext.state.jobsData[ index ].jobType
      actionsObj.name = appStateContext.state.jobsData[ index ].name
      actionsObj.length = appStateContext.state.jobsData[ index ].length
      actionsObj.size = appStateContext.state.jobsData[ index ].size
      actionsObj.date = appStateContext.state.jobsData[ index ].date
      actionsObj.dateRaw = appStateContext.state.jobsData[ index ].dateRaw
      if( appStateContext.state.jobsData[ index ].customModel === "standard" ) {
        actionsObj.characters = "default"
      }
      else {
        const modelData = props.getModelDataById(appStateContext.state.jobsData[ index ].customModel)
        // if model data undefined then user may have deleted the custom character
        // this job was originally created with from their account!
        actionsObj.characters = !modelData ? "(Custom)" : modelData.name
      }

      let jobName = actionsObj.name
      if ( jobName.includes('.') ) {
        jobName = jobName.split('.').slice(0, -1).join('.')
      }

      //////////////////////////////////////////////////////////////////
      // 1. Populate jobs data array for display in table when we render
      //////////////////////////////////////////////////////////////////
      jobsForCurrentPage.push(
        <tr key={"job-id-"+index} >
          <td className="has-text-centered br-0 has-background-link-light">
            <div>
              <span className="icon is-medium">
                <i className={ (actionsObj.jobType ? iconTypePose : iconTypeAnim) + " fa-3x dm-brand-font"}
                  data-for={actionsObj.dateRaw.toString()}
                  data-border={true}
                  data-border-color="black"
                  data-tip
                  data-text-color="#2d4e77"
                  data-background-color="white"
                >
                <ReactTooltip className="tip-max-w" id={actionsObj.dateRaw.toString()} place="right" effect="solid">
                  <div className="subtitle has-text-left dm-brand-font">
                    { actionsObj.jobType ? "3D Pose (Beta)" : "3D Animation" }
                  </div>
                </ReactTooltip>
                </i>

                </span>
            </div>
          </td>
          <td className="has-text-left library-name-col br-0">
            <h5 className="title m-0 px-2 pb-1 is-5"> {jobName} </h5>
            <h6 className="subtitle m-0 px-2 is-6 dm-gray"> {actionsObj.characters} </h6>
          </td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.length.toFixed(0) + " sec"}</h5></td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.size}</h5></td>
          <td className="has-text-left bx-0"><h5 className={`subtitle ${titleSizeClass}`}>{actionsObj.date}</h5></td>
          <td className="has-text-centered bx-0">
            <h5 className={`subtitle ${titleSizeClass}`}>
              {buildPreviewButton( actionsObj.jobId )}  
            </h5>
          </td>
          <td className="has-text-centered bl-0">
            <h5 className={`subtitle ${titleSizeClass}`}>
              {props.buildActionsDropDown(appStateContext.state.jobsData[ index ].rid, appStateContext.state.jobsData[ index ]) }
            </h5>
          </td>
        </tr>
      )
    }

    //////////////////////////////////////////////////////////////////
    // 2. Calculate page data range for display at bottom of library
    //////////////////////////////////////////////////////////////////
    let lowRange = ((appStateContext.state.currPage-1)*appStateContext.state.rowsPerPage) + 1
    let highRange = ((appStateContext.state.currPage-1)*appStateContext.state.rowsPerPage) + appStateContext.state.rowsPerPage
    if( highRange > appStateContext.state.jobsData.length ) {
      highRange = appStateContext.state.jobsData.length
    }

    //////////////////////////////////////////////////////////////////
    // 3. Handle Column Sorting - When a column is NOT sorted the default 
    // sort direction is UP which is displayed when the user hovers over 
    // that column. Otherwise for the column that is currently sorted the 
    // direction and icon will be based on value of state vars 
    // appStateContext.state.currSortDirection & currSortField
    //////////////////////////////////////////////////////////////////
    if( appStateContext.state.currSortDirection === "down" ) {
      for( let i = 0; i < fieldNames.length; i++ ) {
        if( fieldNames[i] === appStateContext.state.currSortField ) {
          sortHoverClasses.push("has-background-link-light")
          titleRowSortIcons.push(<span className="icon mgLeft-5"><i onClick={()=>updateColumnSort("up",fieldNames[i])} className="fas fa-arrow-circle-down fa-lg dm-brand-font"></i></span>)
        }
        else {
          // adding the sort-hover class hides the icon until it's hovered over 
          sortHoverClasses.push("sort-hover has-text-left has-background-link-light")
          titleRowSortIcons.push(<span className="icon mgLeft-5"><i onClick={()=>updateColumnSort("up",fieldNames[i])} className="fas fa-arrow-up fa-lg dm-brand-font"></i></span>)
        }
      }
    }
    else {
      for( let i = 0; i < fieldNames.length; i++ ) {
        if( fieldNames[i] === appStateContext.state.currSortField ) {
          titleRowSortIcons.push(<span className="icon mgLeft-5"><i onClick={()=>updateColumnSort("down",fieldNames[i])} className="fas fa-arrow-circle-up fa-lg dm-brand-font"></i></span>)
          sortHoverClasses.push("has-background-link-light")
          clickDirs[i] = 'down'
        }
        else {
          titleRowSortIcons.push(<span className="icon mgLeft-5"><i onClick={()=>updateColumnSort("up",fieldNames[i])} className="fas fa-arrow-up fa-lg dm-brand-font"></i></span>)
          sortHoverClasses.push("sort-hover has-text-left has-background-link-light")
        }
      }
    }

    // finally we return the JSX for the entire Library page
    return (
      <div className="section py-0 px-2 mb-6">
        
        <LibraryHeroSection />

        {/* Show empty state if no existing jobs found */}
        { appStateContext.state.jobsData.length === 0 
          ?
          <div className="section">
            <div className="columns">
              <div className="column has-text-centered notification is-warning is-light">
                <h2 className="title is-2">Your Library is Empty</h2>
                <h2 className="subtitle is-3">Start creating animations and they will show up here...</h2>
              </div>
            </div>
          </div>
          :
          <React.Fragment>

            {/* The actual list of animation jobs */}
            <table className="table br-4 mt-4 mb-6 bShadow is-bordered dm-brand-border-md is-fullwidth is-hoverable is-info is-light" style={{borderCollapse: 'inherit'}}>
              <thead>
                <tr>
                  <th className="has-background-link-light has-text-centered py-4 px-1 br-0"> <h5 className={`title ${titleSizeClass}`}> Type </h5> </th>
                  <th className={sortHoverClasses[0] + " py-3 px-1 br-0"} onClick={()=>updateColumnSort(clickDirs[0],fieldNames[0])}><h5 className={`title ${titleSizeClass}`}> Name {titleRowSortIcons[0]} </h5></th>
                  <th className={sortHoverClasses[1] + " py-3 px-1 bx-0"} onClick={()=>updateColumnSort(clickDirs[1],fieldNames[1])}><h5 className={`title ${titleSizeClass}`}> Length {titleRowSortIcons[1]}</h5> </th>
                  <th className={sortHoverClasses[2] + " py-3 px-1 bx-0"} onClick={()=>updateColumnSort(clickDirs[2],fieldNames[2])}><h5 className={`title ${titleSizeClass}`}> Size {titleRowSortIcons[2]}</h5></th>
                  <th className={sortHoverClasses[3] + " py-3 px-1 bx-0"} onClick={()=>updateColumnSort(clickDirs[3],fieldNames[3])}><h5 className={`title ${titleSizeClass}`}> Date {titleRowSortIcons[3]}</h5></th>
                  <th className="has-background-link-light bx-0 py-4 px-1 has-text-centered"><h5 className={`title ${titleSizeClass}`}>3D Preview</h5></th>
                  <th className="has-background-link-light bl-0 px-1"><h5 className={`title ${titleSizeClass}`}>Actions</h5></th>
                </tr>
              </thead>
              <tbody>
                {/* Display jobs for the current page */}
                {jobsForCurrentPage}
              </tbody>
            </table>
            <div className="columns is-mobile">
            
              <div className="column is-half has-text-left">
                {/* Builds pagination controls including prev/next buttons */}
                {buildPaginationControls()}
              </div>

              {/* Results per page drop-down: */}
              <div className="column has-text-right">
                <h5 className="subtitle is-5">Show rows: {buildRowsPerPageDropDown()}</h5>
              </div>

              {/* Display current range of jobs: */}
              <div className="column has-text-left">
                <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {appStateContext.state.jobsData.length}</h5>
              </div>
            </div> 
          </React.Fragment>
        }
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Top Hero section for Library page
  ////////////////////////////////////////////////////////////////////
  function LibraryHeroSection() {
    if( !appStateContext.state.accountTotals ) {
      return <div>Loading...</div>
    }
    let totalLibraryAnimTime = "00:00:00"
    if( appStateContext.state.accountTotals.time ) {
      totalLibraryAnimTime = Enums.secondsToTime(Math.ceil(appStateContext.state.accountTotals.time))
    }
    if( totalLibraryAnimTime.substring(0,3) === "00:" ) {
      // only show minutes and seconds if less than 1 hour of time
      totalLibraryAnimTime = totalLibraryAnimTime.substring(3, totalLibraryAnimTime.length)
    }

    return (
      <div className="m-0 p-0 fullwidth">
        <div className="columns m-0 mb-6 p-0 bShadow br-4-top-left fullwidth"> 

          <div className="column is-one-quarter br-4-left m-0 p-0 dm-brand-border-md has-text-centered" style={libraryHeroStyle}>
          </div>

          <div className="column m-0 p-2 br-4-right dm-brand dm-brand-border-md has-text-centered">
            <div className="columns is-mobile m-0 p-0">
              <div className="column bottom-border m-0 p-1 has-text-centered">
                <h2 className="title is-3 has-text-white"> {textMainPageTitle} </h2>
              </div>
            </div>
            <div className="m-0 p-1">
              <div className="columns m-0 is-mobile">
                <div className="column m-0 p-2">
                  <div className="mt-5">
                    <div className="columns">
                      <div className="column disp-grid box my-0 mx-2" style={{backgroundColor:'transparent'}}>
                        {/* <span className="icon is-medium"><i className="far fa-play-circle fa-2x"></i></span> */}
                        <div className="title dm-brand-2-font is-3">
                          {appStateContext.state.jobsData.length}
                        </div>
                        <div className="subtitle has-text-white is-5">Animations</div>
                      </div>
                      <div className="column disp-grid box my-0 mx-2" style={{backgroundColor:'transparent'}}>
                        {/* <span className="icon is-medium"><i className="far fa-clock fa-2x"></i></span> */} 
                        <div className="title dm-brand-2-font is-3">
                        {totalLibraryAnimTime}
                        </div>
                        <div className="subtitle has-text-white is-5">
                          Total Time
                        </div>
                      </div>
                      <div className="column disp-grid box my-0 mx-2" style={{backgroundColor:'transparent'}}>
                        {/* <span className="icon is-medium"><i className="far fa-hdd fa-2x"></i></span> */} 
                        <div className="title dm-brand-2-font is-3">
                          {Enums.formatSizeUnits(Math.ceil(appStateContext.state.accountTotals.size))}
                        </div>
                        <div className="subtitle has-text-white is-5">
                          Total Size
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    )
  }

  /********************************************************************* 
   * Builds the pagination [Next] & [Previous] buttons 
   *********************************************************************/
  function buildPaginationPrevNextButtons() {
    // if only 1 page disable the previous and next buttons...
    if( appStateContext.state.numPages <= 1 ) {
      // disable both buttons
      return (
        <div>
          <a disabled className="pagination-previous">Previous</a>
          <a disabled className="pagination-next">Next page</a>
        </div>
      )
    }
    // else there are at least 2 total pages in the library...
    else {
      if( appStateContext.state.currPage === 1 ) {
        // disable [Previous] button when on first page
        return (
          <div>
            <a disabled className="pagination-previous">Previous</a>
            <a className="pagination-next" onClick={()=>setLibraryPage(appStateContext.state.currPage+1)} tabIndex="10" >Next page</a>
          </div>  
        )
      }
      else if (appStateContext.state.currPage === appStateContext.state.numPages) {
        // disable [Next] button when on last page
        return (
          <div>
            <a className="pagination-previous" onClick={()=>setLibraryPage(appStateContext.state.currPage-1)} tabIndex="10" >Previous</a>
            <a disabled className="pagination-next">Next page</a>
          </div>  
        )
      }
      else {
        // else both buttons are enabled
        return (
          <div>
            <a className="pagination-previous" onClick={()=>setLibraryPage(appStateContext.state.currPage-1)} tabIndex="10" >Previous</a>
            <a className="pagination-next" onClick={()=>setLibraryPage(appStateContext.state.currPage+1)} tabIndex="11" >Next page</a>
          </div>  
        )
      }
    }
  }

  /********************************************************************* 
   * Builds the pagination UI controls 
   *********************************************************************/
  function buildPaginationControls() {

    if( !appStateContext.state.jobsData.length ) {
      return
    }

    let buttonsList = []
    let pages = []
    let pageId = ""
    let pageGoTo = ""

    // we start by building a list of page buttons based on the 
    // number of total pages and the currently active page...
    for( let i = 1; i <= appStateContext.state.numPages; i++ ) {
      pageId = "Page "
      pageGoTo = "Go To Page "
      pageId.concat((i).toString())
      pageGoTo.concat((i).toString())
      if( i === appStateContext.state.currPage ) {
        // add is-current css class for currently selected page
        pages.push(
          <li key={i}>
            <a className="pagination-link is-current" onClick={()=>setLibraryPage(i)} tabIndex={i+1} aria-label={pageId} aria-current="page" key={i}>{i}</a>
          </li>
        )
      }
      else {
        // otherwise add normal page button
        pages.push(
          <li key={i}>
            <a className="pagination-link" onClick={()=>setLibraryPage(i)} tabIndex={i+1} aria-label={pageGoTo} key={i}>{i}</a>
          </li>
        )
      }
    }

    // show all pages if total <= MAX_PAGE_BUTTONS, otherwise show with ellipsis gaps...
    if( appStateContext.state.numPages <= Enums.MAX_PAGE_BUTTONS) {
      return (
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationPrevNextButtons()}
          <ul className="pagination-list">
            {pages}
          </ul>
        </nav>
      )
    }
    else {
      // else, more than MAX_PAGE_BUTTONS pages worth of jobs based on total jobs
      // and current rowsPerPage
      let paginationResult = paginate(
        appStateContext.state.jobsData.length, 
        appStateContext.state.currPage,
        appStateContext.state.rowsPerPage,
        Enums.MAX_PAGE_BUTTONS
      )

      let displayList = []
      for( let i = paginationResult.pages[0]; i <= paginationResult.pages[paginationResult.pages.length-1]; i++ ) {
        if( i === appStateContext.state.currPage ) {
          // add is-current css class for currently selected page
          displayList.push(
            <li key={i}>
              <a className="pagination-link is-current" onClick={()=>setLibraryPage(i)} aria-label={pageId} aria-current="page" key={i}>{i}</a>
            </li>
          )
        }
        else {
          // otherwise add normal page button
          displayList.push(
            <li key={i}>
              <a className="pagination-link" onClick={()=>setLibraryPage(i)} aria-label={pageGoTo} key={i}>{i}</a>
            </li>
          )
        }
      }

      return (
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationPrevNextButtons()}
          <ul className="pagination-list">
            {displayList}
          </ul>
        </nav>
      )
    }
  }

  function buildLibraryPage() {
    return (
      <React.Fragment>
        <div id="anim-fadein" className="column mt-0 has-text-left">
          <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
          <AnimVersionPanel />
          {
            props.LOADING.show
            ?
            <LoadingScreen />
            :
            <div className="pt-4 ml-2 mr-2 mb-6">
              {/***** Jobs Table *****/}
              {buildJobsTable()}
              {/**********************/}
            </div>
          }
        </div>
      </React.Fragment>
    )
  }

  // re-render library on certain state changes
  React.useEffect(() => {
    if( appStateContext.state.libraryInitialized ) {
      buildJobsTable()
    }
  }, [
    appStateContext.state.jobsData,
    appStateContext.state.currPage,
    appStateContext.state.rowsPerPage,
    appStateContext.state.numPages
  ])

  // re-render library on sorting
  React.useEffect(() => {
    if( appStateContext.state.libraryInitialized ) {
      props.sortLibraryByColumn()
    }
  }, [
    appStateContext.state.currSortDirection,
    appStateContext.state.currSortField
  ])

  return(
    <React.Fragment>
      {buildLibraryPage()}
    </React.Fragment>
  )
}