import React, {useContext, useRef} from 'react'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import { Link, withRouter, useHistory } from 'react-router-dom'
import DMDialog from '../ui/DMDialog'
// import { AppStateContext } from '../../AppStateContext'
import logo from '../../images/DM_Logo_WhiteWOrange.png'
import * as Enums from '../common/enums'

// strings
const animServiceTitle = 'Animate 3D'
const upgradeButton = 'Upgrade'

/***************************************************************************
 * Header.js - navigation bar header for whole DM Portal
 *
 * Receives |username|, |uid|, and |group| props for the authenticated user's 
 * group/product membership 
 ***************************************************************************/
export default function Header(props) {

  const history = useHistory()
  const [headerState, setHeaderState] = useStateWithCallbackLazy({
    active: false,
    navbarBurgerClassList: 'navbar-burger burger',
    navbarMenuClassList: 'navbar-menu',
    dropDownStatus: ""
  })
  const [isComponentMounted, setIsComponentMounted] = useStateWithCallbackLazy(false)

  //TODO: Was this being used in previous code base? Don't see a setWrapperRef() call?
  let wrapperRef = useRef(null)

  // component mount:
  React.useEffect( () => { 
    if( !isComponentMounted ) {
      document.addEventListener('mousedown', handleClickOutside), [] 
      setIsComponentMounted(true)
    }
  }, [isComponentMounted])

  // component un-mount:
  React.useEffect(() => {
    return function cleanup() {
      document.removeEventListener('mousedown', handleClickOutside)
      setHeaderState({
        active: false,
        navbarBurgerClassList: 'navbar-burger burger',
        navbarMenuClassList: 'navbar-menu',
        dropDownStatus: ""
      }, () => { 
        setIsComponentMounted(false, () => {
          return
        })
      })
    }
  }, [])

  function handleClickOutside(event) {
    if (wrapperRef && wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      // close drop down when click outside detected by removing
      // the "is-active" class from the <div>
      let currState = headerState
      currState.dropDownStatus = ""
      setHeaderState(currState, () => { return })
    }
  }

  ////////////////////////////////////////////////////////////////////////
  function BuildHeaderNavBar() {
    let navbarItems = []
    navbarItems.push(<div className="navbar-start" key="navbar-start"></div>)

    if( window.location.pathname.toString().includes(Enums.routes.CreateAccount)) {
      navbarItems.push(
        <div className="navbar-end dm-brand m-0" key="navbar-end">
          <div className="navbar-item has-text-centered has-text-white" style={{margin:'auto'}}>
            <a className="button header-btn" href="https://www.deepmotion.com" tabIndex="2">
              <span>Home</span>
            </a>
          </div>
        </div>
      )
      return (
        <div id="deepmotionNavbar" className={headerState.navbarMenuClassList}>
          {navbarItems}
        </div>
      )
    }

    if( !props.STATE.uid || !window.location.pathname.toString().includes(Enums.routes.Dash) ) {
      return (
        <div id="deepmotionNavbar" className={headerState.navbarMenuClassList}>
          {navbarItems}
        </div>
      )
    }

    else {
      navbarItems.push(
        <div className="navbar-end dm-brand m-0" key="navbar-end">
          {buildUpgradeNavButton()}
          <div className="navbar-item has-text-centered has-text-white my-auto mx-auto mr-0">
            {buildProfileMenuDropDown()}
          </div>
        </div>
      )
      return (
        <div id="deepmotionNavbar" className={headerState.navbarMenuClassList}>
          {navbarItems}
        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////////
  function buildUpgradeNavButton() {
    if( props.STATE.subscriptionInfo ) {
      if( props.STATE.subscriptionInfo.name === Enums.accountPlansInfo[0].name ) {
        return (
          <div className="navbar-item has-text-centered has-text-white" style={{margin:'auto'}}>
            <a className="button is-small nav-action" href="https://www.deepmotion.com/pricing" style={{fontWeight:'600'}} target="_blank" rel="noopener noreferrer">{upgradeButton}</a>
          </div>
        )
      }
    }
    else {
      return (<div></div>)
    }
  }

  ////////////////////////////////////////////////////////////////////////
  function buildProfileMenuDropDown() {
    // set dropdown to either active or not active based on state
    let ddClass = "dropdown profile-btn is-right " + headerState.dropDownStatus 
    let subName = props.STATE.subscriptionInfo.isEnterpriseUser ? Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].name : props.STATE.subscriptionInfo.name
    return (
      <div className={ddClass} onClick={updateDropDownStatus}>
        <div className="dropdown-trigger">
          <button className="profile-btn" aria-haspopup="true" aria-controls="profile-menu">
            <span className="icon is-medium">
              <i className="fas fa-user-circle fa-2x"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu menu-grow has-text-left" id="profile-menu" role="menu">
          <div className="dropdown-content">
            <a className="dropdown-item noHover">
              <h3 className="title is-6">{props.STATE.firstName} {props.STATE.lastName}</h3>
              <h4 className="subtitle is-6">{subName}</h4>
            </a>

            {/* Only show admin options for Admin users */}
            {
              props.STATE.groups.includes(process.env.REACT_APP_AG_ADMIN)
              &&
              <React.Fragment>
                <hr className="dropdown-divider" />
                <a className="dropdown-item" tabIndex="4" onClick={()=>history.push(Enums.routes.Admin)} >
                  <h4 className="subtitle is-6 dm-brand-font">
                  <span className="icon is-small mr-3"><i className="fas fa-user-lock fa-sm"></i></span>
                  Admin Tool
                  </h4>
                </a>
                <a className="dropdown-item" tabIndex="5" onClick={()=>history.push(Enums.routes.ActivityPage)} >
                  <h4 className="subtitle is-6 dm-brand-font">
                  <span className="icon is-small mr-3"><i className="fas fa-user-lock fa-sm"></i></span>
                  DRM Logs
                  </h4>
                </a>
              </React.Fragment>
            }

            <hr className="dropdown-divider" />
            <a className="dropdown-item" tabIndex="2" onClick={()=>history.push(Enums.routes.Anim3dProfilePage)}>
              <h4 className="subtitle is-6 dm-brand-font">Profile</h4>
            </a>
            <a className="dropdown-item" tabIndex="3" onClick={()=>props.DISPATCH({confirmDialogId: Enums.confirmDialog.exitApplication})} id="button-logout">
              <h4 className="subtitle is-6 dm-brand-font">Sign out</h4>
            </a>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  function updateDropDownStatus() {
    // flip the menu status state
    let currState = headerState
    if( headerState.dropDownStatus === "is-active" ) {
      currState.dropDownStatus = ""
    }
    else {
      currState.dropDownStatus = "is-active"
    }
    setHeaderState(prevState => ({...prevState, ...currState}), () => {
      return
    })
  }
  
  /////////////////
  // main render():
  /////////////////    
  return (
    <div ref={wrapperRef}>
      <nav className="navbar dm-brand" role="navigation" aria-label="main navigation" >
        <div className="navbar-brand">
          <a className="navbar-item">
            <div className="columns is-vcentered">
            <img src={logo} />
            </div>
          </a>

          { /* Display Animate 3D in Navbar if route contains /animate-3d */
            props.location.pathname.toString().includes('/animate-3d')
            &&
            <div className="navbar-item">
              <h2 className="title is-4" style={{fontColor:'#fab03c',color:'#fab03c'}}>{animServiceTitle}</h2>
            </div>
          }

          <a role="button" className={headerState.navbarBurgerClassList} aria-label="menu" aria-expanded="false" data-target="deepmotionNavbar">
            <span aria-hidden="true" className="has-text-white"></span>
            <span aria-hidden="true" className="has-text-white"></span>
            <span aria-hidden="true" className="has-text-white"></span>
          </a>
        </div>
        <BuildHeaderNavBar />
      </nav>
    </div>
  )
}