import React from 'react'
import paginate from 'jw-paginate'
import * as Enums from './enums'

// builds pagination controls
/* Prop list: ************************
 * totalPages                 (number of pages total)
 * setPageFunction            (set current page)
 * currTablePage              (the page it's currently on)
 * tableDataLength            (the number of entries in the table)
 * rowsToDisplay              (the number of rows to display)
 ************************************/
export function Pagination(props) {

  // set the current page
  function storeCurrTablePage(pageNum) {
    if (pageNum < 1 || pageNum > props.totalPages) {
      return
    }
    props.setPageFunction(pageNum)
  }

  // build pagination prev/next buttons
  function buildPaginationButtons() {
    // disable if only 1 page
    if (props.totalPages <= 1){
      return(
        <div>
          <a disabled className="pagination-previous">Previous</a>
          <a disabled className="pagination-next">Next</a>
        </div>
      )
    } 
    // builds pagination display
    else {
      // disable [Previous] on first page
      if (props.currTablePage === 1) {
        return(
          <div>
            <a disabled className="pagination-previous">Previous</a>
            <a className="pagination-next" onClick={()=>storeCurrTablePage(props.currTablePage+1)} tabIndex="10">Next</a>
          </div>
        )
      } else if (props.currTablePage === props.totalPages) {
        // disable [Next] on last page
        return(
          <div>
            <a className="pagination-previous" onClick={()=>storeCurrTablePage(props.currTablePage-1)} tabIndex="10">Previous</a>
            <a disabled className="pagination-next">Next</a>
          </div>
        )
      } else {
        // on a page somewhere in the middle, enable both
        return(
          <div>
            <a className="pagination-previous" onClick={()=>storeCurrTablePage(props.currTablePage-1)} tabIndex="10">Previous</a>
            <a className="pagination-next" onClick={()=>storeCurrTablePage(props.currTablePage+1)} tabIndex="11">Next</a>
          </div>
        )
      }
    }
  }

  function buildPaginationControls() {
    if (!props.tableDataLength || !props.currTablePage) {
      // return if there is no portal user data
      return
    }

    let pages = []
    let pageId = ""
    let pageGoTo = ""

    for (let i = 1; i <= props.totalPages; i++) {
      pageId = "Page "
      pageGoTo = "Go To Page "
      pageId.concat((i).toString())
      pageGoTo.concat((i).toString())
      if (i == props.currTablePage) {
        // current label for current page
        pages.push(
          <li key={i}>
            <a className="pagination-link is-current" onClick={()=>storeCurrTablePage(i)} tabIndex={i+1} aria-label={pageId} aria-current="page" key={i}>{i}</a>
          </li>
        )
      } else {
        // for all other pages
        pages.push(
          <li key={i}>
            <a className="pagination-link" onClick={()=>storeCurrTablePage(i)} tabIndex={i+1} aria-label={pageGoTo} key={i}>{i}</a>
          </li>
        )
      }
    }

    // display all page numbers if number of pages <= MAX_PAGE_BUTTONS
    if (props.totalPages <= Enums.MAX_PAGE_BUTTONS) {
      return(
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationButtons()}
          <ul className="pagination-list">
            {pages}
          </ul>
        </nav>
      )
    } else {
      // page number is higher than max page buttons, display ellipses
      let paginationResult = paginate(
        props.tableDataLength,
        props.currTablePage,
        props.rowsToDisplay,
        Enums.MAX_PAGE_BUTTONS
      )
      let displayList = []
      for (let i = paginationResult.pages[0]; i <= paginationResult.pages[paginationResult.pages.length-1]; i++) {
        if (i === props.currTablePage) {
          // display current page as current page
          displayList.push(
            <li key={i}>
              <a className="pagination-link is-current" onClick={()=>storeCurrTablePage(i)} aria-label={pageId} aria-current="page" key={i}>{i}</a>
            </li>
          )
        } else {
          // otherwise add normal page button
          displayList.push(
            <li key={i}>
              <a className="pagination-link" onClick={()=>storeCurrTablePage(i)} aria-label={pageGoTo} key={i}>{i}</a>
            </li>
          )
        }
      }

      return(
        <nav className="pagination" role="navigation" aria-label="pagination">
          {buildPaginationButtons()}
          <ul className="pagination-list">
            {displayList}
          </ul>
        </nav>
      )
    }
  }

  return(
    <div className="column is-half has-text-left">
      {buildPaginationControls()}
    </div>
  )
}

// build dropdown selection
/* Prop list: ************************
 * rowsToDisplay              (the number of rows to display)
 * setRowsToDisplay           (event callback to store rows)
 * setNumPages                (set call for number of total pages)
 * tableSize                  (length of dataset)
 * setCurrPage                (set current page)
 * currPage                   (current page)
 ************************************/
export function PaginationRowsPerPageDropdown(props) {

  // update number of results to display per page
  const storeRowsToDisplay = event => {
    const newNumPages = Math.ceil(props.tableSize / parseInt(event.target.value))
    props.setNumPages(newNumPages)
    if (props.currPage > newNumPages) {
      props.setCurrPage(newNumPages)
      props.setRowsToDisplay(event.target.value)
      // rebuild table
    } else {
      props.setRowsToDisplay(event.target.value)
      // rebuild table
    }
  }

  function buildOptions() {
    let options = []
    Enums.ROWS_PER_PAGE.forEach(numRows => {
      options.push(
        <option value={numRows.toString()} key={numRows}>{numRows}</option>
      )
    })
    return(
      <select value={props.rowsToDisplay.toString()} onChange={storeRowsToDisplay}>
        {options}
      </select>
    )
  }
  
  // create dropdown
  return(
    <span>
      <div className="select is-small">
        {buildOptions()}
      </div>
    </span>
  )
}
