import React, { Component } from 'react'
import axios from 'axios'
import CustomModal from './components/CustomModal'
import { LoadingModal, ErrorModal, GenericSuccess } from './components/ConfirmationDialogue'
import * as Enums from '../../common/enums'

export default function GeneralSupportTab (props) {

  const [activeModal, setActiveModal] = React.useState(Enums.activeModal.none)
  // password reset
  const [email, setEmail] = React.useState(
    Enums.buildStateObj("", false)
  )
  const emailFieldValue = React.useRef('')
  const [emailSuccessBody, setEmailSuccessBody] = React.useState("")

  const validatePasswordResetInput = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      false
    )
    if (Enums.validEmailRegex.test(event.target.value)) {
      tmpStateObj.isValid = true
      console.log(tmpStateObj.value)
    }

    setEmail(tmpStateObj)
  }

  function submitPasswordReset() {
    if (!email.isValid) {
      return false
    }
    console.log(email.value)
    emailFieldValue.current.value = ''
    setActiveModal(Enums.activeModal.loadingModal)
    axios.post(`${process.env.REACT_APP_API_URL}/admin/resetUserPass`, {
      email: email.value
    }).then((res) => {
      // tell admin reset link was sent out
      let body = <p>Successfully reset password for <b>{email.value}</b>.</p>
      setEmailSuccessBody(body)
      setActiveModal(Enums.activeModal.success)
      setEmail("")
    }).catch((error) => {
      console.error("Failed to send password reset email.")
      props.processError(error)
      setActiveModal(Enums.activeModal.failureModal)
    })
  }

  function buildPasswordReset() {
    return(
      <div className="field has-addons">
        <div className="control is-expanded">
          <input className="input" type="email" onBlur={validatePasswordResetInput} ref={emailFieldValue} placeholder="user@email.com"></input>
        </div>
        <div className="control">
          <a className="button is-link is-selected" onClick={() => submitPasswordReset()}>Reset Password</a>
        </div>
      </div>
    )
  }

  function displayModal() {
    switch(activeModal) {
      case Enums.activeModal.none:
        return

      case Enums.activeModal.success:
        return(
          <CustomModal {...props}
            title="Success"
            modalBody={() => GenericSuccess(emailSuccessBody)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Error"
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      default:
        return
    }
  }

  React.useEffect(() => {
  }, [
    activeModal,
    email,
    emailFieldValue,
    emailSuccessBody
  ])

  return(
    <div className="section">
      <div className="container">
        <h1 className="title is-4">Reset User Password</h1>
        {buildPasswordReset()}
      </div>
      {displayModal()}
    </div>
  )
}