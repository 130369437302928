import React from 'react'
import ReactTooltip from 'react-tooltip'
import OutsideAlerterFunc from '../common/OutsideAlerterFunc'

/*************************************************************************** 
 * React functional component for building DM DropDown UI element
 * @prop : value - current drop down state value
 * @prop : onChange - callback function for value change
 * @prop : data - array of objects with value (string) and available (boolean) properties
 * @prop : isDisabled - disables the dropdown with lock icon and no-cursor mouse hover
 * ------- OPTIONAL UI & Styling related props -------
 * @prop : label - main label, if null then no tool tip is shown either
 * @prop : isNew - if true adds branded [NEW] tag to the toggle
 * @prop : tipText - string for the info icon text, can be html if isTipHtml=true
 * @prop : isTipHtml - if true interpolates string value into html for rendering
 * @prop : rightAlign - if true right aligns the dropdown menu (default is left)
 * @prop : isUp - drop up menu if true (default is down)
 * @prop : isStyled - adds brand colors and borders to the dropdown
 * @prop : isStyled2 - uses alt/reverse brand colors for styling
 * @prop : textClass - css class for text style
 * @prop : noMargins - sets side margins to 0
 * @prop : outerMarginClass - used to control spacing on the containing div
 ***************************************************************************/
const DMDropDown = (props) => {

  // local state hook(s):
  const [cssClass, setCssClass] = React.useState("")
  if( !props.data ) {
    console.log(`Warning - Dropdown invoked with null data, please pass a "data" prop to this component!`)
    return
  }
  const activeClass = "is-active"
  const toggleDropDownClass = (val) => {
    if( val === 'close' ) {
      // clear the class if called with value 'close'
      setCssClass("")
    }
    else {
      // else flip the value between is-active and null string
      setCssClass(cssClass === "" ? activeClass : "")
    }
  }
  ///////////////////////////////////////////////////////////////////////////
  const buildDMDropDown = () => {
    let spanClass = "subtitle is-5 m-0"
    let disableFlag = ""
    let iconClass = "icon"
    let directionClass = ""

    if( props.isDisabled ) {
      spanClass += " disabled-text no-cursor"
      iconClass += " disabled-text"
      disableFlag = "disabled"
    }
    else {
      spanClass += " has-text-white"
      iconClass += " dm-white"
    }
    if( props.isUp ) {
      directionClass = " is-up "
    }

    const ariaID = props.data[0].value.split(' ').join('')+"-id"
    let ddMargins = props.noMargins ? " mx-0 " : " ml-0 mr-4 "

    return (
      <OutsideAlerterFunc outerMarginClass={props.outerMarginClass} clearActiveDropDowns={()=>toggleDropDownClass('close')}>
        
          <div className={"dropdown " + cssClass + ddMargins + directionClass + (props.rightAlign ? " is-right" : "")} >
            { props.isDisabled
              ?
              <div className="dropdown-trigger no-cursor">
                <button disabled className="button disabled-item no-cursor" aria-haspopup="true" aria-controls={ariaID} style={{border:'none',minWidth:'60px'}}>            
                  <span className="has-text-weight-light is-capitalized">{ props.value }</span>
                </button>
              </div>
              :
              <div className="dropdown-trigger" onClick={()=>toggleDropDownClass()}>
                {
                  props.isStyled
                  ?
                  <div className="buttons has-addons is-centered">
                    <button className="button dm-brand-font mr-0" style={{backgroundColor:'white', border:'2px solid #2d4e77',borderRight:'none'}} aria-haspopup="true" aria-controls={ariaID}>
                      <span className={"has-text-weight-semibold " + props.textClass }>{ props.value }</span>
                    </button>
                    <button className="button dm-brand-2-font dm-brand" style={{border:'2px solid #2d4e77', borderLeft:'none'}} aria-haspopup="true" aria-controls={ariaID}>
                      <span className="icon is-medium" style={{height:'100%'}}>
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  </div>
                  :
                  ( props.isStyled2
                  ?
                    <div className="buttons has-addons is-centered">
                      <button className="button dm-brand-font mr-0" style={{backgroundColor:'white', borderWidth:'2px 0 2px 2px', borderColor:'#fab03c', borderStyle:'solid'}} aria-haspopup="true" aria-controls={ariaID}>
                        <span className={"has-text-weight-semibold " + props.textClass }>{ props.value }</span>
                      </button>
                      <button className="button dm-brand-2-font dm-brand" style={{borderWidth:'2px 2px 2px 0', borderColor:'#fab03c', borderStyle:'solid'}} aria-haspopup="true" aria-controls={ariaID}>
                        <span className="icon is-medium" style={{height:'100%'}}>
                          <i className="fas fa-angle-down" aria-hidden="true"></i>
                        </span>
                      </button>
                    </div>
                    :
                    <button className="button dm-brand-font is-light" aria-haspopup="true" aria-controls={ariaID}>
                      <span className={"has-text-weight-semibold is-capitalized " + props.textClass }>{ props.value }</span>
                      <span className="icon is-small">
                        <i className="fas fa-angle-down" aria-hidden="true"></i>
                      </span>
                    </button>
                  )
                }
              </div>
            }
            <div id={ariaID} className="dropdown-menu menu-grow has-text-left" role="menu">
              
              {buildDropDownContent()}

            </div>
          </div>
          {
            props.label
            &&
            <span className={spanClass} >
              {props.label} 
              <span className={iconClass} style={{fontSize: props.fontSize ? props.fontSize: '1rem'}}>
                <i className="fas fa-info-circle"
                  data-for={props.label.split(' ').join('')+"-tip"} 
                  data-border={true}
                  data-border-color="black"
                  data-tip
                  data-text-color="#2d4e77"
                  data-background-color="white">
                </i>
              </span>
            </span>
          }
          {
            props.label && props.tipText
            &&
            <ReactTooltip className="tip-max-w" id={props.label.split(' ').join('') + "-tip"} place="right" effect="solid">
              <div className="subtitle is_4">
                { props.isTipHtml ? <div dangerouslySetInnerHTML={{__html: props.tipText}} /> : props.tipText }
              </div>
            </ReactTooltip>
          }
        
      </OutsideAlerterFunc>
    )
  }

  ///////////////////////////////////////////////////////////////////////////
  function buildDropDownContent() {
    if( typeof(props.data) !== 'object' ) {
      console.log('ERROR: Failed to render dropdown content - make sure props.data is a valid array of strings!')
      return(
        <div className="dropdown-content">
          <div className="notification is-danger is-light"> Could not read data... </div>
        </div>
      )
    }
    ///// generate the drop list of items:
    const listData = props.data
    const dropDownListContent = listData.map( (elem) => 
      <div key={elem.value}>
        {
          elem.available === true
          ?
          <a onClick={()=>props.onChange(elem.value, toggleDropDownClass)} className="is-capitalized dropdown-item dm-brand-font has-text-weight-semibold">
            {elem.value}
          </a>
          :
          <a className="is-capitalized dropdown-item dropdown-item-disabled no-cursor">
            <span className="icon is-small mr-2">
              <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
            </span>
            {elem.value}
          </a>
        }
      </div>
    )
    // return the list content:
    return (
      <div className="dropdown-content">
        {dropDownListContent}
      </div>
    )
  }
  return buildDMDropDown()
}

export default DMDropDown