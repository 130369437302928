import React from 'react';
import { Link } from "react-router-dom";

/*********************************************************
 * InfoDialog.js
 *
 * @prop title    : Sets the title of the modal display
 * @prop msg      : The body text of the modal
 * @prop label1   : Label for button #1
 * @prop action1  : action for button #1 and the Close (x) button
 * @prop label2   : Label for button #2 [optional]
 * @prop action2  : action for button #2 [optional]
 * @prop isDanger : show as an error level dialog
 * @prop msgFormat : allows html, otherwise text format
 *********************************************************/
export default function InfoDialog(props) {

  let msg = (props.msgFormat === 'html') ? <div dangerouslySetInnerHTML={{__html: props.msg}}></div> : props.msg

  // Build custom modal based on passed props
  return (
    <div id="modal-ter" className="modal is-active">
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head m-0">
          <p className="modal-card-title">{props.title}</p>
          <button className="delete" onClick={() => props.action1()} aria-label="close"></button>
        </header>
        <section className="modal-card-body m-0">
          <div className="content">
            {
              props.isDanger
              ?
              <div className="notification is-danger is-light">
                <div className="columns">
                  <div className="column is-1 p-1 m-1">
                    <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
                  </div>
                  <div className="column p-1 m-1">
                    <div className="subtitle is-5">{msg}</div>
                  </div>
                </div>
              </div>
              :
              <div className="columns">
                <div className="column has-text-left">
                  <div className="subtitle is_3">{msg}</div>
                </div>
              </div>
            }
          </div>
        </section>
        <footer className="modal-card-foot m-0">
          <div className="columns m-0 fullwidth">
            <div className="column p-0">
              <div className="buttons is-right">
                { /* Check for optional button #2 values */
                  (props.action2 && props.label2)
                  ?
                  <React.Fragment>
                    <button className="button" tabIndex="0" onClick={() => props.action1()}>{props.label1}</button>
                    <button className="button action-btn-dark" tabIndex="1" onClick={() => props.action2()}>{props.label2}</button>
                  </React.Fragment>
                  :
                    <button className="button action-btn-dark" tabIndex="0" onClick={() => props.action1()}>{props.label1}</button>
                }
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  )
}