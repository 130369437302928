import React, { useState, useEffect, useRef, useContext } from 'react'
import ReactDOM from 'react-dom'
import { Link, Prompt, useHistory, useLocation } from 'react-router-dom'
import { SketchPicker } from 'react-color'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { AppStateContext } from '../../../AppStateContext'
import { 
  checkForInProgressOrQueuedJobs
} from '../../api/apiRequests'
import DragZone from './DragDrop'
import ProgressDisplay from './ProgressDisplay'
import Previewer from './Previewer'
import JobQueuedUI from './JobQueuedUI'
import DMAnimatedTips from '../../ui/DMAnimatedTips'
// import ParticlesBackground from '../../common/ParticlesBackground'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import * as Enums from '../../common/enums'
import OutsideAlerter from '../../common/OutsideAlerter'

export default function NewJobConfiguration(props) {

  const appStateContext = useContext(AppStateContext)
  const history = useHistory()
  const location = useLocation()

  /////////////////////////////////////////////////////////////////////
  // local component state hooks:
  /////////////////////////////////////////////////////////////////////
  const [componentInitialized, setComponentInitialized] = useState(false)

  // custom character file input reference
  const fileInputRef = useRef()

  // component mount effect hook:
  React.useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  useEffect(() => {
    if( !componentInitialized && appStateContext.state.anim3dInitialized && appStateContext.state.accountDataRetrieved) {
      window.scrollTo(0, 0)
      props.setLOADING({show: true, msg: 'Loading...'})
      let newStateData = { animJobSettings: JSON.parse(JSON.stringify(Enums.JOB_SETTINGS_TEMPLATE)) }
      if( appStateContext.state.animJobSettings.jobType ) {
        // preseve current job type (i.e. anim or pose)
        newStateData.animJobSettings.jobType = 1
      }
      checkForInProgressOrQueuedJobs(true)
      .then(res => {
        if(res.data.count >= 1) {
          console.log(`Found an in-progress or queued job - ${res.data.list[0].rid}`)
          // if at least 1 object returned in the list response we assign first
          // jobId to state var then call checkStatus() to check for potentially
          // queued jobs...
          if( res.data.list[0].rid === appStateContext.state.animJobId && res.data.list[0].status === "PROGRESS" ) {
            // fix for MD-7345
            props.setLOADING( {...props.LOADING, ...{show: false}} )
          }
          else {
            newStateData = {
              ...newStateData,
              ...{animJobId: res.data.list[0].rid},
              ...{currWorkflowStep: Enums.uiStates.jobQueued}
            }
          }
        }
        else {
          props.setLOADING( {...props.LOADING, ...{show: false}} )
        }
        // also set animation job settings to default upon component mount but remember
        // current custom model data if set
        if( appStateContext.state.animJobSettings.customModelInfo.id ) {
          newStateData.animJobSettings.customModelInfo = appStateContext.state.animJobSettings.customModelInfo
        }
        appStateContext.dispatch(newStateData)
        setComponentInitialized(true)
      })
    }
  }, [componentInitialized, appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  function confirmCancelJob() {
    if(!appStateContext.state.animJobId) { 
      return
    }
    appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.cancelInProgressJob})
  }

  /////////////////////////////////////////////////////////////////////
  // use effect hook for component mount
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( appStateContext.state.currWorkflowStep === Enums.uiStates.fileSelected && 
        appStateContext.state.silentUploadInProgress ) {
      props.uploadInputVideo()
    }
  }, [appStateContext.state.currWorkflowStep] )

  /////////////////////////////////////////////////////////////////////
  // changeJobModel() - Removes custom model from current anim job
  /////////////////////////////////////////////////////////////////////
  function changeJobModel() {
    // route back to the custom character upload screen
    history.push(Enums.routes.Anim3dModelUpload)
  }

  /////////////////////////////////////////////////////////////////////
  // confirmChangeJobModel() - Open confirm dialog for removing custom
  // model from the current job
  /////////////////////////////////////////////////////////////////////
  function confirmChangeJobModel() {
    appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.changeJobModel})
  }

  /////////////////////////////////////////////////////////////////////
  // confirmModelRemoval() - Open confirm dialog for removing custom
  // model from the current job
  /////////////////////////////////////////////////////////////////////
  function confirmRemoveJobModel() {
    appStateContext.dispatch({confirmDialogId: Enums.confirmDialog.removeJobModel})
  }

  /////////////////////////////////////////////////////////////////////
  // Builds the select motion clip UI
  /////////////////////////////////////////////////////////////////////
  function buildSelectMotionClipScreen() {
    return (
      <React.Fragment>
        <div id="anim-fadein" className="column">
          <AnimVersionPanel />

          {/* File Upload Area */}
          <div className="section pt-4">
            <div className="columns">
              <div className="column bShadow has-text-centered rounded-corners dm-brand">
                <div className="columns dm-brand rounded-corners">
                  {props.buildJobTypeSelectionRow()}
                </div>
                <div className="columns dm-brand rounded-corners">
                  {props.buildAddMotionClipArea()}
                  {props.buildCharacterSettingsArea()}
                </div>
              </div>
            </div>
            {props.InformationArea()}
          </div>  
        </div>
      </React.Fragment>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // onChange handler for the color picker dialog
  /////////////////////////////////////////////////////////////////////
  function handleColorChangeComplete(color) {
    // only 100% alpha allow currently
    let newJobSettings = appStateContext.state.animJobSettings
    newJobSettings.bgColor = [color.rgb.r, color.rgb.g, color.rgb.b, 1]
    appStateContext.dispatch({ animJobSettings: newJobSettings })
  }

  /******************************************************************
   *** render()
   *** 
   *** We conditionally render UI components based on which step of 
   *** the workflow the user is currently on.
   ******************************************************************/

  if( props.LOADING.show ) {
    return (
      <React.Fragment>
      <AnimVersionPanel />
      <LoadingScreen />
      </React.Fragment>
    )
  }
  else {
    /////////////////////////////////////////////////////
    // Main workflow logic that controls UI steps and 
    // job processing
    /////////////////////////////////////////////////////
    switch(appStateContext.state.currWorkflowStep) {

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.initial (0)
      //
      // Initial state, input file background upload starts
      /////////////////////////////////////////////////////
      case Enums.uiStates.initial:
        return buildSelectMotionClipScreen()
      break

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.fileSelected (1)
      //
      // File Selected, configure animation job settings
      /////////////////////////////////////////////////////
      case Enums.uiStates.fileSelected:
        return (
          <React.Fragment>
            {props.buildFileSelectedScreen()}
          </React.Fragment>
        )
      break

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.jobInProgress (2)
      //
      // Job in progress
      /////////////////////////////////////////////////////
      case Enums.uiStates.jobInProgress:
        return (
          <React.Fragment>
            <div className="column" id="anim-fadein">
              <AnimVersionPanel />
              <div className="section pt-4">
                <ProgressDisplay 
                  animationJobProgress={appStateContext.state.animationJobProgress} 
                  progressMsg={appStateContext.state.progressMsg} 
                  confirmCancelJob={confirmCancelJob}
                />

                {props.InformationArea()}
                {/* TODO: Disabling POC version of animated tips
                <DMAnimatedTips
                  tipList={Enums.tipList}
                  tipListLength={Enums.tipList.length}
                /> 
                */}
                </div>
            </div>
          </React.Fragment>
        ) 
      break

      /////////////////////////////////////////////////////
      // currWorkflowStep : Enums.uiStates.jobQueued (3)
      //
      // Job Queued
      /////////////////////////////////////////////////////
      case Enums.uiStates.jobQueued:
        return (
          <React.Fragment>
            <div id="anim-fadein" className="column">
              <AnimVersionPanel />
              <JobQueuedUI />
              {props.InformationArea()} 
            </div>
          </React.Fragment>
        )
      break

      default:
      break
    }
  }
}