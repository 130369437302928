import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link, useHistory } from "react-router-dom"
import { Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, { Navigation, Scrollbar, EffectFade } from 'swiper'
import {Helmet} from "react-helmet"
import { AppStateContext } from '../../../AppStateContext'
import ReactTooltip from 'react-tooltip'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
import DragZone from './DragDrop'
import DMToolTip from '../../ui/DMToolTip'
import DMDialog from '../../ui/DMDialog'
import useWindowSize from '../../common/useWindowSize'
import * as Enums from '../../common/enums'
import 'swiper/swiper-bundle.css'
import 'bulma-o-steps/bulma-steps.sass'

// images
import guideline1Img from '../../../images/animate-3d/capture-guidelines/NO_Cartoon.jpg'
import guideline2Img from '../../../images/animate-3d/capture-guidelines/NO_Loose_Clothing.jpg'
import guideline3Img from '../../../images/animate-3d/capture-guidelines/NO_MultiPerson.jpg'
import guideline4Img from '../../../images/animate-3d/capture-guidelines/NO_Occusions.jpg'
import guideline5Img from '../../../images/animate-3d/capture-guidelines/NO_Solid_Colors.jpg'
import guideline6Img from '../../../images/animate-3d/capture-guidelines/YES_Clothing_Contrast_M.jpg'
import guideline7Img from '../../../images/animate-3d/capture-guidelines/YES_Clothing_Contrast.jpg'
import guideline8Img from '../../../images/animate-3d/capture-guidelines/YES_FaceTracking.jpg'

// model images
import modelDefault from '../../../images/animate-3d/character-select/default-characters.jpg'
import modelRoblox from '../../../images/animate-3d/character-select/roblox-r15.png'
import modelCustom from '../../../images/animate-3d/custom-characters.jpg'

const welcomeTitle = "Welcome to Animate 3D"
const welcomeText = "Click the button below to start the new animation walkthrough..."
const textStart = "Get Started"
const lengthLimitTitle = "Max Length"
const sizeLimitTitle = "Max Size"
const subscriptionTitle = "Current Plan"
const btnTextNext = "Next"
const guidelinesDescription = "Animation quality mostly depends on your input video, please review the following capture guidelines before continuing..."
const addclipDescription = "Next let's add an input video to generate a 3D Animation from..."
const clipAddedDescription = "Your input video has been successfully uploaded."
const selectmodelDescription = "Choose the character rig to retarget the animation to. Custom characters are unlocked after your first successful job."
const jobsettingsDescription = "Last step is to configure your animation and video settings. Note that enabling certain settings such as MP4 format or Face Tracking increase job processing time."
const customModelUnlockText = "Create your first animation to unlock Custom models"
const iconWarn = "fas fa-exclamation-triangle has-text-warn fa-2x"
const iconSuccess = "fas fa-check-circle has-text-success fa-2x"

const stepsData = [
  {
    workflowStep: Enums.FTESteps.reviewGuidelines,
    icon: "fas fa-video",
    title: "Guidelines"
  },
  {
    workflowStep: Enums.FTESteps.addMotionClip,
    icon: "fas fa-plus",
    title: "Add Video"
  },
  {
    workflowStep: Enums.FTESteps.selectModel,
    icon: "fas fa-user",
    title: "Select Model"
  },
  {
    workflowStep: Enums.FTESteps.choseJobSettings,
    icon: "fas fa-check",
    title: "Job Settings"
  }
]

const guidelinesData = [
  {
    image: guideline1Img,
    guideline: "No Cartoon Characters"
  },
  {
    image: guideline2Img,
    guideline: "No Loose Clothing"
  },
  {
    image: guideline3Img,
    guideline: "No Multiple Persons"
  },
  {
    image: guideline4Img,
    guideline: "No Object Occlusions"
  },
  {
    image: guideline5Img,
    guideline: "No Solid Colored Clothing"
  },
  {
    image: guideline6Img,
    guideline: "Human subject in full view, exposed joints, good contrast with background"
  },
  {
    image: guideline7Img,
    guideline: "Camera is 6-20ft from subject, tight patterned clothing, no occulusion"
  },
  {
    image: guideline8Img,
    guideline: "Face Tracking: Full Body, Half Body, and Portrait"
  },
]

const modelsData = [
  {
    image: modelDefault,
    name: "Default Characters"
  },
  {
    image: modelRoblox,
    name: "Roblox R15"
  },
  {
    image: modelCustom,
    name: "Custom"
  }
]

const lastGuidelineViewedIndex = 4
const modelSelection = Object.freeze({
  default: 0,
  roblox_r15: 1
})

// import additional swiper modules:
SwiperCore.use([Navigation, Scrollbar])

////////////////////////////////////////////////////////////////////////////////////
// Guided FTE (First Time Experience) component for Animate 3D users
////////////////////////////////////////////////////////////////////////////////////
export default function GuidedFTE(props) {
  // browser history
  let history = useHistory()
  const appStateContext = useContext(AppStateContext)
  const [currentStep, setCurrentStep] = useState(Enums.FTESteps.begin)
  const [currentDisplayScreen, setCurrentDisplayScreen] = useState(null)
  const [currentGuidelineIndex, setCurrentGuidelineIndex] = useState(0)
  const [currentModelSelection, setCurrentModelSelection] = useState(modelSelection.default)
  const [showFaceGuidesDialog, setShowFaceGuidesDialog] = useState(false)
  const [lastGuidelineViewed, setLastGuidelineViewed] = useState(false)
  let windowSize = useWindowSize()

  // SEO 
  const docTitle = "Create Animation | DEEPMOTION"
  const metaDesc = `${welcomeTitle} ${welcomeText}`

  ////////////////////////////////////////////////////////////////////
  // component mount
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.accountDataRetrieved || !appStateContext.state.anim3dInitialized ) {
      props.initializeA3DService()
    }
    else {
      props.setLOADING({ ...props.LOADING, ...{show: false} })
    }
  }, [appStateContext.state.accountDataRetrieved, appStateContext.state.anim3dInitialized])

  ////////////////////////////////////////////////////////////////////
  // input video selected hook
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( appStateContext.state.currWorkflowStep === Enums.uiStates.fileSelected ) {
      props.uploadInputVideo()
    }
  }, [appStateContext.state.currWorkflowStep])

  ////////////////////////////////////////////////////////////////////
  // current display screen hook
  ////////////////////////////////////////////////////////////////////  
  useEffect(() => {
    switch( appStateContext.state.currFTEStep ) {
      default:
      case Enums.FTESteps.begin:
        setCurrentDisplayScreen(<WelcomePage />)
        break
      case Enums.FTESteps.reviewGuidelines:
        setCurrentDisplayScreen(<ReviewGuidelines />)
        break
      case Enums.FTESteps.addMotionClip:
        setCurrentDisplayScreen(<AddMotionClip />)
        break
      case Enums.FTESteps.selectModel:
        setCurrentDisplayScreen(<SelectModel />)
        break  
      case Enums.FTESteps.choseJobSettings:
        setCurrentDisplayScreen(<ChoseJobSettings />)
        break
    }
  }, [
    appStateContext.state.currFTEStep, 
    appStateContext.state.currWorkflowStep,
    appStateContext.state.silentUploadInProgress,
    appStateContext.state.animJobSettings,
    appStateContext.state.animJobSettings.poseFilteringStrength,
    appStateContext.state.animJobSettings.videoSpeedMultiplier,
    appStateContext.state.animJobSettings.backdrop,
    appStateContext.state.animJobSettings.greenScreen,
    currentModelSelection,
    showFaceGuidesDialog,
    lastGuidelineViewed,
    props.activeJobMenu])

  ////////////////////////////////////////////////////////////////////
  // special hook for showing Face Tracking Guidelines if/when user
  // selects for the first time
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // check if first time user is enabling FT option so we can pop a dialog
    if( appStateContext.state.animJobSettings.trackFace && !appStateContext.state.isFTEFaceTrackingFirstEnable ) {
      setShowFaceGuidesDialog(true)
    }
    else {
      setShowFaceGuidesDialog(false)
    }
  }, [appStateContext.state.animJobSettings.trackFace, appStateContext.state.isFTEFaceTrackingFirstEnable])
  

  // using react helmet to set page title and metadata
  function seoMetaData_EN() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  ////////////////////////////////////////////////////////////////////
  function WelcomePage(params) {
    return (
      <div id="" className="column has-text-centered">
        <WelcomeTitleAndSubtitle />

        <div className="columns">
          <div className="column has-text-centered">
            <div className="button is-large mt-4 tile-border-pulse glow-on-hover bShadow dm-brand-font has-text-weight-semibold" onClick={()=>navigateToGuideStep(Enums.FTESteps.reviewGuidelines)}> 
              {textStart}
            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  function WelcomeTitleAndSubtitle(params) {
    return (
      <React.Fragment>
        <div className="title is-2 dm-brand-font"> {welcomeTitle + `, ${appStateContext.state.firstName}!`} </div>
        <div className="subtitle is-4"> {welcomeText} </div>
      </React.Fragment>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the steps UI using bulma (sass) component
  ////////////////////////////////////////////////////////////////////
  function WorkflowSteps(params) {
    const stepsList = stepsData.map( (step, index) => 
      <li className={"steps-segment " + (appStateContext.state.currFTEStep === (index+1) ? "is-active has-gaps" : "")} key={index+1}>
          <span className={"steps-marker " + (appStateContext.state.currFTEStep >= (index+1) ? "is-success" : "")} onClick={()=>navigateToGuideStep(step.workflowStep < appStateContext.state.currFTEStep ? step.workflowStep : appStateContext.state.currFTEStep)}>
            <span className="icon">
              <i className={step.icon}></i>
            </span>
          </span>
          <div className="steps-content">
            <p className="heading dm-brand-font"> {step.title} </p>
          </div>
      </li>
    )
    return (
      <ul className="steps is-narrow is-medium is-centered has-content-centered mb-1">
        {stepsList}
      </ul>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Guided Step 1
  ////////////////////////////////////////////////////////////////////
  function ReviewGuidelines(params) {
    const cardContentHeight = windowSize.width >= Enums.fullHDWidth ? "4.5rem" : "6rem"
    // guidelines data array includes face tracking suggestion which
    // we don't show unlesss user enables Face Tracking option later
    let gData = guidelinesData.slice(0, -1)
    // Build the capture guidelines swiper:
    const swiperSlides = gData.map( (item, index) => 
      <SwiperSlide key={`guideline-${index}`} style={{maxHeight:'80%'}}>
        <div className="card ">
          <div className="card-image">
            <figure className="image is-1by1 m-0 br-4" >
              <img src={item.image} alt={`guide-image-${index}`} />
            </figure>
          </div>
          <div className={"card-content has-text-centered px-2" + (index < gData.length-2 ? " has-background-danger-light" : " has-background-success-light")}>
            <div className="media">
              <div className="media-content flex-vert-center" style={{height:'3rem'}}>
                <p className={"subtitle is-6" + (index < gData.length-2 ? " has-text-danger" : " has-text-success-dark") } >{item.guideline}</p>
              </div>
            </div>
          </div>
        </div>
      </SwiperSlide>
    )
    return (
      <div className="column has-text-centered">
        <div className="columns m-0">
          <div className="column is-10 has-text-centered">
            <TitleSection text={guidelinesDescription} />
            <div className="is-relative mt-5 mx-5">
              {/* Build the guidelines Swiper here: */}
              <Swiper className="auto-margins py-1"
                observer={true}
                observeParents={true}
                navigation={{
                  nextEl: ".swiper-next-custom",
                  prevEl: ".swiper-prev-custom"
                }}
                slidesPerView={3}
                slidesPerGroup={1}
                spaceBetween={10}
                loop={false}
                onSlideChange={(swiper) => checkGuidelineSlideIndex(swiper.realIndex)}>
                
                {swiperSlides}
              </Swiper>
              <div className="swiper-next-custom"><span className="icon fas fa-arrow-circle-right fa-3x dm-brand-font tile-border-pulse"></span></div>
              <div className="swiper-prev-custom"><span className="icon fas fa-arrow-circle-left fa-3x dm-brand-font tile-border-pulse"></span></div>
            </div>
          </div>
        </div>
      </div>
    )
  }
  ////////////////////////////////////////////////////////////////////
  function checkGuidelineSlideIndex(index) {
    if( index >= lastGuidelineViewedIndex ) {
      if( !lastGuidelineViewed ) {
        setLastGuidelineViewed(true)
      }
    }
    setCurrentGuidelineIndex(index)
  }
  ////////////////////////////////////////////////////////////////////
  function selectCharacter(index) {
    //TODO: Update STATE customModelInfo? 
    // setCurrentGuidelineIndex(index)
  }

  ////////////////////////////////////////////////////////////////////
  // Guided Step 2
  ////////////////////////////////////////////////////////////////////
  function AddMotionClip(params) {
    // default tag class for Freemium plans
    let subscriptionTagClass = Enums.accountPlansInfo[0].tagClass
    if( appStateContext.state.subscriptionInfo.name ) {
      for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
        if( appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[i].name ) {
          subscriptionTagClass = Enums.accountPlansInfo[i].tagClass
          break;
        }
      }
    }
    const uploadDivHeight = windowSize.width <= Enums.fullHDWidth ? "10rem" : "15rem"

    if( appStateContext.state.currWorkflowStep === Enums.uiStates.fileSelected ) {
      const statusText = appStateContext.state.silentUploadInProgress ? "Uploading media, please wait..." : "Upload Complete"
      const statusIcon = appStateContext.state.silentUploadInProgress ? iconWarn : iconSuccess
      const animClass = appStateContext.state.silentUploadInProgress ? "logo-text-blink" : ""
      return (
        <div id="" className="column has-text-centered">
          <TitleSection text={addclipDescription} />
          <div className="columns m-0 mt-4">
            <div className="column is-10 has-text-centered rounded-corners dm-brand bShadow">
              <div className="columns m-0">
                {props.buildMotionClipSelectedArea()}
                <div className="column is-4 box disabled-switch m-3 mt-3 pl-0 pr-0 pt-0">
                  <div className="columns fullheight m-0">
                    <div className="section fullheight is-flex">
                      <div className="py-2" >
                        <span className={`icon dm-brand-2-font ml-0 mr-1 mb-5 ${animClass}`}>
                          <i className={statusIcon}>
                          </i>
                        </span>
                        <div className={`subtitle is-5 has-text-white ${animClass}`}> {statusText} </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }

    else {
      return (
        <div id="" className="column has-text-centered">
          <div className="columns m-0 mt-2">
            <div className="column is-10 ">
              <TitleSection text={addclipDescription} />

              <div className="columns m-0 mt-5 pt-4 pl-4 has-text-centered rounded-corners dm-brand bShadow">
                <div className="column mt-0 is-8" style={{minHeight:uploadDivHeight}}>

                  {/* Build the drag and drop video area: */}
                  <DragZone key="upload-area" onFilesAdded={props.uploadOnchange} animJobSettings={appStateContext.state.animJobSettings} />

                </div>
                <div className="column mt-0 " style={{minHeight:uploadDivHeight}}>
                  <div className="columns m-0">
                    <div className="column m-0">
                      <div className="subtitle is-5 mb-2 has-text-light is-underlined">{lengthLimitTitle}</div>
                      <div>
                        <span className={`${subscriptionTagClass} has-text-weight-semibold`}>{props.getMaxInputClipLength().toString() + " sec"}
                          <DMToolTip
                            text={Enums.tooltipVideoDurationLimits}
                            isTipHtml={true}
                            tipId="duration-limits"
                            icon="fas fa-info-circle fa-md has-text-info"
                            cursor="cursor-pt"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="columns m-0">
                    <div className="column m-0">
                      <div className="subtitle is-5 mb-2 has-text-light is-underlined">{sizeLimitTitle}</div>
                      <div>
                        <span className={`${subscriptionTagClass} has-text-weight-semibold`}>{(props.getMaxInputClipSize() / 1000000).toString() + " MB"}
                          <DMToolTip
                            text={Enums.tooltipVideoMaxSizeLimits}
                            isTipHtml={true}
                            tipId="size-limits"
                            icon="fas fa-info-circle fa-md has-text-info"
                            cursor="cursor-pt"
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////
  // Guided Step 3
  ////////////////////////////////////////////////////////////////////
  function SelectModel(params) {
    const modelsList = modelsData.map( (model, index) =>
      <div className={"column m-0"} key={index}>

        { index === modelsData.length-1 
        ?
        <div className={"card border-disabled is-relative no-cursor"}
          data-for="custom-unlock-tip"
          data-border={true}
          data-border-color={"black"}
          data-tip
          data-text-color="#2d4e77"
          data-background-color={"#fff"}
          >
          <div className="fullwidth v-and-h-center p-2 " style={{zIndex:'100'}}>
            <span className="icon is-medium has-text-black">
              <i className="fas fa-lock fa-5x" style={{zIndex:'100'}}></i>
            </span>
          </div>
          <div className="is-relative" style={{opacity:'0.3'}} >
            <div className="card-image br-0">
              <figure className="image is-16by9 m-0" >
                <img src={model.image} alt={model.name} />
              </figure>
            </div>
          </div>
          <div className="card-content has-text-centered has-background-grey-lighter px-2">
            <div className="media">
              <div className="media-content flex-vert-center" style={{height:'3rem'}}>
                <p className={"subtitle is-5 dm-brand-font"} > {model.name} </p>
              </div>
            </div>
          </div>
          <ReactTooltip className="tip-max-w" place="bottom" id="custom-unlock-tip"  effect="solid">
            <div className="subtitle has-text-left has-text-dark">
              { customModelUnlockText }
            </div>
          </ReactTooltip>
        </div>
        :
        <div className={"card is-relative " + (index === currentModelSelection ? " tile-border-pulse dm-brand-border-alt-lg bShadow br-4" : " dm-brand-border-lg ")} onClick={()=>setModelForJob(index)}>
          <div className={"card-image "}>
            <figure className={"image is-16by9 m-0 br-4 has-background-dark" }>
              <img src={model.image} alt={model.name} />
            </figure>
          </div>
          <div className="card-content has-text-centered px-2 dm-brand-border">
            <div className="media">
              <div className="media-content flex-vert-center" style={{height:'3rem'}}>
                <p className={"subtitle is-5 " + (index === currentModelSelection ? "dm-brand-2-font has-text-weight-semibold" : "dm-brand-font" )} > {model.name} </p>
              </div>
            </div>
          </div>
        </div>
        }
      </div>
    )

    return (
      <div id="" className="column has-text-centered">
        <div className="columns m-0">
          <div className="column is-10 has-text-centered">

            <TitleSection text={selectmodelDescription} />
            <div className="columns m-0 px-2">
            {modelsList}
            </div>

          </div>
        </div>
      </div>
    )
  }

  function setModelForJob(index) {
    let currModel = appStateContext.state.animJobSettings.customModelInfo
    if( !index ) {
      currModel.id = null
      currModel.name = 'Default Characters'
      
    }
    else {
      currModel.id = Enums.robloxModelId
      currModel.name = 'Roblox R15'
    }
    // update app state with currently selected model/character
    appStateContext.dispatch({animJobSettings: {...appStateContext.state.animJobSettings, ...{customModelInfo: currModel}} })
    setCurrentModelSelection(index)
  }

  function FaceTrackingGuidelineDialog(params) {
    const cardContent = [
      
          <div className="card" key="face-capture-guideline">
            <div className="card-image">
              <figure className="image is-3by1 m-0 br-4" >
                <img src={guidelinesData[guidelinesData.length-1].image} alt={`face-guide-image`} />
              </figure>
            </div>
            <div className={"card-content has-text-centered px-2 has-background-success-light"}>
              <div className="media">
                <div className="media-content flex-vert-center" style={{height:'3rem'}}>
                  <p className={"subtitle is-6 has-text-success-dark" } >{guidelinesData[guidelinesData.length-1].guideline}</p>
                </div>
              </div>
            </div>
          </div>
    ]
    return (
      <div className="columns" >
        <div className="column has-text-left">
          <DMDialog
            title="Face Tracking Distances"
            content={cardContent}
            msgFormat="html"
            label1="Ok"
            action1={ ()=>appStateContext.dispatch({confirmDialog: null, isFTEFaceTrackingFirstEnable: true}) }
          />
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Guided Step 4
  ////////////////////////////////////////////////////////////////////
  function ChoseJobSettings(params) {
    return (
      <div className="column has-text-centered">
        <div className="columns m-0">
          <div className="column is-10 has-text-centered">
            <TitleSection text={jobsettingsDescription} />
          </div>
        </div>
        <div className="columns m-0 ">
          <div className="column is-10 has-text-centered">
            <div className="columns m-0 bShadow">
              <div className="column has-text-centered pt-4 rounded-corners dm-brand">
              {props.buildJobSettingsArea(true)}
              {
                showFaceGuidesDialog
                &&
                <FaceTrackingGuidelineDialog />
              }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  function GuideNavigation() {
    let displayDiv = []
    switch( appStateContext.state.currFTEStep ) {
      default:
      case Enums.FTESteps.choseJobSettings:
      case Enums.FTESteps.begin:
        break
      case Enums.FTESteps.reviewGuidelines:
        if( currentGuidelineIndex >= lastGuidelineViewedIndex || lastGuidelineViewed ) {
          displayDiv.push(buildGuideButtons(Enums.FTESteps.addMotionClip, false))
        }
        else {
          displayDiv.push(buildGuideButtons(Enums.FTESteps.addMotionClip, true))
        }
        break
      case Enums.FTESteps.addMotionClip:
        if( appStateContext.state.currWorkflowStep === Enums.uiStates.fileSelected &&
            !appStateContext.state.silentUploadInProgress ) {
          displayDiv.push(buildGuideButtons(Enums.FTESteps.selectModel, false))
        }
        else {
          displayDiv.push(buildGuideButtons(Enums.FTESteps.selectModel, true))
        }
        break
      case Enums.FTESteps.selectModel:
        displayDiv.push(buildGuideButtons(Enums.FTESteps.choseJobSettings, false))
        break
    }
    // return the guide navigation buttons
    return (
      <React.Fragment>
        {displayDiv}
      </React.Fragment>
    )
  }

  ////////////////////////////////////////////////////////////////////
  function TitleSection(params) {
    return (
      <div className="columns m-0 pt-0 has-background-info-light br-4">
        <div className="column has-text-centered">
          <div className="subtitle is-5 dm-brand-font"> {params.text} </div>
        </div>
        <div className="column is-2 has-text-centered">
          <GuideNavigation />
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  function navigateToGuideStep(step) {
    window.scrollTo(0,0)
    appStateContext.dispatch({currFTEStep: step})
  }

  ////////////////////////////////////////////////////////////////////
  function buildGuideButtons(step1, isDisabled) {
      const buttonClass = isDisabled ? "button is-medium guide-btn-disabled has-text-weight-light no-cursor" :  "button is-medium action-btn glow-on-hover bShadow dm-brand-font has-text-weight-semibold"
      return (
        <div className="buttons is-centered" key={step1}>
          {
            isDisabled
            ?
            <button className={buttonClass}> {btnTextNext} </button>
            :
            <button className={buttonClass} onClick={()=>navigateToGuideStep(step1)}> {btnTextNext} </button>
          }
        </div>
      )
  }

  // set page title and meta data
  const helmetData = seoMetaData_EN()
  ////////////////////////////////////////////////////////////////////
  // Render this component:
  ////////////////////////////////////////////////////////////////////
  if( props.LOADING.show ) {
    return <LoadingScreen />
  }
  else {
    return (
      <React.Fragment>
      <AnimVersionPanel pageTitle={ appStateContext.state.currFTEStep !== Enums.FTESteps.begin ? props.pageTitle : "" } /> 
      <div className="section m-0 p-0 px-5 pb-6 mb-6" >
        {helmetData}

        { /* Don't show steps UI on initial page state */
          appStateContext.state.currFTEStep !== Enums.FTESteps.begin
          && <WorkflowSteps />
        }

        {currentDisplayScreen}
        
        {/*<GuideNavigation />*/}
        
      </div>
      </React.Fragment>
    )
  }
  ////////////////////////////////////////////////////////////////////
} 