import React, {useContext} from 'react'
import { Link, Route, useHistory } from 'react-router-dom'
import { AppStateContext } from '../../AppStateContext'
import ReactTooltip from 'react-tooltip'
import * as Enums from '../common/enums'

// social media icons
import facebook from '../../images/dashboard/facebook.svg'
import youtube from '../../images/dashboard/youtube.svg'
import linkedin from '../../images/dashboard/linkedin.svg'
import twitter from '../../images/dashboard/twitter.svg'

// icons
const iconHome = 'fas fa-home'
const iconCreateAnim = 'far fa-play-circle'
const iconLibrary = 'far fa-folder'
const iconLocked = 'fas fa-lock'
const iconProdAnim3d = 'fas fa-cloud-upload-alt'
const iconProdDMBTSdk = 'fas fa-child'
const iconProdVRSdk = 'fas fa-vr-cardboard'
const iconProdAPESdk = 'fas fa-cube'
const iconBack = 'fas fa-arrow-up'
const iconCharacters = 'fas fa-users'

// strings
const titleWelcome    = 'Welcome'
const titleProducts   = 'Products'
const titleGeneral    = 'General'
const titleAnim3d     = 'Animate 3D'
const titleDMBTSdk    = 'Body Tracking'
const titleVRSdk      = 'VR Tracking'
const titleAPESdk     = 'Physics Engine'
const titleForums     = 'Forums'
const titleTutorials  = 'Tutorials'
const titleContact    = 'Contact'
const titleSubscr     = 'Current Subscription'

// context menus
const backText        = 'Dashboard'
const downloadsTitle  = 'Downloads'
const acctType        = 'Account Type'
const home            = 'Home'
const create_anims    = 'Create'
const library         = 'Library'
const characters      = '3D Models'

const menuBarId       = "sideMenuBar"

/*************************************************************************** 
 * [FUNCTIONAL COMPONENT]
 * We use a React functional component instead of class to represent
 * the side bar menu across the various products/services
 ***************************************************************************/
export default function SideBarMenu(props) {

  const appStateContext = useContext(AppStateContext)

  // browser history & navigation
  const history = useHistory()

  /*************************************************************************** 
   * Builds the side menu based on the current product selection 
   ***************************************************************************/
  function buildSideMenuBar() {
    return (
      <div id={menuBarId} className="column is-one-fifth has-text-left has-text-black side-menu is-relative">

        {/* Collapsable Menu Icon for more screen space */}
        {buildCollapseIcon()}

        {/* Dynamic top contenxt menu based on current product selection */}
        {buildTopContextMenu()}

        {/* Static bottom menu for forums, support, etc */}
        {buildBottomStaticMenu()}

      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////////
  function buildCollapsedBar() {
    return (
      <div id={menuBarId} className="column is-marginless is-paddingless is-gapless is-narrow side-menu-collapsed is-relative">
        {buildCollapseIcon()}
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////////
  function buildCollapseIcon() {
    let arrowIcon = props.STATE.sideMenuCollapsed ? "fa-chevron-circle-right" : "fa-chevron-circle-left"
    return (
        <div className="collapse-icon-wrapper">
          <div className="is-relative">
            <span className="icon side-menu-icon" onClick={()=>toggleMenuCollapse()}>
              <i className={"fas fa-2x sm-icon-color " + arrowIcon}>
              </i>
            </span>
          </div> 
        </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////////
  function toggleMenuCollapse() {
    // collapse or expand the side menu:
    props.DISPATCH({sideMenuCollapsed: !props.STATE.sideMenuCollapsed})
  }

  /*************************************************************************** 
   * Dynamically builds the top context menu based on the users 
   * current location / product selection
   ***************************************************************************/
  function buildTopContextMenu() {

    let userName = appStateContext.state.firstName
    let tagClass = ""
    if( !appStateContext.state.subscriptionInfo ) {
      console.error(`Warning: Null subscriptionInfo detected in side bar render.`)
    }
    let subscriptionName = appStateContext.state.subscriptionInfo.name
    if( appStateContext.state.subscriptionInfo.isEnterpriseUser ) {
      subscriptionName = Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].name
      // use the last tag class in the accountPlans array for Enterprise users
      tagClass = Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].tagClass
    }
    else {
      if( appStateContext.state.subscriptionInfo.name ) {
        for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
          if( appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[i].name ) {
            tagClass = Enums.accountPlansInfo[i].tagClass
            break;
          }
        }
      }
      else {
        // default tag class for Freemium plans
        tagClass = Enums.accountPlansInfo[0].tagClass
      }
    }

    //*** Build the Side Menu:
    switch( window.location.pathname ) {

      // Home Dashboard
      case Enums.routes.Dash:
        return (
          <div className="mb-6">
            <aside className="menu">
              {
                userName 
                ?
                <div className="menu-top-section">
                  <p className="title is-5 has-text-black">
                  {titleWelcome}, {userName}
                  </p>
                </div>
                :
                <div className="menu-top-section">
                </div>
              }
              
              {buildProductsMenu()}
              
            </aside>
          </div>
        )
        break

      // special case side menu for FTE experience
      case Enums.routes.Anim3dGuidedFTE:
        return (
          <div className="mb-6">
            <aside className="menu" id="anim-fadein">
              <div className="menu-list menu-top-section">
                <Link className="button has-text-light btn-shadow" onMouseDown={(e)=>e.preventDefault()} to={Enums.routes.Dash}>
                  <span className="icon"><i className={iconBack} style={{marginRight:'5px'}}></i></span> 
                  <span>{backText}</span>
                </Link>
              </div>
            </aside>
          </div>
        )
        break

      case Enums.routes.Anim3d:
      case Enums.routes.Anim3dCreate:
      case Enums.routes.Anim3dPreview:
      case Enums.routes.Anim3dLibrary:
      case Enums.routes.Anim3dJobTypeSelect:
      case Enums.routes.Anim3dModelSelect:
      case Enums.routes.Anim3dModelUpload:
      case Enums.routes.Anim3dProfilePage:
      case Enums.routes.Anim3dModelManage:
        return (
          <div className="mb-6">
            <aside className="menu" id="anim-fadein">
              <div className="menu-list menu-top-section">
                <Link className="button has-text-light btn-shadow" onMouseDown={(e)=>e.preventDefault()} to={window.location.pathname === Enums.routes.Anim3d ? Enums.routes.Dash : Enums.routes.Anim3d}>
                  <span className="icon"><i className={iconBack} style={{marginRight:'5px'}}></i></span> 
                  <span>{ window.location.pathname === Enums.routes.Anim3d ? "Dashboard" : "Home" }</span>
                </Link>
              </div>
              <p className="menu-label has-text-black">
                {titleAnim3d}
              </p>
              <ul className="menu-list has-text-black">

                <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push( (!appStateContext.state.jobsData.length ? Enums.routes.Anim3dGuidedFTE : Enums.routes.Anim3d) )} ><span className="icon mgRight-10 is-left"><i className={iconHome}></i></span>{home}</div></li>

                {
                  appStateContext.state.accountTotals.currCycleMinsExpired 
                  ?
                  <React.Fragment>
                    <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.Anim3dLibrary)} ><span className="icon mgRight-10 is-left"><i className={iconLibrary}></i></span>{library}</div></li>
                    <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(window.location.pathname)} style={{cursor:'not-allowed'}}><span className="icon mgRight-10 is-left"><i className={iconCreateAnim}></i></span>{create_anims}</div></li>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    {
                      appStateContext.state.isJobInProgress
                      ?
                      <li>
                        <div
                          className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} 
                          onClick={()=>history.push(Enums.routes.Anim3dCreate)} 
                          > 
                          <span className="icon mgRight-10 is-left">
                            <i className={iconCreateAnim}></i>
                          </span>{create_anims} 
                          <span 
                            className="badge is-top-right is-primary"
                            data-for="job-in-progress" 
                            data-border={true}
                            data-border-color="black"
                            data-tip
                            data-text-color="#2d4e77"
                            data-background-color="white"
                            >1</span>
                        </div>
                        <ReactTooltip className="tip-max-w" id="job-in-progress" place="right" effect="solid">
                          <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                            {appStateContext.state.animJobSettings.jobType ? "3D Pose" : "3D Animation"} job currently in progress...
                          </p>
                        </ReactTooltip>
                      </li>
                      :
                      <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.Anim3dCreate)} > <span className="icon mgRight-10 is-left"><i className={iconCreateAnim}></i></span>{create_anims} </div></li>
                    }
                    <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.Anim3dLibrary)} ><span className="icon mgRight-10 is-left"><i className={iconLibrary}></i></span>{library}</div></li>
                  </React.Fragment>
                }
                <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.Anim3dModelManage)} ><span className="icon mgRight-10 is-left"><i className={iconCharacters}></i></span>{characters}</div></li>
              </ul>

              <p className="menu-label mgTop-40 has-text-black">
                {titleSubscr}
              </p>
              <ul className="menu-list has-text-black">
                <li>
                  <div>
                    {
                      !subscriptionName || subscriptionName === ""
                      ?
                      <button className="button has-background-transparent is-loading" />
                      : 
                      <span className={tagClass}>{subscriptionName}</span>
                    }
                  </div>
                </li>
              </ul>
            </aside>
          </div>
        )
        break

      case Enums.routes.DMBTSdk:
      case Enums.routes.VRSdk:
      case Enums.routes.APESdk:
      case Enums.routes.ActivityPage:
      case Enums.routes.FeedbackPage:
      case Enums.routes.ForgotPwdPage:
      case Enums.routes.Admin:
        return (
          <div className="mb-6">
            <aside className="menu">
              <div className="menu-list menu-top-section">
                <Link className="button has-text-light btn-shadow" to={Enums.routes.Dash}>
                  <span className="icon"><i className={iconBack} style={{marginRight:'5px'}}></i></span> 
                  <span>{backText}</span>
                </Link>
              </div>
              
            </aside>
          </div>
        )
        break

      default:
        return 
      break
    }
  }

  /*************************************************************************** 
   * Builds the static bottom menu which contains Forums, Feedback, Support
   ***************************************************************************/
  function buildBottomStaticMenu() {
    return (
      <div className="mb-6">
        <aside className="menu">
          <p className="menu-label has-text-black">
            {titleGeneral}
          </p>
          <ul className="menu-list has-text-black">
            <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.Contact)} ><span className="icon mgRight-10 is-left"><i className="far fa-comment-alt"></i></span>{titleContact}</div></li>
            <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push("https://www.youtube.com/playlist?list=PLZB6KVNbCO3-bWD4__RdbO_FgOHek2g9R")} ><span className="icon mgRight-10 is-left"><i className="fas fa-chalkboard-teacher"></i></span>{titleTutorials}</div></li>
            <li><div className={`button px-5 is-relative button-side-menu ${Enums.menuItemState.enabled}`} onClick={()=>history.push(Enums.routes.UserForums)} ><span className="icon mgRight-10 is-left"><i className="fab fa-forumbee"></i></span>{titleForums}</div></li>
          </ul>

          <ul className="social-menu">
            <div className="columns is-centered">
              <div className="column is-paddingless is-half">
                <div className="columns is-centered">
                  <div className="column is-narrow">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/DeepMotionInc" ><img src= { facebook } className="is-info" alt="dmSocial"/></a>
                  </div>
                  <div className="column is-narrow">
                    <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCgNLNjXsRGqTeQNJDzxj3bQ/videos" ><img src= { youtube } className="is-info" alt="dmSocial"/></a>
                  </div>
                  <div className="column is-narrow">                
                    <a target="_blank" rel="noopener noreferrer" href=" https://www.linkedin.com/company/18160775/" ><img src={ linkedin } className="is-info" alt="dmSocial"/></a>
                  </div>
                  <div className="column is-narrow">  
                    <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/DeepMotionInc" ><img src={ twitter } className="is-info" alt="dmSocial"/></a>
                  </div>
                </div>
              </div>
            </div>
          </ul>
        </aside>
      </div>
    )
  }

  /*************************************************************************** 
   * Builds the top level products menu
   ***************************************************************************/
  function buildProductsMenu() {

    // dynamic css classes and metadata for UI updates and styling
    let menuItemsData = [
      {'route': (appStateContext.state.jobsData && appStateContext.state.jobsData.length ? Enums.routes.Anim3d : Enums.routes.Anim3dGuidedFTE) , 'title': titleAnim3d, 'iconClass': null, 'menuState': null},
      {'route': Enums.routes.DMBTSdk, 'title': titleDMBTSdk, 'iconClass': null, 'menuState': null},
      {'route': Enums.routes.VRSdk, 'title': titleVRSdk, 'iconClass': null, 'menuState': null},
      {'route': Enums.routes.APESdk, 'title': titleAPESdk, 'iconClass': null, 'menuState': null}
    ]

    // check access to the various product groups and update css 
    // classes accordingly to enable/disable menu items
    if( appStateContext.state.accessList[Enums.productInfo.DMBT_Cloud.id-1] ) {
      menuItemsData[Enums.productInfo.DMBT_Cloud.id-1].iconClass = iconProdAnim3d
      menuItemsData[Enums.productInfo.DMBT_Cloud.id-1].menuState = Enums.menuItemState.enabled
    }
    else {
      menuItemsData[Enums.productInfo.DMBT_Cloud.id-1].iconClass = iconLocked
      menuItemsData[Enums.productInfo.DMBT_Cloud.id-1].menuState = Enums.menuItemState.disabled
    }
    if( appStateContext.state.accessList[Enums.productInfo.DMBT_SDK.id-1] ) {
      menuItemsData[Enums.productInfo.DMBT_SDK.id-1].iconClass = iconProdDMBTSdk
      menuItemsData[Enums.productInfo.DMBT_SDK.id-1].menuState = Enums.menuItemState.enabled
    }
    else {
      menuItemsData[Enums.productInfo.DMBT_SDK.id-1].iconClass = iconLocked
      menuItemsData[Enums.productInfo.DMBT_SDK.id-1].menuState = Enums.menuItemState.disabled
    }
    if( appStateContext.state.accessList[Enums.productInfo.VR_SDK.id-1] ) {
      menuItemsData[Enums.productInfo.VR_SDK.id-1].iconClass = iconProdVRSdk
      menuItemsData[Enums.productInfo.VR_SDK.id-1].menuState = Enums.menuItemState.enabled
    }
    else {
      menuItemsData[Enums.productInfo.VR_SDK.id-1].iconClass = iconLocked
      menuItemsData[Enums.productInfo.VR_SDK.id-1].menuState = Enums.menuItemState.disabled
    }
    if( appStateContext.state.accessList[Enums.productInfo.APE_SDK.id-1] ) {
      menuItemsData[Enums.productInfo.APE_SDK.id-1].iconClass = iconProdAPESdk
      menuItemsData[Enums.productInfo.APE_SDK.id-1].menuState = Enums.menuItemState.enabled
    }
    else {
      menuItemsData[Enums.productInfo.APE_SDK.id-1].iconClass = iconLocked
      menuItemsData[Enums.productInfo.APE_SDK.id-1].menuState = Enums.menuItemState.disabled
    }

    // Sort menu items so enabled products are at the top of the list
    for( let i = 0; i < menuItemsData.length-1; i++ ) {
      if( menuItemsData[i].menuState === Enums.menuItemState.disabled && 
          menuItemsData[i+1].menuState === Enums.menuItemState.enabled ) {
        // swap the menu items
        let temp = menuItemsData[i+1]
        menuItemsData[i+1] = menuItemsData[i]
        menuItemsData[i] = temp
      }
    }

    // Build the product menu list after we sorted above...  
    let productMenuList = []
    for( let i = 0; i < menuItemsData.length; i++ ) {
      // Connect to the product's Route only if the menu item is enabled
      if( menuItemsData[i].menuState === Enums.menuItemState.enabled ) {
        productMenuList.push(
          <li key={i}><Link to={menuItemsData[i].route} className={menuItemsData[i].menuState}><span className="icon mgRight-10 is-left"><i className={menuItemsData[i].iconClass}></i></span>{menuItemsData[i].title}</Link></li>
        )
      }
      else {
        // otherwise set to current dashboard route which in turn disables the link
        productMenuList.push(
          <li key={i}><Link to={Enums.routes.Dash} style={{cursor:'not-allowed'}} className={menuItemsData[i].menuState}><span className="icon mgRight-10 is-left"><i className={menuItemsData[i].iconClass}></i></span>{menuItemsData[i].title}</Link></li>
        )
      }
    }

    // return the top level products side menu: 
    return (
      <div>
        <p className="menu-label has-text-black">
          {titleProducts}
        </p>
        <ul className="menu-list has-text-black">

          {/* Top menu items list here... */}
          {productMenuList}

        </ul>
      </div>
    )
  }
  /////////////////////////////////////////////////////////////////////  
  // re-render when sideBar state changes
  /////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
  }, [appStateContext.state.sideMenuCollapsed])
  /////////////////////////////////////////////////////////////////////
  // component un-mount
  /////////////////////////////////////////////////////////////////////
  React.useEffect(() => {
    return function cleanup() {
      // cleanup or take any needed actions...
    }
  }, [])

  /*************************************************************************** 
   *** Main Render for this Function Component 
   ***************************************************************************/
  if( appStateContext.state.sideMenuCollapsed ) { 
    return buildCollapsedBar()
  }
  else { return buildSideMenuBar() }

}