import React from 'react'
import axios from 'axios'
import CustomModal from './components/CustomModal'
import SearchField from './components/SearchField'

export default function ListJobsTab(props) {

  const [jobData, setJobData] = React.useState([])
  const [jobDataLoaded, setJobDataLoaded] = React.useState(false)

  function getJobData() {
    const authToken = btoa(`${process.env.REACT_APP_ADMIN_CID}:${process.env.REACT_APP_ADMIN_SECRET}`)
    let urlPath = process.env.REACT_APP_ADMIN_APP_DEV_URL
    if (props.env === "production") {
      urlPath = process.env.REACT_APP_ADMIN_APP_LIVE_URL
    }
    // axios.get(`${urlPath}/admin/tempUrl`, {
    //   headers: { 'Authorization': `Basic ${authToken}` }
    // }).then((res) => {
    //   console.log(res.data)
    //   setJobData(res.data)
    //   setJobDataLoaded(true)
    // }).catch((error) => {
    //   console.log('Error: Could not retrieve job data')
    //   setJobDataLoaded(false)
    //   props.isError(true)
    // })
  }

  function buildJobTable() {
    return(
      <div className="columns is-centered">
        <div className="column is-narrow">
          <div className="table-container">
            <table className="table is-striped is-hoverable">
              <thead>
                <tr>
                  <th title="jobID">Job ID</th>
                  <th title="owner">Owner</th>
                  <th title="sDate">Start Date</th>
                  <th title="duration">Duration</th>
                </tr>
              </thead>
              {/*function for building table rows from data*/}
              {buildJobTableRows()}
            </table>
          </div>
        </div>
      </div>
    )
  }

  function buildJobTableRows() {

  }

  React.useEffect(() => {
    if (!jobDataLoaded) {
      getJobData()
      setJobDataLoaded(true)
    }

  }, [
    jobData
  ])

  return(
    <div className="container">
      <div className="columns">
        <div className="column">
          {/*** TODO ***/}
          <SearchField {...props}
            
          />
        </div>
      </div>
      {buildJobTable()}
    </div>
  )
}