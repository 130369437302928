import React, { useState, useEffect } from 'react'
import logo from '../../images/DM_Glyph_FullColor.png'

export default function LoadingScreen(props) {
  const [loadingMsg, setLoadingMsg] = useState(props.msg ? props.msg : 'Loading...')
  let backClass = "has-background-transparent"
  let textClass = "has-text-dark"
  if( props.backColor === "dark" ) {
    backClass = "has-background-black"
    textClass = "has-text-light"
  }
  var wait = setTimeout( () => {
    // update loading message after 12 seconds...
    setLoadingMsg("Still loading, please wait...")
  } , 12000)

  /////////////////////////////////////////////////////////////////////
  // component un-mount
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    return function cleanup() {
      setLoadingMsg('')
    }
  }, [])

	return (
    <div>
      <div className={backClass} >
        <div className="columns pt-6 pb-6">
          <div className="column pt-6 pb-6 mt-6 mb-6 has-text-centered">
            <img src={logo} className="App-logo" alt="logo" />
            <div className={`subtitle is-5 logo-text-blink ${textClass} mt-4`}> {loadingMsg} </div>
          </div>
        </div>
      </div>
    </div>
  )
}