import React from 'react'
import { Redirect } from 'react-router-dom'
import OktaSignInWidget from './OktaSignInWidget'
import { useOktaAuth } from '@okta/okta-react'
import * as Enums from '../common/enums'

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth()

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens)
  }

  const onError = (err) => {
    console.log('error logging in', err)
  }

  if (!authState) return null

  if( authState.isAuthenticated ) {
    return <Redirect to={{ pathname: Enums.routes.Dash }}/>
  }
  else {
    return <OktaSignInWidget
      config={config}
      onSuccess={onSuccess}
      onError={onError}/>
  }
}
export default Login
