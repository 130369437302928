import React, { Component } from 'react'
import axios from 'axios'
import paginate from 'jw-paginate'
import CustomModal from './components/CustomModal'
import TableButton from './components/TableButton'
import SearchField from './components/SearchField'
import { PackCreateConfirm, LoadingModal, ErrorModal } from './components/ConfirmationDialogue'
import { Pagination, PaginationRowsPerPageDropdown } from '../../common/Pagination'
import * as Enums from '../../common/enums'

export default function CustomPackTab(props) {
  
  const [currentModal, setActiveModal] = React.useState(Enums.activeModal.none)
  const [minutePackId, setMinutePackId] = React.useState("")
  const [minutePackName, setMinutePackName] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [numMinutesInPack, setNumMinutesInPack] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [validDurationMinutePack, setValidDurationMinutePack] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [featurePackId, setFeaturePackId] = React.useState("")
  const [featurePackName, setFeaturePackName] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [featurePackLogo, setFeaturePackLogo] = React.useState("0")
  const [featurePackFps, setFeaturePackFps] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [featurePackVideoWidth, setFeaturePackVideoWidth] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [featurePackVideoHeight, setFeaturePackVideoHeight] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [featurePackNumCharacters, setFeaturePackNumCharacters] = React.useState(
    Enums.buildStateObj("", false)
  )
  const [activePackData, setActivePackData] = React.useState()
  const [tableChanged, setTableChanged] = React.useState(false)

  // variable storage and input validation
  const storeMinutePackId = event => {
    setMinutePackId(event.target.value)
  }

  const validateMinutePackName = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      minutePackName.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value !== "") {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setMinutePackName(tmpStateObj)
  }

  const validateNumMinutes = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      numMinutesInPack.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setNumMinutesInPack(tmpStateObj)
  }

  const validateMinuteMonths = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      validDurationMinutePack.isValid
    )
    // -1 is for a pack that does not expire
    if (tmpStateObj.value && (tmpStateObj.value > 0 || tmpStateObj.value === -1)) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setValidDurationMinutePack(tmpStateObj)
  }

  const storeFeaturePackId = event => {
    setFeaturePackId(event.target.value)
  }

  const validateFeaturePackName = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      featurePackName.isValid
    )
    if (tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setFeaturePackName(tmpStateObj)
  }

  const storeFeaturePackLogo = event => {
    setFeaturePackLogo(event.target.value)
  }

  const validateFeaturePackFps = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackFps.isValid
    )
    // should the FPS be divisible by 30?
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setFeaturePackFps(tmpStateObj)
  }

  const validateFeaaturePackVideoWidth = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackVideoWidth.isValid
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setFeaturePackVideoWidth(tmpStateObj)
  }

  const validateFeaturePackVideoHeight = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      setFeaturePackVideoHeight.isValid
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setFeaturePackVideoHeight(tmpStateObj)
  }

  const validateFeaturePackNumCharacters = event => {
    let tmpStateObj = Enums.buildStateObj (
      (event.target.value),
      featurePackNumCharacters.isValid
    )
    if (tmpStateObj.value && parseInt(tmpStateObj.value) > 0) {
      tmpStateObj.isValid = true
    } else {
      tmpStateObj.isValid = false
    }

    setFeaturePackNumCharacters(tmpStateObj)
  }

  // search function
  function performSearch(searchTerm) {
    if (searchTerm === "") {
      props.setTable(props.unfilteredTable)
      props.setNumPages(Math.ceil(props.unfilteredTable.length / props.numRows))
      return
    }

    console.log(`search term: ${searchTerm}`)
    let filteredData = []
    props.unfilteredTable.forEach(function(item) {
      let searchVal = parseInt(searchTerm)
      if (item.id.includes(searchTerm)) {
        filteredData.push(item)
      } else if (props.tableType === Enums.packTableType.minutePack) {
        if (searchVal === item.minutes) {
          filteredData.push(item)
        }
      } else if (props.tableType === Enums.packTableType.featurePack) {
        if (searchVal === item.max_video_fps || searchVal === item.max_video_height || 
          searchVal === item.max_video_width || searchVal === item.max_custom_character) {
          filteredData.push(item)
        }
      }
    })
    props.setNumPages(Math.ceil(filteredData.length / props.numRows))
    props.setTable(filteredData)
  }

  function setActiveDataAndEnableModal(rowData) {
    setActivePackData(rowData)
    setActiveModal(Enums.activeModal.packDetailView)
  }

  function submitNewMinutePack() {
    if (minutePackName.isValid && numMinutesInPack.isValid && validDurationMinutePack.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      axios.post(`${process.env.REACT_APP_API_URL}/admin/createMinutesPack`, {
        "id": minutePackId,
        "name": minutePackName.value,
        "minutes": numMinutesInPack.value,
        "duration": validDurationMinutePack.value
      }).then((res) => {
        setActiveModal(Enums.activeModal.packCreateConfirmation)
        props.setDataLoaded(false)
        setTableChanged(true)
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function submitNewFeaturePack() {
    if (featurePackName.isValid && featurePackFps.isValid 
        && featurePackVideoWidth.isValid && featurePackVideoHeight.isValid
        && featurePackNumCharacters.isValid) {
      setActiveModal(Enums.activeModal.loadingModal)
      axios.post(`${process.env.REACT_APP_API_URL}/admin/createFeaturesPack`, {
        "id": featurePackId,
        "name": featurePackName.value,
        "logo": featurePackLogo,
        "max_video_fps": featurePackFps.value,
        "max_video_width": featurePackVideoWidth.value,
        "max_video_height": featurePackVideoHeight.value,
        "max_custom_character": featurePackNumCharacters.value
      }).then((res) => {
        setActiveModal(Enums.activeModal.packCreateConfirmation)
        props.setDataLoaded(false)
        setTableChanged(true)
      }).catch((error) => {
        props.processError(error)
        setActiveModal(Enums.activeModal.failureModal)
      })
    }
  }

  function buildButtonGroup() {
    let minuteBtnClass = "button"
    let featureBtnClass = "button"
    if (props.tableType === Enums.packTableType.minutePack) {
      featureBtnClass += " is-hidden"
    } else {
      minuteBtnClass += " is-hidden"
    }
    return(
      <div className="buttons is-centered is-grouped">
        <button
          className={minuteBtnClass}
          onClick={() => setActiveModal(Enums.activeModal.createMinutePack)}
        >
          Create Minutes Pack
        </button>
        <button
          className={featureBtnClass}
          onClick={() => setActiveModal(Enums.activeModal.createFeaturePack)}
        >
          Create Feature Pack
        </button>
      </div>
    )
  }

  function regenerateTableData(dataType) {
    props.setTableType(dataType)
    props.setDataLoaded(false)
    setTableChanged(true)
  }

  function buildTableTypeSelect() {
    let minuteBtnClass = "button" 
    let featureBtnClass = "button"
    if (props.tableType === Enums.packTableType.minutePack) {
      minuteBtnClass += " is-link is-selected"
    } else {
      featureBtnClass += " is-link is-selected"
    }

    return(
      <div className="buttons is-centered has-addons">
        <button
          className={minuteBtnClass}
          onClick={() => regenerateTableData(Enums.packTableType.minutePack)}
        >
          Display Minute Packs
        </button>
        <button
          className={featureBtnClass}
          onClick={() => regenerateTableData(Enums.packTableType.featurePack)}
        >
          Display Feature Packs
        </button>
      </div>
    )
  }

  function buildMinutePackModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">ID (Optional)</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={storeMinutePackId} type="text" placeholder="ID"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateMinutePackName} type="text" placeholder="Name"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Number of Minutes</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateNumMinutes} type="number" placeholder="0 minutes"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Valid Duration</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateMinuteMonths} type="number" placeholder="0 months"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildFeaturePackModal() {
    return(
      <section className="modal-card-body">
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">ID (Optional)</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={storeFeaturePackId} type="text" placeholder="ID"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateFeaturePackName} type="text" placeholder="Name"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Use Logo</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <div className="control">
                <div className="select is-fullwidth">
                  <select value={featurePackLogo} onChange={storeFeaturePackLogo}>
                    <option value="0">No Logo</option>
                    <option value="1">Use Logo</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Video FPS</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateFeaturePackFps} type="number" placeholder="30"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Video Width</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateFeaaturePackVideoWidth} type="number" placeholder="1920"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Video Height</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateFeaturePackVideoHeight} type="number" placeholder="1080"></input>
              </p>
            </div>
          </div>
        </div>
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Max Custom Characters</label>
          </div>
          <div className="field-body">
            <div className="field">
              <p className="control is-expanded">
                <input className="input" onChange={validateFeaturePackNumCharacters} type="number" placeholder="5"></input>
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
  
  function buildPackDetailModal() {
    let modalBodyInfo
    if (props.tableType === Enums.packTableType.minutePack) {
      modalBodyInfo = 
        <div className="content">
          <p><b>Plan ID:</b> {activePackData.id}</p>
          <p><b>Minutes:</b> {activePackData.minutes}</p>
          <p><b>Duration:</b> {`${activePackData.duration} months`}</p>
          <p><b>Renewable:</b> {activePackData.renewable === 1 ? "Yes" : "No"}</p>
        </div>
    } else {
      modalBodyInfo = 
        <div className="content">
          <p><b>Plan ID:</b> {activePackData.id}</p>
          <p><b>Logo:</b> {activePackData.logo === 1 ? "Yes" : "No"}</p>
          <p><b>Maximum Video FPS:</b> {activePackData.max_video_fps}</p>
          <p><b>Maximum Video Width:</b> {activePackData.max_video_width}</p>
          <p><b>Maximum Video Height:</b> {activePackData.max_video_height}</p>
          <p><b>Allowed Custom Characters:</b> {activePackData.max_custom_character}</p>
          <p><b>Renewable:</b> {activePackData.renewable === 1 ? "Yes" : "No"}</p>
        </div>
    }

    return(
      <section className="modal-card-body">
        {modalBodyInfo}
      </section>
    )
  }
  
  function displayModal() {
    switch(currentModal) {
      case Enums.activeModal.none:
        return false
      
      case Enums.activeModal.createMinutePack:
        return(
          <CustomModal {...props}
            title="Create Minutes Pack"
            func={() => submitNewMinutePack()}
            modalBody={() => buildMinutePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.createFeaturePack:
        return(
          <CustomModal {...props}
            title="Create Feature Pack"
            func={() => submitNewFeaturePack()}
            modalBody={() => buildFeaturePackModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.packCreateConfirmation:
        let pName = props.tableType === Enums.packTableType.minutePack ? minutePackName.value : featurePackName.value
        return(
          <CustomModal {...props}
            title={`${props.tableType} Created`}
            func="OK"
            modalBody={() => PackCreateConfirm(props.tableType, pName)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
      
      case Enums.activeModal.packDetailView:
        return(
          <CustomModal {...props}
            title={`${props.tableType} Details`}
            modalBody={() => buildPackDetailModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.loadingModal:
        return(
          <CustomModal {...props}
            title="Loading..."
            modalBody={() => LoadingModal()}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )

      case Enums.activeModal.failureModal:
        return(
          <CustomModal {...props}
            title="Error"
            modalBody={() => ErrorModal(props.errCode)}
            closeModal={() => setActiveModal(Enums.activeModal.none)}
          />
        )
    }
  }

  function getPackTableData() {
    axios.post(`${process.env.REACT_APP_API_URL}/admin/listPacks`, {
      "type": (props.tableType === Enums.packTableType.featurePack) ? 1 : 0
    }).then((res) => {
      // console.log(res.data)
      props.setTable(res.data)
      props.setNumPages(Math.ceil(res.data.length / props.numRows))
      props.setUnfilteredTable(res.data)
      props.setDataLoaded(true)
      setTableChanged(false)
    }).catch((error) => {
      console.log(`Error: Could not retrieve pack data for ${props.tableType}`)
      props.setDataLoaded(false)
    })
  }

  function buildPackTable() {
    if (!props.isDataLoaded) {
      return
    }
    // calculate page range to display
    let lowRange = ((props.currPage-1)*props.numRows) + 1
    let highRange = ((props.currPage-1)*props.numRows) + props.numRows
    if (highRange > props.table.length) {
      highRange = props.table.length
    }

    return(
      <div className="section">
        <div className="columns is-centered">
          <div className="column is-narrow">
            <div className="table-container">
              <table className="table is-striped is-hoverable">
                <thead>
                  <tr>
                    <th title="Pack Name">Available Packs</th>
                    <th title="Minutes">View Pack Info</th>
                  </tr>
                </thead>
                {buildPackTableRows()}
              </table>
            </div>
          </div>
        </div>
        <div className="columns is-mobile">
          {/* pagination pages and prev/next buttons */}
          <Pagination {...props}
            totalPages={props.numPages}
            setPageFunction={(page) => props.setCurrPage(page)}
            currTablePage={props.currPage}
            tableDataLength={props.table.length}
            rowsToDisplay={props.numRows}
          />

          {/* dropdown to select rows per page */}
          <div className="column has-text-right">
          <h5 className="subtitle is-5">Show rows: 
              <PaginationRowsPerPageDropdown {...props}
                rowsToDisplay={props.numRows}
                setRowsToDisplay={(row)=>props.setNumRows(row)}
                setNumPages={(pages)=>props.setNumPages(pages)}
                tableSize={props.table.length}
                setCurrPage={(page)=>props.setCurrPage(page)}
                currPage={props.currPage}
              />
            </h5>
          </div>

          {/* show user range of current page */}
          <div className="column has-text-left">
            <h5 className="subtitle is-5">Showing {lowRange} to {highRange} / {props.table.length}</h5>
          </div>
        </div>
      </div>
    )
  }

  function buildPackTableRows() {
    if(!props.isDataLoaded && !props.table) {
      return false
    }

    let packsOnPage = []
    let index = 0

    for (let i = 0; i < props.numRows; i++) {
      index = i + ((props.currPage-1)*props.numRows)
      if (index > props.table.length - 1) {
        break
      }

      let pack = props.table [ index ]
      const pid = pack.id
      const key = pid + index.toString()

      packsOnPage.push(
        <tr key={key}>
          <td>{pid}</td>
          <td>
            <TableButton
              label="Details"
              modalFunction={() => setActiveDataAndEnableModal(pack)}
            />
          </td>
        </tr>
      )
    }

    return(
      <tbody>
        {packsOnPage}
      </tbody>
    )
  }

  React.useEffect(() => {

    if (!props.isDataLoaded) {
      // console.log(`starting to get pack table data for ${props.tableType}`)
      props.setTable([])
      setActivePackData({})
      getPackTableData()
      props.setDataLoaded(true)
      setTableChanged(false)
    }

  }, [
    currentModal,
    minutePackId,
    minutePackName,
    numMinutesInPack,
    validDurationMinutePack,
    featurePackId,
    featurePackName,
    featurePackLogo,
    featurePackFps,
    featurePackVideoWidth,
    featurePackVideoHeight,
    featurePackNumCharacters,
    activePackData,
    tableChanged
  ])

  return(
    <div className="container">

      <div className="columns">
        <div className="column">
          <SearchField {...props}
            query={(term) => performSearch(term)}
          />
        </div>
        <div className="column">
          {buildButtonGroup()}
        </div>            
      </div>
      
      {buildTableTypeSelect()}
      {buildPackTable()}
      {displayModal()}
    </div>
  )
}