import React from 'react'
import { Link, Route } from "react-router-dom"
import * as Enums from '../common/enums'

/*************************************************************************** 
 * Functional component for the Animate 3D Version container
 ***************************************************************************/
export default function AnimVersionPanel(props) {

  // strings
  const appVersion = `v${process.env.REACT_APP_APP_VERSION}`

  /*************************************************************************** 
   * Builds the App Version tag at top of certain pages
   ***************************************************************************/
  return (
    <div className="columns m-0">
      <div className="column m-0 p-0 pl-4 has-text-left">
        <div className="subtitle is-4 text-3d-shadow ml-2"> {props.pageTitle}  </div>
      </div>
      <div className="column m-0 p-0 is-one-fifth has-text-right">
        <h2 className="subtitle is-5 text-3d-shadow mr-4"> {appVersion} </h2>
      </div>
    </div>
  )
}