import React from 'react';
import { Link } from "react-router-dom";
import '../../../styles/progress-3d.css'
import * as Enums from '../../common/enums'

export default function ProgressDisplay(props) {

  /******************************************************************
   ProgressArea - displays progress of the current job

   Expected Params:  props.animationJobProgress, props.progressMsg
   ******************************************************************/

  // cap the progress to less than 100% if job is still running
  const jobProgress = (props.animationJobProgress >= 100) 
    ? ("99%")
    : (props.animationJobProgress + "%")

  return (
    <div id="anim-fadein" className="columns rounded-corners bShadow anim-fadein" style={{height:'210px',backgroundColor: '#2d4e77'}}>
      <div className="column" >
        <div className="columns rounded-corners has-text-left" >
          <div className="column has-text-left">
            <div className="columns">
              <div className="column has-text-left">
                <h2 className="title logo-text-blink has-text-left pl-4" style={{color:'white'}}>{props.progressMsg}</h2>
              </div>  
              <div className="column has-text-right pr-6">
                { props.progressMsg === "Creating animations..." || props.progressMsg === "Creating 3D pose..." ?
                  <button type="button" className="button cancel-job-btn is-normal" onClick={props.confirmCancelJob}>Cancel</button>
                  :
                  <button type="button" className="button cancel-btn is-normal" style={{pointerEvents:'none', color:'transparent',backgroundColor:'transparent',borderColor:'transparent'}}>Cancel</button>
                }
              </div>
            </div>
            <div className="columns">
              <div className="column pt-5 pb-5 has-text-left">

                <div className="wrap">
                  <div className="cube">
                    <div className="front">
                      <div className="progress-3d" style={{width: jobProgress}}><span className="dm-brand-font has-text-weight-semibold">{props.animationJobProgress === 0 ? "" : `${jobProgress}`}</span></div>
                    </div>
                    <div className="back">
                      <div className="progress-3d" style={{width: jobProgress}}><span className="dm-brand-font has-text-weight-semibold">{props.animationJobProgress === 0 ? "" : `${jobProgress}`}</span></div>
                    </div>
                    <div className="top">
                      <div className="progress-3d" style={{width: jobProgress}}><span className="dm-brand-font has-text-weight-semibold">{props.animationJobProgress === 0 ? "" : `${jobProgress}`}</span></div>
                    </div>
                    <div className="bottom">
                      <div className="progress-3d" style={{width: jobProgress}}><span className="dm-brand-font has-text-weight-semibold">{props.animationJobProgress === 0 ? "" : `${jobProgress}`}</span></div>
                    </div>
                    <div className="left"></div>
                    <div className="right"></div>
                  </div>
                </div>

              {/* Previous (2D) anim progress meter:
                <div className="meter has-text-left" >
                  <span style={{width: jobProgress}}></span>
                </div>
              */}  
              </div>  
            </div>
          </div>  
        </div>
      </div>  
    </div>  
  )
}