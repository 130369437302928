import React from 'react'
import ReactTooltip from 'react-tooltip'

/*************************************************************************** 
 * React functional component for building a simple tooltip icon and hover text
 * @prop : [REQUIRED] text - The tooltip text to display
 * @prop : [REQUIRED] tipId - must be unique
 -------------------------------------------------------------------------------
 * @prop : [OPTIONAL] icon - the info icon to display, default = info icon
 * @prop : [OPTIONAL] iconColor - font color of the info icon
 * @prop : [OPTIONAL] iconSize - css acceptable sizes, default is "1rem"
 * @prop : [OPTIONAL] textColor - tooltip text color
 * @prop : [OPTIONAL] backColor - background color of the tooltip
 * @prop : [OPTIONAL] borderColor - tooltip border color
 * @prop : [OPTIONAL] onClick - callback function for click event on tip icon
 * @prop : [OPTIONAL] cursor - sets the mouse cursor through CSS
 * @prop : [OPTIONAL] isTipHtml - if true interpolates string value into html for rendering
 * @prop : [OPTIONAL] noMargins - if true no side margins on icon, default is mx-2 margins
 ***************************************************************************/
const DMToolTip = (props) => {

  let iconSpanStyle = {
    fontSize: (props.iconSize ? props.iconSize : "1rem")
  }
  if( props.iconColor ) {
    iconSpanStyle['color'] = props.iconColor
  }

  function buildDMToolTip() {
    return (
      <React.Fragment>
        <span className={"icon" + (props.iconColor ? " " : " dm-white ") + (props.noMargins ? " " : " mx-2 ") + (props.cursor ? props.cursor : "")} style={iconSpanStyle} onClick={props.onClick}>
          <i className={props.icon ? props.icon : "fas fa-info-circle"}
            data-for={props.tipId}
            data-border={true}
            data-border-color={props.borderColor ? props.borderColor : "black"}
            data-tip
            data-text-color={props.textColor ? props.textColor : "#2d4e77"}
            data-background-color={props.backColor ? props.backColor : "white"}>
          </i>
        </span>
        <ReactTooltip className="tip-max-w" id={props.tipId} place="right" effect="solid">
          <div className="subtitle has-text-left">
            { props.isTipHtml ? <div dangerouslySetInnerHTML={{__html: props.text}} /> : props.text }
          </div>
        </ReactTooltip>
      </React.Fragment>
    )
  }

  return buildDMToolTip()
}

export default DMToolTip