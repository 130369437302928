import React, {useContext, useEffect} from 'react'
import { Link } from 'react-router-dom'
import { AppStateContext } from '../../AppStateContext'
import * as Enums from '../common/enums'
import AnimVersionPanel from '../common/AnimVersionPanel'
import LoadingScreen from '../common/LoadingScreen'
import HelmetPageData from '../common/HelmetPageData'
// import ParticlesBackground from '../common/ParticlesBackground'

const docTitle = "VR Tracking SDK | DEEPMOTION"
const metaDesc = "Our full body VR Tracking SDK uses 3+ trackers to track your movements in real-time."

/******************************************************************
 Product page for DM Body Tracking SDK 
 ******************************************************************/
export default function VRSdkPage(props) {
  
  const appStateContext = useContext(AppStateContext)
  
  ///////////////////////////////////////////////////////////////////
  function buildPage() {
    // multiple groups have access to the DMBT real-time SDK
    let group = ""
    if( appStateContext.state.groups.includes(process.env.REACT_APP_AG_DM3PT_SDK_WIN) ) {
      group = process.env.REACT_APP_AG_DM3PT_SDK_WIN
    }
    else if( appStateContext.state.groups.includes(process.env.REACT_APP_AG_DM3PT_SDK_WIN_AND) ) {
      group = process.env.REACT_APP_AG_DM3PT_SDK_WIN_AND
    }

    // construct the download and documentation URLs...
    let sdkDownloadUrl = process.env.REACT_APP_API_URL + "/ss_download?email=" + appStateContext.state.email +'&group=' + group
    // let documentationUrl = process.env.REACT_APP_API_URL + "/ss_doc_doanload"

    return(
      <div id="anim-fadein" className="column">
        <AnimVersionPanel />
        <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
        {
          props.LOADING.show
          ?
          <LoadingScreen />
          :
          <React.Fragment>
          <div className="columns" style={{marginTop:'100px'}}>
            <div className="column has-text-centered">
              <h1 className="title is-1"> DEEPMOTION VR Tracking SDK </h1>
              <h2 className="subtitle is-3">Immersive full body avatars using minimal trackers</h2>
            </div>
          </div>
          <div className="columns" >
            <div className="column has-text-centered">
              <a className="button library-btn is-large" href={sdkDownloadUrl}> Download SDK </a>
            </div>
          </div>
          </React.Fragment>
        }
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  //////////////////
  return buildPage()
  //////////////////
}