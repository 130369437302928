import React, { useEffect, useContext } from 'react'
import { Link, Route, withRouter} from 'react-router-dom'
import { useStateWithCallbackLazy } from 'use-state-with-callback'
import {redirectToCustomerPortal, getUserPlanData, listSubscriptions} from '../api/apiRequests'
import {Helmet} from 'react-helmet'
import { AppStateContext } from '../../AppStateContext'
import queryString from 'query-string'
import * as Enums from '../common/enums'
// import ParticlesBackground from '../common/ParticlesBackground'
import AnimVersionPanel from '../common/AnimVersionPanel'

const titlePage = "Profile"
const titleAccount = "Account Plan"
const titleName = "User name"
const titleEmail = "User email"
const titleSub = "Plan"
const titleBillCycle = "Billing Cycle"
const titleUsedTime = "Current cycle time (used)"
const titleRemainingTime = "Current cycle time (remaining)"
const titlePlan = "Account Info"
const titleChangePlan = "Update Subscription"
const titleCloseAccount = "Close Account"
const titleViewPlans = "View Plans & Pricing"
const textAnim3dHome = "Animate 3D Home"
const textBackToDash = "Back to Dashboard"
const docTitle = "Profile | DEEPMOTION"
const metaDesc = "Account profile page for DeepMotion Animate 3D service"

/*************************************************************************** 
 * [FUNCTIONAL COMPONENT]
 ***************************************************************************/
export default function AccountProfilePage(props) {

  const [dataLoaded, setDataLoaded] = React.useState(false)
  const appStateContext = useContext(AppStateContext)

  ////////////////////////////////////////////////////////////////////////
  // Generates url and redirects user to their respective Customer Portal
  ////////////////////////////////////////////////////////////////////////
  function redirectToCheckout() {
    const userInfo = {
      email: appStateContext.state.email,
      customerId: appStateContext.state.subscriptionInfo.cid
    }
    redirectToCustomerPortal(userInfo)
    .then((res) => {
      // ==> Upon success user redirect user to customer portal
      window.location.href = res.data
    })
    .catch((error) => {
      console.error(`Error: Could not open Checkout portal!\n${error}`)
      props.setErrorDialogInfo(true, Enums.eCodes.OtherError, "Could Not Open Checkout Portal")
    })
  }

  ////////////////////////////////////////////////////////////////////////
  // Get latest subscription information from payment system
  //
  // @param cb : optional callback function
  ////////////////////////////////////////////////////////////////////////
  function getLatestAccountSubscription(cb) {
    
    if( !appStateContext.state.subscriptionInfo.cid || appStateContext.state.subscriptionInfo.cid === '' || appStateContext.state.subscriptionInfo.cid === 'undefined' ) {
      props.setLOADING({...props.LOADING, ...{show: false}})
      props.setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Profile Data")
      return
    }
    listSubscriptions(appStateContext.state.subscriptionInfo.cid)
    .then((res) => {
      if( res.data.data.length > 0 ) {
        let subInfo = {}
        for( let i = 0; i < res.data.data.length; i++ ) {
          if( res.data.data[i].plan.name !== appStateContext.state.subscriptionInfo.name && (res.data.data[i].status === 'active' || res.data.data[i].status === 'past_due') ) {
            // only update subscription if we find an active one
            subInfo.name = res.data.data[i].plan.name
            subInfo.status = res.data.data[i].status
            subInfo.cid = appStateContext.state.subscriptionInfo.cid
            subInfo.current_period_start = res.data.data[i].current_period_start
            subInfo.current_period_end = res.data.data[i].current_period_end
            subInfo.cancel_at_period_end = res.data.data[i].cancel_at_period_end
            subInfo.cancel_at = res.data.data[i].cancel_at
            appStateContext.dispatch({subscriptionInfo: subInfo}, () => {
              // can't break since inside a callback...
              i = res.data.data.length
            })
          }
        }
        // if( !subInfo ) {
        //   // if subInfo is null after above for-loop then we found 
        //   // subscription(s) but none were active
        //   // appStateContext.dispatch({loading: false})
        //   // setDataLoaded(true)
        // }
        if( cb ) {
          return cb()
        }
      }
      else {
        // else no active subscriptions found, ensure the UI indicates user is 
        // on the Freemium plan in case they just downgraded from a paid plan
        if( appStateContext.state.subscriptionInfo.name !== Enums.accountPlansInfo[0].name ) {
          console.log(`!!! appStateContext.state.subscriptionInfo --> ${JSON.stringify(appStateContext.state.subscriptionInfo, null, 4)}`)
          subInfo.name = Enums.accountPlansInfo[0].name
          subInfo.status = 'active'
          subInfo.cid = appStateContext.state.subscriptionInfo.cid
          appStateContext.dispatch({subscriptionInfo: subInfo}, () => {
            if( cb ) {
              return cb()
            }
          })
        }
        else {
          if( cb ) {
            return cb()
          }
        }
      }
    })
    .catch((error) => {
      // props.setLOADING({...props.LOADING, ...{show: false}})
      setDataLoaded(true)
      console.log(`Could not retrieve user profile information - \n${error}`)
      props.setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Profile Data")
    })
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders User Profile info including name and email
  ////////////////////////////////////////////////////////////////////////
  function buildUserProfileSection() {
    return (
      <div>
        <div className="columns">
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleName} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {appStateContext.state.firstName} {appStateContext.state.lastName} </h2>
          </div>
        </div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleEmail} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {appStateContext.state.email} </h2>
          </div>  
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders Account subscription info section
  ////////////////////////////////////////////////////////////////////////
  function buildAccountInfoSection() {
    const productData = retriveProductDataFromName(appStateContext.state.subscriptionInfo.name)
    const currCycleStartDate = Enums.dateConverter(appStateContext.state.subscriptionInfo.current_period_start, true)
    const currCycleEndDate = Enums.dateConverter(appStateContext.state.subscriptionInfo.current_period_end, true)

    const usedRounded = Math.ceil( appStateContext.state.accountTotals.maxTimeInSeconds - appStateContext.state.accountTotals.remainingTimeInSeconds )
    const remainingRounded = Math.floor( appStateContext.state.accountTotals.remainingTimeInSeconds )
    const usedMonthlyTime = (!Enums.secondsToTime( usedRounded ) || Enums.secondsToTime( usedRounded ) === "") ? "00:00:00" : Enums.secondsToTime( usedRounded )
    const remainingMonthlyTime = (!Enums.secondsToTime( remainingRounded ) || Enums.secondsToTime( remainingRounded ) === "") ? "00:00:00" : Enums.secondsToTime( remainingRounded )
    const subscriptionName = appStateContext.state.subscriptionInfo.isEnterpriseUser ? Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].name : appStateContext.state.subscriptionInfo.name

    return (
      <div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleSub} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {subscriptionName}</h2>
          </div>  
        </div>
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleBillCycle} </h2>
          </div>
          <div className="column">
          {
            appStateContext.state.subscriptionInfo.cancel_at_period_end
            ?
              <h2 className="subtitle is-5 has-text-danger"> Your plan will be canceled on {Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at, true)} </h2>
            :
              <h2 className="subtitle is-5 dm-brand-font"> {currCycleStartDate} - {currCycleEndDate} </h2>
          }
          </div>
        </div>
        
        {/*** Temporarily removing mins from profile page
        
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleUsedTime} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {usedMonthlyTime} (hh:mm:ss) </h2>
          </div>  
        </div>
        
        <div className="columns">  
          <div className="column is-one-third">
            <h2 className="title is-5"> {titleRemainingTime} </h2>
          </div>
          <div className="column">
            <h2 className="subtitle is-5 dm-brand-font"> {remainingMonthlyTime} (hh:mm:ss) </h2>
          </div>  
        </div>

        ***/}
        
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  // Renders the entire profile page
  ////////////////////////////////////////////////////////////////////////
  function buildProfilePage() {
    if( props.loading ) {
      return (
        <React.Fragment>
          <div className="section" id="anim-fadein">
            <div className="columns is-vcentered">
              <div className="column is-one-third has-text-centered is-vcentered">  
                <p className="title is-4">Loading...</p>
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
    else {
      return (
        
        <div className="column mt-0 anim-fadein" id="anim-fadein" >
          {helmetData}
          <AnimVersionPanel />
          <div className="m-5 p-5 br-4" style={{background: 'rgba(239, 245, 251, 0.65)', border: '1px solid #2d4e77' }}>
            <div className="columns">
              <div className="column">
                <h1 className="subtitle is-3 profile-label has-text-black" style={{marginBottom: '15px'}}> {titlePage} </h1>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                {buildUserProfileSection()}
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <h1 className="subtitle is-3 profile-label has-text-black mt-5"> {titleAccount} </h1>
              </div>
            </div>
            <div className="columns mgTop-20">
              <div className="column">
                {buildAccountInfoSection()}
              </div>
            </div>
            <div className="columns mgTop-20">
              <div className="column">

                <div className="field is-grouped">
                  <p className="control">
                    { 
                      appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                      ? 
                      <a className="button library-btn is-normal" target="_blank" rel="noopener noreferrer" href="https://www.deepmotion.com/pricing"><span className="no-side-margins">{titleViewPlans}</span></a>
                      : 
                      <a className="button library-btn is-normal" onClick={()=>redirectToCheckout()} ><span className="no-side-margins">{titleChangePlan}</span></a>
                    }
                  </p>
                  <p className="control">
                    { 
                      appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                      &&
                      <a className="button cancel-btn is-normal" onClick={()=>props.DISPATCH({confirmDialogId: Enums.confirmDialog.closeUserAccount})} ><span className="no-side-margins">{titleCloseAccount}</span></a>
                    }
                  </p>
                </div>
                
              </div>
            </div>
            
            {/* Don't show Anim3D home button if users does not have product access */}
            {
              appStateContext.state.accessList[0]
              &&
              <div className="columns mb-0">
                <div className="column has-text-left">
                  <Link to={!appStateContext.state.jobsData.length ? Enums.routes.Anim3dGuidedFTE : Enums.routes.Anim3d}> {textAnim3dHome} </Link>
                </div>
              </div>
            }

            <div className="columns">
              <div className="column has-text-left">
                <Link to={Enums.routes.Dash}> {textBackToDash} </Link>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////////
  // Retrieves associated product metadata based on name
  ////////////////////////////////////////////////////////////////////////
  function retriveProductDataFromName( planName ) {
    for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
      if( planName === Enums.accountPlansInfo[i].name ) {
        return Enums.accountPlansInfo[i]
      }
    }
    // if none matched assume Freemium
    return Enums.accountPlansInfo[0]
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }
  // set page title and meta data
  var helmetData = seoMetaData_EN()

  //---------------------------------------------------------
  // React useEffect() hook for when state changes:
  //---------------------------------------------------------
  useEffect(() => {
    if( !dataLoaded ) {
      if( queryString.parse(props.location.search).return ) {
        if( appStateContext.state.subscriptionInfo.cid ) {
          getLatestAccountSubscription( () => {
            getUserPlanData()
            .then( res => props.updateUserPlanData(res.data) )
            .then( data => {
              let tmpData = appStateContext.state.subscriptionInfo
              if( tmpData.name === Enums.accountPlansInfo[0].name ) {
                // Special case for Freemium plans (otherwise data is read
                // from Stripe backend for paid subscriptions)
                let startDate = new Date(data.user.plan_expiary_date)
                // Set cycle start date to one month ago
                startDate.setMonth(startDate.getMonth() - 1)
                tmpData.current_period_start = Date.parse(startDate)/1000
                tmpData.current_period_end = data.user.plan_expiary_date/1000
                appStateContext.dispatch({subscriptionInfo: tmpData}, () => {
                  setDataLoaded(true)
                  return
                })
              }
              else {
                setDataLoaded(true)
              }
            })
          })
        }
      }
    }
    else {
      if( props.LOADING ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [dataLoaded, appStateContext.state.subscriptionInfo.cid])

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( queryString.parse(props.location.search).return || !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved, appStateContext.state.subscriptionInfo])

  ////////////////////////////////////////////////////////////////////////
  // Main Render for this Functional Component 
  ////////////////////////////////////////////////////////////////////////
  return buildProfilePage()

}