import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import logo from '../../images/logo.png'
import axios from 'axios'
import queryString from 'query-string'
import * as Enums from '../common/enums'
import '../../styles/form.css'

/* 
 * React Component: ForgotPwdPage
 *
 * Custom page & form for letting users reset their account
 * passwords in self-service fashion. Upon successful reset
 * an email is sent with a temporary one-time password the 
 * user can use to reset their password then must change to
 * something else. 
 */
class ForgotPwdPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: null,          // the user's email address
      error: {              
        val: false,         // true if error response from backend
        code: null,         // error status code returned
      },
      isSuccessful: false,  // true if reset request is successfull
      loading: false,       // true while reset attemp is in progress
      emailIsValid: false,  // whether current email is valid or not
      returnToPricing: false  // returns to pricing page if forgot request came from
                              // checkout authentication flow on company website
    }
    this.onClickHandler = this.onClickHandler.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)    
  }

  componentWillUnmount() {
    this.setState({ email: null, error: {val:false,code:null}, isSuccessful: false, loading: false, emailIsValid: false })
  }

  componentDidMount() {
    const returnUri = queryString.parse(this.props.location.search).return
    if( returnUri === "checkout" ) {
      this.setState({returnToPricing: true}, () => {
        return
      })
    }
  }

  // handle email input
  onChangeHandler(e) {
    const name = e.target.name
    const value = e.target.value
    if (name === 'email') { 
      this.setState({ email: value }, () => {
        if( Enums.validEmailRegex.test(value) ) {
          this.setState({emailIsValid: true}, () => {
            return
          })
        }
        else {
          this.setState({emailIsValid: false}, () => {
            return
          })
        }
      })
    }
  }

  // handle form submission
  onClickHandler(e) {
    e.preventDefault()
    this.setState({loading: true}, () => {
      // Okta API request to reset the user's password
      axios.post(`${process.env.REACT_APP_API_URL}${Enums.routes.ForgotPwdPage}`, {
      email: this.state.email
      }).then(() => {
        this.setState({ isSuccessful: true, error: {val:false,code:null}, loading: false })
      } )
      .catch((err) => {
        this.setState({ error: {val:true, code:err.message}, loading: false }, () => {
          console.log(err)
          return
        })
      })
    })
    
  }

  //--- navigates the user back to sign in page
  goToSignInPage() {
    this.props.history.push(Enums.routes.SignIn)
  }

  //--- reset all form fields and state vars
  resetForm() {
    this.setState({
      email: null,
      error: {
        val: false,
        code: null
      },
      isSuccessful: false,
      loading: false,
      emailIsValid: false
    }, () => { 
      return 
    })
  }

  ////////////////////////////////////
  // Render the reset pwd form:
  ////////////////////////////////////
	render () {

    // strings
    const resetPwd = 'Reset Password'
    const resetMsg = 'Enter your email address to reset your password'
    const backToSignIn = 'Back to Sign In'
    const backStr = 'Back'
    const tryAgain = 'Try Again'
    const resetGeneric = 'If a matching account was found, a temporary password was sent to '
    let resetButton = null

    // disable the reset button if email is not valid
    if(this.state.emailIsValid) {
      resetButton = <button type="submit" className="button button-block" > {resetPwd} </button>
    }
    else { 
      resetButton = <button disabled className="button button-block" > {resetPwd} </button>
    }

    const paragraphStyle = {
      padding: '40px',
      fontSize: '1.25rem',
      marginTop: '15px',
      marginBottom: '15px'
    }
    const signInButtonStyle = {
      marginTop: '10px',
      marginBottom: '10px'
    }

    let currFormState = 
      <form onSubmit={ this.onClickHandler }>
        <div className="field-wrap">
          <h3 className="subtitle is-5" > {resetMsg} </h3>
          <input type="email" name="email" placeholder="email" onChange={ this.onChangeHandler } />
        </div>
        {resetButton}
        <div className="columns has-text-centered" style={{marginTop:'0'}}>
          <div className="column" style={{paddingBottom:'0'}}>
            { 
              this.state.returnToPricing 
              ?
              <a href="https://www.deepmotion.com/pricing" > {backStr} </a>
              :
              <Link to={ Enums.routes.SignIn } > {backStr} </Link>
            }
          </div>
        </div>
      </form>

    // 
    // If error or successful, tell user it may have succeeded and give option to 
    // go back to signin or back to reset form
    //
    if (this.state.error.val || this.state.isSuccessful) {
      currFormState = 
        <div style={ paragraphStyle }> 
          {resetGeneric}<b>{this.state.email}</b>
          <div className="columns has-text-centered">
            <div className="column" style={{paddingBottom:'0'}}>
              <button onClick={()=>this.goToSignInPage()} className="button button-block" style={signInButtonStyle}> {backToSignIn} </button>
            </div>
          </div>
          <div className="columns has-text-centered" style={{marginTop:'0'}}>
            <div className="column" style={{paddingTop:'0',paddingBottom:'0'}}>
              { 
                this.state.returnToPricing 
                ?
                <a href="https://www.deepmotion.com/pricing" > {backStr} </a>
                :
                // <Link to={ Enums.routes.SignIn } > {backToSignIn} </Link>
                <Link onClick={()=>this.resetForm()} > {tryAgain} </Link>
              }
            </div>
          </div>
        </div>
    }

    //----- Render the Forgot Password form:
    return (
      <div id="anim-fadein">
        <div className="form">   
          <div className="logo"><img src={logo} /></div>

          { currFormState }

          { 
            this.state.loading && 
            <div className="columns has-text-centered">
              <div className="column">
                <button className="button is-link is-outlined is-loading">Loading</button>
              </div>  
            </div>
          }
        </div>
      </div>
    )
  }
}

export default withRouter(ForgotPwdPage)