import React from 'react';
import * as Enums from '../../common/enums'
import '../../../sass/App.scss';

export default class Dropzone extends React.Component {
  constructor(props) {
    super(props)
    this.state = {hightlight: false}
    this.fileInputRef = React.createRef()

    this.openFileDialog = this.openFileDialog.bind(this)
    this.onFilesAdded = this.onFilesAdded.bind(this)
    this.onDragOver = this.onDragOver.bind(this)
    this.onDragLeave = this.onDragLeave.bind(this)
    this.onDrop = this.onDrop.bind(this)
  }

  openFileDialog() {
    if (this.props.disabled) return
    this.fileInputRef.current.click()
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return
    const files = evt.target.files

    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
  }
  
  onDragOver(evt) {
    evt.preventDefault()

    if (this.props.disabled) return

    this.setState({ hightlight: true })
  }

  onDragLeave() {
    this.setState({ hightlight: false })
  }

  onDrop(evt) {
    evt.preventDefault()

    if (this.props.disabled) return

    const files = evt.dataTransfer.files

    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files)
      this.props.onFilesAdded(array)
    }
    this.setState({ hightlight: false })
  }

  fileListToArray(list) {
    const array = []
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i))
    }
    return array
  }
  
  // if prop "type" is passed with value "custom-character" then we 
  // want to allow .fbx file uploads, otherwise we default to the
  // standard video upload component
  buildDragInput() {
    let acceptList = "video/mp4,video/quicktime,video/avi,image/jpeg,image/jpg,image/png,image/bmp"
    if( this.props.type === "custom-character" ) {
      // acceptList = ".fbx,.glb,.gltf,.vrm"
      acceptList = ".fbx,.glb,.vrm"
    }
    return(
      <input
        ref={this.fileInputRef}
        className="FileInput"
        type="file"
        accept={acceptList}
        onChange={this.onFilesAdded}
      />
    )
  }

  render() {
    let dragMessage = this.props.animJobSettings.jobType === Enums.jobTypes.animation ? 
      "Drag and drop video" : "Drag and drop image"
    if( this.props.type === "custom-character" ) {
      dragMessage = "Drag and drop 3D model"
    }
    return (
        <div
          className={`Dropzone ${this.state.hightlight ? 'Highlight animated-border' : 'animated-border'}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          style={{ cursor: this.props.disabled ? 'default' : 'pointer' }}
        >

          {this.buildDragInput()}

          <p className="subtitle mb-1"> {dragMessage} </p>
          <p className="subtitle mb-2" >or</p>
          <p className="subtitle is-6 has-text-link has-text-weight-semibold" >Browse files</p>
        
        </div>
    )
  }
}
