import React from 'react'
import ReactTooltip from 'react-tooltip'
import Knob from './KnobElem'
import OutsideAlerterFunc from '../common/OutsideAlerterFunc'

/*************************************************************************** 
 * React functional component for building Knob elem triggered by drop down
 * @prop : value - current drop down state value
 * @prop : onChange - callback function for value change
 * @prop : isDisabled - disables the dropdown with lock icon and no-cursor mouse hover
 * @prop : label - main label, if null then no tool tip is shown either
 * @prop : tipText - string for the info icon text, can be html if isTipHtml=true
 * @prop : isTipHtml - if true interpolates string value into html for rendering
 * @prop : buttonClass - optional custom css button class
 ***************************************************************************/
const DMDropDownKnob = (props) => {

  const activeClass = "is-active"

  // local state hook(s):
  const [cssClass, setCssClass] = React.useState("")

  const toggleDropDownClass = (val) => {
    if( val === 'close' ) {
      // clear the class if called with value 'close'
      setCssClass("")
    }
    else {
      // else flip the value between is-active and null string
      setCssClass(cssClass === "" ? activeClass : "")
    }
  }

  const buildDropDownKnob = () => {

    return (
      <div className={"dropdown mx-2 " + cssClass} >
        { props.isDisabled
          ?
          <div className="dropdown-trigger no-cursor">
            <button disabled className="button disabled-item no-cursor" aria-haspopup="true" aria-controls={props.id} style={{border:'none',minWidth:'60px'}}>            
              <span className="has-text-weight-light is-capitalized">{props.value <= 0 ? "0.0" : (props.value === 1 ? "1.0" : props.value) }</span>
            </button>
          </div>
          :
          <div className="dropdown-trigger" onClick={()=>toggleDropDownClass()}>
            <button className={`button ${props.buttonClass ? props.buttonClass : "dm-brand-font"}`} aria-haspopup="true" aria-controls={props.id}>
              <span className="has-text-weight-semibold is-capitalized">{props.value <= 0 ? "0.0" : (props.value === 1 ? "1.0" : props.value) }</span>
              <span className="icon is-small">
                <i className="fas fa-angle-down" aria-hidden="true"></i>
              </span>
            </button>
          </div>
        }
        <div id={props.id} className="dropdown-menu menu-grow" role="menu">
          <div className="dropdown-content dropdown-knob-bkgd" >
            {buildDropDownContent()}
          </div>
        </div>
      </div>
    )
  }

  // builds Knob UI element for PE Smoothness selector
  const buildDropDownContent = () => {

    return (

      <OutsideAlerterFunc clearActiveDropDowns={()=>toggleDropDownClass('close')}>
        <div style={{display:'inline-flex'}}>
          {
            props.isDisabled
            ?
            <div className="no-cursor">
              <Knob className="mr-4 no-cursor" onMouseDown={null} onClick={null}
                disabled={true}
                size={46}
                numTicks={9}
                degrees={180}
                min={1}
                max={100}
                value={0}
                color={true}
              />
            </div>
            :
            <div className="cursor-grab">
              <Knob className="mr-4"
                size={46}
                numTicks={9}
                degrees={180}
                min={1}
                max={100}
                color={true}
                value={props.value}
                onChange={props.onChange}
              />
            </div>
            
          }
          {
            props.label
            &&
            <span className={"subtitle is-5 ml-5 " + (props.isDisabled ? " no-cursor dm-gray" : " has-text-white") } style={{marginTop:'auto',marginBottom:'auto'}}>
              {
                props.isDisabled
                &&
                <span className="icon is-small ml-2 mr-4">
                  <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
                </span>
              }
              {props.label}
              <span className="mr-2">
                { props.value === 0.0
                  ?
                  " 0.0"
                  :
                  <span className="has-text-success">
                  {
                    (parseInt(props.value) === 1 ? " 1.0" : (" " + props.value) ) 
                  }
                  </span>
                }
              </span>
            <span className="icon dm-white" style={{fontSize:'1rem', cursor:'auto'}}>
              <i className="fas fa-info-circle"
                data-for={props.label.split(' ').join('')+"-tip"} 
                data-border={true}
                data-border-color="black"
                data-tip
                data-text-color="#2d4e77"
                data-background-color="white">
              </i>
              </span>
            </span>
          }
          {
            props.label && props.tipText
            &&
            <ReactTooltip className="tip-max-w" id={props.label.split(' ').join('')+"-tip"} place="right" effect="solid">
              <div className="subtitle is_4">
                { props.isTipHtml ? <div dangerouslySetInnerHTML={{__html: props.tipText}} /> : props.tipText }
              </div>
            </ReactTooltip>
          }
        </div>
      </OutsideAlerterFunc>
    )
  }

  return buildDropDownKnob()
}

export default DMDropDownKnob