import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, { Navigation, Pagination, EffectCube } from 'swiper'
import * as Enums from './enums'
import 'swiper/swiper-bundle.css'
import defaultModel1 from '../../images/animate-3d/female.jpg'
import defaultModel2 from '../../images/animate-3d/female-slim.jpg'
import defaultModel3 from '../../images/animate-3d/male.jpg'
import defaultModel4 from '../../images/animate-3d/male-fat.jpg'
import defaultModel5 from '../../images/animate-3d/male-young.jpg'
import defaultModel6 from '../../images/animate-3d/child.jpg'
const imgList = [defaultModel1, defaultModel2, defaultModel3, defaultModel4, defaultModel5, defaultModel6]
React.useLayoutEffect = React.useEffect 

// import additional swiper modules:
SwiperCore.use([Navigation, Pagination, EffectCube])

export default function CharacterSwiperDefault(props) {

  function buildCharacterCard(index) {
    return (
      <div className="card dm-brand-border-lg">
        <div className="card-image">
          <figure className="image is-16by9 m-0" style={{borderRadius:'4px'}}>
            <img src={imgList[index-1]} alt={`image-${Enums.characterModels[`${index}`].fileName}`} />
          </figure>
        </div>
        <div className="card-content dm-brand has-text-centered">
          <div className="media">
            <div className="media-content">
              <p className="title is-4 mb-4 has-text-white">{Enums.characterModels[`${index}`].uiName}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // dynamically build the swiper slides using props data passed in
  let swiperSlides = []
  Object.entries(Enums.characterModels).forEach(([key, value], index) => {
    let content = buildCharacterCard(index+1)
    swiperSlides.push(
      <SwiperSlide key={`slide-${key}`} className="bShadow">
        {content}
      </SwiperSlide>
    )
  })

  function updateSelectedModel(index) {
    props.setCurrDownloadModel(Enums.characterModels[`${index}`].fileName)
    // props.DISPATCH({currDownloadModel: Enums.characterModels[`${index}`].fileName})
  }

  //----------------------------------------------------------
  // Render the component...
  //----------------------------------------------------------
  return (

    <React.Fragment>
      <Swiper id="anim-fadein"
        observer={true}
        observeParents={true}
        slidesPerView={1}
        spaceBetween={5}
        navigation
        effect="cube"
        loop={true}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => updateSelectedModel(swiper.realIndex+1)}
      >
        {swiperSlides}

      </Swiper>
    </React.Fragment>
  )
}