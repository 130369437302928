import React from 'react'

export default function TableButton(props) { 
  return(
    <div className="buttons">
      <button
        className="button is-small preview-btn"
        onClick={() => props.modalFunction()}
      >
        {props.label}
      </button>
    </div>
  )
}