import React from 'react'

export default function SearchField(props) { 

  const [searchQuery, setSearchQuery] = React.useState("")

  const storeSearchQuery = event => {
    setSearchQuery(event.target.value.toString())
    props.query(event.target.value.toString())
  }

  function searchInput() {

    return(
      <p className="control">
        <input className="input" onChange={storeSearchQuery} type="text" placeholder="Filter Results..." />
      </p>
    )
  }

  function searchButton() {

    return(
      <p className="control">
        <a className="button is-link" onClick={() => props.query(searchQuery)}>Search</a>
      </p>
    )
  }

  React.useEffect(() => {
  }, [
    searchQuery
  ])

  return(
    <div className="field is-horizontal">
      <div className="field-body">
        <div className="field has-addons ml-6">
          {searchInput()}
          {searchButton()}
        </div>
      </div>
    </div>
  )
}