import React, { useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {Helmet} from 'react-helmet'
import axios from 'axios'
import HCaptcha from '@hcaptcha/react-hcaptcha'
// import ParticlesBackground from '../common/ParticlesBackground'
import useWindowSize from '../common/useWindowSize'
import * as Enums from '../common/enums'
const qs = require('querystring')

//------------------------------------------------------------
  const appId = process.env.REACT_APP_PODIO_ANIM3D_AID
  const appToken = process.env.REACT_APP_PODIO_ANIM3D_APT
  const clientId = process.env.REACT_APP_PODIO_ANIM3D_CID
  const clientSecret = process.env.REACT_APP_PODIO_ANIM3D_CLS
  const redirectUri = process.env.REACT_APP_PODIO_RED
  const csrfState = process.env.REACT_APP_PODIO_CSR
  const authUrl = process.env.REACT_APP_PODIO_AUTH_URL
  const restApiUrl = process.env.REACT_APP_PODIO_BASE_URL + `/${appId}`
  //------------------------------------------------------------
  
  // SEO 
  const docTitle = "Create Account | DEEPMOTION"
  const metaDesc = "Create your Animate 3D account today and harness the power of AI-based body tracking animation. Generate 3D animations from 2D videos."

  // form strings
  const submitButton    = "Get Started"
  const tryAgainButton  = "Try Again"
  const signInButton    = "Sign In"
  const backButton      = "Home"
  const goToSignInPage  = "Open Animate 3D"
  const formTitle       = "Create Freemium Account"
  const formText        = "No credit card required" 
  const interestTitle   = "What best describes your interest in Animate 3D?"
  const optionOtherEx   = "If Other, please explain"
  const howHearTitle    = "How did you hear about DeepMotion?"
  const inProgressTitle = "Submitting request..."
  const successTitle    = "Your DeepMotion Account Has Been Created!"
  const accountExistsTitle = "DeepMotion Account Already Exists"
  const accountExistsText = "We found an existing account using the email you submitted, please sign in using the link below or use a different email address."
  const successSub1     = "Please check your email for an activation link to access the service, have fun!"
  const successSub2     = "Once ready you can sign in here"
  const errorTitle      = "Sorry, something went wrong..."
  const errorSubtitle   = "We encountered an error submitting your request, unable to connect to server."
  const errorSubtitle2  = "If the problem continues please contact Deepmotion Support."


/*================================================== 
  [FUNCTIONAL COMPONENT]
  Create New Account Sign Up Form
 ===================================================*/
export default function Anim3DSignUpPage(props) {

  // browser history
  let history = useHistory()

  //************************************************************ 
  // State variables for sign up form entries: 
  //************************************************************
  const [firstName, setFirstName] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [lastName, setLastName] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [userEmail, setUserEmail] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [userPwd, setUserPwd] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [company, setCompany] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [solutionsInterestList, setSolutionsInterestList] = React.useState(
    // solutions list uses array of booleans for its value...
    Enums.buildStateObj([false,false,false,false,false], false, Enums.inputSpanHide, Enums.normalInputClass)
  )
  const [solutionsInterestOtherField, setSolutionsInterestOtherField] = React.useState(
    Enums.buildStateObj("", true, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const [howHearField, setHowHearField] = React.useState(
    // how hear field uses single integer value
    Enums.buildStateObj(0, false, Enums.inputSpanHide, Enums.normalSelectClass)
  )
  const [howHearOtherField, setHowHearOtherField] = React.useState(
    Enums.buildStateObj("", true, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const [promoCode, setPromoCode] = React.useState(
    Enums.buildStateObj("", true, Enums.inputSpanHide, Enums.normalInputClass)
  )
  // used for controlling form submission workflow
  const [submitStatus, setSubmitStatus] = React.useState(Enums.FORM_STATE.ready)
  // used to parse what type of error may have occurred
  const [errorType, setErrorType] = React.useState(null)
  // google recaptch configuration
  const [captchaConfigured, setCaptchaConfigured] = React.useState(false)
  const [captchaValue, setCaptchaValue] = React.useState(null)
  
  // Using a react hook to dynamically retrieve window dimensions for 
  // responsiveness
  const windowSize = useWindowSize();

  /*
   * onClick() event for First Name field
   */
  const validateFirstNameField = event => {
    let fName = event.target.value
    if( !Enums.validNameRegex.test(fName) || fName[0] === '.') {
      fName = fName.slice(0,-1)
    }
    // capitalize the first letter
    fName = capitalizeStr(fName)

    let tmpStateObj = Enums.buildStateObj(
      fName,
      firstName.isValid,
      firstName.span,
      firstName.iClass
    )
    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minNameLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //*** finally we update the state with latest data
    if( !Enums.compareStates(firstName,tmpStateObj) ) {
      setFirstName(tmpStateObj)
    }
  }

  /*
   * onClick() event for Last Name field
   */
  const validateLastNameField = event => { 
    let lName = event.target.value
    if( !Enums.validNameRegex.test(lName) || lName[0] === '.') {
      lName = lName.slice(0,-1)
    }
    // capitalize the first letter
    lName = capitalizeStr(lName)
    let tmpStateObj = Enums.buildStateObj(
      lName,
      lastName.isValid,
      lastName.span,
      lastName.iClass
    )
    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minNameLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setLastName(tmpStateObj)
  }

  /*
   * onClick() event for Company field
   */
  const validateCompanyField = event => {
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      company.isValid,
      company.span,
      company.iClass
    )
    // verify the new value is not null, meets min length requirements, and is not all spaces...
    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minCompanyLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingInputClass ) {
        tmpStateObj.iClass = Enums.normalInputClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setCompany(tmpStateObj)
  }

  /*
   * Performs email input validation on each text change to the 
   * email field and marks if the field is valid or not...
   */
  const validateEmailField = event => { 
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      Enums.validEmailRegex.test(event.target.value),
      userEmail.span,
      userEmail.iClass
    )
    //***
    setUserEmail(tmpStateObj)
  }

  /*
   * Promo Code validation
   */
  const validatePromoCodeField = event => { 
    // promo code is optional so always set to valid
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      true,
      promoCode.span,
      promoCode.iClass
    )
    //***
    setPromoCode(tmpStateObj)
  }

  /*
   * Performs email input validation on each text change to the 
   * email field and marks if the field is valid or not...
   */
  const validatePwdField = event => { 
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      //TODO: Set min pwd requirements
      (event.target.value.length >= Enums.minPwdLength ? true : false),
      userPwd.span,
      userPwd.iClass
    )
    //***
    setUserPwd(tmpStateObj)
  }

  /*
   * onClick() handler for solution(s) interest
   *
   * Checkbox list is represented as a boolean array
   */
  const validateSolutionsInterestList = event => { 
    let tmpStateObj = Enums.buildStateObj(
      solutionsInterestList.value,
      solutionsInterestList.isValid,
      solutionsInterestList.span,
      solutionsInterestList.iClass
    )
    // flip the value at this index
    tmpStateObj.value[event.target.id-1] = !tmpStateObj.value[event.target.id-1]
    
    // check if at least one of the options is selected by user...
    let oneOrMoreOptionsSelected = false
    for( let i = 0; i < tmpStateObj.value.length; i++ ) {
      if( tmpStateObj.value[i] ) {
        oneOrMoreOptionsSelected = true
      }
    }
    if( oneOrMoreOptionsSelected ) {
      // if last option "Other" is selected...
      if( tmpStateObj.value[ Enums.interestOptions.length - 1 ] ) {
        // console.log(`LAST option is selected`)
        tmpStateObj.isValid = true
        let interestOtherObj = Enums.buildStateObj(
          solutionsInterestOtherField.value,
          solutionsInterestOtherField.isValid,
          solutionsInterestOtherField.span,
          solutionsInterestOtherField.iClass
        )
        // ensure the value is not null, meets in length requirements, and is not all spaces
        if( interestOtherObj.value && (interestOtherObj.value !== "") && (interestOtherObj.value.length >= Enums.minOtherLength) && interestOtherObj.value.trim().length ) {
          interestOtherObj.isValid = true
        }
        else {
          interestOtherObj.isValid = false
        }
        if( interestOtherObj.isValid !== solutionsInterestOtherField.isValid ) {
          // console.log(`setting interest OTHER = ${JSON.stringify(interestOtherObj)}`)
          setSolutionsInterestOtherField(interestOtherObj)
        }
      }
      else {
        // console.log(`last option NOT selected`)
        tmpStateObj.isValid = true
        let interestOtherObj = Enums.buildStateObj(
          solutionsInterestOtherField.value,
          true,
          solutionsInterestOtherField.span,
          Enums.normalTextAreaClass
        )
        setSolutionsInterestOtherField(interestOtherObj)
      }
    }
    else {
      tmpStateObj.isValid = false
    }
    //***
    setSolutionsInterestList(tmpStateObj)
  }

  /*
   * onClick() event for Interest Other selected
   */
  const validateSolutionsInterestOtherField = event => {
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      solutionsInterestOtherField.isValid,
      solutionsInterestOtherField.span,
      solutionsInterestOtherField.iClass
    )
    
    if( tmpStateObj.value && (tmpStateObj.value !== "") && (tmpStateObj.value.length >= Enums.minOtherLength) && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }
    //***
    setSolutionsInterestOtherField(tmpStateObj) 
  }

  /*
   * onClick() handler for how did you hear about Select
   */
  const validateHowHearField = event => { 
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      howHearField.isValid,
      howHearField.span,
      howHearField.iClass
    )
    // mark as valid if any option except the first option is selected
    if( tmpStateObj.value && (parseInt(tmpStateObj.value) !== 1) ) {
      tmpStateObj.isValid = true
      // if last option checked also validate the how hear other field...
      if( parseInt(tmpStateObj.value) === Enums.hearFromOptions.length ) {
        let howHearOtherObj = Enums.buildStateObj(
          howHearOtherField.value,
          howHearOtherField.isValid,
          howHearOtherField.span,
          howHearOtherField.iClass
        )
        // ensure the value is not null, meets in length requirements, and is not all spaces
        if( howHearOtherObj.value && (howHearOtherObj.value !== "") && (howHearOtherObj.value.length >= Enums.minOtherLength) && howHearOtherObj.value.trim().length ) {
          howHearOtherObj.isValid = true
        }
        else {
          howHearOtherObj.isValid = false
        }
        if( !Enums.compareStates(howHearOtherObj,howHearOtherField) ) {
          setHowHearOtherField(howHearOtherObj)
        }
      }
      else {
        // else, some valid option other than last is selected, mark the
        // how hear other field as valid...
        if( !howHearOtherField.isValid || (howHearOtherField.iClass.toString() === Enums.missingTextAreaClass) ) {
          let howHearOtherObj = Enums.buildStateObj(
            howHearOtherField.value,
            true,
            howHearOtherField.span,
            Enums.normalTextAreaClass
          )
          setHowHearOtherField(howHearOtherObj)
        }
      }
    }
    else {
      tmpStateObj.isValid = false
    }
    //***
    setHowHearField(tmpStateObj)
  }

  /*
   * onClick() event for How Hear Other selected
   */
  const validateHowHearOtherField = event => {
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      howHearOtherField.isValid,
      howHearOtherField.span,
      howHearOtherField.iClass
    )

    if( tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minOtherLength && tmpStateObj.value.trim().length ) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else { 
      tmpStateObj.isValid = false
    }

    //***
    setHowHearOtherField(tmpStateObj)
  }

  /*
   * Special highlight for first name field when field loses focus
   * through the onBlur() event
   */
  const highlightFirstNameError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      firstName.value,
      firstName.isValid,
      firstName.span,
      firstName.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setFirstName(tmpStateObj)
  }

  /*
   * Special highlight for last name field when field loses focus
   * through the onBlur() event
   */
  const highlightLastNameError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      lastName.value,
      lastName.isValid,
      lastName.span,
      lastName.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setLastName(tmpStateObj)
  }

  /*
   * Special highlight for email field when field loses focus
   * through the onBlur() event
   */
  const highlightEmailError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      userEmail.value,
      userEmail.isValid,
      userEmail.span,
      userEmail.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setUserEmail(tmpStateObj)
  }

  /*
   * Special highlight for pwd field when field loses focus
   * through the onBlur() event
   */
  const highlightPwdError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      userPwd.value,
      userPwd.isValid,
      userPwd.span,
      userPwd.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setUserPwd(tmpStateObj)
  }

  /*
   * Special highlight for company field when field loses focus
   * through the onBlur() event
   */
  const highlightCompanyError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      company.value,
      company.isValid,
      company.span,
      company.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingInputClass
    }
    else {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    setCompany(tmpStateObj)
  }

  /*
   * Special highlight for solutions interest other field loses focus
   * through the onBlur() event
   */
  const highlightSolutionsInterestsOtherError = () => {
    let tmpStateObj = Enums.buildStateObj(
      solutionsInterestOtherField.value,
      solutionsInterestOtherField.isValid,
      solutionsInterestOtherField.span,
      solutionsInterestOtherField.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    setSolutionsInterestOtherField(tmpStateObj)
  }

  /*
   * Special highlight for how hear field loses focus
   * through the onBlur() event
   */
  const highlightHowHearError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      howHearField.value,
      howHearField.isValid,
      howHearField.span,
      howHearField.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingSelectClass
    }
    else {
      tmpStateObj.iClass = Enums.normalSelectClass
    }
    setHowHearField(tmpStateObj)
  }

  /*
   * Special highlight for how hear other field loses focus
   * through the onBlur() event
   */
  const highlightHowHearOtherError = () => { 
    let tmpStateObj = Enums.buildStateObj(
      howHearOtherField.value,
      howHearOtherField.isValid,
      howHearOtherField.span,
      howHearOtherField.iClass,
    )
    if( !tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    setHowHearOtherField(tmpStateObj)
  }

  // helper function for capitalizing strings
  function capitalizeStr(s) {
    if (typeof s !== 'string') return ''
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  /* 
   * Returns true if all form field values are ready for submission,
   * false otherwise 
   */
  function isFormReadyForSubmission() {
    // check state variables for validating each form field
    let formFields = [
      { "name": "First Name", "value": firstName.isValid },
      { "name": "Last Name", "value": lastName.isValid },
      { "name": "Email", "value": userEmail.isValid },
      // { "name": "Pwd", "value": userPwd.isValid },
      { "name": "Company", "value": company.isValid },
      { "name": "Solutions Interest", "value": solutionsInterestList.isValid },
      { "name": "Interest Other", "value": solutionsInterestOtherField.isValid },
      { "name": "How Hear List", "value": howHearField.isValid },
      { "name": "How Hear Other", "value": howHearOtherField.isValid }
    ]
    let formStatus = true
    let printString = "Required form fields missing:\n"

    formFields.forEach(function (aItem) {
      if( !aItem.value ) {
        formStatus = false
        printString += ("\t- " + aItem.name + "\n")
      }
    });
    if( !formStatus ) {
      console.log(printString)
      return false
    }

    ////////////////////////////////////////////////////
    else {
      // console.log(`Form is ready`)
      return true
    }
    ////////////////////////////////////////////////////
  }

  /* 
   * Reset form and all state variables after form submission
   */
  function resetForm() {
    // reset all form field values to null/default:
    setFirstName( 
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass) 
    )
    setLastName(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
    )
    setUserEmail(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
    )
    setUserPwd(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
    )
    setCompany(
      Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalInputClass)
    )
    setSolutionsInterestList(
      Enums.buildStateObj([false,false,false,false,false], false, Enums.inputSpanHide, Enums.normalInputClass)
    )
    setSolutionsInterestOtherField(
      Enums.buildStateObj("", true, Enums.inputSpanHide, Enums.normalTextAreaClass)
    )
    setHowHearField(
      Enums.buildStateObj(0, false, Enums.inputSpanHide, Enums.normalSelectClass)
    )
    setHowHearOtherField(
      Enums.buildStateObj("", true, Enums.inputSpanHide, Enums.normalTextAreaClass)
    )
    setSubmitStatus(Enums.FORM_STATE.ready)
  }

  /******************************************************************
   ****************************************************************** 
   * createDeepMotionUserAccount()
   * 
   * Submits the sign up for using the Podio API
   ******************************************************************
   ******************************************************************/
  function createDeepMotionUserAccount() {
    // verify recaptcha value
    // 
    if( !captchaValue ) {
      // check if any required fields also not completed yet
      highlightFirstNameError()
      highlightLastNameError()
      highlightEmailError()
      highlightPwdError()
      highlightCompanyError()
      return
    }
    if( isFormReadyForSubmission() ) {
      // mark state as form submission in-progress
      setSubmitStatus(Enums.FORM_STATE.inProgress)
      
      // read state form field values:
      let fName = firstName.value
      let lName = lastName.value
      let email = userEmail.value
      // let pwd = userPwd.value
      let org = company.value

      // get how did you hear lead data
      let howHear = Enums.hearFromOptions[howHearField.value-1]
      if( howHear === Enums.hearFromOptions.Other ) {
        howHear += ` - ${howHearOtherField.value}`
      }
      
      // get solutions interest lead data  
      let sList = []
      for( let i = 0; i < solutionsInterestList.value.length; i++ ) {
        if( solutionsInterestList.value[i] ) {
          // special check for 'OTHER' which is last item
          if( i === solutionsInterestList.value.length-1 ) {
            sList.push(Enums.interestOptions[i] + " - " + solutionsInterestOtherField.value)
          }
          else {
            sList.push(Enums.interestOptions[i])
          }
        }
      }
      sList = sList.join()

      const newUser = {
        firstName: fName,
        lastName: lName,
        email: email,
        login: email,
        company: org,
        howDidHear: howHear,
        leadInterests: sList,
        captchaToken: captchaValue

      }
      
      axios.post(`${process.env.REACT_APP_API_URL}${Enums.routes.CreateAccount}`, newUser )
      .then(() => {
        //===============================================================
        // Create the Podio lead record if the okta account is
        // successfully created
        createSalesLeadRecord()
        //===============================================================
      } )
      .catch((error) => {
        setSubmitStatus(Enums.FORM_STATE.failure)
        console.log(`Error creating new user (status code: ${error.response.data.status})`)
        console.log(`${error.response.data.message})`)
        if( error.response ) {
          if( error.response.data.status === Enums.eCodes.BadRequest ) {
            // account exists error
            if( error.response.data.errorCode === Enums.oktaErrorCodes.AccountExists ||
              error.response.data.errorCode === Enums.oktaErrorCodes.UnsupportedOp ) {
              setErrorType(Enums.oktaErrorCodes.AccountExists)
            }
          }
        }
      })
    }
    //----------------------------------------------------
    // Else form is NOT ready for submission, update
    // classes to highlight errors/required fields
    //----------------------------------------------------
    else {
      // run checks for required fields that should be highlighted
      highlightFirstNameError()
      highlightLastNameError()
      highlightEmailError()
      highlightPwdError()
      highlightCompanyError()
    }
  }

  /******************************************************************
   ****************************************************************** 
   * createSalesLeadRecord()
   * 
   * Submits the sign up for using the Podio API
   ******************************************************************
   ******************************************************************/
  function createSalesLeadRecord() {

    // Two API requests needed to submit form through Podio API:
    // 1. authenticate with auth server to get auth token
    // 2. create new record in Podio cloud signup app

    // mark state as form submission in-progress
    // setSubmitStatus(FORM_STATE['in-progress'])

    // build the request body needed for oauth authentication
    let authRequestBody = {
      grant_type:'app',
      app_id:appId,
      app_token:appToken,
      client_id:clientId,
      redirect_uri:redirectUri,
      client_secret:clientSecret
      ,state:csrfState
    }

    // set axios request headers for token request:
    const authRequestHeaders = {
      headers: {
        // Podio OAuth API requires the
        // application/x-www-form-urlencoded content-type
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }
    console.log(`~~~ creating Podio record...`)
    /*******************************************************
     *******************************************************
     * Podio API #1 - Get access token 
     *******************************************************
     *******************************************************/
    axios.post(authUrl, qs.stringify(authRequestBody), authRequestHeaders)
      .then((res) => {
        // If successfully authenticated, make POST request to Podio
        // API to create a new lead record...
        let aToken = res.data.access_token
        // let rToken = res.data.refresh_token

        // add auth token to request header for next API request
        const apiRequestHeaders = {
          headers: {
            // Podio createItem() API requires a JSON request body...
            'Content-Type': 'application/json',
            'Authorization': `OAuth2 ${aToken}`
          }
        }
        /*******************************************************
         *******************************************************
         * Podio API #2 - Create new item in cloud signup app
         *******************************************************
         *******************************************************/
        let iListConverted = []
        for( let i = 0; i < solutionsInterestList.value.length; i++ ) {
          if( solutionsInterestList.value[i] ) {
            // convert boolean list to array of integers for 
            // format podio api expects...
            iListConverted.push(i+1)
          }
        }
        // configure API request body for Create Item call
        let createItemRequestBody = {
          "external_id":"Cloud Animation Signup",
          "fields": [{
            "external_id": "first-name",
            "values": firstName.value
          },
          {
            "external_id": "last-name",
            "values": lastName.value
          },
          {
            "external_id": "email-address",
            "values": [{
              "type": "work",
              "value": userEmail.value
            }]
          },
          {
            "external_id": "organisation",
            "values": [company.value]
          },
          {
            "external_id": "promo-code",
            "values": promoCode.value ? promoCode.value : " "
          },
          {
            "external_id": "what-best-describes-your-interest-and-intended-use-of-t",
            "values": iListConverted
          },
          { 
            "external_id": "if-other-please-explain",
            "values": solutionsInterestOtherField.value ? solutionsInterestOtherField.value : "n/a"
          },
          {
            "external_id": "how-did-you-hear-about-us-2",
            "values": [parseInt(howHearField.value)]
          },
          {
            "external_id": "how-hear-other",
            "values": howHearOtherField.value ? howHearOtherField.value : "n/a"
          },
          { 
            "external_id": "category",
            "values": [1]
          },
          { 
            "external_id": "deepmotion-neuron-solutions-of-interest",
            "values": [2]
          }]
        }
        //***** PODIO POST API - Create New Item:
        axios.post(restApiUrl, JSON.stringify(createItemRequestBody), apiRequestHeaders)
          .then((res) => {
            console.log(`~~~ Podio record succesfully created!`)
            setSubmitStatus(Enums.FORM_STATE.success)
            return
          })
          // Create Item API returned error
          .catch( async function (error) {
            // we do not treat Podio lead record capture as failure, only
            // if the okta user record fails to be created
            setSubmitStatus(Enums.FORM_STATE.success)
            console.log(`Error - ${JSON.stringify(error)}`) 
          })
        })
      .catch((error) => {
        // we do not treat Podio lead record capture as failure, only
        // if the okta user record fails to be created
        setSubmitStatus(Enums.FORM_STATE.success)
        console.log(`Error - could not authenticate with server\n${JSON.stringify(error)}`) 
      })
  }

  /************************************************************************ 
   * Builds the main signup for Animate 3D which is designed
   * to be mobile responsive:
   ************************************************************************/
  function buildAccountSignUpForm() {

    // dynamically build the interests list
    let interestOptionsList = []
    for( let i = 0; i < Enums.interestOptions.length; i++ ) {
      if( i === Enums.interestOptions.length - 1) {
        interestOptionsList.push(
          <label key={i+1} className="checkbox">
            <input type="checkbox" id={i+1} onChange={validateSolutionsInterestList} />
              <span className="checkbox-opt subtitle is-5">{Enums.interestOptions[i]}</span>
          </label>
        )
      }
      else {
        interestOptionsList.push(
          <label  key={i+1} className="checkbox">
            <input type="checkbox" id={i+1} onChange={validateSolutionsInterestList} />
              <span className="checkbox-opt subtitle is-5">{Enums.interestOptions[i]}</span>
          </label>
        )
        interestOptionsList.push(
          <br key={i+100}/>
        )
      }
    }
    // dynamically build the how hear about list of options
    let howHearAboutUsList = []
    for( let i = 0; i < Enums.hearFromOptions.length; i++ ) {
      howHearAboutUsList.push(
        <option id={i+1} value={i+1} key={i+1}> {Enums.hearFromOptions[i]} </option>
      )
    }

    //////////////////////////
    /// Render the Signup Form
    //////////////////////////
    return (
      <div className="columns">
        <div className="column mt-0 mb-0">
          <div className="pt-6">

            {/*** Move title above form for mobile ***/}
            <h1 className="title is-2 text-3d-shadow" style={{marginBottom:'15px'}}>{formTitle}</h1>
            <h1 className="title is-4 dm-brand-font" style={{marginBottom:'35px'}}>{formText}</h1>

            {/*** FIRST & LAST NAME ***/}
            <div className="field is-grouped">
              <div className="control has-icons-right is-expanded">
                <input className={firstName.iClass} value={firstName.value} onChange={validateFirstNameField} onBlur={highlightFirstNameError} type="text" placeholder="First Name" />
                <span className={firstName.span}>
                  <i className="fas fa-check"></i>
                </span>
              </div>
              <div className="control has-icons-right is-expanded">
                <input className={lastName.iClass} value={lastName.value} onChange={validateLastNameField} onBlur={highlightLastNameError} type="text" placeholder="Last Name"/>
                <span className={lastName.span}>
                  <i className="fas fa-check"></i>
                </span>
              </div>
            </div>

            {/*** EMAIL ***/}
            <div className="field">
              <div className="control has-icons-left has-icons-right">

                <input className={userEmail.iClass} type="email" onChange={validateEmailField} onBlur={highlightEmailError} name="EMAIL" id="mce-EMAIL" placeholder="Email address" required />

                <span className="icon is-small is-left">
                  <i className="fas fa-envelope"></i>
                </span>
                { userEmail.isValid &&
                  <span className={userEmail.span}>
                    <i className="fas fa-check"></i>
                  </span>
                }
              </div>
              {/* <p className="hide help is-danger">This email is invalid</p> */}
            </div>

            {/*** PWD 
            <div className="field">
              <div className="control has-icons-left has-icons-right">

                <input className={userPwd.iClass} type="Password" onChange={validatePwdField} onBlur={highlightPwdError} name="Pwd" id="mce-Pwd" placeholder="Password" required />

                <span className="icon is-small is-left">
                  <i className="fas fa-lock"></i>
                </span>
                { userPwd.isValid &&
                  <span className={userPwd.span}>
                    <i className="fas fa-check"></i>
                  </span>
                }
              </div>
            </div>
            ***/}

          {/*** COMPANY ***/}  
            <div className="field">
              <div className="control has-icons-right">
                <input className={company.iClass} onChange={validateCompanyField} onBlur={highlightCompanyError} type="text" placeholder="Company"/>
                <span className={company.span}>
                  <i className="fas fa-check"></i>
                </span>
              </div>
            </div>

          {/*** OPTIONAL PROMO / DISCOUNT CODE 
          <div className="field">
              <div className="control">
                <input className={promoCode.iClass} type="text" onChange={validatePromoCodeField} name="Promo Code" id="mce-Code" placeholder="Promo Code (optional)" />
              </div>
            </div>
          ***/}  

          {/*** INTEREST/INTENDED USE ***/}
            <div className="field has-text-left" >
              <label className="label subtitle is-5">{interestTitle}</label>
              {interestOptionsList}
            </div>

          {/*** INTEREST/INTENDED: If Other ***/}
          {/*** Show if last option in list is selected ***/}
          {
            solutionsInterestList.value[ Enums.interestOptions.length-1 ]
            ?
            <div className="field has-text-left">
              <label className="label">{optionOtherEx}</label>
              <div className="control">
                <textarea className={solutionsInterestOtherField.iClass} onChange={validateSolutionsInterestOtherField} onBlur={highlightSolutionsInterestsOtherError} placeholder="Textarea"></textarea>
              </div>
            </div>
            :
            <div></div>
          }

          {/*** HOW HEAR ABOUT US ***/}
            <div className="field has-text-left">
              <label className="label subtitle is-5">{howHearTitle}</label>
              <div className="control">
                <div className={howHearField.iClass} onChange={(e) => validateHowHearField(e)} onBlur={highlightHowHearError}>
                  <select onChange={validateHowHearField}>
                    {howHearAboutUsList}
                  </select>
                </div>
              </div>
            </div>

          {/*** HOW HEAR ABOUT US: If Other ***/}
          {/*** Show if last option in list is selected ***/}
          { parseInt(howHearField.value) === Enums.hearFromOptions.length
            ?
            <div className="field has-text-left">
              <label className="label">{optionOtherEx}</label>
              <div className="control">
                <textarea className={howHearOtherField.iClass} onChange={validateHowHearOtherField} onBlur={highlightHowHearOtherError} placeholder="Textarea"></textarea>
              </div>
            </div>
            :
            <div></div>
          }

            {/*** hCaptcha Verification ***/}
            <div className="field is-pulled-left">
              <HCaptcha
                sitekey={process.env.REACT_APP_HCAPTCHA_ID}
                onVerify={onCaptchaValueChange}
              />
            </div>

            {/*** [SUBMIT] button ***/}
            <div className="field">
              <div className="control">
                <button type="button" className="button fullwidth action-btn glow-on-hover is-normal" onClick={createDeepMotionUserAccount}>
                  <span className="no-side-margins">{submitButton}</span>
                </button>
              </div>
            </div>

            {/* ToC & Privacy Agreement */}
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <span>By clicking the {submitButton} button above, you agree to the <a href="https://www.deepmotion.com/terms-of-use" target="_blank" rel="noreferrer">Terms of Use</a> and <a href="https://www.deepmotion.com/privacy-policy" rel="noreferrer" target="_blank"> Privacy Policy</a></span>
              </div>
            </div>

          </div>
        </div>
      </div>
    )
  }

  function onCaptchaValueChange(value) {
    setCaptchaValue(value)
  }

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  /* 
   * Builds the progress bar column for when a submission is in-progress
   */
  function buildProgressDisplay() {
    return (
      <div className="Absolute-Center" >
        <div className="column has-text-centered vcenter-flex" style={{minHeight:'50vh',marginBottom:'0'}}>

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="subtitle is-4 has-text-black mgTop-50">{inProgressTitle}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column hcenter is-half">
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
  }

  //////////////////////////////////////////////////////////////////////////////
  // shows the Freemium features list on right hand side of screen
  //////////////////////////////////////////////////////////////////////////////
  function buildFreemiumInfoPanel() {

    let padClass = ""
    if( windowSize.width >= Enums.mobileWidth ) {
      padClass += "pt-6"
    }

    ////////////////////////
    // render this component
    ////////////////////////
    return (
      <div className="column mt-0 pt-0 mb-0 bg-ab">
        {/*skuList*/}
        <div className="section pt-0">
          <div className={"columns pb-6 " + padClass}>
            <div className={"column has-text-centered " + padClass}>
              <div className="title is-3 pb-3 has-text-white" style={{borderBottom:'.125rem solid #fab03c'}}>
                Features
              </div>
              <div className="content has-text-left ">
                <ul type="1">
                  <strong>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].mins}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].output}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].resolution}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].models}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].fps}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].storage}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].privacy}</li>
                  <li className="subtitle is-4 has-text-white">{Enums.accountPlansInfo[0].rights}</li>
                  </strong>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>  
    )

  }

  /* 
   * Builds the submission successful display
   */
  function buildSubmitSuccessDisplay() {
    return (

      <div className="" style={{paddingTop:'15px'}}>

        <h1 className="subtitle is-2 animate-header" style={{marginBottom:'35px'}}>{formTitle}</h1>

        <div className="column ">
          <div className="">
            <h1 className="title is-5 has-text-black mgTop-50">{successTitle}</h1>
            <div className="columns">
              <div className="column is-half has-text-centered ">
                <span className="icon has-text-success">
                  <i className="fas fa-check fa-5x"></i>
                </span>
              </div>
            </div>
            <h2 className="title is-4 has-text-black ml-4 mr-4"> {successSub1} </h2>
          </div>
        </div>
      </div>  
    )
  }

  // 
  // Builds the submission error display
  //
  function buildSubmitErrorDisplay() {

    let titleList = []
    let subTitlesList = []
    let buttonsList = []
    // check for common "account already exists" error
    if( errorType === Enums.oktaErrorCodes.AccountExists ) {
      titleList.push(
        <h1 key="title-1" className="title is-3 has-text-black mgTop-50">{accountExistsTitle}</h1>
      )
      subTitlesList.push(
        <h2 key="subtitle-1" className="subtitle is-4 has-text-black" >{accountExistsText}</h2>,
      )
      buttonsList.push(
        <button key="button-1" onClick={()=>history.push(Enums.routes.SignIn)} className="button preview-btn">{signInButton}</button>,
        <Link key="button-2" onClick={()=>resetForm()} to={Enums.routes.CreateAccount}> Use Different Email Address </Link>
      )
    }
    // generic error from backend
    else {
      titleList.push(
        <h1 key="title-1" className="title is-3 has-text-black mgTop-50">{errorTitle}</h1>
      )
      subTitlesList.push(
        <h2 key="subtitle-1" className="subtitle is-4 has-text-black" >{errorSubtitle}</h2>,
        <h2 key="subtitle-2" className="subtitle is-4 has-text-black" >{errorSubtitle2}</h2>
      )
      buttonsList.push(
        <button key="button-1" onClick={()=>resetForm()} className="button preview-btn">{tryAgainButton}</button>,
        <button onClick={()=>history.push(Enums.routes.SignIn)} className="button is-outlined">{backButton}</button>
      )
    }

    return (
      <div className="Absolute-Center" style={{paddingTop:'20px'}}>
        <div className="column" >
          <div className="">
              {titleList}
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">
                <span className="icon">
                  <i className="fas fa-exclamation-triangle fa-3x" style={{color:'orange'}}></i>
                </span>
              </div>
            </div>
              {subTitlesList}
            <div className="columns">
              <div className="column is-half has-text-centered hcenter ">

              {/*
                <div className="field is-grouped">
                  <div className="control">
                    {buttonsList[0]}
                  </div>
                  <div className="control">
                    {buttonsList[1]}
                  </div>
                </div>
              */}

                <div className="field is-grouped">
                  <div className="control">
                    {buttonsList[0]}
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    {buttonsList[1]}
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>  
    )
  }

  ///********************************************************************
  ///Builds the entire screen for both desktop and mobile
  ///********************************************************************/
  function BUILD_SCREEN() {
    switch( submitStatus ) {
      case Enums.FORM_STATE.ready:
        return (
          <div className="section pt-0">
            <div className="columns has-text-centered" >
              <div className="column" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildAccountSignUpForm()}

              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.inProgress:
        return (
          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the contact sales signup form: ***/}
                {buildProgressDisplay()}
              </div>
            </div>
          </div>
        )
      
      case Enums.FORM_STATE.success:
        // TODO: Copied code from company website form, need to refactor! 
        return (
          <div className="columns mgTop-20 has-text-centered form-col" style={{marginBottom:'0'}}>
            {buildSubmitSuccessDisplay()}
          </div>
        )
      
      case Enums.FORM_STATE.failure:
        return (

          <div className="mgTop-20">
            <div className="columns has-text-centered" >
              <div className="column is-half" style={{marginBottom:'0'}}>
                {/*** render the feedback signup form: ***/}
                {buildSubmitErrorDisplay()}

              </div>
            </div>
          </div>  
        )
      
      default:
        return (<div></div>)
    }
  }

  //---------------------------------------------------------
  // React useEffect() hook for when form state changes:
  //---------------------------------------------------------

  React.useEffect(() => {
    let tmpStateObj = null
    //**************************************************
    //------ Validate First Name Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      firstName.value,
      firstName.isValid,
      firstName.span,
      firstName.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(firstName,tmpStateObj) ) {
      setFirstName(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Last Name Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      lastName.value,
      lastName.isValid,
      lastName.span,
      lastName.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(lastName,tmpStateObj) ) {
      setLastName(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Email Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      userEmail.value,
      userEmail.isValid,
      userEmail.span,
      userEmail.iClass
    )
    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.iClass = Enums.normalInputClass
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    else {
      tmpStateObj.span = Enums.inputSpanHide
    }
    if( !Enums.compareStates(userEmail,tmpStateObj) ) {
      setUserEmail(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate Company Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      company.value,
      company.isValid,
      company.span,
      company.iClass
    )

    if( tmpStateObj.iClass.toString() !== Enums.missingInputClass ) {
      tmpStateObj.iClass = Enums.normalInputClass
    }
    tmpStateObj.span = Enums.inputSpanHide
    if( tmpStateObj.isValid ) {
      tmpStateObj.span = Enums.inputSpanShowSuccess
    }
    if( !Enums.compareStates(company,tmpStateObj) ) {
      setCompany(tmpStateObj)
    }

    //**************************************************
    //------ Validate Solutions Interest Other Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      solutionsInterestOtherField.value,
      solutionsInterestOtherField.isValid,
      solutionsInterestOtherField.span,
      solutionsInterestOtherField.iClass
    )
    if( (solutionsInterestOtherField.iClass.toString() !== Enums.missingTextAreaClass) || ( solutionsInterestList.isValid && solutionsInterestOtherField.isValid ) ) {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }
    if( !Enums.compareStates(solutionsInterestOtherField,tmpStateObj) ) {
      setSolutionsInterestOtherField(tmpStateObj)
    }
    //****************

    //**************************************************
    //------ Validate How Hear Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      howHearField.value,
      howHearField.isValid,
      howHearField.span,
      howHearField.iClass
    )
    if( (tmpStateObj.iClass.toString() !== Enums.missingSelectClass) || (howHearField.isValid && howHearOtherField.isValid) ) {
      tmpStateObj.iClass = Enums.normalSelectClass
    }
    if( !Enums.compareStates(howHearField,tmpStateObj) ) {
      setHowHearField(tmpStateObj)
    }
    //****************


    //**************************************************
    //------ Validate How Hear Other Field ------
    //**************************************************
    tmpStateObj = Enums.buildStateObj(
      howHearOtherField.value,
      howHearOtherField.isValid,
      howHearOtherField.span,
      howHearOtherField.iClass
    )
    if( (tmpStateObj.iClass.toString() !== Enums.missingTextAreaClass) || (howHearField.isValid && howHearOtherField.isValid) ) {
      howHearOtherField.iClass = Enums.normalTextAreaClass
    }
    if( !Enums.compareStates(howHearOtherField,tmpStateObj) ) {
      setHowHearOtherField(tmpStateObj)
    }
    //****************

  }, [
    firstName,
    lastName,
    userEmail,
    company,
    solutionsInterestList,
    solutionsInterestOtherField,
    howHearField,
    howHearOtherField,
    submitStatus,
    errorType,
    captchaValue
  ]);

  React.useEffect(() => {
    // set recaptcha option
    if( !captchaConfigured ) {
      window.recaptchaOptions = {
        useRecaptchaNet: true,
      }
      setCaptchaConfigured(true)
    }
  }, [captchaConfigured]);

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  //setFormControllerState(formContext)

  /**************************
   * Return the component...
   **************************/
  return (
      <div className="column mt-0 pl-0 pr-0">
        {helmetData}
        <div className="columns mb-0">
          <div className="column mt-0 mb-0 pt-0 pb-6 pr-3 pl-3">
            {BUILD_SCREEN()}
          </div>
          {buildFreemiumInfoPanel()}
        </div>
        <div className="columns is-marginless" style={{background:'#f7f3f3', minHeight:'150px'}}>
          <div className="column has-text-centered">

            {/*** End of Page CTAs: ***/}
            <button className="button back-btn is-normal mb-4" onClick={()=>window.location="https://www.deepmotion.com/pricing"} >View All Plans</button>
            <h5 className="subtitle is-6 has-text-black"> Already have an account? <a className="has-text-link" href="/" >Sign In</a> </h5>

          </div>
        </div>
      </div>
  )
} 