import React, { useRef, useState, useEffect, useContext } from 'react'
import { Link, useHistory } from "react-router-dom"
import 'react-circular-progressbar/dist/styles.css'
import {Helmet} from "react-helmet"
import { AppStateContext } from '../../../AppStateContext'
import SideBarMenu from '../../navigation/sideBarMenu'
import ReactTooltip from 'react-tooltip'
import LoadingScreen from '../../common/LoadingScreen'
import AnimVersionPanel from '../../common/AnimVersionPanel'
// import ParticlesBackground from '../../common/ParticlesBackground'
import DMToolTip from '../../ui/DMToolTip'
import * as Enums from '../../common/enums'
import useWindowSize from '../../common/useWindowSize'

// images
import imgNewAnim from '../../../images/dashboard/animate-3d.jpg'
import img3DModels from '../../../images/animate-3d/custom-characters.jpg'

// strings
const textSubInfo = "Subscription Info"
const textCurrMonth = "Account Balance"
const textAnimTime = "Animation Time"
const textAccountInfo = "Subscription"
const textRerunsTitle = "Reruns"
const textPlanName = "Plan Name"
const textTotalAnims = "Animations"
const textTotalTime = "Total Time"
const textCustomChars = "Custom Models"
const textNewAnim = "Create"
const textViewLibrary = "View Library"
const textViewProfile = "View Profile"
const textExpiredMins = "You have used all of your animation time for the current month."
const textAnnouncement1 = "Animate 3D v3.4.0 available..."
const textAnnouncement2 = "New Unreal MetaHuman tutorial..."
const textAnnouncement3 = "New ROBLOX + Blender tutorial..."

// SEO & metadata
const docTitle = "Animate 3D | DEEPMOTION"
const metaDesc = "Welcome to your Animate 3D Dashboard"

const tileNewAnimStyle = {
  backgroundImage: `url(${imgNewAnim})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`,
  borderRadius: `6px`
}

let bottomRowTitleClass = "is-5"

/*================================================== 
  [FUNCTIONAL COMPONENT]
  Animate 3D Home Page
 ===================================================*/
export default function Animate3DHome(props) {

  // browser history
  let history = useHistory()
  let windowSize = useWindowSize()

  const appStateContext = useContext(AppStateContext)
  const [charactersDivHeight, setCharactersDivHeight] = useState(0)
  const divRef = useRef(null)
  
  // responsive title, subtitle, and button sizes/heights
  let titleClass = "title is-5"
  let subtitleClass = "subtitle is-6"
  let animTimeTextClass = "subtitle is-5"
  let announcementsClass = "subtitle is-6"
  let newAnimBtnClass = "title is-4"
  let newAnimBtnStyle = {
    height: '85px'
  }

  if( windowSize.width > Enums.largeDisplayWidth ) {
    titleClass = "title is-2"
    subtitleClass = "subtitle is-3"
    animTimeTextClass = "title is-1"
    announcementsClass = "subtitle is-3"
    newAnimBtnClass = "title is-1"
    newAnimBtnStyle = {
      height: '250px',
      width: '500px'
    }
  }

  if( windowSize.width < Enums.fullHDWidth ) {
    bottomRowTitleClass = "is-6"
  }
  else if( windowSize.width >= Enums.fullHDWidth && windowSize.width < Enums.largeDisplayWidth ) {
    bottomRowTitleClass = "is-5"
  }
  else {
    bottomRowTitleClass = "is-4"
  }

  ////////////////////////////////////////////////////////////////////
  // handle component mount
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    window.scrollTo(0, 0)
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    // using a react hook to dynamically get div height of custom characters
    // tile once it's rendered so we can build the Announcements tile the same
    // exact height
    setCharactersDivHeight(divRef.current ? divRef.current.clientHeight : 0)
  })
  /////////////////////////////////////////////////////////////////////
  // component un-mount
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    return function cleanup() {
      setCharactersDivHeight(0)
    }
  }, [])
  
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Library tile with total animations and link to library
  ////////////////////////////////////////////////////////////////////
  function LibraryCardTile() {
    return (
      <div className="columns br-4 fullwidth m-0 p-0 bShadow" style={{backgroundColor:'#2d4e77'}}>
        <div className="column p-0 my-0">

          <div className="card dm-brand" style={{height:'100%'}}>
            <div className="card-image">
              <figure className="image is-16by9">
                <img src={imgNewAnim} className="br-4-top" />
              </figure>
            </div>
            <div className="card-content dm-brand-border-md">
              <div className="content has-text-centered">
                <div className={ (windowSize.width > Enums.largeDisplayWidth ? "title is-1 " : "title is-4") +  " has-text-white"}> {appStateContext.state.jobsData.length} Animations </div>
                <div className="block button fullwidth is-info is-outlined is-normal" onClick={handleGoToLibrary}> View Library </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the main CTA button (New Animation) for the page
  ////////////////////////////////////////////////////////////////////
  function NewAnimationTile() {
    return(
      <article 
        className={`tile is-child has-text-centered tile-border-pulse-hover glow-on-hover bShadow`}
        onClick={handleGoToNewAnim}
        style={{transition: 'background 0.5s ease-in-out'}}>
        <div className="columns m-0 fullheight br-4 new-anim-grad">
          <div className="column">
            <p className="icon-text">
              <span className="icon dm-brand-font is-medium mr-2"><i className="far fa-play-circle fa-2x"></i></span><span className={newAnimBtnClass + " mr-4 dm-brand-font"}> {textNewAnim} </span>
            </p>
          </div>
        </div>
      </article>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Anim Time Remaining progress meter for the account
  ////////////////////////////////////////////////////////////////////
  function AnimationTimeMeterTile() {
    return (
      <article className="tile is-child notification dm-brand bShadow has-text-white px-4">
        <div className="columns mb-2">
          <div className="column has-text-left">
            <p className={titleClass + " has-text-left"}>{textAnimTime}</p>
          </div>
        </div>
        <div className="columns">
          <div className="column pt-0 has-text-left"> 
          {
            appStateContext.state.subscriptionInfo.cancel_at_period_end
            ?
              <p className={subtitleClass + " pt-0 has-text-danger has-text-left dm-brand-font"}>Cancels on {Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at, true)}</p>
            :
              <p className={subtitleClass + " pt-0 has-text-left has-text-white"}>{appStateContext.state.currentBillCycleInfo.currCycleStartDate} - {appStateContext.state.currentBillCycleInfo.currCycleEndDate}</p>
          }
          </div>
        </div>

        <div className="columns mt-0 mb-0">
          <div className="column is-half pt-0 has-text-centered"> 
            {props.buildRemainingMinutesMeter(appStateContext.state.currentBillCycleInfo.usagePercent)}
          </div>
        </div>
        <div className="columns mt-0 mb-0">
          <div className="column is-11 pt-0 pb-0 px-0">
            <div className="has-text-centered">
              {
                appStateContext.state.accountTotals.remainingTimeInSeconds >= 1.0 
                ?
                <React.Fragment>
                  <span className={animTimeTextClass + " has-text-weight-semibold"}> {Enums.secondsToHms(appStateContext.state.currentBillCycleInfo.remainingRounded, true)}
                    <span className="icon" style={{fontSize:'1rem'}}>
                    <i className="fas fa-info-circle"
                      data-for="animTimeTip" 
                      data-border={true}
                      data-border-color="#2d4e77"
                      data-tip
                      data-text-color="#2d4e77"
                      data-background-color="white">
                    </i>      
                    </span>
                  </span>
                  <ReactTooltip className="tip-max-w" id="animTimeTip" place="right" effect="solid">
                    <div className="subtitle is-5 has-text-left">
                      You have <span className="has-text-weight-semibold">{Enums.secondsToHms(appStateContext.state.currentBillCycleInfo.remainingRounded, true)} </span>
                      of animation time remaining for the current billing period which ends on <span className="has-text-weight-semibold">{appStateContext.state.subscriptionInfo.cancel_at_period_end ? Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at) : appStateContext.state.currentBillCycleInfo.currCycleEndDate}.</span>
                    </div>
                  </ReactTooltip>
                </React.Fragment>
                :
                <div className={animTimeTextClass}> No animation time remaining for the current billing cycle which ends on <span className="has-text-weight-semibold">{appStateContext.state.subscriptionInfo.cancel_at_period_end ? Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at) : appStateContext.state.currentBillCycleInfo.currCycleEndDate}</span> </div>
              }
            </div>
          </div>
        </div>
      </article>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Custom Characters tile including add character button
  ////////////////////////////////////////////////////////////////////
  function CustomCharactersTile() {
    if( !appStateContext.state.accountTotals || !appStateContext.state.accountTotals.charactersList ) {
      return <div/>
    }
    let charactersProgressValue = null
    if( !appStateContext.state.accountTotals.characterLimit ) {
      charactersProgressValue = 0
    }
    else {
      charactersProgressValue = (appStateContext.state.accountTotals.charactersList.length/appStateContext.state.accountTotals.characterLimit) * 100
    }
    return (
      <article className="tile is-child notification dm-brand bShadow has-text-white p-0" ref={divRef} >
        <div className="columns m-0 p-0 br-4-left">
          <div className="column m-0 p-0 br-4-left has-text-centered">
            <div className="img-16by9-wrapper">
              <div className="img-16by9-img">
                <figure className="image is-16by9">
                  <img src={img3DModels} className="br-4-left" />
                </figure>
              </div>
            </div>
          </div>
          <div className="column m-0 p-4 br-4-right dm-brand-border has-text-centered">
            <div className="columns mb-0 is-mobile is-justify-content-center">
              <div className="column is-7 has-text-left">
                <div className={`subtitle ${bottomRowTitleClass}`}> 3D Models</div>
              </div>
              <div className="column has-text-right">
                <div className={`subtitle ${bottomRowTitleClass}`}> {appStateContext.state.accountTotals.charactersList.length} / {appStateContext.state.accountTotals.characterLimit}</div>
              </div>
            </div>
            <div className="columns" style={{height:'100%'}}>
              <div className="column m-0 has-text-centered">
                <progress className={`progress mb-4 ${props.getProductColorCSSClass()}`} value={charactersProgressValue} max="100"></progress>
                <div className="button fullwidth is-info is-outlined is-normal" onClick={handleGoTo3DModels} > View Characters </div>
              </div>
            </div>
          </div>
        </div>
      </article>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Announcements tile in bottom right
  ////////////////////////////////////////////////////////////////////
  function AnnouncementsTile() {
    let subtitleSizeClass = (windowSize.width < Enums.fullHDWidth ? "subtitle is-7" : announcementsClass)
    return (
        <article className="tile is-child notification dm-brand bShadow has-text-white px-4" style={{height:charactersDivHeight+'px'}}>
          <div className="columns m-0 p-0">
            <div className="column m-0 p-0 has-text-left">
              <div className={`subtitle ${bottomRowTitleClass} has-text-white mb-2`}> Announcements </div>

                {/*** Announcement 1 ***/}
                <div><span className="icon has-text-white"><i className="far fa-bell"></i></span>
                  <span className={subtitleSizeClass + " has-text-white"}><a href="https://blog.deepmotion.com/2021/10/22/v34/" className="announcement-link" target="_blank" rel="noopener noreferrer">{textAnnouncement1} </a> </span>
                </div>
                
                {/*** Announcement 2 ***/}
                <div><span className="icon has-text-white"><i className="far fa-bell"></i></span>
                  <span className={subtitleSizeClass + " has-text-white"}><a href="https://www.youtube.com/watch?v=XWIi0_S_b1w" className="announcement-link" target="_blank" rel="noopener noreferrer">{textAnnouncement2} </a></span>
                </div>
                
                {/*** Announcement 3 ***/}
                <div><span className="icon has-text-white"><i className="far fa-bell"></i></span>
                  <span className={subtitleSizeClass + " has-text-white"}><a href="https://blog.deepmotion.com/2021/09/17/roblox-tutorial-how-to-animate-in-the-metaverse/" className="announcement-link" target="_blank" rel="noopener noreferrer">{textAnnouncement3} </a></span>
                </div>
            </div>
          </div>
        </article>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the available Reruns tile for current billing cycle
  ////////////////////////////////////////////////////////////////////
  function RerunMeterTile() {
    if( appStateContext.state.accountTotals.max_rerun === undefined ) {
      return
    }

    let remainingRerunPercent = 0
    let rerunRemainingStr = ""
    let remainingReruns = ""
    let rerunTipText = `<div className="block">You have <span class="has-text-weight-semibold"> ${remainingReruns} </span> reruns available for the current billing period which ends on <span class="has-text-weight-semibold">${appStateContext.state.subscriptionInfo.cancel_at_period_end ? Enums.dateConverter(appStateContext.state.subscriptionInfo.cancel_at) : appStateContext.state.currentBillCycleInfo.currCycleEndDate}</span>. <div class="notification mt-3 is-info is-light">Animation reruns can be used to improve the quality of your existing animations without using any account animation time</div></div>`

    // special case for Freemium accounts that are not allowed any reruns
    if( appStateContext.state.subscriptionInfo.name === Enums.accountPlansInfo[0].name ) {
      remainingRerunPercent = 0
      rerunRemainingStr = remainingReruns = 0
      rerunTipText = `Reruns are not available for Freemium subscriptions.`
    }
    else {
      if( appStateContext.state.accountTotals.max_rerun === -1 ) {
        remainingRerunPercent = 100
        rerunRemainingStr = remainingReruns = "Unlimited"
      }
      else {
        remainingRerunPercent = ((appStateContext.state.accountTotals.max_rerun - appStateContext.state.accountTotals.rerun_count) / appStateContext.state.accountTotals.max_rerun) * 100
        rerunRemainingStr = `${appStateContext.state.accountTotals.max_rerun - appStateContext.state.accountTotals.rerun_count} / ${appStateContext.state.accountTotals.max_rerun}`
        remainingReruns = `${appStateContext.state.accountTotals.max_rerun - appStateContext.state.accountTotals.rerun_count}`
      }
    }

    return (
      <article className="tile is-child notification bShadow dm-brand px-4">
        <div className="columns mb-1">
          <div className="column has-text-left">
            <p className={titleClass + " has-text-left has-text-white"}>{textRerunsTitle}</p>
          </div>
        </div>
        <div className="columns">
          <div className="column has-text-centered">
            <progress className={`progress ${props.getProductColorCSSClass()}`} value={remainingRerunPercent} max="100"></progress>
          </div>
        </div>
        <div className="columns mb-2">
          <div className="column has-text-centered">
            <div className={titleClass + " has-text-white"}> {rerunRemainingStr}
              <DMToolTip
                text={rerunTipText}
                isTipHtml={true}
              />
            </div>
          </div>
        </div>
      </article>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the user's Animate 3D Home Page
  ////////////////////////////////////////////////////////////////////
  function buildHomePage() {
    if( !appStateContext.state.jobsData ) {
      return
    }

    return (
      <div id="anim-fadein" className="column">

        <div className="ml-6 mr-6 my-1">
          <div className="columns">
            <div className="column has-text-left">
              <div className="tile is-ancestor">
                <div className="tile">

                  <div className="tile is-parent mt-0 mb-0">
                    <AnimationTimeMeterTile />
                  </div>

                  <div className="tile is-parent is-vertical mt-0 mb-0">
                    <RerunMeterTile />
                    <NewAnimationTile />
                  </div>

                  <div className="tile is-parent mt-0 mb-0">
                    <LibraryCardTile />
                  </div>
                </div>

              </div>

              <div className="tile is-ancestor">
                <div className="tile">
                  <div className="tile is-parent is-8">
                    <CustomCharactersTile />
                  </div>
                  <div className="tile is-parent">
                    <AnnouncementsTile />
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // NAVIGATION HELPER FUNCTIONS:
  ////////////////////////////////////////////////////////////////////
  function handleGoToNewAnim() {
    props.history.push(Enums.routes.Anim3dCreate)
  }
  function handleGoToLibrary() {
    props.history.push(Enums.routes.Anim3dLibrary)
  }
  function handleGoTo3DModels() {
    props.history.push(Enums.routes.Anim3dModelManage)
  }

  // set page title and meta data
  const helmetData = seoMetaData_EN()

  /////////////
  // Render
  /////////////
  return (
    <React.Fragment>
    <AnimVersionPanel pageTitle="Home" /> 
    {
      props.LOADING.show
      ?
      <LoadingScreen />
      :
      <div className="section m-0 p-0">
        {helmetData}
        {buildHomePage()}
      </div>
    }
    </React.Fragment>
  )
} 