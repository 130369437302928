import React, { useContext, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AppStateContext } from '../../AppStateContext'
import * as Enums from '../common/enums'
import AnimVersionPanel from '../common/AnimVersionPanel'
import LoadingScreen from '../common/LoadingScreen'
import HelmetPageData from '../common/HelmetPageData'
// import ParticlesBackground from '../common/ParticlesBackground'

const docTitle = "Body Tracking SDK | DEEPMOTION"
const metaDesc = "Real time body tracking SDK for mobile and PC platforms."

/******************************************************************
 Product page for DM Body Tracking SDK 
 ******************************************************************/
export default function DMBTSdkPage(props) {
  
  const appStateContext = useContext(AppStateContext)
  
  ///////////////////////////////////////////////////////////////////
  function buildPage() {

    // multiple groups have access to the DMBT real-time SDK
    let group = ""
    if( appStateContext.state.groups.includes(process.env.REACT_APP_AG_SAM_TV) ) {
      group = process.env.REACT_APP_AG_SAM_TV
    }
    else if( appStateContext.state.groups.includes(process.env.REACT_APP_AG_DMBT_SDK_AND) ) {
      group = process.env.REACT_APP_AG_DMBT_SDK_AND
    }
    else if( appStateContext.state.groups.includes(process.env.REACT_APP_AG_DMBT_SDK_WIN_AND) ) {
      group = process.env.REACT_APP_AG_DMBT_SDK_WIN_AND
    }
    console.log(`GROUP = ${group}\n${JSON.stringify(group, null, 4)}`)
    // construct the download and documentation URLs...
    let sdkDownloadUrl = process.env.REACT_APP_API_URL + "/ss_download?email=" + appStateContext.state.email +'&group=' + group
    let documentationUrl = process.env.REACT_APP_API_URL + "/ss_doc_doanload"

    return(
      <div id="anim-fadein" className="column">
        <HelmetPageData docTitle={docTitle} metaDesc={metaDesc} />
        <AnimVersionPanel />
        {
          props.LOADING.show
          ?
          <LoadingScreen />
          :
          <React.Fragment>
          <div className="columns" style={{marginTop:'100px'}}>
            <div className="column has-text-centered">
              <h1 className="title is-1"> DEEPMOTION Body Tracking SDK </h1>
              <h2 className="subtitle is-3">Detect and Reconstruct Human Motion in Real-Time</h2>
            </div>
          </div>
          <div className="columns" >
            <div className="column has-text-centered">
              <a className="button library-btn is-large" href={sdkDownloadUrl} style={{marginRight:'5px'}}> Download SDK </a>
              <a className="button library-btn is-large" href={documentationUrl} style={{marginLeft:'5px'}}> Developer Guide </a>
            </div>
          </div>
          </React.Fragment>
        }
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // custom hook to re-initialize service upon handle browser refresh
  ////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if( !appStateContext.state.anim3dInitialized || !appStateContext.state.accountDataRetrieved ) {
      props.initializeA3DService()
    } 
    else {
      if( props.LOADING.show ) {
        props.setLOADING({...props.LOADING, ...{show: false}})
      }
    }
  }, [appStateContext.state.anim3dInitialized, appStateContext.state.accountDataRetrieved])

  //////////////////
  return buildPage()
  //////////////////
}