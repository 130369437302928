import React, { useRef, useContext } from 'react'
import { Link, useHistory } from "react-router-dom"
import {Helmet} from "react-helmet"
import axios from 'axios'
import { AppStateContext } from '../../../../AppStateContext'
import {
  getUserPlanData,
  addNewModelToAccount, 
  getAccountCustomCharacters } from '../../../api/apiRequests'
import CharacterSwiper from './CharacterSwiper'
import CharacterUpload from './CharacterUpload'
// import ParticlesBackground from '../../../common/ParticlesBackground'
import AnimVersionPanel from '../../../common/AnimVersionPanel'
import LoadingScreen from '../../../common/LoadingScreen'
import useWindowSize from '../../../common/useWindowSize'
import * as Enums from '../../../common/enums'
import img3DModels from '../../../../images/animate-3d/custom-characters.jpg'

// strings
const selectCharacterBtn = "Select Model"
const removeCharacterBtn = "Remove Character"
const newAnimBtn = "Create Animation"
const newPoseBtn = "Create Static Pose"
const pageTitle = "3D Models"
const tabAvailableModels = "Available Characters"
const uploadsNotAllowedText1 = "You have reached the maximum character limit allowed for your plan"
const uploadsNotAllowedText2 = "To add new characters please remove one or more existing characters from your account."
const confirmModelTitle = "New Character"
const confirmModelMsgAdded = "New custom character has been successfully"
const confirmModelDeletedTitle = "Character Removed"
const confirmModelMsgDeleted = "The character was successfully deleted from your account."
const textMainMessage = "Create, upload, and view custom characters to use in your animations"
const textNoModelsInAccount = "No models found, start creating or uploading characters and they will show up here..."
const textSelectModel = "Select Model"
const warningIcon = "fas fa-exclamation-triangle fa-3x"

// SEO 
const docTitle = "3D Models | DEEPMOTION"
const metaDesc = "Manage your custom character models and generate new 3D models on demand."

const tabStatus = Object.freeze({
  none:                "",
  active:              "is-active", 
  hidden:              "is-hidden"
})

const tabIds = Object.freeze({
  availableModels: 1,
  createNewModel:  2,
  uploadModel:     3,
  
})

const charactersHeroStyle = {
  backgroundImage: `url(${img3DModels})`,
  backgroundSize: `cover`,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `center center`
}

//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
// [FUNCTIONAL COMPONENT]
// Custom Character Management Page
//+++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
export default function CharacterManagePage(props) {

  // browser history
  let history = useHistory()
  
  //TODO: find out acceptable window dimensions/limits
  let windowSize = useWindowSize()

  const appStateContext = useContext(AppStateContext)

  //************************************************************ 
  // Custom Character page state variables
  //************************************************************
  const [characterIndex, setCharacterIndex] = React.useState(0)
  const [modelImageLoaded, setModelImageLoaded] = React.useState(false)
  const [rpmCharacterUrl, setRpmCharacterUrl] = React.useState(null)
  const [modelName, setModelName] = React.useState('')
  const [activeTab, setActiveTab] = React.useState(appStateContext.state.accountTotals.charactersList && (appStateContext.state.accountTotals.charactersList.length > 0) ? tabIds.availableModels : tabIds.createNewModel)
  //---------------------------------------------------------------

  //---------------
  // using react helmet to set page title and metadata
  function seoMetaData_EN(props) {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {docTitle} </title>
        <meta name="description" content= {metaDesc} />
      </Helmet>
    )
  }

  function BUILD_SCREEN() {

    if( !appStateContext.state.accountTotals.charactersList ) {
      // page still loading data...
      return null
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.renameModelDialog ) {
      return buildRenameModelDialog()
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.modelCreatedDialog ) {
      let title = confirmModelTitle + ' Created'
      let msg = confirmModelMsgAdded + ' created.'
      let icon = "fas fa-user-cog fa-3x"
      return buildModelConfirmationDialog(title, msg, icon, updateUiStateCallback)
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.modelUploadedDialog ) {
      let title = confirmModelTitle + ' Uploaded'
      let msg = confirmModelMsgAdded + ' uploaded.'
      let icon = "fas fa-user-plus fa-3x"
      return buildModelConfirmationDialog(title, msg, icon, updateUiStateCallback)
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.modelDeletedDialog ) {
      let title = confirmModelDeletedTitle
      let msg = confirmModelMsgDeleted
      let icon = "fas fa-user-minus fa-3x"
      return buildModelConfirmationDialog(title, msg, icon, updateUiStateCallback)
    }

    let currentScreen = null
    switch(activeTab) {
      default:
      case tabIds.availableModels:
        currentScreen = buildAvailableModelsSelector()
        break
      case tabIds.createNewModel:
        currentScreen = buildCharacterMaker()
        break
      case tabIds.uploadModel:
        currentScreen = buildCharacterUploadScreen()
        break
    }

    return (
      <div className="section py-0 px-2">
        
        <ModelsHeroSection />

          {/*** Display Screen Selector Tabs: ***/}
          <div className="columns mx-2 my-5">
            <div className="column">
              <div className="buttons is-centered has-addons">
                { 
                  appStateContext.state.accountTotals.charactersList.length > 0
                  &&
                  <button onClick={()=>setActiveTab(tabIds.availableModels)} className={"button is-medium " + (activeTab === tabIds.availableModels ? "is-link is-selected" : "is-outlined  is-info")}>
                    View
                  </button>
                }
                <button onClick={()=>setActiveTab(tabIds.createNewModel)} className={"button is-medium " + (activeTab === tabIds.createNewModel ? "is-link is-selected" : "is-outlined  is-info")}>
                  Create
                </button>
                <button onClick={()=>setActiveTab(tabIds.uploadModel)} className={"button is-medium " + (activeTab === tabIds.uploadModel ? "is-link is-selected" : "is-outlined  is-info")}>
                  Upload
                </button>
              </div>
            </div>
          </div>

          {/*** Build the current screen content (View | Create | Upload) ***/}
          {currentScreen}

      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds top page hero section with summary info
  ////////////////////////////////////////////////////////////////////
  function ModelsHeroSection() {

    let charactersProgressValue = null
    if( !appStateContext.state.accountTotals.characterLimit ) {
      charactersProgressValue = 0
    }
    else {
      charactersProgressValue = (appStateContext.state.accountTotals.charactersList.length/appStateContext.state.accountTotals.characterLimit) * 100
    }

    return (
      <div className="m-0 p-0 fullwidth">
        <div className="columns m-0 mb-6 p-0 bShadow br-4-top-left fullwidth"> 
          
          <div className="column is-one-quarter m-0 p-0 br-4-left dm-brand dm-brand-border-md has-text-centered" style={charactersHeroStyle}>
          </div>
          
          <div className="column m-0 p-2 br-4-right dm-brand dm-brand-border-md has-text-centered">
            <div className="columns is-mobile m-0 p-0">
              <div className="column bottom-border m-0 p-1 has-text-centered">
                <h2 className="title is-3 has-text-white"> {pageTitle} </h2>
              </div>
            </div>
            <div className="m-0 p-1">
              <div className="columns m-0 is-mobile">
                <div className="column m-0 p-2">
                  <div className="mt-5">
                    <div className="columns">
                      
                      <div className="column disp-grid box my-0 mx-2" style={{backgroundColor:'transparent'}}>
                        <div className="title dm-brand-2-font is-3">
                          {appStateContext.state.accountTotals.charactersList.length} / {appStateContext.state.accountTotals.characterLimit}
                        </div>
                        <div className="subtitle has-text-white is-5">Available Characters</div>
                      </div>

                      <div className="column has-text-centered">
                        <progress className={`progress mb-4 ${props.getProductColorCSSClass()}`} value={charactersProgressValue} max="100"></progress>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    )
  }

  function buildUploadsNotAllowedScreen() {
    return (
      <div className="columns m-0 p-0">
        <div className="column is-11 mb-6 rounded-corners-top rounded-corners-bottom bShadow">
          <div className="columns notification has-background-danger-light">
            <div className="column is-10 has-text-left">
              <div className="columns">
                <div className="column is-2 has-text-centered">
                  <span className="icon has-text-danger">
                    <i className={warningIcon}></i>
                  </span>
                </div>
                <div className="column has-text-left">
                  <h3 className="title is-4 has-text-danger">
                    {uploadsNotAllowedText1} ({appStateContext.state.accountTotals.charactersList.length}/{appStateContext.state.accountTotals.characterLimit}). {uploadsNotAllowedText2}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the Characters Selection UI using Swiper.js
  ////////////////////////////////////////////////////////////////////
  function buildAvailableModelsSelector() {

    // special case if no models in account yet
    if( !appStateContext.state.accountTotals.charactersList.length ) {
      return (
        <div className="columns is-vcentered">
          <div className="column notification is-warning is-light has-text-centered"> 
            <h3 className="title is-4"> {textNoModelsInAccount} </h3>
          </div>
        </div>
      )
    }
    else {
      return (
        <div className="columns my-4">
          <div className="column is-11 p-6 mb-6 rounded-corners-top rounded-corners-bottom bShadow has-background-link-light">
            <div className="columns is-vcentered mb-6">
              <div className="column has-text-centered"> 
                <CharacterSwiper charactersList={appStateContext.state.accountTotals.charactersList} setCharacterIndex={setCharacterIndex} setModelImageLoaded={setModelImageLoaded} />
              </div>
              <div className="column has-text-left">
                <div className="columns is-vcentered">
                  <div className="column"> 
                    {/* only render create animations button current cycle time remains */}
                    {
                      !appStateContext.state.accountTotals.currCycleMinsExpired
                      &&
                      <React.Fragment>
                        <div className="buttons">
                          <div className="button is-medium btn-shadow action-btn glow-on-hover" onClick={()=>selectCustomCharacter(Enums.jobTypes.animation)} style={{width: '75%'}}><span className="no-side-margins">{newAnimBtn}</span></div>
                        </div>
                        <div className="buttons">
                          <div className="button is-medium btn-shadow action-btn glow-on-hover" onClick={()=>selectCustomCharacter(Enums.jobTypes.staticPose)} style={{width: '75%'}}><span className="no-side-margins">{newPoseBtn}</span></div>
                        </div>
                      </React.Fragment>
                    }
                    <div className="buttons">
                      <div className="button is-medium discard-btn-no-outline" onClick={()=>handleRemoveCustomCharacter()} style={{width: '75%'}}><span className="no-side-margins">{removeCharacterBtn}</span></div>
                    </div>
                  </div>
                </div>
                <div className="columns is-vcentered">
                  <div className="column"> 
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////
  // set custom character then navigate to the create anim page
  ////////////////////////////////////////////////////////////////////
  function selectCustomCharacter(jobType) {
    let tmpStateObj = JSON.parse(JSON.stringify(Enums.customModelObj))
    tmpStateObj.id = appStateContext.state.accountTotals.charactersList[characterIndex].id
    tmpStateObj.name = appStateContext.state.accountTotals.charactersList[characterIndex].name
    tmpStateObj.thumbImg = appStateContext.state.accountTotals.charactersList[characterIndex].thumbImg
    const newJobType = jobType === Enums.jobTypes.animation ? Enums.jobTypes.animation : Enums.jobTypes.staticPose
    const newAnimSettings = {...appStateContext.state.animJobSettings, ...{jobType: newJobType} }
    appStateContext.dispatch({animJobSettings: {...newAnimSettings, ...{'customModelInfo': tmpStateObj}}})
    history.push(Enums.routes.Anim3dCreate)
  }

  function buildCharacterUploadScreen() {
    return (
      <CharacterUpload
        appStateContext={appStateContext}

        displayAsTab={true}
        confirmDialogId={props.confirmDialogId}
        animJobSettings={appStateContext.state.animJobSettings}
        updateAnimJobSettings={props.updateAnimJobSettings}
        getNewPEServiceToken={props.getNewPEServiceToken}
        handleHttpError={props.handleHttpError}
        logoutUser={props.logoutUser}
        setErrorDialogInfo={props.setErrorDialogInfo}
        accountTotals={appStateContext.state.accountTotals}
        setDialogInfo={props.setDialogInfo}
        getAccountCustomCharacters={props.getAccountCustomCharacters}
        modelUploadedCallback={modelUploadedCallback}
        closeModal={props.closeModal} 
      />
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Builds the RPM embedded iFrame for custom model generation
  ////////////////////////////////////////////////////////////////////
  function buildCharacterMaker() {
    if( appStateContext.state.accountTotals.charactersList && (appStateContext.state.accountTotals.charactersList.length >= appStateContext.state.accountTotals.characterLimit) ) {
      return (
        <React.Fragment>
          {buildUploadsNotAllowedScreen()}
        </React.Fragment>
      )
    }
    else {
      return (
        <div>
          <div className="columns m-0 mt-4 mb-6">
            <div className="column is-11 p-6 rounded-corners-top rounded-corners-bottom bShadow has-background-link-light has-text-centered">
              {
                appStateContext.state.pageState_CharacterManage === Enums.pageState.ready
                &&
                <div className="rpm-container">
                  <iframe className="iframe rpm-content" allow="camera *; microphone *" id="iframe" width="600" height="600" src="https://deepmotion.readyplayer.me/" /*sandbox=""*/ >
                    {/* default message in case browser does not support iFrames */}
                    <p>Your browser does not support iframes.</p>
                  </iframe>
                </div>
              }
            </div>
          </div>
        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////////////
  // Builds input modal for renaming new custom character
  ////////////////////////////////////////////////////////////////////
  function buildRenameModelDialog() {
    let title = "Name Your New Character"
    return (
      <div id="modal-ter" className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head is-marginless">
            <p className="modal-card-title">{title}</p>
          </header>
          <section className="modal-card-body is-marginless">
            <div className="content">
              <div className="columns has-text-centered">
                <div className="column is-2">
                  <span className="icon has-text-link is-large"><i className="fas fa-user-edit fa-3x"></i></span>
                </div>
                <div className="column">
                  <div className="columns has-text-left">  
                    <div className="column">
                      <div className="field">
                        <p className="control">
                          <input className="input pr-0" onChange={(event) => validateModelName(event.target.value)} value={modelName} type="text" placeholder="Name" />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="columns">
                { modelName && modelName.length > 0 
                  ?
                  <div className="button preview-btn" onClick={()=>appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.savingModel})}>Save</div>
                  :
                  <div disabled className="button fullwidth">Save</div>
                }
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Generic dialog for model upload/create/delete confirmations
  ////////////////////////////////////////////////////////////////////
  function buildModelConfirmationDialog(title, msg, icon, action) {
    let iconClass = "icon is-large"
    if( appStateContext.state.pageState_CharacterManage === Enums.pageState.modelCreatedDialog || appStateContext.state.pageState_CharacterManage === Enums.pageState.modelUploadedDialog ) {
      iconClass += " has-text-success"
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.modelDeletedDialog ) {
      iconClass += " has-text-danger"
    }
    return (
      <div id="modal-ter" className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head is-marginless">
            <p className="modal-card-title"> {title} </p>
          </header>
          <section className="modal-card-body is-marginless">
            <div className="content">
              <div className="columns has-text-centered">
                <div className="column">
                  <span className={iconClass}><i className={icon}></i></span>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <h2 className="subtitle is-5"> {msg} </h2>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <div className="button preview-btn" onClick={() => action()}>OK</div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////
  // Shows dialog for custom character removal
  //
  // @param charId : the modelId of the custom character to remove
  ////////////////////////////////////////////////////////////////////
  function handleRemoveCustomCharacter(charId) {
    let tmpStateObj = appStateContext.state.animJobSettings.customModelInfo
    tmpStateObj.modelId = appStateContext.state.accountTotals.charactersList[characterIndex].id
    tmpStateObj.name = appStateContext.state.accountTotals.charactersList[characterIndex].name
    tmpStateObj.thumbImg = appStateContext.state.accountTotals.charactersList[characterIndex].thumbImg
    tmpStateObj.date = appStateContext.state.accountTotals.charactersList[characterIndex].mtime
    tmpStateObj.clbFunc = removeCharacterCallback
    appStateContext.dispatch({dialogInfo: tmpStateObj, confirmDialogId: Enums.confirmDialog.removeCustChar})
    // props.setDialogInfo(tmpStateObj, () => {
    //   props.setConfirmDialogId(Enums.confirmDialog.removeCustChar)
    // })
  }

  function removeCharacterCallback() {
    // reset character index after deletion
    setCharacterIndex(0)
    appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.modelDeletedDialog})
  }
  function modelUploadedCallback() {
    appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.modelUploadedDialog})
  }
  function updateUiStateCallback() {
    const newStateData = {
      ...{accountDataRetrieved: false}, // set to false to force page to re-initialize after the update
      ...{pageState_CharacterManage: Enums.pageState.init}
    }
    return appStateContext.dispatch(newStateData)
  }

  ////////////////////////////////////////////////////////////////////
  // upload a custom GLB model URL we get from ReadyPlayerMe iFrame
  //
  // @param url : the signed gcp URL to upload the model to
  // @param cb : optional callback function
  ////////////////////////////////////////////////////////////////////
  function uploadCustomModelToGCP(url, cb) {
    delete axios.defaults.headers.post["Content-Type"]
    axios.put(url, rpmCharacterUrl,{
      processData: false,
      contentType: false
    })
    .then(res => { // then print response status
      if(res.status === 200) {
        console.log("Successfully uploaded glb link.")
      }
      if( cb ) {
        cb()
      } else return
    }).catch((error) => {
      if(error.response) {
        if(error.response.status === Enums.eCodes.Unauthorized) {
          if( retry ) {
            // upon un-authorized error attempt to retrieve a new access token for the PE backend service
            props.getNewPEServiceToken( () => {
              // recursively call function with false flag to break recursion
              uploadCustomModelToGCP(url, false, cb)
            })
          }
          else {
            props.handleHttpError(error, "Upload Process Failed", true)
          }
        }
        if(error.response.status === Enums.eCodes.Forbidden) {
          props.logoutUser()
        }
        if(error.response.status === Enums.eCodes.InternalServerError) {          
          props.setErrorDialogInfo(
            true, 
            Enums.eCodes.InternalServerError, 
            Enums.customErrors[error.response.data.error], 
            error.response.data.message, () => {
              return
          })
        }
      }
      else {
        props.handleHttpError(error, "Upload Process Failed", true)
      }
    })
  }

  function validateModelName(value) {
    let mName = value
    if( mName === '' ) {
      setModelName(mName)
      return
    }

    // limit the size of the input
    if( mName.length > Enums.MAX_FILENAME_LENGTH ) {
      mName = mName.slice( 0, Enums.MAX_FILENAME_LENGTH-mName.length )
    }

    // remove illegal characters & sequences from the input
    const sanitized = Enums.removeIllegalOrReservedCharacters(mName)
    setModelName(sanitized)
  }

  function receiveMessage(event) {
    if( typeof(event.data) === 'string' ) {
      if( (Enums.getFileExtension(event.data)).toLowerCase() === 'glb' ) {
        // success- 
        // update state with glb URL data
        onGLBReceivedEvent(event.data)
      }
    }
    setTimeout(() => {}, 1000)
  }

  async function onGLBReceivedEvent(data) {
    await setRpmCharacterUrl(event.data)
    appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.renameModelDialog})
    // await props.setCharacterManagePageState(Enums.pageState.renameModelDialog)
  }

  //---------------------------------------------------------
  // React useEffect() hook for component lifecycle changes
  //---------------------------------------------------------
  React.useEffect(() => {
    if( appStateContext.state.pageState_CharacterManage === Enums.pageState.mount ) {
      window.addEventListener("message", receiveMessage, false)
      appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.init})
    }
    
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.init /* && appStateContext.state.accountTotals */ ) {
      let accountTotals = null
      appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.apiInProgress})
      props.initializeCharacterManagePage()
      .then( res => {
        appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.ready})
      })
    }
    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.savingModel ) {
      window.scrollTo(0, 0)
      props.setLOADING({show: true, msg: "Generating 3D model, please wait..."})
      // appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.apiInProgress})
      addNewModelToAccount(rpmCharacterUrl, modelName, true)
      .then( data  => props.processCustomCharacterData(data.list) )
      .then( unused  => setRpmCharacterUrl(null) )
      .then( unused => {
        // finally we switch to the available characters tab once done:
        setActiveTab(tabIds.availableModels)
        // ensure loading screen is hidden as we push the confirmation dialog
        props.setLOADING({...props.LOADING, ...{show: false}})
        appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.modelCreatedDialog})
      })
      .catch(error => {
        console.error(`Error encountered while uploading model:\n${error}`)
        appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.init})
      })
    }

    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.apiInProgress ) {
      window.scrollTo(0, 0)
      props.setLOADING({show: true, msg: props.LOADING.msg === "" ? 'Loading...' : props.LOADING.msg})
      return
    }

    else if( appStateContext.state.pageState_CharacterManage === Enums.pageState.ready ) {
      window.scrollTo(0, 0)
      // display Available Characters tab if at least one model found
      // in account, otherwise display the Create Character tab
      if( appStateContext.state.accountTotals.charactersList && appStateContext.state.accountTotals.charactersList.length > 0 ) {
        setActiveTab(tabIds.availableModels)
      } 
      else { 
        setActiveTab(tabIds.createNewModel) 
      }
      props.setLOADING({...props.LOADING, ...{show: false}})
    }
  }, [
    appStateContext.state.pageState_CharacterManage,
    appStateContext.state.accountTotals
  ])

  // use a seperate useEffect hook with empty dependency array
  // to only act when component un-mounts
  React.useEffect(() => {
    // force scroll to top when this component mounts
    window.scrollTo(0, 0)
    // remove wolf3d iFrame event listener when this component un-mounts
    // and make sure to also update page state in parent controller
    return function cleanup() {
      window.removeEventListener("message", receiveMessage, false)
      appStateContext.dispatch({pageState_CharacterManage: Enums.pageState.mount})
    }
  }, [])

  // set page title and meta data
  var helmetData = seoMetaData_EN()

  ////////////////////////////////////////////////////////////////////
  // Render this functional component:
  ////////////////////////////////////////////////////////////////////
  return (
    <div id="anim-fadein" className="column mt-0">
      {helmetData}
      <AnimVersionPanel />
      {
        props.LOADING.show
        ?
        <LoadingScreen />
        :
        <div className="pt-0 ml-2 mr-2">
          {BUILD_SCREEN()}
        </div>
      }
    </div>
  )
} 