import React, { useState, useEffect } from 'react'
import {Link} from 'react-router-dom'
import { Swiper, SwiperSlide} from 'swiper/react'
import SwiperCore, { Navigation, Pagination, EffectCube } from 'swiper'
import * as Enums from '../../../common/enums'
import 'swiper/swiper-bundle.css'
import imgCustom from '../../../../images/animate-3d/character-select/model-custom.jpg'

// import additional swiper modules:
SwiperCore.use([Navigation, Pagination, EffectCube])

let textNotAvailable = "Thumbnail not available"

export default function CharacterSwiper(props) {

  function buildCharacterCard(thumbNail, fName, fDate) {
    let image = (thumbNail instanceof Blob) ? URL.createObjectURL(thumbNail) : imgCustom
    return (
      <div className="card dm-brand-border-lg">
        <div className="card-image">
          <figure className="image is-16by9">
            <img onLoad={() => props.setModelImageLoaded(true)} src={image} alt={`image-${fName}`} />
          </figure>
          {
            image === imgCustom
            &&
            <div className="thumbnail-unavail has-text-centered">
              <div className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                {textNotAvailable}
              </div>
            </div>
          }
        </div>
        <div className="card-content dm-brand">
          <div className="media">
            <div className="media-content">
              <p className="title is-4 has-text-white">{fName}</p>
              <p className="subtitle is-6 mgBot-20 has-text-white">{fDate}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // update character index state hook in parent:
  function updateCharacterSelection(index) {
    props.setCharacterIndex(index)
  }

  // dynamically build the swiper slides using props data passed in
  let swiperSlides = []
  if( props.charactersList ) {
    for( let i = 0; i < props.charactersList.length; i++ ) {
      let content = buildCharacterCard(props.charactersList[i].thumbImg, props.charactersList[i].name, "Added: " + Enums.dateConverter(props.charactersList[i].mtime/1000, true) )
      swiperSlides.push(
        <SwiperSlide key={`slide-${i}`} className="bShadow">
          {content}
        </SwiperSlide>
      )
    }
  }

  //----------------------------------------------------------
  // Render the component...
  //----------------------------------------------------------
  return (

    <React.Fragment>
      <Swiper id="anim-fadein"
        observer={true}
        observeParents={true}
        slidesPerView={1}
        spaceBetween={5}
        navigation
        effect="cube"
        loop={true}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => updateCharacterSelection(swiper.realIndex)}
      >
        {swiperSlides}

      </Swiper>
    </React.Fragment>
  )
}