import React from 'react'
import {Helmet} from "react-helmet"

/*************************************************************************** 
 * React functional component for page titles and metadata
 ***************************************************************************/
export default function HelmetPageData(props) {

  function helmetData() {
    return (
      <Helmet>
        <meta charSet="utf-8" />
        <title> {props.docTitle} </title>
        <meta name="description" content= {props.metaDesc} />
      </Helmet>
    )
  }

  // return the component
  return helmetData()
}