import React from 'react'
import axios from 'axios'
// import ParticlesBackground from '../common/ParticlesBackground'
import * as Enums from '../common/enums'
import '../../styles/form.css'
const qs = require('querystring')

export default function AccountClosedPage(props) {

  // account closed text
  const accountClosedMessage = "We're sorry to see you go."
  const accountClosedSubtitle = "You will no longer be able to log into your account."

  // form text
  const formTitle = "Before you go, please let us know why you're leaving."
  const formDropdownLabel = "Reason for Leaving"
  const formDropdownList = [
    "Select Reason",
    "Service not reliable",
    "Animation not as good as expected",
    "Not enough animation time",
    "Other"
  ]
  const textareaLabel = "If Other, please specify."

  // feedback submit success
  const submitSuccessTitle = "Thank you for your feedback!"
  const submitSuccessReturn = "Click the button below to return to the DeepMotion Home Page."

  // feedback submit failure
  const submitErrorTitle = "We encountered a problem submitting your feedback."
  const submitErrorReturn = "Click to try again or return to home."

  // progress text
  const inProgressTitle = "Submitting feedback..."

  // state
  const [reason, setReason] = React.useState(
    Enums.buildStateObj(0, false, Enums.inputSpanHide, Enums.normalSelectClass)
  )
  const [reasonInteracted, setReasonInteracted] = React.useState(false)
  const [otherDetails, setOtherDetails] = React.useState(
    Enums.buildStateObj("", false, Enums.inputSpanHide, Enums.normalTextAreaClass)
  )
  const [otherDetailsInteracted, setOtherDetailsInteracted] = React.useState(false)
  const [submitStatus, setSubmitStatus] = React.useState(Enums.FORM_STATE.ready)

  //---------------------------------------------------------------
  const appId         = process.env.REACT_APP_PODIO_AID
  const appToken      = process.env.REACT_APP_PODIO_APT
  const clientId      = process.env.REACT_APP_PODIO_CID
  const clientSecret  = process.env.REACT_APP_PODIO_CLS
  const redirectUri   = process.env.REACT_APP_PODIO_RED
  const csrfState     = process.env.REACT_APP_PODIO_CSR
  const authUrl       = process.env.REACT_APP_PODIO_AUTH_URL
  const restApiUrl    = process.env.REACT_APP_PODIO_BASE_URL + `/${appId}`
  //---------------------------------------------------------------

  const buttonStyle = {
    border: '0',
    outline: 'none',
    borderRadius: '4px',
    fontSize: '18px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '.1em',
    background: '#2d4e77',
    color: '#ffffff'
  }

  const buttonSecondaryStyle = {
    border: '0',
    outline: 'none',
    borderRadius: '4px',
    fontSize: '18px',
    fontWeight: '400',
    textTransform: 'uppercase',
    letterSpacing: '.1em',
    background: '#a8a8a8',
    color: '#ffffff'
  }

  const validateReason = event => {
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      reason.isValid,
      reason.span,
      reason.iClass
    )

    if (tmpStateObj.value && tmpStateObj.value > 1) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingSelectClass ) {
        tmpStateObj.iClass = Enums.normalSelectClass
      }
    } 
    else {
      tmpStateObj.isValid = false
    }

    if (parseInt(tmpStateObj.value) === formDropdownList.length) {
      setOtherDetailsInteracted(false)
    }

    setReason(tmpStateObj)
  }

  const validateOtherDetails = event => {
    let tmpStateObj = Enums.buildStateObj(
      event.target.value,
      otherDetails.isValid,
      otherDetails.span,
      otherDetails.iClass
    )

    if (tmpStateObj.value && tmpStateObj.value !== "" && tmpStateObj.value.length >= Enums.minOtherLength && tmpStateObj.value.trim().length) {
      tmpStateObj.isValid = true
      if( tmpStateObj.iClass.toString() === Enums.missingTextAreaClass ) {
        tmpStateObj.iClass = Enums.normalTextAreaClass
      }
    }
    else {
      tmpStateObj.isValid = false
    }

    setOtherDetails(tmpStateObj)
  }

  const highlightReason = event => {
    let tmpStateObj = Enums.buildStateObj(
      reason.value,
      reason.isValid,
      reason.span,
      reason.iClass
    )

    if (!tmpStateObj.isValid) {
      tmpStateObj.iClass = Enums.missingSelectClass
    } 
    else {
      tmpStateObj.iClass = Enums.normalSelectClass
    }

    setReason(tmpStateObj)
    setReasonInteracted(true)
  }

  const highlightOtherDetails = event => {
    let tmpStateObj = Enums.buildStateObj(
      otherDetails.value,
      otherDetails.isValid,
      otherDetails.span,
      otherDetails.iClass
    )

    if (!tmpStateObj.isValid && parseInt(reason.value) === formDropdownList.length) {
      tmpStateObj.iClass = Enums.missingTextAreaClass
    }
    else {
      tmpStateObj.iClass = Enums.normalTextAreaClass
    }

    setOtherDetails(tmpStateObj)
    setOtherDetailsInteracted(true)
  }

  function isFormReadyForSubmission() {
    if (!reason.isValid) {
      return false
    }
    if (!otherDetails.isValid && parseInt(reason.value) === formDropdownList.length) {
      return false
    }

    return true
  }

  function submitAccountClosedForm() {
    if (!isFormReadyForSubmission()) {
      return
    }

    // build the request body needed for oauth authentication
    let authRequestBody = {
      grant_type: 'app',
      app_id: appId,
      app_token: appToken,
      client_id: clientId,
      redirect_uri: redirectUri,
      client_secret: clientSecret,
      state: csrfState
    }

    // set axios request headers for token request:
    const authRequestHeaders = {
      headers: {
        // Podio OAuth API requires the Content-Type header to be
        // set to application/x-www-form-urlencoded 
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }

    /*******************************************************
     *******************************************************
     * Podio API #1 - Get access token 
     *******************************************************
     *******************************************************/
    axios.post(authUrl, qs.stringify(authRequestBody), authRequestHeaders)
      .then((res) => {
      // If successfully authenticated, make POST request to Podio
      // API to create a new lead record...
      let aToken = res.data.access_token
      // let rToken = res.data.refresh_token
      console.log(`Access token received`)
      // add auth token to request header for next API request
      const apiRequestHeaders = {
        headers: {
          // Podio createItem() API requires a JSON request body...
          'Content-Type': 'application/json',
          'Authorization': `OAuth2 ${aToken}`
        }
      }

      /*******************************************************
       *******************************************************
        * Podio API #2 - Create new item in cloud signup app
        *******************************************************
        *******************************************************/

      // configure API request body for Create Item call
      // TODO: use the same form?
      let createItemRequestBody = {
        "external_id":"Customer Feedback",
        "fields": [
          {
            "external_id": "title",
            "values": "[Deactivated] - " + formDropdownList[reason.value-1]
          },
          {
            "external_id": "feedback",
            "values": otherDetails.value
          },
          {
            "external_id": "may-we-contact-you-re-your-feedback",
            "values": [1]
          },
          {
            "external_id": "email",
            "values": [{
              "type": "work",
              "value": props.email !== null ? props.email : "no-email@no-email.com" // user's email read in through props!
            }]
          }
        ]
      }

      //==> PODIO POST API - Create New Item:
      axios.post(restApiUrl, JSON.stringify(createItemRequestBody), apiRequestHeaders)
        .then((res) => {
          //=============================================================
          // --> ON SUCCESSFUL SUBMIT REDIRECT BACK TO ANIMATE 3D PRODUCT 
          // PAGE WITH URL QUERY PARAM TO SHOW SUCCESS MODAL
          //=============================================================
          
          console.log(`Form submitted successfully`)
          setSubmitStatus(Enums.FORM_STATE.success)
          // history.push(Enums.routes.Dash + '?form=complete')
          return
        })
        // Create Item API returned error
        .catch( async function (error) {
          //TODO: Handle error
          console.log(`Error encountered while submitting feedback...\n`)
          console.log(`${JSON.stringify(error)}`)
          // mark state as failed submission status
          setSubmitStatus(Enums.FORM_STATE.failure)
        })
      })
    .catch((error) => {
      //TODO: Handle error
      console.log(`Error - could not authenticate with server.`) 
      // mark state as failed submission status
      setSubmitStatus(Enums.FORM_STATE.failure)
    })
  }

  function buildFormDropdown() {
    let formSelect = []
    for (let i = 0; i < formDropdownList.length; i++) {
      formSelect.push(
        <option value={i+1} key={i+1}>{formDropdownList[i]}</option>
      )
    }

    return(
      <React.Fragment>
        {formSelect}
      </React.Fragment>
    )
  }

  function buildForm() {
    return(
      <div>
        <h4 className="title is-5">{formTitle}</h4>
        <div className="field">
          <label className="label">{formDropdownLabel}</label>
          <div className="control">
            <div className={reason.iClass} onBlur={highlightReason} onChange={validateReason}>
              <select onChange={validateReason}>
                {buildFormDropdown()}
              </select>
            </div>
          </div>
        { !reason.isValid && reasonInteracted
          ?
          <p className="help is-danger">Please select a reason.</p>
          :
          <p></p>
        }
        </div>

        <div className="field">
          <label className="label">
            {textareaLabel}
            { parseInt(reason.value) === formDropdownList.length
              ?
              <span className="has-text-danger is-size-7"> * Requied</span>
              :
              <span></span>
            }
            
          </label>
          <textarea className={otherDetails.iClass} onBlur={highlightOtherDetails} onChange={validateOtherDetails} placeholder="..."></textarea>
        { !otherDetails.isValid && otherDetailsInteracted && parseInt(reason.value) === formDropdownList.length
          ?
          <p className="help is-danger">Additional details are required.</p>
          :
          <p></p>
        }
        </div>

        <div className="field">
          <div className="control">
            <button className="button is-medium preview-btn" onClick={()=>submitAccountClosedForm()}>Submit</button>
          </div>
        </div>
      </div>
    )
  }

  function buildAccountClosedBlockAndForm() {
    return(
      <div className="box"> 
        <div className="block">
          <h3 className="title is-4">{accountClosedMessage}</h3>
          <h3 className="subtitle is-5">{accountClosedSubtitle}</h3>
          <div className="content">
            <p>
              Please contact <a href="mailto:support@deepmotion.com">support@deepmotion.com</a> if this was a mistake and you wish to reopen your account.
            </p>
          </div>
        </div>
        {buildForm()}
      </div>
    )
  }

  function buildProgressDisplay() {
    return (
      <div className="Absolute-Center" >
        <div className="column has-text-centered vcenter-flex" style={{minHeight:'50vh',marginBottom:'0'}}>

          <div className="">
            <div className="columns">
              <div className="column">
                <h4 className="subtitle is-4 has-text-black mgTop-50">{inProgressTitle}</h4>
              </div>
            </div>
            <div className="columns">
              <div className="column hcenter is-half">
                <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
              </div>
            </div>    
          </div>
        </div>
      </div>
    )
  }

  function buildSuccessMessage() {
    return(
      <div className="box">
        <div className="block">
          <h3 className="title is-4">{submitSuccessTitle}</h3>
          <h3 className="subtitle is-5">{submitSuccessReturn}</h3>
        </div>
        <div className="block is-centered">
          <a className="button" href="https://www.deepmotion.com/" style={buttonStyle}>DeepMotion Home</a>
        </div>
      </div>
    )
  }

  function buildFailureMessage() {
    return(
      <div className="box">
        <div className="block">
          <h3 className="title is-4">{submitErrorTitle}</h3>
          <h3 className="subtitle is-5">{submitErrorReturn}</h3>
        </div>
        <div className="block is-centered">
          <div className="field is-grouped">
            <p className="control">
              <a className="button" onClick={() => submitAccountClosedForm()} style={buttonStyle}>Retry</a>
            </p>
            <p className="control">
              <a className="button" href="https://www.deepmotion.com/" style={buttonSecondaryStyle}>DeepMotion Home</a>
            </p>
          </div>
        </div>
      </div>
    )
  }

  function buildPage() {
    switch( submitStatus ) {
      case Enums.FORM_STATE.ready:
        return(
          buildAccountClosedBlockAndForm()
        )

      case Enums.FORM_STATE.inProgress:
        return(
          buildProgressDisplay()
        )

      case Enums.FORM_STATE.success:
        return(
          buildSuccessMessage()
        )

      case Enums.FORM_STATE.failure:
        return(
          buildFailureMessage()
        )
    }
  }

  React.useEffect(() => {
  }, [
    reason,
    reasonInteracted,
    otherDetails,
    otherDetailsInteracted,
    submitStatus
  ])

  return(
    <div className="block">
      <div className="columns is-centered">
        <div className="column is-half">
          {buildPage()}
        </div>
      </div>
    </div>
  )
}