import React, { Component } from 'react'
import axios from 'axios'
import CustomModal from './components/CustomModal'
import TableButton from './components/TableButton'
import SearchField from './components/SearchField'
import * as Enums from '../../common/enums'

const modalId = Object.freeze({
  "none":                  null,
  "confirm":                  1,
  "success":                  2,
  "error":                    3
})

const delimType = Object.freeze({
  "newline":                  0,
  "comma":                    1
})

const bulkInfoTemplate = Object.freeze({
  usersList: null,
  errorList: null,
  numSuccess: 0,
  numErrors: 0
})

const opType = Object.freeze({
  deactivateUser:     0,
  removeUserGroup:    1,
  deleteUser:         2,
  addMinutesPack:     3,
  addMinutesBalance:  4
})

export default function BulkUpdateTab(props) {

  const [activeModal, setActiveModal] = React.useState(modalId.none)
  const [errorMsg, setErrorMsg] = React.useState("")

  // if a backend API request is currently in progress
  const [apiInProgress, setApiInProgress] = React.useState(false)
  // can be newline (\n) or comma (,) separated
  const [dataDelimiterType, setDataDelimiterType] = React.useState(delimType.newline)
  // additional info for adding minutes
  const [minuteInfo, setMinuteInfo] = React.useState("")
  // live info for each job
  const [bulkInfoData, setBulkInfoData] = React.useState(bulkInfoTemplate)
  const [bulkOperation, setBulkOperation] = React.useState(opType.deactivateUser)
  // cancel current job in progress (not working yet)
  const [jobCancelled, setJobCancelled] = React.useState(false)

  const buildInputArea = () => {

    let commaButtonClass = "button"
    let newlineButtonClass = "button"
    let deactiveUserButtonClass = "button"
    let removeGroupButtonClass = "button"
    let deleteUserButtonClass = "button"
    let addBulkMinutesClass = "button"
    let extraAddMinuteActionsClass = "columns"
    let actionButtonText = ""
    let actionLabel = ""
    if (dataDelimiterType === delimType.comma) {
      commaButtonClass += ' is-link is-selected'
    } else {
      newlineButtonClass += ' is-link is-selected'
    }

    if( bulkOperation === opType.deactivateUser ) {
      deactiveUserButtonClass += ' is-primary is-selected'
      extraAddMinuteActionsClass += ' is-hidden'
      actionButtonText = "Bulk Deactivate"
      actionLabel = "deactivate"
    }
    else if( bulkOperation === opType.removeUserGroup ) {
      removeGroupButtonClass += ' is-primary is-selected'
      extraAddMinuteActionsClass += ' is-hidden'
      actionButtonText = "Bulk Group Remove"
      actionLabel = "group remove"
    }
    else if( bulkOperation === opType.deleteUser ) {
      deleteUserButtonClass += ' is-primary is-selected'
      extraAddMinuteActionsClass += ' is-hidden'
      actionButtonText = "Bulk Delete"
      actionLabel = "delete"
    } 
    else {
      addBulkMinutesClass += ' is-primary is-selected'
      actionButtonText = "Bulk Add Minutes"
      actionLabel = "add mintues"
    }

    let placeholderText = (dataDelimiterType === delimType.comma) 
      ? "xxxxx,yyyyy,zzzzz,..." 
      : "xxxxx\nyyyyy\nzzzzz\n..."
    let labelText = (dataDelimiterType === delimType.comma) 
      ?
      `Enter comma separated list of userIds to bulk ${actionLabel}:`
      :
      `Enter new line separated list of userIds to bulk ${actionLabel}:`

    let minutePackButtonClass = "button"
    let minuteRefillButtonClass = "button"
    if( bulkOperation === opType.addMinutesPack ) {
      minutePackButtonClass += ' is-primary is-selected'
    }
    else if( bulkOperation === opType.addMinutesBalance ) {
      minuteRefillButtonClass += ' is-primary is-selected'
    }

    let buttonDisabled = true 
    if( bulkInfoData ) {
      if( bulkInfoData.usersList ) {
        if( bulkInfoData.usersList.length ) {
          // only enable if usersList has been populated
          // and there's at least one element
          buttonDisabled = false
        }
      }
    }

    return (

      <React.Fragment>
        <div className="columns">

          <div className="column is-8">
            <h3 className="title is-5 mb-3">Bulk Operation</h3>
            <div className="buttons has-addons">
              <button
                className={deactiveUserButtonClass}
                onClick={() => setBulkOperation(opType.deactivateUser)}
              >
                Deactivation
              </button>
              <button
                className={removeGroupButtonClass}
                onClick={() => setBulkOperation(opType.removeUserGroup)}
              >
                Group Removal
              </button>
              <button
                className={deleteUserButtonClass}
                onClick={() => setBulkOperation(opType.deleteUser)}
              >
                Deletion
              </button>
              <button
                className={addBulkMinutesClass}
                onClick={() => setBulkOperation(opType.addMinutesPack)}
              >
                Add Minutes
              </button>
            </div>
          </div>

          <div className="column">
            <h3 className="title is-5 mb-3">Data Delimter</h3>
            <div className="buttons has-addons">
              <button
                className={newlineButtonClass}
                onClick={() => setDataDelimiterType(delimType.newline)}
              >
                newline (\n)
              </button>
              <button
                className={commaButtonClass}
                onClick={() => setDataDelimiterType(delimType.comma)}
              >
                comma (,)
              </button>
            </div>
          </div>
        </div>

        <div className={extraAddMinuteActionsClass}>
          <div className="column">

            <div className="field is-horizontal">

              <div className="field-label">
                <label className="label">{bulkOperation === opType.addMinutesPack ? "Minute Pack" : "Number of Minutes"}</label>
              </div>

              <div className="field-body">

                <div className="field">
                  <input className="input" type="text" onChange={parseMinuteData} placeholder=""></input>
                </div>

                <div className="field">
                  <div className="buttons has-addons">
                    <button className={minutePackButtonClass} onClick={() => setBulkOperation(opType.addMinutesPack)}>Minute Pack</button>
                    <button className={minuteRefillButtonClass} onClick={() => setBulkOperation(opType.addMinutesBalance)}>Minute Refill</button>
                  </div>
                </div>

              </div>

            </div>

          </div>
        </div>

        <div className="columns">
          <div className="column">

            <div className="field">
              <label className="label">{labelText}</label>
              <div className="control">
                <textarea className="textarea" onChange={parseInputData} placeholder={placeholderText}></textarea>
              </div>
            </div>
            <div className="field">
              <div className="control">
                {
                  buttonDisabled
                  ?
                  <button className="button" disabled style={{cursor:'not-allowed'}}>{actionButtonText}</button>
                  :
                  <button className="button action-btn-dark" onClick={()=>setActiveModal(modalId.confirm)} >{actionButtonText}</button>
                }
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }

  const runModalChecks = () => {
    if( !bulkInfoData || !bulkInfoData.usersList ) {
      return
    }
    let modalTitle = ""
    let successText = ""
    let errorText = ""

    switch( activeModal ) {
      case modalId.confirm:
        switch( bulkOperation ) {
          default:
          case opType.deactivateUser:
            modalTitle = `Deactivate ${bulkInfoData.usersList.length} Users?`
            break
          case opType.removeUserGroup:
            modalTitle = `Group Remove ${bulkInfoData.usersList.length} Users?`
            break
          case opType.deleteUser:
            modalTitle = `Permanently Delete ${bulkInfoData.usersList.length} Users?`
            break
        }
        return (
          <CustomModal {...props}
            title={modalTitle}
            func={() => performBulkOperation()}
            modalBody={() => buildConfirmModal()}
            closeModal={() => setActiveModal(modalId.none)}
          />
        )
      case modalId.success:
        let formattedLogsData = bulkInfoData.errorList.map(function(elem, idx){
            return ((idx+1).toString() + ` : ${elem}`)
        })
        switch( bulkOperation ) {
          default:
          case opType.deactivateUser:
            successText = "Successful deactivations:"
            errorText = "Failed deactivations:"
            break
          case opType.removeUserGroup:
            successText = "Successful group removals:"
            errorText = "Failed group removals:"
            break
          case opType.deleteUser:
            successText = "Successful deletions:"
            errorText = "Failed deletions:"
            break
          case opType.addMinutesPack:
            successText = "Sucessful minute packs added:"
            errorText = "Failed minute packs added:"
          case opType.addMinutesBalance:
            successText = "Successful changes to minute balance:"
            errorText = "Failed changes to minute balance:"
        }
        return (
          <div className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head m-0">
                <p className="modal-card-title">Results</p>
                <button className="delete" aria-label="close" onClick={()=> setActiveModal(modalId.none)}></button>
              </header>
              <section className="modal-card-body m-0">
                <React.Fragment>
                  <div className="columns has-text-centered">
                    <div className="column">
                      <h2 className="title is-4">Processed {bulkInfoData.usersList.length} Accounts</h2>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <h2 className="subtitle has-text-success is-5">{successText}</h2>
                    </div>
                    <div className="column">
                      <h2 className="subtitle is-5">{bulkInfoData.numSuccess}</h2>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">
                      <h2 className="subtitle has-text-danger is-5">{errorText}</h2>
                    </div>
                    <div className="column">
                      <h2 className="subtitle is-5">{bulkInfoData.numErrors}</h2>
                    </div>
                  </div>
                </React.Fragment>
              </section>
              <footer className="modal-card-foot m-0">
                <button className="button action-btn-dark" onClick={()=> resetScreen()}> Close </button>
                <button className="button " onClick={()=> downloadLogs( "error-logs.txt", JSON.stringify(formattedLogsData, null, '\t'))}> Download Logs </button>
              </footer>
            </div>
          </div>
        )
      case modalId.error:
        return (
          <CustomModal {...props}
            title="Error"
            func={"OK"}
            modalBody={() => buildErrorModal(errorMsg)}
            closeModal={() => setActiveModal(modalId.none)}
          />
        )
      default:
        return <div />
    }
  }

  function resetScreen() {
    setActiveModal(modalId.none)
    setBulkInfoData(bulkInfoTemplate)
    setBulkOperation(opType.deactivateUser)
  }
  
  // reads extra info needed for minute pack submission
  const parseMinuteData = event => {
    let tmpStateObj = Enums.buildStateObj(
      (event.target.value),
      false
    )

    if( bulkOperation === opType.addMinutesPack ) {
      let packId = event.target.value.toString().trim()
      if( packId.length > 0 ) {
        tmpStateObj.isValid = true
      }
    } 
    else if( bulkOperation === opType.addMinutesBalance ) {
      if ( event.target.value > 0 ) {
        tmpStateObj.isValid = true
      }
    }

    setMinuteInfo(tmpStateObj)
  }

  // reads the comma separate list of user ids and parses into state vars
  const parseInputData = event => {
    let ulistAsArray = null
    let newStateObj = JSON.parse(JSON.stringify(bulkInfoTemplate))
    if( !event.target.value || event.target.value === "" ) {
      setBulkInfoData(newStateObj)
    }
    else {
      if( dataDelimiterType === delimType.comma ) {
        ulistAsArray = (event.target.value).split(',')
      }
      else if( dataDelimiterType === delimType.newline ) {
        ulistAsArray = (event.target.value).split('\n')
      }
      newStateObj.usersList = ulistAsArray
      newStateObj.errorList = new Array(ulistAsArray)
      setBulkInfoData(newStateObj)
      console.log(`Found ${ulistAsArray.length} unique user entries.`)
    }
  }

  const performBulkOperation = async () => {
    if( !bulkInfoData || !bulkInfoData.usersList ) {
      return false
    }
    let tmpErrorList = new Array(bulkInfoData.usersList.length)
    let successCount = 0
    let errorCount = 0
    // clear confirm dialog and set state to show loading bar
    setActiveModal(modalId.none)
    setApiInProgress(true)

    let apiEndpoint = getOperationUrl()
    try {
      for( let i = 0; i < bulkInfoData.usersList.length; i++ ) {
        let tmpStateObj = null
        if( jobCancelled ) {
          console.log(`*********** Cancelling job...`)
          i = bulkInfoData.usersList.length
        }
        switch( bulkOperation ){
          default:
            await axios.post(process.env.REACT_APP_API_URL + apiEndpoint, {
              "uid": bulkInfoData.usersList[i]
            }).then( (res) => {       
              if( tmpErrorList[i] !== undefined ){
                errorCount--
              }
                successCount++
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numSuccess = successCount
                tmpErrorList[i] = `success`
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
            }).catch( (error) => {
              if( tmpErrorList[i] !== undefined ){
                console.error(`Error deactivating user ${i+1}: ${error.response.data.errorSummary}`)
                errorCount++
                tmpErrorList[i] = error.response.data.errorSummary
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numErrors = errorCount
                tmpStateObj.errorList = tmpErrorList[i]
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
              }
            })
          case opType.addMinutesPack:
            await axios.post(process.env.REACT_APP_API_URL + apiEndpoint, {
              "uid": bulkInfoData.usersList[i],
              "clientId": process.env.REACT_APP_OKTA_CID,
              "packId": minuteInfo.value
            }).then( (res) => {       
              if( tmpErrorList[i] !== undefined ){
                errorCount--
              }
                successCount++
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numSuccess = successCount
                tmpErrorList[i] = `success`
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
            }).catch( (error) => {
              if( tmpErrorList[i] !== undefined ){
                console.error(`Error adding minutes pack to user ${i+1}: ${error.response.data.errorSummary}`)
                errorCount++
                tmpErrorList[i] = error.response.data.errorSummary
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numErrors = errorCount
                tmpStateObj.errorList = tmpErrorList[i]
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
              }
            })
          case opType.addMinutesBalance:
            await axios.post(process.env.REACT_APP_API_URL + apiEndpoint, {
              "users": [{
                "uid": bulkInfoData.usersList[i],
                "clientId": process.env.REACT_APP_OKTA_CID,
                "minutes": minuteInfo.value,
                "addToExisting": 1
              }]
            }).then( (res) => {     
              if( tmpErrorList[i] !== undefined ){
                errorCount--
              }
                successCount++
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numSuccess = successCount
                tmpErrorList[i] = `success`
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
            }).catch( (error) => {
              if( tmpErrorList[i] !== undefined ){
                console.error(`Error adding minutes to user ${i+1}: ${error.response.data.errorSummary}`)
                errorCount++
                tmpErrorList[i] = error.response.data.errorSummary
                tmpStateObj = JSON.parse(JSON.stringify(bulkInfoData))
                tmpStateObj.numErrors = errorCount
                tmpStateObj.errorList = tmpErrorList[i]
                console.log(`success: ${successCount}`)
                console.log(`error:   ${errorCount}`)
                // await setBulkInfoData(tmpStateObj)
              }
            })
        }
        console.log(`success 1:  ${tmpStateObj.numSuccess}`)
        console.log(`error 1:    ${tmpStateObj.numErrors}`)
        await setBulkInfoData(tmpStateObj)
        console.log(`success 2:  ${bulkInfoData.numSuccess}`)
        console.log(`error 2:    ${bulkInfoData.numErrors}`)
      }

      // bulk operation complete, set final data:
      let newStateObj = JSON.parse(JSON.stringify(bulkInfoData))
      newStateObj.errorList = tmpErrorList
      newStateObj.numSuccess = successCount
      newStateObj.numErrors = errorCount
      await setBulkInfoData(newStateObj)
      setJobCancelled(false)
      setApiInProgress(false)
      setActiveModal(modalId.success)
    }
    catch (error) {
      console.error(`Error while bulk de-activating users:\n${error}`)
      setApiInProgress(false)
      setActiveModal(modalId.error)
      return false
    }
  }

  function getOperationUrl() {
    let url
    switch( bulkOperation ) {
      default:
      case opType.deactivateUser:
        url = "/admin/deactivateUser"
        break
      case opType.removeUserGroup:
        url = "/admin/removeUserGroup"
        break
      case opType.deleteUser:
        url = "/admin/deleteUser"
        break
      case opType.addMinutesPack:
        url = "/admin/addMinutesPack"
        break
      case opType.addMinutesBalance:
        url = "/admin/setMinuteBalance"
        break
    }
    return url
  }

  // create a local download link for error logs 
  function downloadLogs(filename, text) {
    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  function buildConfirmModal() {
    let msg = ""
    switch(bulkOperation) {
      default:
      case opType.deactivateUser:
        msg = `WARNING: You are about to bulk deactivate ${bulkInfoData.usersList.length} users, continue?`
        break
      case opType.removeUserGroup:
        msg = `WARNING: You are about to remove group access for ${bulkInfoData.usersList.length} users, continue?`
        break
      case opType.deleteUser:
        msg = `WARNING: You are about to permanently delete ${bulkInfoData.usersList.length} users, continue?`
        break
      case opType.addMinutesPack:
        msg = `WARNING: You are about to add permanent minutes to ${bulkInfoData.usersList.length} users, continue?`
        break
      case opType.addMinutesBalance:
        msg = `WARNING: You are about to change the minute balance to ${bulkInfoData.usersList.length} users, continue?`
        break
    }
    return(
      <section className="modal-card-body m-0">
        <div className="content">
          <div className="columns has-text-centered">
            <div className="column">
              <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
            </div>
          </div> 
          <div className="columns has-text-centered">
            <div className="column">
              <h2 className="subtitle is-5">{msg}</h2>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildSuccessModal() {
    const msg = `Users have been successfully deactivated.`
    return(
      <section className="modal-card-body m-0">
        <div className="content">
          <div className="columns has-text-centered">
            <div className="column">
              <span className="icon is-success has-text-success is-large"><i className="fas fa-check fa-3x"></i></span>
            </div>
          </div> 
          <div className="columns has-text-centered">
            <div className="column">
              <h2 className="subtitle is-5">{msg}</h2>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildErrorModal(error) {
    return(
      <section className="modal-card-body m-0">
        <div className="content">
          <div className="columns has-text-centered">
            <div className="column">
              <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
            </div>
          </div> 
          <div className="columns has-text-centered">
            <div className="column">
              <h2 className="subtitle is-5">{error}</h2>
            </div>
          </div>
        </div>
      </section>
    )
  }

  function buildProcessingDisplay() {
    let percent = parseInt( (bulkInfoData.numErrors+bulkInfoData.numSuccess) / bulkInfoData.usersList.length * 100 ) 
    return(
      <div className="section">
        <div className="columns has-text-centered">
          <div className="column is-8">
            <div className="columns has-text-centered">
              <div className="column ">
                <h2 className="subtitle has-text-black is-5">{percent}%</h2>
                <progress className="progress is-info" value={percent} max="100"></progress>
              </div>
            </div> 
            <div className="columns">
              <div className="column has-text-left">
                <h2 className="subtitle has-text-success is-5">Success count: {bulkInfoData.numSuccess}</h2>
              </div>
              <div className="column has-text-right">
                <h2 className="subtitle has-text-danger is-5">Error count: {bulkInfoData.numErrors}</h2>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <button className="button action-btn-dark" onClick={()=>setJobCancelled(true)} >Stop Job</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  // custom useEffect hook triggers when the state vars listed
  // in the dependency array change, causing this component to
  // re-render with the newly updated info
  React.useEffect(() => {
  }, [activeModal, apiInProgress, minuteInfo, bulkInfoData, bulkOperation, jobCancelled])

  return(
    <React.Fragment>
      {
        apiInProgress
        ?
        buildProcessingDisplay()
        :
        <React.Fragment>
          {runModalChecks()}
          <div className="section pt-3">
            <div className="columns">
              <div className="column has-text-centered">
                <h1 className="title is-4"> Bulk Operations </h1>
              </div>
            </div>
            {buildInputArea()}
          </div>
        </React.Fragment>
      }  
    </React.Fragment>
  )
}