import React from 'react'
import * as Enums from '../../../common/enums'

/* input list: ********************************************
 * packId                       (pack id of submitted pack)
 * userId                       (okta id of user pack was added to)
 ********************************************************/
export function PackConfirm(packId, userId) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p>Successfully added <b>{packId}</b> to user <b>{userId}</b>.</p>
        </div>
      </div>
    </section>
  )
}

/* input list: ********************************************
 * payDate                      (new date)
 * userId                       (okta id of user pack was added to)
 ********************************************************/
export function PayDateConfirm(payDate, userId) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p>Successfully updated <b>{userId}</b> to new date <b>{payDate}</b>.</p>
        </div>
      </div>
    </section>
  )
}

/* input list: ********************************************
 * minAdd                       (number of minutes added)
 * userId                       (okta id of user minutes was added to)
 ********************************************************/
export function TempMinConfirm(minAdd, userId) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p>Successfully added <b>{minAdd}</b> minutes to user <b>{userId}</b>.</p>
        </div>
      </div>
    </section>
  )
}

/* input list: ********************************************
 * credId                       (id of credential created)
 * appName                      (name of app created)
 ********************************************************/
export function CredentialConfirm(credId, cid, secret, appName) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p>Successfully created <b>{appName}</b> under <b>{credId}</b>.</p>
          <ul>
            <li><b>Client ID:</b> {cid}</li>
            <li><b>Secret:</b> {secret}</li>
          </ul>
        </div>
      </div>
    </section>
  )
}

/* input list: ********************************************
 * credId                       (id of credential created)
 * appName                      (name of app created)
 ********************************************************/
export function PackCreateConfirm(packType, packName) {
  let pType = packType === Enums.packTableType.minutePack ? "minute pack" : "feature pack"
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p>Successfully created a <b>{pType}</b> with the name <b>{packName}</b>.</p>
        </div>
      </div>
    </section>
  )
}

export function GenericSuccess(content) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          {content}
        </div>
      </div>
    </section>
  )
}

export function LoadingModal() {
  return(
    <section className="modal-card-body">
      <div className="columns is-vcentered">
        <div className="column is-one-third has-text-centered is-vcentered">  
          <p className="title is-4">Processing</p>
          <progress className="progress indeterminate is-medium loading-bar" max="100"></progress>
        </div>
      </div>
    </section>
  )
}

/* input list: ********************************************
 * error                        (error value)
 ********************************************************/
export function ErrorModal(error) {
  return(
    <section className="modal-card-body">
      <div className="block">
        <div className="content">
          <p><b>{error}</b>: failed.</p>
        </div>
      </div>
    </section>
  )
}