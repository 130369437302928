////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// Main Functional Component for this application:
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
import React, {useState, useEffect, useReducer} from 'react'
import { Route, Switch, Link, useHistory, useLocation } from 'react-router-dom'
import { Security, SecureRoute, LoginCallback, useOktaAuth } from '@okta/okta-react'
import { AppStateContext } from './AppStateContext'
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import ReactDOMServer from 'react-dom/server'
import ReactTooltip from 'react-tooltip'
import { useIdleTimer } from 'react-idle-timer'
import axios from 'axios'
import 'bulma/css/bulma.css'

// PROJECT SOURCE
import {
  retrieveUserProfileInfo,
  getNewPEServiceToken,
  getLinksForJob, 
  getUserPlanData, 
  getJobsDataForAccount,
  getAccountCustomCharacters,
  downloadModelThumbnails,
  startNewAnimOrPoseJob,
  checkJobStatus,
  removeJobFromAccount,
  removeCustomModelFromAccount,
  uploadJobDataToBackend,
  analyzeVideoInputData,
  stopInProgressJob } from './components/api/apiRequests'
import PageTemplate from './components/PageTemplate'
import LoadingScreen from './components/common/LoadingScreen'
import InfoDialog from './components/common/InfoDialog'
import ContactUs from './components/common/ContactUs'
import FeedbackForm from './components/common/FeedbackForm'
import AnimVersionPanel from './components/common/AnimVersionPanel'
import DragZone from './components/products/animate-3d/DragDrop'
import Support from './components/common/Support'
// import ParticlesBackground from './components/common/ParticlesBackground'
import DashboardPage from './components/DashboardPage'
import Anim3DHome from './components/products/animate-3d/Animate3DHome'
import GuidedFTE from './components/products/animate-3d/GuidedFTE'
import NewJobConfig from './components/products/animate-3d/NewJobConfiguration'
import Library from './components/products/animate-3d/Library'
import Previewer from './components/products/animate-3d/Previewer'
import Login from './components/authentication/SignInPage'
import ProfilePage from './components/authentication/AccountProfilePage'
import ForgotPwdPage from './components/authentication/ForgotPwdPage'
import Anim3DSignUpPage from './components/authentication/Anim3DSignUpPage'
import ActivityPage from './components/admin/ActivityPage'
import AdminAPIApp from './components/admin/admin-tool/AdminAPIApp'
import AccountClosedPage from './components/authentication/AccountClosedPage'
import CharacterManagePage from './components/products/animate-3d/custom-character/CharacterManage'
import ProgressProvider from './components/products/animate-3d/ProgressProvider'
import DMBTSdkPage from './components/products/dmbtSdk'
import VRSdkPage from './components/products/vrSdk'
import YesNoSlider from './components/ui/YesNoSlider'
import DMDropDown from './components/ui/DMDropDown'
import DMDialog from './components/ui/DMDialog'
import DMToolTip from './components/ui/DMToolTip'
import DMDropDownKnob from './components/ui/DMDropDownKnob'
import DMDropDownColorPicker from './components/ui/DMDropDownColorPicker'
import Knob from './components/ui/KnobElem'
import CharacterSwiperDefault from './components/common/CharacterSwiperDefault'
import * as Enums from './components/common/enums'
import { oktaAuthConfig, oktaSignInConfig } from './config'
import {initialAppState} from './components/common/initialAppState'
// CSS
import 'react-circular-progressbar/dist/styles.css'
import './sass/App.scss'
// IMAGES 
import logo from './favicon.svg'
import imgDefaultCharacters from './images/animate-3d/character-select/default-characters.jpg'
import imgRobloxR15 from './images/animate-3d/character-select/roblox-r15.png'
import imgStandard from './images/animate-3d/character-select/default-characters.jpg'
import imgCustom from './images/animate-3d/character-select/model-custom.jpg'
import imgFaceModel from './images/animate-3d/female-face.jpg'
import imgNewAnim from './images/animate-3d/new-animation.jpg'
import imgNewPose from './images/animate-3d/new-static-pose.jpg'
// strings
const pageTitleDashboard = "Product Dashboard"
const pageTitleA3DHome = "Home"
const titleDownload = "Download"
const titleCreateAnim = "Create"
const textNotAvailable = "Thumbnail not available"
const textManageModels = "Manage Models"
const titleCouldNotRetrieve = "Could Not Retrieve Animations"
const textCouldNotRetrieve = "Sorry there was a problem, we could not retrieve animations for this job."
const text3DPoseTitle = "3D Pose Settings"
const textGenericErrorTitle = "Sorry, Something Went Wrong"
const textInputVideoErrorTitle = "Problem with Input Video"
const textDialogOk = "Ok"
const textDialogSupport = "Support"
const textDialogCancel = "Cancel"
const textDialogClose = "Close"
const textDialogDelete = "Delete"
const textDialogUpgrade = "Upgrade Plan"
const textDefaultLoadingMsg = "Loading..."
const settingFlags = [
  <span key="setting-on" className="icon is-medium"><i className="fas fa-check-circle has-text-success fa-lg"></i></span>,
  <span key="setting-off" className="icon is-medium"><i className="fas fa-times-circle has-text-danger fa-lg"></i></span>
]
// strings
const jobType3dAnim = "3D Animation"
const jobType3dPose = "3D Pose"
const newAnimTitle = "New Animation"
const newPoseTitle = "New Pose"
const mp4EnableOutputTitle = "Enable MP4 Output"
const mp4EnableCameraMotionTitle = "Camera Mode"
const mp4EnableShadowTitle = "Enable Shadow"
const mp4EnableAudioTitle = "Enable MP4 Audio"
const mp4SetBackgroundTitle = "Custom Background"
const mp4BackColorTitle = "Background Color"
const speedMultFilterTitle = "Speed Multiplier"
const modelSectionDefault = "Default"
const textUploadingVideo = "Uploading video, please wait..."
const textAnalyzing = "Analyzing..."
const textAddMotionClip = "Add Motion Clip"
const textAddImage = "Add Image"
const textPoseDescr = "Your static pose will be retargeted to the selected model(s)"
// icons
const iconMotionClip = "far fa-play-circle"
const iconImage = "far fa-image"
// default background (ie. Green Scren) color:
let defaultGSColor = [0,177,64,0]
const animJobTypes = Object.freeze([
  {value: jobType3dAnim, available: true},
  {value: jobType3dPose, available: true}
])
var tmpSize = 0


const oktaAuth = new OktaAuth(oktaAuthConfig)

const closeModalFlags = Object.freeze({
  routeToModelSelect:   0,
  routeToLibrary:       1,
  routeToA3dHome:       2,
  routeToModelManage:   3,
  routeToProfilePage:   4
})
const NUM_MODELS_PER_ROW = 3

const resetJobObject = Object.freeze({
  isJobInProgress: false,
  animationJobProgress: 0,
  currWorkflowStep: Enums.uiStates.initial,
  silentUploadInProgress: false,
  videoStorageUrl: null,
  videoFileName: null
})

////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
// App Entry Point:
////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////////
export default function App() {

  // browser history
  const history = useHistory()
  const location = useLocation()

  const customAuthHandler = () => {
    history.push('/')
  }
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    // currently always routing to main products dashboard on login/re-login. if we want
    // to remember their last logged in route line with originalUri though likely need
    // make sure app state logic always has all the info it needs upon re-login.
    history.replace(toRelativeUrl(Enums.routes.Dash, window.location.origin))
    // history.replace(toRelativeUrl(originalUri || Enums.routes.Dash, window.location.origin))
  }

  /////////////////////////////////////////////////////////////////////
  // Reducer function that allows for more complex state management
  // note: function accepts 2 different formats for the |action| param:
  //    1. {...STATE} - an object of 1 or more STATE key-value pairs
  //    2. {dispatchType: "string", action: {...STATE} } - where the
  //      |dispatchPatch| key can be used for custom state updates. 
  /////////////////////////////////////////////////////////////////////
  const stateReducer = (state, action) => {
    if( !('dispatchType' in action) ) {
      // if dispatchType key not present then update state using 
      // the |action| object parameter
      return {...state, ...action}
    }
    else {
      // otherwise custom update:
      switch (action.dispatchType) {
        case 'initializeAccountInfo':
          return {...state, ...action.payload, ...{accountDataRetrieved: true} }
        case 'initializeService':
          return {...state, ...action.payload, ...{anim3dInitialized: true, accountDataRetrieved: true} }
        case 'initializeLibrary':
          return {...state, ...action.payload, ...{libraryInitialized: true} }
        case 'initialize3dModelsPages':
          // side effect of initializing 3d models page is it also gets needed data for the general service
          return {...state, ...action.payload, ...{libraryInitialized: true, accountDataRetrieved: true, anim3dInitialized: true} }  
        case 'DIALOG_AnimDownloadDefaultModel':
          const newState = {...action.payload, ...{confirmDialogId: Enums.confirmDialog.standardDownload} }
          return {...state, ...newState }
        case 'DIALOG_AnimDownloadCustomModel':
          return {...state, ...action.payload, ...{confirmDialogId: Enums.confirmDialog.customDownload} }  
        case 'resetModelsPageAndDialogInfo':
          let payload = location.pathname === Enums.routes.Anim3dModelManage ? {pageState_CharacterManage: Enums.pageState.init} : {}
          payload = {...payload, ...{errorDialogInfo: {show: false, id: null, title: '', msg: ''} }}
          return {...state, ...payload }
        case 'reset':
          // resets entire app state
          return {...state, ...initialAppState }
        default:
          console.error(`Invalid state dispatch type passed: ${action.dispatchType}`)
      }
    }
  }

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // --- APP STATE ---
  const [STATE, DISPATCH] = useReducer(stateReducer, initialAppState)
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++

  //additional state hooks:
  const [isComponentMounted, setIsComponentMounted] = useState(false)
  const [activeJobMenu, setActiveJobMenu] = useState(Enums.jobMenu.animSettings)
  const [LOADING, setLOADING] = useState({show: false, msg:textDefaultLoadingMsg})
  const [selectedFileType, setSelectedFileType] = useState(Enums.animFileType.FBX)
  const [closeAccountButtonEnabled, setcloseAccountButtonEnabled] = useState(false)
  const [deleteJobButtonEnabled, setDeleteJobButtonEnabled] = useState(false)
  const [rerunViewState, setRerunViewState] = useState(Enums.pageState.rerunAnimSettings)
  const [rerunConfirmInfo, setRerunConfirmInfo] = useState({showModal: false, jobId: 0})  
  const [uploadCancelled, setUploadCancelled] = useState(false)
  const [showColorPickerDialog, setShowColorPickerDialog] = useState(false)

  /////////////////////////////////////////////////////////////////////
  // use effect hook for component mount
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( !isComponentMounted ) {
      componentMount()
    }
  }, [isComponentMounted] );
  /////////////////////////////////////////////////////////////////////
  // component un-mount
  /////////////////////////////////////////////////////////////////////
  useEffect(() => {
    return function cleanup() {
      componentUnMount()
    }
  }, [])

  /////////////////////////////////////////////////////////////////////
  // use effect hook for dashboard & FTE initialization
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( location.pathname === Enums.routes.Dash || Enums.routes.Anim3dGuidedFTE) {
      if( STATE.accountDataRetrieved && STATE.anim3dInitialized ) {
        // initialization function that supports browser refresh
        if( LOADING.show ) {
          setLOADING({show: false, msg:textDefaultLoadingMsg})
        }
      }
      else {
        if( !LOADING.show ) {
          // ensure loading wheel displayed if data not initialized yet
          setLOADING({...LOADING, ...{show: true}})
        }
      }
    }
  }, [STATE.accountDataRetrieved, STATE.anim3dInitialized, location.pathname] )

  /////////////////////////////////////////////////////////////////////
  // use effect hook for A3d home page
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( location.pathname === Enums.routes.Anim3d ) {
      if( STATE.anim3dInitialized && STATE.accountDataRetrieved) {
        if( LOADING.show ) {
          setLOADING({show: false, msg:textDefaultLoadingMsg})
        }
      }
      else {
        if( !LOADING.show ) {
          setLOADING({...LOADING, ...{show: true}})
        }
      }
    }
  }, [STATE.anim3dInitialized, location.pathname] )

  /////////////////////////////////////////////////////////////////////
  // use effect hook for library initialization
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( location.pathname === Enums.routes.Anim3dLibrary ) {
      if( STATE.libraryInitialized ) {
        sortLibraryByColumn()
        .then(res => {
          setLOADING({show: false, msg:textDefaultLoadingMsg})
        })
      }
      else {
        if( !LOADING.show ) {
          setLOADING({...LOADING, ...{show: true}})
        }
      }
    }
  }, [STATE.libraryInitialized, location.pathname] )

  /////////////////////////////////////////////////////////////////////
  // use effect hook for 3d models page initialization
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( location.pathname === Enums.routes.Anim3dModelManage ) {
      if( STATE.accountTotals.charactersList && STATE.pageState_CharacterManage !== Enums.pageState.init ) {
        // if accountDataRetrieved was just set to true and we are currently
        // on dashboard route disable the loading screen
        if( LOADING.show && STATE.pageState_CharacterManage === Enums.pageState.ready ) {
          setLOADING({show: false, msg:textDefaultLoadingMsg})
        }
      }
      else {
        if( !LOADING.show ) {
          setLOADING({...LOADING, ...{show: true}})
        }
      }
    }
  }, [STATE.pageState_CharacterManage, STATE.accountTotals.charactersList, location.pathname] )

  /////////////////////////////////////////////////////////////////////
  // hook for opening anim previewer
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( STATE.displayPreview && isExistingJob(STATE.animJobId) && Object.keys(STATE.animJobLinks).length !== 0 ) {
      history.push(Enums.routes.Anim3dPreview)
    }
  }, [STATE.displayPreview] )

  /////////////////////////////////////////////////////////////////////
  // hook for when current workflow step changes
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( STATE.currWorkflowStep === Enums.uiStates.jobInProgress && STATE.isJobInProgress ) {
      // TODO: Refactor to use single state to represent job in progress?
      if( location.pathname !== Enums.routes.Anim3dCreate ) {
        // jobs that are not started from the new job settings page are re-runs, re-route
        // the user with rerun query param when the workflow step changes to in progress
        history.push(`${Enums.routes.Anim3dCreate}?rerun=true`)
      }
    }
  }, [STATE.currWorkflowStep] )

  /////////////////////////////////////////////////////////////////////
  // use effect hook for download links change 
  /////////////////////////////////////////////////////////////////////
  React.useEffect( () => {
    if( STATE.currDownloadLinks && Object.keys(STATE.currDownloadLinks).length && isExistingJob(STATE.animJobId) ) {
      buildOutputFileTypeDropDown()
    }
  }, [STATE.currDownloadLinks] )
  /////////////////////////////////////////////////////////////////////
  // re-render when dialogId changes
  /////////////////////////////////////////////////////////////////////
  React.useEffect( () => {  
    if( !STATE.confirmDialogId ) {
      // reset dialog related state when dialog closes
      setSelectedFileType(Enums.animFileType.FBX)
      setRerunViewState(Enums.pageState.rerunAnimSettings)
      setRerunConfirmInfo({showModal:false, jobId: 0})
      setcloseAccountButtonEnabled(false)
      setDeleteJobButtonEnabled(false)
    }
  }, [STATE.confirmDialogId] )

  /////////////////////////////////////////////////////////////////////
  // hook for setting a new job in progress
  /////////////////////////////////////////////////////////////////////
  useEffect( () => {
    if( STATE.isJobInProgress ) { // if a job was just started...
      if( STATE.currWorkflowStep === Enums.uiStates.jobInProgress && 
        STATE.animJobId && STATE.animJobId !== 0 && STATE.animJobId !== "" ) {
        checkStatus(false, true)
      }
    }
  }, [STATE.isJobInProgress, STATE.animJobId] )

  /////////////////////////////////////////////////////////////////////
  // define auth related functions for timeout and idle timer reset
  /////////////////////////////////////////////////////////////////////
  const handleOnIdle = event => {
    console.error(`user is idle, was last active at ${getLastActiveTime()}\n${event}`)
    if( window.location.pathname === Enums.routes.SignIn ) { 
      // no need to do anything if user is idle on signin page 
      return
    }
    setErrorDialogInfo(true, Enums.eCodes.Unauthorized, "Your session has expired")
  }
  const handleOnActive = event => reset()
  const handleOnAction = event => reset()

  const { reset, getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 1000 * 60 * Enums.idleTimeoutInMins,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
    crossTab: {
      emitOnAllTabs: true
    }
  })

  /////////////////////////////////////////////////////////////////////
  // add/remove event listener on component mount/un-mount 
  // for detecting page refresh and maintaining React state
  /////////////////////////////////////////////////////////////////////
  function componentMount() {
    // Run okta as a service for several benefits including cross-tab synchronization
    // https://github.com/okta/okta-auth-js#running-as-a-service
    // console.log(`~~~ Attempting to initialize okta service ~~~`)
    // oktaAuth.start()

    // window.addEventListener("load", onLoad)
    // window.addEventListener("beforeunload", onUnload)

    if( process.env.REACT_APP_DEBUG ) {
      // enable axios debugging in staging and local builds
      localStorage.setItem('debug', 'axios')
    }
    setIsComponentMounted(true)
  }
  /////////////////////////////////////////////////////////////////////
  function componentUnMount() {
    // console.log(`--- Attempting to stop okta service ---`)
    // oktaAuth.stop() // stop running service
    // window.removeEventListener("load", onLoad)
    // window.removeEventListener("beforeunload", onUnload)
    if( process.env.REACT_APP_DEBUG ) {
      // remove axios debug cookie on component un-mount
      localStorage.removeItem('debug')
    }
    setIsComponentMounted(false)
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for page un-load in progress
  /////////////////////////////////////////////////////////////////////
  function onUnload(e) {
    // only backup state to local storage if user on an authenticated route
    // (ie. includes '/dashboard' in the path)
    if( window.location.pathname.toString().includes(Enums.routes.Dash) ) {
      saveStateToLocalStorage( () => {
        // don't return until saving to local storage complete
        return
      })
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Special handling for page load in progress
  /////////////////////////////////////////////////////////////////////
  function onLoad(e) {
    // only restore state from local storage if user on an authenticated route. this
    // helps manage state if/when the user refreshes the page/browser
    if( window.location.pathname.toString().includes(Enums.routes.Dash) ) {
      checkAndRestoreStateIfCached()
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Restores state from browser's local storage if key found
  /////////////////////////////////////////////////////////////////////
  function checkAndRestoreStateIfCached() {
    const localStorageData = localStorage.getItem(Enums.localStorageStateId)
    if( localStorageData !== null ) {
      restoreAppStateFromLocalStorage(localStorageData)
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Saves react state to browser's local storage
  /////////////////////////////////////////////////////////////////////
  function saveStateToLocalStorage(cb) {
    // to maintain state across page refresh we temporarily save all
    // state data to local storage, allow page refresh to happen,
    // then read back from storage and remove once complete
    let dataStr = JSON.stringify(STATE)
    localStorage.setItem(Enums.localStorageStateId, dataStr)
    if( cb ) {
      cb()
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Updates state hook defined in this component, mostly used
  // for restoring app state after page/broswer refresh
  //
  // @param storageData : data returned from local storage API
  /////////////////////////////////////////////////////////////////////
  function restoreAppStateFromLocalStorage(storageData) {
    const tmpData = JSON.parse(storageData)
    // tmpData.browserRefresh = true
    localStorage.removeItem(Enums.localStorageStateId)
    DISPATCH(tmpData)
  }

  /////////////////////////////////////////////////////////////////////
  // Removes local auth and storage keys that may have been
  // set during app execution 
  //
  // @param cb : callback function when done
  /////////////////////////////////////////////////////////////////////
  function removeLocalStorageData(cb) {
    // first remove any previous local storage data
    localStorage.removeItem(Enums.localStorageOktaId)
    localStorage.removeItem(Enums.localStorageStateId)
    if( cb ) { 
      return cb() 
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  function getUserInfoAndSubscriptionData() {
    return new Promise((resolve, reject) => {
      getNewPEServiceToken()
      .then( res => {
        let accountInfo = null
        let aList = null
        retrieveUserProfileInfo()
        .then( res => {
          // store the account info so we can update further below...
          accountInfo = res.data
          return updateProductsAccessList(JSON.parse(accountInfo.groups))
        })
        .then( data => {
          aList = data
          return getUserPlanData(true) 
        })
        .then( res => updateUserPlanData({...res.data, ...{subcriptionInfo: accountInfo.subs}}) )
        .then( res => {
          let tmpData = {...STATE.subscriptionInfo, ...accountInfo.subs, ...res.subscriptionInfo}
          if( tmpData.name === Enums.accountPlansInfo[0].name ) {
            // Set billing cycle dates for Freemium plans (otherwise data
            // is read from Stripe for paid subscriptions)
            let startDate = new Date(res.subscriptionInfo.plan_expiary_date)
            // Set cycle start date to one month ago
            startDate.setMonth(startDate.getMonth() - 1)
            tmpData.current_period_start = Date.parse(startDate)/1000
            tmpData.current_period_end = res.subscriptionInfo.plan_expiary_date/1000
          }
          resolve({
            firstName: accountInfo.firstName,
            lastName: accountInfo.lastName,
            uid: accountInfo.uid,
            groups: JSON.parse(accountInfo.groups),
            email: accountInfo.email,
            accessList: aList,
            // for paid users read subscription data from backend API response
            subscriptionInfo: (tmpData.name === Enums.accountPlansInfo[0].name ? tmpData : accountInfo.subs)
          })
        })
        .catch( (error) => {
          console.error(`Could not retrieve user info -> ${error}`)
          reject(error)
        })
      })
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  function initializeAccountInfo(skipUpdate) {
    return new Promise((resolve, reject) => {
      if( !LOADING.show ) {
        setLOADING( {...LOADING, ...{show: true}} )
      }
      return Promise.resolve()
        .then( res => getUserInfoAndSubscriptionData() )
        .then( data => {
          if( !skipUpdate ) {
            DISPATCH({dispatchType: 'initializeAccountInfo', payload: data })
          }
          resolve(data)
        })
        .catch((error) => {
          console.error(`Error encountered initializing dashboard.\n${error}`)
          reject(error)
        })
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  function initializeA3DService(skipUpdate) {
    return new Promise((resolve, reject) => {
      setLOADING({show: true, msg: textDefaultLoadingMsg})
      let userPlanData = null
      // create a running state object that we add to and propagate down
      // through the below Promise() chain
      let newStateDataObject = {}
      
      return Promise.resolve()
        .then( res => {
          if( !STATE.accountDataRetrieved ) {
            return initializeAccountInfo(true)
          }
          else return {
            accountTotals: STATE.accountTotals,
            subscriptionInfo: STATE.subscriptionInfo
          }
        })
        .then( data => {
          if( data ) {
            newStateDataObject = {...newStateDataObject, ...data}
          }
          return getJobsDataForAccount(true)
        })
        .then( res => parseAccountJobsData(res) )
        .then( data => {
          // store account jobs data
          newStateDataObject = {...newStateDataObject, ...data}
          newStateDataObject = {...newStateDataObject, ...{isFTEMode: data.jobsData.length ? false : true}}
          return getUserPlanData(true)
        })
        .then( res => {
          userPlanData = {
            ...res.data, 
            ...{accountTotals: newStateDataObject.accountTotals}, 
            ...{subscriptionInfo: newStateDataObject.subscriptionInfo}
          }
          return updateUserPlanData(userPlanData)
        })
        .then( data => {
          // add current running subscription data to update function so that we can pass the
          // data through to the end of this Promise chain
          userPlanData = {...userPlanData, ...data}
          return updateBillingCycleData(userPlanData)
        })
        .then( data => {
          // store user plan data
          newStateDataObject = {...newStateDataObject, ...data}
          return getAccountCustomCharacters(true)
        })
        .then( res => parseAccountModelsData(res) )
        .then( data => {
          newStateDataObject = {...newStateDataObject, ...data}
          if( !skipUpdate ) {
            DISPATCH({dispatchType: 'initializeService', payload: newStateDataObject })
          }
          resolve(newStateDataObject)
        })
        .catch((error) => {
          console.error(`Error encountered while initializing Animate 3D service.\n${error}`)
          reject(error)
        })
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  function initializeLibrary() {
    return new Promise((resolve, reject) => {
      setLOADING({show: true, msg: 'Getting jobs data...'})
      if( !STATE.jobsData || !STATE.anim3dInitialized || !STATE.accountDataRetrieved ) {
        let dataObj = {}
        initializeAccountInfo(true)
        .then( res => { 
          dataObj = {...dataObj, ...res}
          return initializeA3DService(true) 
        })
        .then( res => {
          dataObj = {...dataObj, ...res}
          return getJobsDataForAccount(true) 
        })
        .then( res => parseAccountJobsData(res) )
        .then( data => {
          dataObj = {
            ...dataObj,
            ...{animJobId: data.jobsData[0].rid},
            ...{displayPreview: STATE.openFirstJob ? true : false},
            ...data
          }
          resolve( 
            DISPATCH({dispatchType: 'initializeLibrary', payload: dataObj})
          )
        })
      }
      else {
        resolve(
          DISPATCH({dispatchType: 'initializeLibrary', payload: {
            displayPreview: false,
            numPages: Math.ceil(STATE.jobsData.length / STATE.rowsPerPage)
          }})
        )
      }
    })
  }

  /////////////////////////////////////////////////////////////////////////////
  function initializeCharacterManagePage() {
    return new Promise((resolve, reject) => {
      setLOADING({show: true, msg: 'Getting model data...'})
      let newStateData = {}
      if( !STATE.anim3dInitialized || !STATE.accountDataRetrieved /* || STATE.pageState_CharacterManage === Enums.pageState.init */ ) {
        initializeAccountInfo(true)
        .then( res => initializeA3DService(true) )
        .then( data => {
          newStateData = {...newStateData, ...data}
          return getJobsDataForAccount(true) 
        })
        .then( res => parseAccountJobsData(res) )
        .then( data => {
          newStateData = {...newStateData, ...{numPages: Math.ceil(data.jobsData.length / STATE.rowsPerPage)} }
          return getAccountCustomCharacters(true) 
        })
        .then( res => processCustomCharacterData(res.data.list) )
        .then( data => {
          newStateData.accountTotals = data.accountTotals
          if( !STATE.accountTotals.characterLimit || STATE.accountTotals.characterLimit === 'undefined' ) {
            return getUserPlanData(true)
          }
          else return {}
        })
        .then( res => {
          const newTotals = {...STATE.accountTotals, ...newStateData.accountTotals}
          // add additional data to new state data object
          newStateData = {
            ...newStateData,
            ...{accountTotals: newTotals},
            ...{pageState_CharacterManage: Enums.pageState.ready}
          }
          resolve(DISPATCH({dispatchType: 'initialize3dModelsPages', payload: newStateData}))
        })
        .catch( error => {
          DISPATCH({pageState_CharacterManage: Enums.pageState.ready})
          console.error(`Error encountered while intializing 3d models page data:\t${error}\n${JSON.stringify(error, null, 4)}`)
          reject(`Error encountered while intializing 3d models page data:\t${error}\n${JSON.stringify(error, null, 4)}`)
        })
      }
      // else if account info and service have been initialized but charactersList
      // is null retrieve new list from backend
      else if( STATE.accountTotals.charactersList === null ) {
        getAccountCustomCharacters(true)
        .then( res => processCustomCharacterData(res.data.list) )
        .then( data => {
          newStateData.accountTotals = data.accountTotals
          if( !STATE.accountTotals.characterLimit || STATE.accountTotals.characterLimit === 'undefined' ) {
            return getUserPlanData(true)
          }
          else return {}
        })
        .then( res => {
          const newTotals = {...STATE.accountTotals, ...newStateData.accountTotals}
          // add additional data to new state data object
          newStateData = {
            ...newStateData,
            ...{accountTotals: newTotals},
            ...{pageState_CharacterManage: Enums.pageState.ready}
          }
          resolve(DISPATCH({dispatchType: 'initialize3dModelsPages', payload: newStateData}))
        })
        .catch( error => {
          console.error(`Error encountered while retrieving custom characters list:\t${error}\n${JSON.stringify(error, null, 4)}`)
          reject(DISPATCH({pageState_CharacterManage: Enums.pageState.ready}))
        })
      }
      else {
        resolve(DISPATCH({pageState_CharacterManage: Enums.pageState.ready}))
      }
    })
  }
  ////////////////////////////////////////////////////////////////////
  function getLatestPlanMinutes() {
    return new Promise((resolve, reject) => {
      let userPlanData = null
      getUserPlanData(true)
      .then( res => {
        userPlanData = {...res.data}
        return updateUserPlanData(userPlanData)
      })
      .then( data => {
        // accountTotals and subscriptionInfo
        userPlanData = {...userPlanData, ...data}
        return updateBillingCycleData(userPlanData)
      })
      .then( data => {
        let accountInfo = {
          accountTotals: {...STATE.accountTotals, ...data.accountTotals},
          subscriptionInfo: {...STATE.subscriptionInfo, ...data.subscriptionInfo},
          currentBillCycleInfo: {...STATE.currentBillCycleInfo, ...data.currentBillCycleInfo}
        }
        return resolve(accountInfo)
      })
    })
  }

  ////////////////////////////////////////////////////////////////////
  // parses the data returned from the list models request
  ////////////////////////////////////////////////////////////////////
  function processCustomCharacterData(data) {
    return new Promise((resolve, reject) => {
      let outputList = []
      data.forEach( item => {
        const d = new Date(item.ctime)
        // convert date to human readable format
        const addDate = [
          d.getMonth()+1,
          d.getDate(),
          d.getFullYear()].join('/')+' '+
        [ Enums.addZero(d.getHours()),
          Enums.addZero(d.getMinutes()),
          Enums.addZero(d.getSeconds())].join(':')

        const modelObject = {
          id: item.id,
          name: item.name,
          thumb: item.thumb,
          ctime: item.ctime,
          date: addDate,
          thumbImg: item.thumbImg
        }
        outputList = Enums.addCharacterToSortedArray(modelObject, outputList)
      })
      let updateObj = {charactersList: outputList}
      const returnObj = {...STATE.accountTotals, ...{charactersList: outputList}}
      resolve(returnObj)
    })
  }

  ////////////////////////////////////////////////////////////////////
  // Gets/Updates latest minutes balance for the current account
  // 
  // @param data : response data from user data API
  ////////////////////////////////////////////////////////////////////
  function updateUserPlanData(data) {
    return new Promise((resolve, reject) => {
      let tmpVal = data.accountTotals ? data.accountTotals : STATE.accountTotals
      tmpVal.maxTimeInSeconds = 0

      // get latest remaining/used minutes data:
      for( let i = 0; i < data.userPackMinute.length; i++ ) {
        if( data.userPackMinute[i].active ) {
          tmpVal.maxTimeInSeconds = data.userPackMinute[i].staticData.minutes * 60
        }
      }
      // Aggregate max monthly mins + permanent mins to calculate total seconds
      if( data.user.minute_balance_permanent ) {
        tmpVal.maxTimeInSeconds += data.user.minute_balance_permanent * 60
      }

      if( !tmpVal.maxTimeInSeconds ) {
        // if no active plans found default to Freemium mins
        tmpVal.maxTimeInSeconds = Enums.accountPlansInfo[0].minsInt * 60
      }

      // Aggregate recurring mins + permanent mins balances
      let remainingTimeInSeconds = data.user.minute_balance * 60
      remainingTimeInSeconds += data.user.minute_balance_permanent * 60
      tmpVal.remainingTimeInSeconds = remainingTimeInSeconds

      // get users remaining rerun count for current billing cycle
      tmpVal.rerun_count = data.user.rerun_count
      tmpVal.max_rerun = data.userPackFeature[0].staticData.max_rerun

      // check for higher max rerun account added to account through feature pack(s):
      for( let i = 0; i < data.userMaxUpgradePackFeature.length; i++ ) {
        if( data.userMaxUpgradePackFeature[i].staticData.max_rerun > tmpVal.max_rerun ) {
          tmpVal.max_rerun = data.userMaxUpgradePackFeature[i].staticData.max_rerun
        }
      }

      // partial seconds are omitted when checking for expired balance
      if( Math.floor(remainingTimeInSeconds) === 0 ) {
        tmpVal.currCycleMinsExpired = true
      }
      else {
        tmpVal.currCycleMinsExpired = false
      }

      // check for max character limit:
      tmpVal.characterLimit = Enums.accountPlansInfo[0].modelsInt
      for( let i = 0; i < data.userPackFeature.length; i++ ) {
        if( data.userPackFeature[i].active ) {
          tmpVal.characterLimit = data.userPackFeature[i].staticData.max_custom_character
        }
      }

      let tmpObj = {...data.subscriptionInfo}
      // store cycle end date (for both monthly & annual plans)
      tmpObj.plan_expiary_date = data.user.plan_expiary_date
      // check for enterprise user flag
      if( data.user.isEnterpriseUser ) {
        tmpObj.isEnterpriseUser = true
      }
      else {
        tmpObj.isEnterpriseUser = false 
      }
      // finally we update the accountTotals state
      // and resolve the promise
      const returnObj = {accountTotals: tmpVal, subscriptionInfo: tmpObj}
      // DISPATCH(returnObj)
      resolve(returnObj)
    })
  }

  ////////////////////////////////////////////////////////////////////
  // Performs background upload API for Guided FTE and normal
  // job creation
  ////////////////////////////////////////////////////////////////////
  function uploadInputVideo() {
    return new Promise((resolve, reject) => {
      // start background upload process for input video/image
      performBackgroundVideoUpload(true)
      .then( res => {
        // now that we have video metadata check against subscription limits and
        // feature gates such as max duration, fps, resolution
        const maxDuration = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration)
        const maxFps = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)
        const maxResolution = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)
        let errorFlags = {}

        if( STATE.inputVideoData.fileLength > STATE.currentBillCycleInfo.remainingRounded ) {
          errorFlags.minsBalanceTooLow = true
        }
        if( STATE.inputVideoData.fileLength > maxDuration ) {
          errorFlags.maxDuration = true
        }
        if( STATE.inputVideoData.fps > maxFps ) {
          errorFlags.maxFps = true
        }
        if( STATE.inputVideoData.videoRes.w > maxResolution.w || STATE.inputVideoData.videoRes.h > maxResolution.h ) {
          errorFlags.maxResolution = true
        }
        let tmpObj = STATE.inputVideoData
        tmpObj.errorFlags = errorFlags
        // check if any video validation errors present
        if( Object.keys(errorFlags).length !== 0 ) {
          const inputData = STATE.inputVideoData
          inputData.errorFlags = errorFlags
          DISPATCH({
            confirmDialogId: Enums.confirmDialog.VideoValidationFailed,
            currWorkflowStep: Enums.uiStates.initial,
            isJobInProgress: false,
            inputVideoData: inputData,
            silentUploadInProgress: false
          })
        }
        else {
          DISPATCH({
            inputVideoData: tmpObj,
            silentUploadInProgress: false
          })
        }
      })
      .catch( error => {
        console.error(`Problem encountered during background upload process-\n${error}`)
        if( !STATE.inputVideoData.videoRes ) {
          // MD-8355 - check to videoRes in case completely invalid file such as a PDF
          // renamed as MP4 since we attempt to access keys of that object when valid
          let dialogData = STATE.dialogInfo
          dialogData.videoFileName = STATE.inputVideoData.fileName
          DISPATCH({ ...{dialogInfo: dialogData}, confirmDialogId: Enums.confirmDialog.inputInvalidOrCorrupt})
          console.error(`Could not read video data from user selected file.\n${error}`)
          return
        }
        else {
          DISPATCH({
            confirmDialogId: Enums.eCodes.InternalServerError
          })
          return
        }
      })
    })
  }

  ////////////////////////////////////////////////////////////////////
  // stops an in-progress job
  ////////////////////////////////////////////////////////////////////
  function stopCurrentHandler(retry) {
    stopInProgressJob(STATE.animJobId)
    .then(res => {
      if(res.status !== 200) { 
        console.error(`Error attempting to cancel job ${STATE.animJobId} --> [HTTP ${res.status}] ${res.error ? res.error : JSON.stringify(res.data, null, 4) }`)
        return
      }
      let inputData = {
        selectedFile: null,       
        fileName: null,
        fileLength: null,
        fileSize: null,
        videoRes: null,
        fps: null,
        codec: null
      }
      // cancel the current job and reset the UI workflow 
      DISPATCH({
        inputVideoData: inputData,
        currWorkflowStep: Enums.uiStates.initial,
        animJobId: 0,
        animationJobProgress: 0,
        isJobInProgress: false,
        confirmDialogId: Enums.confirmDialog.none
      })
    })
    .catch( (error) => {
      console.error('Problem encountered while stopping the current job.');
      handleHttpError(error, "Problem Stopping Job")
    })
  }

  ////////////////////////////////////////////////////////////////////
  // Checks for access to specific features based on account plan
  ////////////////////////////////////////////////////////////////////
  function checkFeatureAvailability(planName, featureName) {
    let planIndex = 0 // default plan to Freemium
    let activePackNames = []
    // First we loop through the custom packs array and record the names
    // of any active packs we find
    if( STATE.subscriptionInfo.featurePacks ) {
      for (let pack of STATE.subscriptionInfo.featurePacks) {
        if( pack.active ) {
          // parse pack name, check if index higher
          activePackNames.push( pack.pack_id)
        }
      }
    }
    // get index of plan based on subscription name, scan through in
    // reverse order to make sure we apply highest pack features
    for( let i = Enums.accountPlansInfo.length-1; i >= 0; i-- ) {
      let found = false
      for ( let activePack of activePackNames ) {
        if( activePack.toLowerCase().includes(Enums.accountPlansInfo[i].name.toLowerCase()) ) {
          planIndex = i
          found = true
          break
        }
      }
      if( found ) {
        break
      }
      else {
        if( Enums.accountPlansInfo[i].name.toLowerCase() === planName.toLowerCase() ) {
          planIndex = i
          break
        }
      }
    }
    // find corresponding feature data for feature in question
    for (const [key, value] of Object.entries(Enums.featureLocksData)) {
      if( key === featureName ) {
        return value[planIndex]
      }
    }
    console.log(`Warning: Could not find feature availability data for feature: ${featureName}`)
    return null
  }

  ////////////////////////////////////////////////////////////////////
  // builds Knob UI element for PE Smoothness selector
  ////////////////////////////////////////////////////////////////////
  function buildPoseFilteringSelect(stateObj) {
    if( !STATE.subscriptionInfo ) {
      return
    }
    let featureAvailable = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness)
    return (
      <div style={{display:'inline-flex'}}>
        {
          featureAvailable
          ?
          <div className="cursor-grab">
            <Knob className="mr-4"
              size={46}
              numTicks={9}
              degrees={180}
              min={1}
              max={100}
              value={stateObj.poseFilteringStrength * 100}
              color={true}
              lMargin={10}
              rMargin={15}
              onChange={changeSmoothnessSelect}
            />
          </div>
          :
          <div className="no-cursor">
            <Knob className="mr-4 no-cursor" onMouseDown={null} onClick={null}
              disabled={true}
              size={46}
              numTicks={9}
              degrees={180}
              min={1}
              max={100}
              value={0}
              color={true}
              lMargin={10}
              rMargin={15}
            />
          </div>
        }
        <span className={"subtitle is-5 ml-5 " + (!featureAvailable ? " no-cursor dm-gray" : " has-text-white") } style={{marginTop:'auto',marginBottom:'auto'}}>
          {
            !featureAvailable
            &&
            <span className="icon is-small ml-2 mr-4">
              <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
            </span>
          }
          {Enums.dropDownLabels.motionSmoothing}
          <span className="mr-2">
            { stateObj.poseFilteringStrength === 0.0
              ?
              " 0.0"
              :
              <span className="has-text-success">
              {
                (parseInt(stateObj.poseFilteringStrength) === 1 ? " 1.0" : (" " + stateObj.poseFilteringStrength) ) 
              }
              </span>
            }
          </span>
          <DMToolTip
            text={Enums.toolTipSmoothness}
            tipId="smoothness-tip"
            isTipHtml={true}
            noMargins={true}
          />
        </span>
      </div>  
    )
  }

  ////////////////////////////////////////////////////////////////////
  // builds Knob UI element for Video Speed Multiplier feature
  ////////////////////////////////////////////////////////////////////
  function buildSpeedMultiplierSelect(stateObj) {
    if( !STATE.subscriptionInfo ) {
      return
    }
    let featureAvailable = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion)
    return (
      <div style={{display:'inline-flex'}}>
        {
          featureAvailable
          ?
          <div className="cursor-grab">
            <Knob className="mr-4"
              size={46}
              numTicks={9}
              degrees={180}
              min={1}
              max={100}
              value={ ((stateObj.videoSpeedMultiplier - 1 ) / 7.0 * 100) }
              color={true}
              lMargin={10}
              rMargin={15}
              onChange={changeSpeedMultSelect}
            />
          </div>
          :
          <div className="no-cursor">
            <Knob className="mr-4 no-cursor" onMouseDown={null} onClick={null}
              disabled={true}
              size={46}
              numTicks={9}
              degrees={180}
              min={1}
              max={100}
              value={0}
              color={true}
              lMargin={10}
              rMargin={15}
            />
          </div>
        }
        <span className={"subtitle is-5 ml-5 " + (!featureAvailable ? " no-cursor dm-gray" : " has-text-white") } style={{marginTop:'auto',marginBottom:'auto'}}>
          {
            !featureAvailable
            &&
            <span className="icon is-small ml-2 mr-4">
              <i className="fas fa-lock fa-lg has-text-black" aria-hidden="true"></i>
            </span>
          }
          {Enums.dropDownLabels.videoSpeedMultiplier} 
          <span className="mr-2">
            { stateObj.videoSpeedMultiplier <= 1.0
              ?
              " 1.0"
              :
              <span className="has-text-success">
                {/* highlight values > 1.0 */}
                {" " + stateObj.videoSpeedMultiplier}
              </span>
            }
          </span>
          <DMToolTip
            text={Enums.toolTipVideSpeedMult}
            tipId="videospeed-tip"
            isTipHtml={true}
            noMargins={true}
          />
        </span>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////////////////
  function changeSmoothnessSelect(value) {
    let newStateData = STATE.animJobSettings
    // convert from [0-100] int range to float [0.0-1.0]
    newStateData.poseFilteringStrength = Math.abs( (parseFloat(value) / 100).toFixed(1) )
    DISPATCH({animJobSettings: newStateData})
  }
  ////////////////////////////////////////////////////////////////////////
  function changeSmoothnessReRunSelect(value) {
    let newStateData = STATE.rerunSettings
    // convert from [0-100] int range to float [0.0-1.0]
    newStateData.poseFilteringStrength = Math.abs( (parseFloat(value) / 100).toFixed(1) )
    DISPATCH({rerunSettings: newStateData})
  }
  ////////////////////////////////////////////////////////////////////////
  function changeSpeedMultSelect(value) {
    let newStateData = STATE.animJobSettings
    // convert from [0-100] int range to float [1.0-8.0]
    newStateData.videoSpeedMultiplier = (Math.abs( (value / 100.0 ) * 7.0) + 1).toFixed(1)
    DISPATCH({animJobSettings: newStateData})
  }
  ////////////////////////////////////////////////////////////////////////
  function changeSpeedMultReRunSelect(value, cb) {
    let newStateData = STATE.rerunSettings
    // convert from [0-100] int range to float [1.0-8.0]
    newStateData.videoSpeedMultiplier = (Math.abs( (value / 100.0 ) * 7.0) + 1).toFixed(1)
    DISPATCH({rerunSettings: newStateData})
  }

  /////////////////////////////////////////////////////////////////////
  // checkStatus() : Checks status of the currently processing animation 
  // job. This is called on a timing loop when a job is currently processing 
  // to get progress updates, is also called upon component mount.
  //
  // @param isJobQueuedOrFailed : If true there were 1+ results returned from 
  //  /emojis/PROGRESS URI, peforms extra check for potentially queued/failed jobs
  // @param retry : if we should retry on auth error encountered 
  /////////////////////////////////////////////////////////////////////
  function checkStatus(isJobQueuedOrFailed, retry) {
    const timer = setInterval( () => {
      if(STATE.animJobId === null || STATE.animJobId === "" || STATE.animJobId === 0) {
        console.log(`\n${JSON.stringify(STATE, null, 4)}\n`)
        clearInterval(timer)
        setLOADING({show: false, msg: textDefaultLoadingMsg})
        return
      }
      checkJobStatus(STATE.animJobId)
      .then(res => {
          // check against count, loop through count checking statuses
          // status array element contains job's rid, status, step, and total fields
          if( res.data.count > 0 ) {
            setLOADING({show: false, msg: textDefaultLoadingMsg})
            const statusInfo = res.data.status[0]
            if(!statusInfo.status) {
              console.error(`Warning: Found ${res.data.count} jobs however statusInfo() is null. Stopping current status checks. This may indicate a network connectivity issue or a server-side problem.`) 
              clearInterval(timer)
              return      
            }
            if(statusInfo.status === "FAILURE") {
              clearInterval(timer)()
              ///--- Check for known backend errors and display custom message
              if( checkForKnownErrors(statusInfo) ) {
                return
              }
              // else push a generic job failure error since an unknown / un-registered error
              DISPATCH({currWorkflowStep: Enums.uiStates.initial,isJobInProgress: false, animationJobProgress: 0})
              setErrorDialogInfo(Enums.eCodes.OtherError, "Job Failed", true)
              handleHttpError("There was an error trying to create animations from the video provided, if the problem continues please contact DeepMotion Support.", "Job Failed", true)
              console.log('Animation job has failed!')
            }
            else if(statusInfo.status === "RETRY") {
              clearInterval(timer)
              ///--- Check for known backend errors and display custom message
              if( checkForKnownErrors(statusInfo) ) {
                return
              }
              // else push a generic job failure error since an unknown / un-registered error
              DISPATCH({currWorkflowStep: Enums.uiStates.initial,isJobInProgress: false, animationJobProgress: 0})
              setErrorDialogInfo(Enums.eCodes.OtherError, "Job Failed", true)
              handleHttpError("There was an error trying to create animations from the video provided, if the problem continues please contact DeepMotion Support.", "Job Failed", true)
              console.log('Animation job has failed!')
            }
            else if(statusInfo.status === "SUCCESS") {
              console.log('Animation job has successfully completed.')
              clearInterval(timer)
              let animLinks = null
              let newJobsData = null
              let newAccountData = null
              getLatestPlanMinutes() 
              .then( data => {
                newAccountData = data
                return getJobsDataForAccount(true) 
              })
              .then( res => parseAccountJobsData(res) )
              .then( res => {
                newJobsData = res.jobsData
                return getLinksForJob(STATE.animJobId, true)
              })
              .then( res => parseJobLinksData(res) )
              .then( data => { 
                animLinks = data.animJobLinks
                return parseModelDownloadLinks(data.animJobLinks) 
              })
              .then( dLinks => {
                // once below async call complete a custom useEffect() hook 
                // will re-route user to the Previewer
                DISPATCH({
                  // set info needed to open previewer for this job
                  animJobLinks: animLinks,
                  currDownloadLinks: dLinks,
                  currWorkflowStep: Enums.uiStates.initial,
                  jobsData: newJobsData,
                  isJobInProgress: false,
                  displayPreview: true,
                  videoStorageUrl: null,
                  videoFileName: null,
                  animationJobProgress: 0,
                  progressMsg: "Initializing process...",
                  animJobSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
                  rerunSettings: {...Enums.JOB_SETTINGS_TEMPLATE},
                  ...newAccountData
                })
                return <div/>
              })
            }
            else if (statusInfo.status === "PROGRESS") {
              const step = statusInfo["details"]["step"]
              const total = statusInfo["details"]["total"]
              if( step === 0 ) {
                DISPATCH({
                  progressMsg: "Starting job...",
                  currWorkflowStep: Enums.uiStates.jobInProgress,
                  isJobInProgress: true
                })
              }
              else {
                DISPATCH({
                  progressMsg: STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? "Creating 3D pose..." : "Creating animations...",
                  currWorkflowStep: Enums.uiStates.jobInProgress,
                  isJobInProgress: true
                })
              }
              calculateProgress(total, step)
            }
          }
          // Else data list count is < 1...
          else {
            // if isJobQueuedOrFailed == true it means at least one progress result
            // was returned from /emojis/status endpoint and indicates current
            // job is queued (pending) status...
            if( isJobQueuedOrFailed ) {
              console.log(`Queued job found, updating UI...`)
              // if status = PROGRESS but count is < 1 then job is either queued 
              // ie pending status or celery backend worker might have failed 
              if( STATE.currWorkflowStep !== Enums.uiStates.jobQueued ) {
                DISPATCH({currWorkflowStep: Enums.uiStates.jobQueued})
              }
            }
          }
      })
      .catch( (error) => {
        clearInterval(timer)
        console.error(`Problem checking job status ${STATE.animJobId}, error is ${error}`)
        setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Checking Job Status")
        return <div/>
      })
    }, 5000)
  }

  /////////////////////////////////////////////////////////////////////
  function checkForKnownErrors(statusInfo) {
    if( statusInfo && statusInfo.details && statusInfo.details.exc_message ) {
      let errObj = {}
      let errorFound = false
      switch( statusInfo.details.exc_message[0] ) {
        
        //-----------------------------------------
        // Base 100: Enforcement
        //-----------------------------------------
        // check for not enough anim minutes
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.MinutesBalanceTooLow.toString()):
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.MinutesBalanceTooLow}})
          errorFound = true
          break
        // check for max video resolution exceeded
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.MaxResolutionExceeded.toString()):
          errObj[Enums.featureLockNames.maxResolution] = true
          displayVideoValidationErrors(errObj)
          errorFound = true
          break
        // check for max video FPS exceeded
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.MaxFPSExceeded.toString()):
          errObj[Enums.featureLockNames.maxFps] = true
          displayVideoValidationErrors(errObj)
          errorFound = true
          break
        
        //-----------------------------------------
        // Base 200: Asset Pre-processing
        //-----------------------------------------

        // check for video copy or model copy to gcp error
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.VideoOrModelCopyError.toString()):
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.VideoOrModelCopyError}})
          errorFound = true
          break
        // check for invalid/un-supported video codec
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.InvalidVideoCodecError.toString()):
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InvalidVideoCodecError}})
          errorFound = true
          break

        //-----------------------------------------
        // Base 300: CLI Pipeline
        //----------------------------------------- 
        case statusInfo.details.exc_message.includes(Enums.confirmDialog.InternalCliPipelineError.toString()):
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InternalCliPipelineError}})
          errorFound = true
          break

        //-----------------------------------------
        // Base 500: DMBT
        //----------------------------------------- 
        case Enums.confirmDialog.InternalCliPipelineError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InternalCliPipelineError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToParseArgsError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToParseArgsError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToLoadDataError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToLoadDataError}})
          errorFound = true
          break
        case Enums.confirmDialog.ExplosionDetectedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.ExplosionDetectedError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToCreatePoseEstimatorError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToCreatePoseEstimatorError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToCreateBodyTrackerError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToCreateBodyTrackerError}})
          errorFound = true
          break
        case Enums.confirmDialog.PoseEstimationTrackingError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.PoseEstimationTrackingError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToLoadConfigError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToLoadConfigError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToOpenFileForWritingError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToOpenFileForWritingError}})
          errorFound = true
          break
        case Enums.confirmDialog.InterruptedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InterruptedError}})
          errorFound = true
          break

        //-----------------------------------------
        // Base 700: DMFT
        //----------------------------------------- 
        case Enums.confirmDialog.InternalFaceTrackingError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InternalFaceTrackingError}})
          errorFound = true
          break

        //-----------------------------------------
        // Base 900: Vector CLI
        //----------------------------------------- 
        case Enums.confirmDialog.LoadMeshFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.LoadMeshFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.LoadBvhFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.LoadBvhFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.CopyAnimFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.CopyAnimFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.ExportFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.ExportFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.MeshNotProvidedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.MeshNotProvidedError}})
          errorFound = true
          break
        case Enums.confirmDialog.BlendShapesLessThanHalfError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.BlendShapesLessThanHalfError}})
          errorFound = true
          break
        case Enums.confirmDialog.LoadFaceDefinitionFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.LoadFaceDefinitionFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.LoadDMFTDataFailedError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.LoadDMFTDataFailedError}})
          errorFound = true
          break
        case Enums.confirmDialog.LoadHumanoidMapError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.LoadHumanoidMapError}})
          errorFound = true
          break

        //-----------------------------------------
        // Base 1100: Render CLI
        //----------------------------------------- 
        case Enums.confirmDialog.RenderCliError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.RenderCliError}})
          errorFound = true
          break
        case Enums.confirmDialog.InvalidInputParameterError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.InvalidInputParameterError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToLoadOrPlayInputVideoError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToLoadOrPlayInputVideoError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToLoadInputBvhError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToLoadInputBvhError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToLoadInputCharacterError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToLoadInputCharacterError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToAttachAnimToCharacterError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToAttachAnimToCharacterError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToConfigureBackDropError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToConfigureBackDropError}})
          errorFound = true
          break
        case Enums.confirmDialog.FailedToCreateGifError.toString():
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.FailedToCreateGifError}})
          errorFound = true
          break

        // --------
        // Generic fallback check using other common error strings (see Enums.customErrors)
        // --------
        default:
        case (statusInfo.details.exc_message.includes(Enums.customErrors.VideoToAnimProcessingError) ||
          statusInfo.details.exc_message.includes(Enums.customErrors.GCPVideoCopyError) ):
          DISPATCH({...resetJobObject, ...{confirmDialogId: Enums.confirmDialog.Video2AnimFailure}})
          errorFound = true
          break
      }
      return errorFound
    }
    else return false
  }

  /////////////////////////////////////////////////////////////////////
  function buildCustomJobFailedDialog(msg) {
    const htmlMsgContent = [<div key="error-dialog" className="notification is-danger is-light"><div className="columns"><div className="column is-1 p-1 m-1"><span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span></div> <div className="column p-1 m-1"><div className="subtitle is-5"> <div dangerouslySetInnerHTML={Enums.createMarkup(msg)} /> </div></div></div></div>]

    // local helper function for navigating upon dialog close
    function closeDialogAndNavigateToRoute(route) {
      closeModal(true)
      history.push(route)
    }
    function closeDialogAndOpenEmailClient() {
      closeModal(true)
      //TODO: Update action1 button to route to new Support page once ready
      window.open(`mailto:support@deepmotion.com?subject=Animate%203D%20Support]`)
    }

    return (
      <DMDialog
        title={STATE.animJobSettings.jobType ? "Problem Creating 3D Pose" : "Problem Creating Animation"}
        content={htmlMsgContent}
        msgFormat="html"
        label1={textDialogSupport}
        action1={ ()=>closeDialogAndOpenEmailClient() }
        label2={textDialogOk}
        action2={()=>closeModal(true)}
      />
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Updates current download model and links state vars
  // 
  // @param newModel : if null or false we default to model1
  /////////////////////////////////////////////////////////////////////
  function setCurrDownloadModel(newModel) {
      const selectedDownloadModel = !newModel ? Enums.characterModels['1'].fileName : newModel
      
      // DISPATCH({currDownloadModel: selectedDownloadModel})
      setCurrentModelLinks(selectedDownloadModel)
  }

  function parseModelDownloadLinks(jobLinksData) {
    let dLinks = {}
    // async call to get links is made upon component mount, so here we 
    // make sure the links are not null before assigning download links
    if( !jobLinksData ) {
      console.error(`Warning: Invalid job links data passed to parsing function.`)
    }
    let found = false
    for (const model of jobLinksData.downloadLinks.models) {
      // dynamically update the download links based on the currently selected download model
      // for standard model jobs match the expected model name against current state var. For 
      // custom model jobs the model.name will be the modelId which is a GUID
      if (model.name.includes('bvh-framesMap') 
        || model.name.includes('framesIssueStatus') 
        || model.name.includes('stderr') 
        || model.name.includes('stdout') 
        || model.name.includes('inter')
        || model.name.includes('pose_estimation_result')) {
        continue
      }
      if( (!jobLinksData.downloadLinks.customMode && model.name === STATE.currDownloadModel) 
        || (!jobLinksData.downloadLinks.customMode && jobLinksData.downloadLinks.faceTrackingMode)
        || jobLinksData.downloadLinks.customMode 
        || model.name === "landmarks" ) { // dmpe files part of landmarks node
        for (const link of model.links) {
          if (link.type === Enums.animFileType.fbx) {
            dLinks.fbxLink = link.link
          }
          if (link.type === Enums.animFileType.bvh) {
            dLinks.bvhLink = link.link
          }
          if (link.type === Enums.animFileType.gif) {
            dLinks.gifLink = link.link
          }
          if (link.type === Enums.animFileType.jpg) {
            dLinks.jpgLink = link.link
          }
          if (link.type === Enums.animFileType.png) {
            dLinks.pngLink = link.link
          }
          if (link.type === Enums.animFileType.glb) {
            dLinks.glbLink = link.link
          }
          if (link.type === Enums.animFileType.mp4) {
            dLinks.mp4Link = link.link
          }
          if (link.type === Enums.animFileType.dmpe) {
            dLinks.dmpeLink = link.link
          }
        }
      }
    }
    return dLinks
  }

  /////////////////////////////////////////////////////////////////////
  // Retrieves/sets download links for currently selected download model
  /////////////////////////////////////////////////////////////////////
  function setCurrentModelLinks(newModel) {
    let dLinks = {}
    const currSelectedModel = newModel ? newModel : STATE.currDownloadModel
    // async call to get links is made upon component mount, so here we 
    // make sure the links are not null before assigning download links
    if( STATE.animJobLinks ) {
      let found = false
      for (const model of STATE.animJobLinks.downloadLinks.models) {
        // dynamically update the download links based on the currently selected download model
        // for standard model jobs match the expected model name against current state var. For 
        // custom model jobs the model.name will be the modelId which is a GUID
        if (model.name.includes('bvh-framesMap') 
          || model.name.includes('framesIssueStatus') 
          || model.name.includes('stderr') 
          || model.name.includes('stdout') 
          || model.name.includes('inter')
          || model.name.includes('pose_estimation_result')) {
          continue
        }
        if( (!STATE.animJobLinks.downloadLinks.customMode && model.name === currSelectedModel) 
          || (!STATE.animJobLinks.downloadLinks.customMode && STATE.animJobLinks.downloadLinks.faceTrackingMode)
          || STATE.animJobLinks.downloadLinks.customMode 
          || model.name === "landmarks" ) { // dmpe files part of landmarks node
          for (const link of model.links) {
            if (link.type === Enums.animFileType.fbx) {
              dLinks.fbxLink = link.link
            }
            if (link.type === Enums.animFileType.bvh) {
              dLinks.bvhLink = link.link
            }
            if (link.type === Enums.animFileType.gif) {
              dLinks.gifLink = link.link
            }
            if (link.type === Enums.animFileType.jpg) {
              dLinks.jpgLink = link.link
            }
            if (link.type === Enums.animFileType.png) {
              dLinks.pngLink = link.link
            }
            if (link.type === Enums.animFileType.glb) {
              dLinks.glbLink = link.link
            }
            if (link.type === Enums.animFileType.mp4) {
              dLinks.mp4Link = link.link
            }
            if (link.type === Enums.animFileType.dmpe) {
              dLinks.dmpeLink = link.link
            }
          }
        }
      }
      DISPATCH({currDownloadLinks: dLinks})
    }
  }

  //////////////////////////////////////////////////////////////////////
  function buildModelThumbnailsSection() {
    const defaultModelSet = {id: null, name: 'Default Character Set', ctime: null}
    const robloxModel = {id: Enums.robloxModelId, name: 'Roblox R15', ctime: null}
    const defaultModelObj = {...STATE.animJobSettings.customModelInfo, ...defaultModelSet}
    const robloxModelObj = {...STATE.animJobSettings.customModelInfo, ...robloxModel}
    let modelThumbnailsList = []

    // add default characters option first:
    modelThumbnailsList.push(
      <div className="column has-text-centered m-2" key="Default-Characters">
        <figure className="image is-16by9 br-4 selection-card" onClick={ ()=>DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': defaultModelObj} }}) } id="default-characters">
          <div className={STATE.animJobSettings.customModelInfo.id === null ? " br-4 animated-border" : " br-4 dm-brand-border-md"}>
            <img src={imgDefaultCharacters} className={ STATE.animJobSettings.customModelInfo.id === null ? " p-1 br-4 animated-border bShadow has-background-light" : " br-4 dm-brand-border-md bShadow has-background-light"} alt="default-characters" />
          </div>
        </figure>
      </div>
    )

    // next we add the Roblox R15 model to the list:
    modelThumbnailsList.push(
      <div className="column has-text-centered m-2" key="Roblox-R15-Model">
        <figure className="image is-16by9 br-4 selection-card" onClick={ ()=>DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': robloxModelObj} }}) } id="roblox-r15-model">
          <div className={STATE.animJobSettings.customModelInfo.id === Enums.robloxModelId ? " br-4 animated-border" : " br-4 dm-brand-border-md"}>
            <img src={imgRobloxR15} className={ STATE.animJobSettings.customModelInfo.id === Enums.robloxModelId ? " p-1 br-4 animated-border bShadow has-background-light" : " br-4 dm-brand-border-md bShadow has-background-light"} alt="roblox-r15-model" />
          </div>
        </figure>
      </div>
    )

    let numModels = STATE.accountTotals.charactersList.length
    // last elem in accountPlansInfo object is special case sku for Enterprise branding
    // so index of Studio plan is -2
    const maxPossibleModels = Enums.accountPlansInfo[Enums.accountPlansInfo.length-2].modelsInt
    if( numModels > maxPossibleModels ) {
      numModels = maxPossibleModels
    }
    // first we build image columns for each model thumbnail
    for( let i = 0; i < numModels; i++) {
      let image = (STATE.accountTotals.charactersList[i].thumbImg instanceof Blob) ? URL.createObjectURL(STATE.accountTotals.charactersList[i].thumbImg) : imgCustom
      modelThumbnailsList.push(
        <div className="column has-text-centered m-2" key={i.toString()+STATE.accountTotals.charactersList[i].name}>
            <figure className="image is-16by9 br-4 selection-card" onClick={ ()=>DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': STATE.accountTotals.charactersList[i]} }}) } id={STATE.animJobSettings.customModelInfo.id === STATE.accountTotals.charactersList[i].id ? "anim-fadein" : ""}>
              <div className={STATE.animJobSettings.customModelInfo.id === STATE.accountTotals.charactersList[i].id ? " br-4 animated-border" : " br-4 dm-brand-border-md"}>
                <img src={image} className={ STATE.animJobSettings.customModelInfo.id === STATE.accountTotals.charactersList[i].id ? " p-1 br-4 animated-border bShadow has-background-light" : " br-4 dm-brand-border-md bShadow has-background-light"} alt={`${STATE.accountTotals.charactersList[i].name}`} />
              </div>
            </figure>
        </div>
      )
    }
    let thumbnailsSection = []

    // account for the Default + Roblox models we added manually above!
    numModels = numModels + 2 
    const numModelRows = Math.ceil(numModels / NUM_MODELS_PER_ROW)
    const numLastRowThumbs = numModels % NUM_MODELS_PER_ROW 
    for( let i = 0; i < numModelRows; i++  ) {
      if( i === (numModelRows - 1) && numLastRowThumbs ) {
        let lastRow = []
        // add models for last row:
        for( let j = 0; j < numLastRowThumbs; j++ ) {
          lastRow.push(
            <React.Fragment key={`model-col-${i+j}`}>
              {modelThumbnailsList[i*3+j]}
            </React.Fragment>
          )
        }
        // pad last row with colums to ensure model tiles
        // stay same size
        for( let j = 0; j < NUM_MODELS_PER_ROW - numLastRowThumbs; j++ ) {
          lastRow.push(
            <div className="column" key={`col-pad-${j}`}>
            </div>
          )
        }
        thumbnailsSection.push(
          <div className="columns m-2 p-4" key={`model-row-${i}`}>
            {lastRow}
          </div>
        )
      }
      else {
        thumbnailsSection.push(
          <div className="columns m-2 p-4" key={`model-row-${i}`}>
            {modelThumbnailsList[i*3]}
            {modelThumbnailsList[i*3+1]}
            {modelThumbnailsList[i*3+2]}
          </div>
        )
      }
    }

    return (
      // make character thumbnails section vertically scrollable...
      <div className="scroll-y">
        {thumbnailsSection}
      </div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Handler for Downloading job directly from the library page
  // 
  // @param rid : request ID of the job to download assets for
  /////////////////////////////////////////////////////////////////////
  function handleDownloadJobClick(rid) {
    let animLinks = null
    getLinksForJob(rid, true)
    .then( res => parseJobLinksData(res) )
    .then( res => { 
      animLinks = res.animJobLinks
      return parseModelDownloadLinks(res.animJobLinks) 
    })
    .then( dLinks => {
      const dialogType = doesJobUseCustomModel(rid) ? 'DIALOG_AnimDownloadCustomModel' : 'DIALOG_AnimDownloadDefaultModel'
      DISPATCH({ dispatchType: dialogType, payload: {
        animJobId: rid,
        animJobLinks: animLinks,
        currDownloadLinks: dLinks
      }})
      return
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Returns true if a custom model was used for the animation job
  /////////////////////////////////////////////////////////////////////
  function doesJobUseCustomModel(jobId) {
    if( !STATE.jobsData ) {
      return false
    }
    // default model jobs have customModel property = "standard", otherwise
    // for custom model jobs they will have the modelId as the value
    for( let i = 0; i < STATE.jobsData.length; i++ ) {
      if( STATE.jobsData[i].rid === jobId ) {
        if( STATE.jobsData[i].customModel !== "standard" ) {
          return true
        }
      }
    }
    return false
  }

  /////////////////////////////////////////////////////////////////////
  // Calculates the current animation job progress based on step and
  // total counts returned from backend
  /////////////////////////////////////////////////////////////////////
  function calculateProgress(total, step, lastProgress=0) {
    if (total <= 0) total = 1
    if (step <= 0) step = 0
    if (step > total) step = total

    let progress = Math.floor(step * 100 / total)
    let maxStepProgress = Math.floor((step + 1) * 100 / total)
    if (lastProgress >= progress && lastProgress + 1 >= maxStepProgress) {
      progress = maxStepProgress
    } else if (lastProgress >= progress) {
      progress = lastProgress + 1
    }
    if( progress > STATE.animationJobProgress ) {
      // don't allow progress bar to be updated with a lower value than current
      DISPATCH({animationJobProgress: progress})
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Calculates used minutes for the current billing cycle by
  // scanning through jobs data and aggregating jobs created
  // between billing cycle start and end dates
  //
  // --> Returns job time in seconds (Promise)
  /////////////////////////////////////////////////////////////////////
  function calculateUsedTimeForCurrCycle() {
    return new Promise((resolve, reject) => {
      try {
        if( !STATE.jobsData || STATE.jobsData.length === 0 ) {
          resolve(0)
        }
        else {
          let usedTime = 0.0
          // loop through jobs data...
          STATE.jobsData.forEach(job => {
            if( parseInt(job.dateRaw/1000) >= STATE.subscriptionInfo.current_period_start && job.dateRaw/1000 < STATE.subscriptionInfo.current_period_end ) {
              usedTime += job.lengthRaw
            }
          })
          resolve(usedTime)
        }
      }
      catch(error) {
        console.error(`Error encountered while calculating used cycle time: ${error}`)
        reject(error)
      }
    })
  }

  ////////////////////////////////////////////////////////////////////
  // parse jobs data returned from API and update app state
  ////////////////////////////////////////////////////////////////////
  function parseAccountJobsData(res) {
    return new Promise((resolve, reject) => {
      // sort response data jobs list from oldest to newest to help
      // with the rerun removal algorithm further below...
      const list = res.data.list.sort((a, b) => a.mtime - b.mtime)
      let logs = []
      let accTotals = STATE.accountTotals
      // reset time and size total before re-calculating
      accTotals.time = 0
      accTotals.size = 0

      for( let i = 0; i < list.length; i++ ) {
        const d = new Date(list[i].mtime)
        const date = [ d.getMonth()+1, d.getDate(), d.getFullYear() ].join('/')
        // sum the animation times and sizes
        accTotals.time += list[i].fileDuration
        accTotals.size += list[i].fileSize
        //if not getting fileduration from backend, display nothing at thing time
        const fLength = list[i].fileDuration ? list[i].fileDuration : ' '
        const fSize = Enums.formatSizeUnits(list[i].fileSize)
        let settings = {}
        // jobs created before corresponding backend changes (for the environment in question)
        // will not have previous run parameters/settings available
        if( !(Object.keys(list[i].parameters).length === 0 && list[i].parameters.constructor === Object) ) {  
          settings.inputVideoData = {}
          settings.inputVideoData.fileName = list[i].fileName
          settings.inputVideoData.fileLength = fLength
          // TODO: No resolution or FPS being returned for jobs currently
          settings.inputVideoData.videoRes = null
          settings.formats = list[i].parameters.formats
          if( list[i].parameters.createDMPE ) {
            // append dmpe format if enabled
            settings.formats = `${settings.formats},dmpe`
          }
          // Convert to an object since buildJobSummaryInfoTables() 
          // expects an object {val:true} of format extensions...
          settings.formats = settings.formats.split(',')
          let formatObj = {}
          for( let i = 0; i < settings.formats.length; i++ ) {
            formatObj[settings.formats[i]] = true
          }
          settings.formats = formatObj
          settings.trackFace = parseInt(list[i].parameters.trackFace)
          settings.physicsSim = (list[i].parameters.dis && list[i].parameters.dis.includes('s')) ? false : true
          settings.footLockMode = list[i].parameters.footLockingMode
          settings.videoSpeedMultiplier = list[i].parameters.videoSpeedMultiplier
          settings.poseFilteringStrength = list[i].parameters.poseFilteringStrength ? list[i].parameters.poseFilteringStrength : "0"
          settings.keyframeReducer = 'vector.fbx_key_reduce' in list[i].parameters ? true : false
          settings.camMode = (!list[i].parameters['render.camMode'] || list[i].parameters['render.camMode'] === "-1" || list[i].parameters['render.camMode'] === -1) 
            ? 'n/a' : 
            Enums.cameraMotionSettings[parseInt(list[i].parameters['render.camMode'])]
          // ensure rootJointAtOrigin value remains a boolean
          settings.rootJointAtOrigin = (list[i].parameters.rootAtOrigin === 'true' || list[i].parameters.rootAtOrigin === '1')

          settings.sbs = (list[i].parameters['render.sbs'] === '1')
          settings.shadow = (list[i].parameters['render.shadow'] === '1')
          // includeAudio property is only present if audio option disabled for job
          settings.includeAudio = (list[i].parameters['render.includeAudio'] === "0") ? false : true
          if( list[i].parameters['render.backdrop'] === Enums.mp4BkgdOption.studio ) {
            settings.backdrop = list[i].parameters['render.backdrop']
            settings.bgColor = list[i].parameters['render.bgColor'].split(',')
          }
          else if( 'render.bgColor' in list[i].parameters ) {
            settings.backdrop = false
            settings.bgColor = list[i].parameters['render.bgColor'].split(',')
          }
          else {
            if( settings.sbs ) {
              settings.backdrop = false
              settings.bgColor = 'n/a'
            }
            else {
              settings.backdrop = false
              settings.bgColor = Enums.defaultGSColor.toString().split(',')
            }
          }
        }
        let settingsData = settings
        // push each job's data into logs array
        logs.push({
          name : list[i].fileName,
          length : fLength,
          lengthRaw : list[i].fileDuration,
          size : Enums.formatSizeUnits(list[i].fileSize),
          sizeRaw : list[i].fileSize,
          date : date,
          dateRaw : list[i].mtime,
          rid : list[i].rid,
          sourceJobId : list[i].parameters.sourceJobId,
          customModel : list[i].modelId,
          settings: settings,
          jobType: list[i].jobType
        })
      }//end for

      const newNumPages = Math.ceil(logs.length / STATE.rowsPerPage)
      let newCurrPage = STATE.currPage
      if( STATE.currPage > newNumPages && newNumPages !== 0 ) { 
        // set to last page if beyond current index after updating jobs data
        newCurrPage = newNumPages
      }
      resolve({
        accountTotals: accTotals,
        currPage: newCurrPage,
        jobsData: logs,
        numPages: newNumPages
      })
    })
  }

  /////////////////////////////////////////////////////////////////////
  function updateBillingCycleData(data) {
    return new Promise((resolve, reject) => {
      let subData = data.subscriptionInfo
      subData.featurePacks = data.userPackFeature
      let totalCurrCycleMins = data.userMaxUpgradePackMinute.staticData.minutes
      calculateUsedTimeForCurrCycle()
      .then( time => {
        let usedRounded = Math.ceil( time )
        let remainingRounded = Math.floor( STATE.accountTotals.remainingTimeInSeconds )
        let usedMonthlyTime = (!Enums.secondsToTime( usedRounded ) || Enums.secondsToTime( usedRounded ) === "") ? "00:00:00" : Enums.secondsToTime( usedRounded )
        let remainingMonthlyTime = (!Enums.secondsToTime( remainingRounded ) || Enums.secondsToTime( remainingRounded ) === "") ? "00:00:00" : Enums.secondsToTime( remainingRounded )
        let usagePercent = Math.ceil( ((totalCurrCycleMins*60)-remainingRounded)/(totalCurrCycleMins*60) * 100 )
        if( usagePercent < 0 ) {
          usagePercent = 0
        } 
        // convert billing cycle dates from unix timestamp to date strings
        let currCycleEndDate = Enums.dateConverter(subData.plan_expiary_date/1000, true)
        let startDate = new Date(subData.plan_expiary_date)
        startDate.setMonth(startDate.getMonth() - 1)  // subtract one month
        const currCycleStartDate = Enums.dateConverter(startDate.getTime()/1000, true)
        const newBillingCycleData = {
          usedRounded: usedRounded,
          remainingRounded: remainingRounded,
          usedMonthlyTime: usedMonthlyTime,
          remainingMonthlyTime: remainingMonthlyTime,
          usagePercent: usagePercent,
          currCycleStartDate: currCycleStartDate,
          currCycleEndDate: currCycleEndDate,
          totalCurrCycleMins: totalCurrCycleMins
        }

        resolve({
          currentBillCycleInfo: newBillingCycleData, 
          subscriptionInfo: subData,
          accountTotals: data.accountTotals
        })
        
      })
    })
  }

  /////////////////////////////////////////////////////////////////////
  function parseAccountModelsData(res) {
    return new Promise((resolve, reject) => {
      try {
        let modelsList = []
        const modelsListAll = res.data.list
        if( res.data.list.length > STATE.accountTotals.characterLimit ) {
          // cap maximum # of characters, starting from most recent...
          modelsList = res.data.list.slice(res.data.list.length-STATE.accountTotals.characterLimit,res.data.list.length)
          if( !STATE.accountTotals.characterLimit || STATE.accountTotals.characterLimit === 0 ) {
            console.log(`Warning: Invalid account character limit encountered in getAccountCustomCharacters().`)
          }
        }
        else {
          modelsList = res.data.list
        }
        let data = STATE.accountTotals
        data.charactersList = modelsList // up to their subscription limit
        downloadModelThumbnails(data)
        .then(data => {
          resolve(data)
        })
      }
      catch(error) {
        reject(error)
      }
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Get info on a specific animation job based on job ID:
  /////////////////////////////////////////////////////////////////////
  function getJobDetailsById(jobId) {
    for( let i = 0; i < STATE.jobsData.length; i++ ) {
      if( STATE.jobsData[i].rid === jobId ) {
        return STATE.jobsData[i]
      }
    }
    return null
  }

  /////////////////////////////////////////////////////////////////////
  // Returns true if jobId is an existing (ie. already completed) job,
  // otherwise returs false
  /////////////////////////////////////////////////////////////////////
  function isExistingJob(jobId) {
    let found = STATE.jobsData.find(job => job.rid === jobId)
    return (found === undefined ? false : true)
  }

  /////////////////////////////////////////////////////////////////////
  // Retrieve model thumbnail from app state
  // @param modelId : id of the model to retrieve
  /////////////////////////////////////////////////////////////////////
  function getModelDataById(modelId) {
    if( modelId === Enums.robloxModelId ) {
      return {id: Enums.robloxModelId, name: 'Roblox R15'}
    }
    else {
      let found = STATE.accountTotals.charactersList.find(model => model.id === modelId)
      return (found === undefined ? null : found)
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Previews an animation job using the Unity online preivewer 
  //
  // @param rid : the requestID (ie job id) for the given job
  /////////////////////////////////////////////////////////////////////
  function openAnimationPreviewer(rid) {
    setLOADING({...LOADING, ...{show: true}})
    let animLinks = null
    getLinksForJob(rid, true)
    .then( res => parseJobLinksData(res) )
    .then( res => {
      animLinks = res.animJobLinks
      return parseModelDownloadLinks(res.animJobLinks) 
    })
    .then( dLinks => {
      return DISPATCH({
        animJobId: rid,
        animJobLinks: animLinks,
        currDownloadLinks: dLinks,
        displayPreview: true
      })
    })
    .catch( (error) => {
      console.error(`Error while retrieving animation job links! ${error}\n${JSON.stringify(error, null, 4)}`)
      setErrorDialogInfo(true, Enums.eCodes.OtherError, "Problem Getting Job Links")
    })
  }

  /////////////////////////////////////////////////////////////////////
  function parseJobLinksData(res) {
    return new Promise((resolve, reject) => {
      const data = res.data
      let downloadLinks = {}
      let name = data.links[0].name
      downloadLinks.fileName = name
      downloadLinks.models = []
      let previewLinks = {}
      let frameMapFiles = []
      let bvhFiles = []
      let modelFiles = []
      let humanoidmapFiles = []
      previewLinks.video = data.links[0].inputWebm

      let predefinedCharacterNamesInOrder = [Enums.characterModels['1'].fileName, Enums.characterModels['2'].fileName, Enums.characterModels['3'].fileName, Enums.characterModels['4'].fileName, Enums.characterModels['5'].fileName, Enums.characterModels['6'].fileName]
      previewLinks.customMode = downloadLinks.customMode = data.links[0].modelId ? (data.links[0].modelId !== 'standard') : doesJobUseCustomModel(rid)
      previewLinks.faceTrackingMode = downloadLinks.faceTrackingMode = data.links[0].faceDataType ? (data.links[0].faceDataType > 0) : false

      if (!previewLinks.customMode && !previewLinks.faceTrackingMode) {
        previewLinks.character = ['female', 'female slim', 'male', 'male fat', 'male young', 'child']
        previewLinks.bvh = []
        previewLinks.framesMap = []
      }

      data.links[0].urls.forEach(items => {
        const temp = []
        items.files.forEach(item => {
          Object.entries(item).forEach(([key, value]) => {
            temp.push({type:key, link: value}); 
            if(key === 'bvh') {
              //previewLinks[items.name] = value
              bvhFiles.push({name: items.name, url: value})
            }
            // json key indicates a frames map file 
            if(key === 'json' && items.name.endsWith('framesMap')) {
              frameMapFiles.push({name: items.name, json: value})
            }
            // glb is a model file
            if(key === 'glb') {
              modelFiles.push({name: items.name, url: value})
            }
            // humanoid is a mapping file of dm standard skeleton vs user's custom character skeleton
            if(key === 'humanoidmap') {
              humanoidmapFiles.push({name: items.name, url: value})
            }
          })
        }) 
        downloadLinks.models.push({name: items.name, links: temp})
      })
      if (!previewLinks.customMode && !previewLinks.faceTrackingMode) { // standard characters
        predefinedCharacterNamesInOrder.forEach(character => {
          previewLinks.bvh.push(bvhFiles.find(bvh => bvh.name === character).url)
          if( frameMapFiles.length !== 0 ) {
            previewLinks.framesMap.push(frameMapFiles.find(map => map.name.split('.')[0] === character).json)
          }
        })
      }
      else { // custom character
        previewLinks.character = modelFiles[0].url
        previewLinks.bvh = bvhFiles[0].url
        if( frameMapFiles.length !== 0 ) {
          previewLinks.framesMap = frameMapFiles[0].json
        }
      if( humanoidmapFiles.length !== 0 ) {
          previewLinks.humanoidmap = humanoidmapFiles[0].url
        }
      }
      // NOTE: for the previewer, previewLinks now contains the query parameter that the previewer can directly consume,
        // means in GetConfig() in previewer's index.html there is no further json string composing code needed
        // GetConfig() now simply needs to do this:
          /*  var src = window.location.href.split('?src=')[1]
              jsonStr = decodeURI(src)
              unityInstance.SendMessage("Previewer", "LoadConfig", jsonStr);*/
        // the motivation of simplifying the previewer's index.html as above is that
        // scattering the code to accomplish one function in different places makes maintenance difficult
      // construct the new links data
      let newLinks = {
        previewLinks:previewLinks, 
        downloadLinks:downloadLinks
      }
      resolve({animJobLinks: newLinks})
    })
  }

  /////////////////////////////////////////////////////////////////////
  // called once user initiaties a new animation job
  // @param params : object with key-value pairs of parameters
  /////////////////////////////////////////////////////////////////////
  function initNewAnimationJob(params) {
    window.scrollTo(0, 0)
    // start processing job immediately if we already have storage link
    if( STATE.videoStorageUrl ) {
      DISPATCH({
        isJobInProgress: true,
        currWorkflowStep: Enums.uiStates.jobInProgress,
        progressMsg: "Initializing process...",
        confirmDialogId: Enums.confirmDialog.none,
        animJobId: 0
      })
      beginAnimationJob({processUrl: STATE.videoStorageUrl, retry: true})
    }
    // otherwise start a brand new job starting with upload
    else {
      uploadJobDataToBackend(
        STATE.inputVideoData.fileName, 
        STATE.inputVideoData.selectedFile,
        true
      )
      .then(res => {
        console.log(`Done uploading to GCP...`)
        DISPATCH({
          currWorkflowStep: Enums.uiStates.jobInProgress,
          isJobInProgress: true,
          progressMsg: "Uploading data...",
          videoStorageUrl: res.videoStorageUrl,
          confirmDialogId: Enums.confirmDialog.none,
          animJobId: 0
        })
        beginAnimationJob( {processUrl: res.videoStorageUrl, retry: true} )
      })
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Begins a new animation job
  /////////////////////////////////////////////////////////////////////
  function beginAnimationJob(params) {
    const jobParams = createAnimJobSettingsObject( params.rerun )
    // form request data body for job request:
    let requestData = {
      processor: "video2anim",
      params: jobParams
    }
    // set rid or url param based on if rerun or regular job, respectively
    if( params.rerun ) {
      requestData.rid = params.rid
    }
    else {
      requestData.url = params.processUrl
    }
    startNewAnimOrPoseJob(requestData, true)
    .then(res => {
      let newStateData = {
        isJobInProgress: true,
        currWorkflowStep: Enums.uiStates.jobInProgress,
        progressMsg: "Initializing process...",
        confirmDialogId: null
      }
      if( res.data && res.data.rid && res.data.rid !== "" ) {
        newStateData.animJobId = res.data.rid
      }
      DISPATCH(newStateData)
      if( params.rerun ) {
        history.push(`${Enums.routes.Anim3dCreate}?rerun=true`)
      }
    })
    .catch( (error) => {
      if(error.response) {
        if(error.response.status === Enums.eCodes.Forbidden) {
          console.error(`Access forbidden error encountered while starting animation job - \n${error}`)
          logoutUser()
        }
        if(error.response.status === Enums.eCodes.InternalServerError) {          
          setErrorDialogInfo(
            true, 
            Enums.eCodes.InternalServerError, 
            Enums.customErrors[error.response.data.error], 
            error.response.data.message, () => {
              return
          })
        }
      }
      else {
        handleHttpError(error, "Problem Starting New Job", true)
        return
      }
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Deletes a custom model from the signed in user's account
  //
  // @param modelId : unique model id of the character to delete
  // @param clbFunc : callback function once done
  /////////////////////////////////////////////////////////////////////
  function deleteCustomModel(modelId, clbFunc) {
    DISPATCH({confirmDialogId: null})  // close dialog if up
    removeCustomModelFromAccount(modelId)
    .then(res => {
      // once job is deleted we retrieve the list of custom characters again
      // to make sure state vars are in-sync
      getAccountCustomCharacters(false)
      .then( res => {
        if( clbFunc ) {
          return clbFunc()
        }
        else {
          return res  
        }
      })
    }).catch( (error) => {
      // handle error
      console.log('There was a problem removing the custom character.');
      if(error.response) {
        if(error.response.status === Enums.eCodes.Unauthorized) {
          logoutUser()
        }
        if(error.response.status === Enums.eCodes.Forbidden) {
          logoutUser()
        }
        else {
          handleHttpError(error, "Could Not Delete Character")
        }
      }
      else {
        handleHttpError(error, "Could Not Delete Custom Character")
      }
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Delete a previous animation job
  //
  // @param rid : the requestID (ie job id) for the given job
  /////////////////////////////////////////////////////////////////////
  function deleteAnimationJob(rid) {
    window.scrollTo(0, 0)
    DISPATCH({confirmDialogId: null})  // close dialog if up
    removeJobFromAccount(rid)
    .then(res => {
      // once job is deleted we retrieve the list of jobs and sort them
      // based on the current column and sort direction
      
      getJobsDataForAccount(true)
      .then( res => parseAccountJobsData(res) )
      .then( res => {
        // sortLibraryByColumn() // list is sorted when library gets re-initialized
        return DISPATCH({jobsData: res.jobsData, libraryInitialized: false})
      })
      .catch(error => {
        console.error(`Error encountered while refreshing jobs list after deleting job ${rid}.`)
      })
    })
    .catch( (error) => {
      // handle error
      console.error(`There was a problem while attempting to delete the job ${rid} --> ${error}`);
      handleHttpError(error, "Could Not Delete Job")
    })
  }

  /////////////////////////////////////////////////////////////////////
  // onClick() handler for Deleting a job from the library page
  // 
  // @param rid : request ID of the anim job to delete
  // @param fileName : job file name (used in delete confirmation dialog)
  // @param fileDuration : job file length (used in delete confirmation dialog)
  // @param fileSize : job file size (used in delete confirmation dialog)
  // @param date : job creation date (used in delete confirmation dialog)
  /////////////////////////////////////////////////////////////////////
  function handleDeleteJobClick(rid, fileName, fileDuration, fileSize, date) {
    const dialogObj = {"name":fileName,"length":fileDuration,"size":fileSize,"date":date}
    DISPATCH({dialogInfo: dialogObj, confirmDialogId: rid})
  }
  //////////////////////////////////////////////////////////////////////
  // local helper functions for anim delete modal...
  //////////////////////////////////////////////////////////////////////
  function deleteAnimJobAndClearLocalState(rid) {
    setDeleteJobButtonEnabled(false)
    deleteAnimationJob(rid)
  }
  function closeDeleteAnimJobModal() {
    setDeleteJobButtonEnabled(false)
    closeModal()
  }
  /////////////////////////////////////////////////////////////////////
  function validateJobDeleteInput(event) {
    if( event && event.target ) {
      if( event.target.value === STATE.dialogInfo.name ) {
        setDeleteJobButtonEnabled(true)
      }
      else {
        setDeleteJobButtonEnabled(false)
      }
    }
  }

  //////////////////////////////////////////////////////////////////////
  // local helper functions for accountClose modal...
  //////////////////////////////////////////////////////////////////////
  function validateCloseAccountInput(event) {
    if( event && event.target ) {
      if( event.target.value === STATE.email ) {
        setcloseAccountButtonEnabled(true)
      }
      else {
        setcloseAccountButtonEnabled(false)
      }
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Sorts the library data based on column and direction
  //
  // @param cb : optional callback fcn for when finished
  /////////////////////////////////////////////////////////////////////
  function sortLibraryByColumn() {
    return new Promise((resolve, reject) => {
      // to sort the library we sort the jobsData state array 
      let logs = STATE.jobsData
      if( logs ) {
        switch(STATE.currSortField) {
          case Enums.libraryColName[0]:
            if( STATE.currSortDirection === 'up' ) {
              logs.sort((a, b) => a.name.localeCompare(b.name))
            }
            else {
              logs.sort((a, b) => b.name.localeCompare(a.name))
            }
            break
          case Enums.libraryColName[1]:
            if( STATE.currSortDirection === 'up' ) {
              logs.sort((a, b) => a.lengthRaw - b.lengthRaw)
            }
            else {
              logs.sort((a, b) => b.lengthRaw - a.lengthRaw)
            }
            break
          case Enums.libraryColName[2]:
            if( STATE.currSortDirection === 'up' ) {
              logs.sort((a, b) => a.sizeRaw - b.sizeRaw)
            }
            else {
              logs.sort((a, b) => b.sizeRaw - a.sizeRaw)
            }
            break
          case Enums.libraryColName[3]:
            if( STATE.currSortDirection === 'up' ) {
              logs.sort((a, b) => a.dateRaw - b.dateRaw)
            }
            else {
              logs.sort((a, b) => b.dateRaw - a.dateRaw)
            }
            break
          default:
            break
        }
      }
      else {
        console.log(`sortLibraryByColumn() called with null jobs data!`)
      }
      // update the jobs data with sorted data to refresh the library
      DISPATCH({jobsData: logs})
      resolve('ok')
    })
  }

  /////////////////////////////////////////////////////////////////////
  // updateProductsAccessList()
  //
  // Parses the user's okta group(s) membership and enables/disabled products
  // based off which ones they have access to
  //
  // @param cb : optional callback function to call once done updating
  /////////////////////////////////////////////////////////////////////
  function updateProductsAccessList(groupList) {

    return new Promise((resolve, reject) => {
      let aList = STATE.accessList
      if( !groupList ) {
        console.log(`Warning: missing user groups info!`)
        reject(`Warning: missing user groups info!`)
      }

      //////////////////////////////////////////////////////////
      // DeepMotion Admin group
      //////////////////////////////////////////////////////////
      if( groupList.includes( process.env.REACT_APP_AG_ADMIN ) ) {
        aList[ Enums.productInfo.DMBT_Cloud.id-1 ] = true
        aList[ Enums.productInfo.DMBT_SDK.id-1 ] = true
        aList[ Enums.productInfo.VR_SDK.id-1 ] = true
        aList[ Enums.productInfo.APE_SDK.id-1 ] = true
        // enable all products for admin group and return
        resolve(aList)
      }
      else {

        //////////////////////////////////////////////////////////
        // [Product 1] ANIMATE 3D Access Groups
        //////////////////////////////////////////////////////////
        if( groupList.includes( decodeURI(process.env.REACT_APP_AG_DMBT_CLOUD) ) ||
            groupList.includes( decodeURI(process.env.REACT_APP_AG_DMBT_CLOUD_TEST) ) ) {
          aList[ Enums.productInfo.DMBT_Cloud.id-1 ] = true
        }
        else {
          aList[ Enums.productInfo.DMBT_Cloud.id-1 ] = false
        }

        //////////////////////////////////////////////////////////
        // [Product 2] DMBT Real Time Body Tracking SDK (SamTv, 
        // Windows || Windows & Android)
        //////////////////////////////////////////////////////////
        if( groupList.includes( process.env.REACT_APP_AG_SAM_TV ) ||
            groupList.includes( process.env.REACT_APP_AG_DMBT_SDK_AND ) ||
            groupList.includes( process.env.REACT_APP_AG_DMBT_SDK_WIN_AND ) ) {
          aList[ Enums.productInfo.DMBT_SDK.id-1 ] = true
        }
        else {
          aList[ Enums.productInfo.DMBT_SDK.id-1 ] = false
        }

        //////////////////////////////////////////////////////////
        // [Product 3] DMBT VR 3PT Tracking SDK (Windows || 
        // Windows & Android)
        //////////////////////////////////////////////////////////
        if( groupList.includes( process.env.REACT_APP_AG_DM3PT_SDK_WIN ) ||
            groupList.includes( process.env.REACT_APP_AG_DM3PT_SDK_WIN_AND ) ) {
          aList[ Enums.productInfo.VR_SDK.id-1 ] = true
        }
        else {
          aList[ Enums.productInfo.VR_SDK.id-1 ] = false
        }

        //////////////////////////////////////////////////////////
        // [Product 4] Avatar Physics Engine (ie. APE + ACE SDK)
        //////////////////////////////////////////////////////////
        if( groupList.includes( process.env.REACT_APP_AG_PHYSICS_ENGINE ) ) {
          aList[ Enums.productInfo.APE_SDK.id-1 ] = true
        }
        else {
          aList[ Enums.productInfo.APE_SDK.id-1 ] = false
        }

        ////////////////////////////////////////
        // *** Closed *** Avatar Alpha Program:
        ////////////////////////////////////////
        if( groupList.includes( process.env.REACT_APP_AG_FREE ) && 
          groupList.length <= 2 ) {
          // disable access to all products if they are only a member
          // of the closed Avatar program and Everyone group which
          // all users are a member of
          aList[ Enums.productInfo.DMBT_Cloud.id-1 ] = false
          aList[ Enums.productInfo.DMBT_SDK.id-1 ] = false
          aList[ Enums.productInfo.VR_SDK.id-1 ] = false
          aList[ Enums.productInfo.APE_SDK.id-1 ] = false
        }

        // update the access list state array
        resolve(aList)
      }
    })
  }

  /////////////////////////////////////////////////////////////////////
  // returns true if any glb download links found
  /////////////////////////////////////////////////////////////////////
  function jobContainsGLBDownloadLinks() {
    for (const model of STATE.animJobLinks.downloadLinks.models) {      
      if( !model.name.includes('bvh-framesMap') && !model.name.includes('framesIssueStatus') ) {
        //TODO: update above IF statement once backend is updated to 
        //return if a particular job used standard characters or custom
        for (const link of model.links) {
          if (link.type === Enums.animFileType.glb) {
            return true
          }
        }
      }
    }
    return false
  }

  /////////////////////////////////////////////////////////////////////
  // onClick() handler for closing/canceling modal
  // @param reset : if true stops online previewer and resets job links
  /////////////////////////////////////////////////////////////////////
  function closeModal(resetJobData) {
    const dialogData = {confirmDialogId: Enums.confirmDialog.none, dialogInfo: {}}
    if( resetJobData ) {
      DISPATCH({...resetJobObject, ...dialogData})
    }
    else {
      DISPATCH(dialogData)
    }
  }

  ///--------------------------------------------------------------------
  /// onClick() handler for closing custom error dialog
  ///--------------------------------------------------------------------
  function closeModalAndResetWorkflow(flag) {
    setRerunConfirmInfo({showModal:false, jobId: 0})
    setSelectedFileType(Enums.animFileType.FBX)
    setcloseAccountButtonEnabled(false)
    setDeleteJobButtonEnabled(false)
    setRerunViewState(Enums.pageState.rerunAnimSettings)
    DISPATCH({
      currWorkflowStep: Enums.uiStates.initial,
      animationJobProgress: 0,
      jobInProgress: false,
      displayPreview: false,
      confirmDialogId: Enums.confirmDialog.none,
      errorDialogInfo: {show: false, id: "", title: "", msg: ""}
    })
    // route back to model select page once done reseting state
    if( flag === closeModalFlags.routeToLibrary) {
      history.push(Enums.routes.Anim3dLibrary)
    }
    else if( flag === closeModalFlags.routeToModelSelect) {
      history.push(Enums.routes.Anim3dModelSelect)
    }
    else if( flag === closeModalFlags.routeToA3dHome) {
      history.push(Enums.routes.Anim3d)
    }
    else if( flag === closeModalFlags.routeToProfilePage) {
      history.push(Enums.routes.Anim3dProfilePage)
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Creates a job settings object to be used in API request for
  // starting a new animation job.
  //
  // @param isRerun : if true reads job settings data from |rerunSettings| 
  // state object, if false reads from |animJobSettings| state
  /////////////////////////////////////////////////////////////////////
  function createAnimJobSettingsObject(isRerun) {
    let outputFormatsList = []
    for (const [key, value] of Object.entries(getSettingValueBasedOnJobType(isRerun).formats)) {
      if(value === true) {
        outputFormatsList.push(key)
      }
    }

    outputFormatsList = outputFormatsList.join(',')

    // create animation job params array to include in request body 
    let jobParams = [ 
      `config=configWebApp`,
      `fullLog`,
      `formats=${outputFormatsList}`
    ]

    if( isRerun ) {
      // do not allow modification of custom character setting for reruns
      if( STATE.rerunSettings.customModelInfo && STATE.rerunSettings.customModelInfo.id !== 'standard' ) {
        jobParams.push(`model=${STATE.rerunSettings.customModelInfo.id}`)
      }
    }
    else {
      if( STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id ) {
        jobParams.push(`model=${STATE.animJobSettings.customModelInfo.id}`)
      }
    }

    // add face tracking, foot locking, speed mult, smoothness, and camera motion params
    jobParams.push(`trackFace=${getSettingValueBasedOnJobType(isRerun).trackFace}`)
    jobParams.push(`footLockingMode=${getSettingValueBasedOnJobType(isRerun).footLockMode}`)
    if( checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion) ) {
      jobParams.push(`videoSpeedMultiplier=${getSettingValueBasedOnJobType(isRerun).videoSpeedMultiplier}`)
    }
    if( checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness) ) {
      jobParams.push(`poseFilteringStrength=${getSettingValueBasedOnJobType(isRerun).poseFilteringStrength}`)
    }
    jobParams.push(`rootAtOrigin=${getSettingValueBasedOnJobType(isRerun).rootJointAtOrigin}`)
    if( getSettingValueBasedOnJobType(isRerun).jobType === Enums.jobTypes.staticPose ) {
      // set camera to Fixed for static pose jobs
      jobParams.push(`render.camMode=1`)
    }
    else {
      jobParams.push(`render.camMode=${Enums.cameraMotionSettings.indexOf( getSettingValueBasedOnJobType(isRerun).camMode)}`)
    }

    // enable DMPE output for premium (Pro+) subscriptions
    if( isProfessionalOrHigherPlan() ) {
      jobParams.push(`createDMPE=${true}`)
    }

    // configure side-by-side rendering based on user selection
    if( getSettingValueBasedOnJobType(isRerun).sbs ) {
      jobParams.push("render.sbs=1")
    }
    else {
      jobParams.push("render.sbs=0")
      if( getSettingValueBasedOnJobType(isRerun).greenScreen ) {
        // MP4 green-screen background color
        let color = getSettingValueBasedOnJobType(isRerun).bgColor.toString()
        // remove quotes from color string
        color = color.replace(/"/g, "")
        jobParams.push(`render.bgColor=${color}`)
      }
      else if( getSettingValueBasedOnJobType(isRerun).backdrop ) {
        jobParams.push("render.backdrop=studio")
        // MP4 render backdrop color 
        const cArray = getSettingValueBasedOnJobType(isRerun).bgColor
        cArray[3] = 255 // render backdrop requires alpha = 255 !
        let color = cArray.toString()
        // remove quotes from color string
        color = color.replace(/"/g, "")
        jobParams.push(`render.bgColor=${color}`) 
      }
    }

    if( getSettingValueBasedOnJobType(isRerun).shadow ) {
      // render shadow in MP4 output
      jobParams.push("render.shadow=1")
    }
    else {
      jobParams.push("render.shadow=0")
    }

    if( !getSettingValueBasedOnJobType(isRerun).includeAudio ) {
      jobParams.push(`render.includeAudio=0`)
    }

    // check for job settings the user has selected
    if (getSettingValueBasedOnJobType(isRerun)['keyframeReducer'] === true) {
      jobParams.push("vector.fbx_key_reduce")
    }
    // check for job settings the user has selected
    if (getSettingValueBasedOnJobType(isRerun)['physicsSim'] === false) {
      jobParams.push("dis=s")
    }

    // Add DM Logo to MP4 Output (if enabled)
    // TODO: update logic once account plans are enabled
    jobParams.push("render.logo=1")

    // return the job settings object...
    return jobParams
  }

  ////////////////////////////////////////////////////////////////////
  // Checks for access to specific feature based on account plan/pack
  ////////////////////////////////////////////////////////////////////
  function checkFeatureAvailability(planName, featureName) {
    let planIndex = 0 // default plan to Freemium
    let activePackNames = []
    // first we loop through the custom packs array and record the names
    // of any active packs we find
    if( STATE.subscriptionInfo.featurePacks ) {
      for (let pack of STATE.subscriptionInfo.featurePacks) {
        if( pack.active ) {
          // parse pack name, check if index higher
          activePackNames.push( pack.pack_id)
        }
      }
    }
    // get index of plan based on subscription name, scan through in
    // reverse order to make sure we apply highest pack features
    for( let i = Enums.accountPlansInfo.length-1; i >= 0; i-- ) {
      let found = false
      for ( let activePack of activePackNames ) {
        if( activePack.toLowerCase().includes(Enums.accountPlansInfo[i].name.toLowerCase()) ) {
          planIndex = i
          found = true
          break
        }
      }
      if( found ) {
        break
      }
      else {
        if( Enums.accountPlansInfo[i].name.toLowerCase() === planName.toLowerCase() ) {
          planIndex = i
          break
        }
      }
    }

    // find corresponding feature data for feature in question
    for (const [key, value] of Object.entries(Enums.featureLocksData)) {
      if( key === featureName ) {
        return value[planIndex]
      }
    }
    console.log(`Warning: Could not find feature availability data for feature: ${featureName}`)
    return null
  }

  ////////////////////////////////////////////////////////////////////
  // Retrieves the job settings object for regular & rerun jobs
  ////////////////////////////////////////////////////////////////////
  function getSettingValueBasedOnJobType(isRerun) {
    return (isRerun ? STATE.rerunSettings : STATE.animJobSettings)
  }

  ////////////////////////////////////////////////////////////////////
  // get max input video size allowed based on user's subscription
  ////////////////////////////////////////////////////////////////////
  function getMaxInputClipSize() {
    for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
      if( STATE.subscriptionInfo.name === Enums.accountPlansInfo[i].name ) {
        return Enums.accountPlansInfo[i].uploadLimitInBytes
      }
    }
    // default to Freemium upload size limit
    return Enums.accountPlansInfo[0].uploadLimitInBytes
  }

  ////////////////////////////////////////////////////////////////////
  // get single video length limit based on user's subscription
  ////////////////////////////////////////////////////////////////////
  function getMaxInputClipLength() {
    for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
      if( STATE.subscriptionInfo.name === Enums.accountPlansInfo[i].name ) {
        return Enums.accountPlansInfo[i].maxVideoDurationInSec
      }
    }
    // default to Freemium upload size limit
    return Enums.accountPlansInfo[0].maxVideoDurationInSec
  }

  ////////////////////////////////////////////////////////////////////
  // get max input video durations / length
  ////////////////////////////////////////////////////////////////////
  function getMaxInputClipLength() {
    for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
      if( STATE.subscriptionInfo.name === Enums.accountPlansInfo[i].name ) {
        return Enums.accountPlansInfo[i].maxVideoDurationInSec
      }
    }
    // default to Freemium upload size limit
    return Enums.accountPlansInfo[0].maxVideoDurationInSec
  }

  ////////////////////////////////////////////////////////////////////
  // True for subscriptions = Professional or higher
  ////////////////////////////////////////////////////////////////////
  function isProfessionalOrHigherPlan() {

    if( STATE.subscriptionInfo.isEnterpriseUser ) {
      return true
    }
    // customer may have a stripe subscription but a different set of account plan 
    // features may be applied through feature packs. Backend prioritizes feature packs
    // so we first check for highest level feature pack
    if( STATE.subscriptionInfo.featurePacks && STATE.subscriptionInfo.featurePacks.length ) {
      let oneActivePackFound = false
      for( let i = 0; i < STATE.subscriptionInfo.featurePacks.length; i++ ) {
        let pack = STATE.subscriptionInfo.featurePacks[i]
        if( pack.active ) {
          oneActivePackFound = true
          // return true if "professional", "studio", or "enterprise" pack
          if( pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[ Enums.accountPlansInfo.length-1 ].name.toLowerCase()) ||
              pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[ Enums.accountPlansInfo.length-2 ].name.toLowerCase()) || 
              pack.pack_id.toLowerCase().includes(Enums.accountPlansInfo[ Enums.accountPlansInfo.length-3 ].name.toLowerCase())) {
            return true
          }
        }
      }
      if( oneActivePackFound ) {
        // if there was at least one active pack and it was not Pro+ then
        // a lower level pack has been applied to the account
        return false
      }
    }
    if( STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[ Enums.accountPlansInfo.length-2 ].name.toLowerCase() ||
        STATE.subscriptionInfo.name.toLowerCase() === Enums.accountPlansInfo[ Enums.accountPlansInfo.length-3 ].name.toLowerCase() ) {
      return true
    }
    else {
      return false
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Builds the title row with job type drop down selector
  /////////////////////////////////////////////////////////////////////
  function buildJobTypeSelectionRow() {
    return (
      <div className="column box disabled-switch m-3 p-0 ">
        <div className="columns m-0 br-4">
          <div className="column is-2 m-0 p-0 has-text-centered">
            <figure className="image is-16by9">
              <img src={STATE.animJobSettings.jobType ? imgNewPose : imgNewAnim} className="br-4-left" alt="Default charaters" />
            </figure>
          </div>
          <div className="column m-0 p-0 has-text-centered flex-vert-center">
            <h1 className="title is-3 has-text-light"> 
              { STATE.animJobSettings.jobType ? newPoseTitle : newAnimTitle }
            </h1>
          </div>
          <div className="column m-0 p-0 has-text-right flex-vert-center">
            <DMDropDown
              value={(STATE.animJobSettings.jobType ? Enums.jobTypes.staticPoseText : Enums.jobTypes.animationText )}
              onChange={handleAnimSettingsJobTypeChange}
              data={animJobTypes}
              textClass="title is-5 dm-brand-font"
              isStyled2={true}
              rightAlign={true}
              noMargins={true}
              outerMarginClass="mr-4"
            />
          </div>
        </div>
      </div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function handleAnimSettingsJobTypeChange(value, cb) {
    // if switching from anim job to 3d pose and user has already uploaded input file or there
    // is a video upload in progress warn them that they will lose the uploaded media
    if( STATE.videoStorageUrl || STATE.silentUploadInProgress ) {
      DISPATCH({confirmDialogId: Enums.confirmDialog.confirmLoseUploadData})
    }
    else {
      DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{jobType: value === jobType3dPose ? 1 : 0 } } })
    }
    // call callback if present
    if( cb ) { cb() }
  }

  /////////////////////////////////////////////////////////////////////
  // Builds the file selected UI
  /////////////////////////////////////////////////////////////////////
  function buildFileSelectedScreen() {
    if( !STATE.inputVideoData || Object.keys(STATE.inputVideoData).length === 0 ) {
      return <div/>
    }
    let customModelName = null
    let uiImage = null
    let descr = ""
    let imgRatio = "is-256x256"
    // show custom model info if model ID present
    if( STATE.animJobSettings.customModelInfo.id ) {
      customModelName = STATE.animJobSettings.customModelInfo.name
      uiImage = (STATE.animJobSettings.customModelInfo.thumbImg instanceof Blob) ? URL.createObjectURL(STATE.animJobSettings.customModelInfo.thumbImg) : imgCustom
      descr = customModelName
      imgRatio = "is-16by9"
    }
    else {
      // otherwise standard cloud character set
      customModelName = ""
      uiImage = imgStandard
      descr = modelSectionDefault
    }
    if( STATE.inputVideoData.fileLength > STATE.currentBillCycleInfo.remainingRounded ) {
      // reset UI and display not enough mins dialog if selected video duration
      // is longer than remaining monthly time
      console.log(`Not enough animation time left in current cycle. Input video is ${STATE.inputVideoData.fileLength} seconds long however only ${STATE.currentBillCycleInfo.remainingRounded} animation time remains.`)
      resetDialogsData(null, true)
      DISPATCH({confirmDialogId: Enums.confirmDialog.MinutesBalanceTooLow})
    }
    else if( STATE.inputVideoData.fileLength > process.env.REACT_APP_MAX_CLIP_LENGTH ) {
      console.log(`Error: Max motion clip length of ${process.env.REACT_APP_MAX_CLIP_LENGTH} exceeded, input file was ${STATE.inputVideoData.fileLength} seconds.`);
      resetDialogsData(null, true)
      DISPATCH({confirmDialogId: Enums.confirmDialog.inputFileTooLong})
      return <div />
    }
    let staticImgBlob = null
    let staticImgUrl = null
    if( STATE.animJobSettings.jobType === Enums.jobTypes.staticPose 
        && !Enums.isFileExtensionVideoFormat(STATE.inputVideoData.fileName) ) {
      // create an image for static pose jobs that do not use video type, 
      // otherwise an html <video/> tag is used
      staticImgBlob = new Blob( [ STATE.inputVideoData.selectedFile ] )
      staticImgUrl = URL.createObjectURL( staticImgBlob )
    }

    // show progress border when background upload in progress
    let borderClass = STATE.silentUploadInProgress ? "bkgd-progress-bar meter-bkgd" : ""

    return (
      <React.Fragment>
        <div id="anim-fadein" className="column">
          {
            window.location.pathname !== Enums.routes.Anim3dGuidedFTE
            &&
            <AnimVersionPanel />
          }
          <div className="section pt-4">
            <div className="columns">
              <div className="column bShadow">
                <div className="columns dm-brand rounded-corners-top">
                  {buildJobTypeSelectionRow()}
                </div>
                <div className="columns dm-brand" style={{padding:'0 10px'}}>
                  
                  { 
                    !STATE.inputVideoData.selectedFile 
                    ?
                    buildAddMotionClipArea()
                    :
                    buildMotionClipSelectedArea()
                  }

                  {/*** DISPLAY CHARACTER SETTINGS ***/}
                  {buildCharacterSettingsArea()}
                </div>

                {/*** JOB SETTINGS UI ***/}
                {buildJobSettingsArea()}

              </div>  
            </div>
          </div>
          {InformationArea()} 
        </div>
      </React.Fragment>
    )
  }

  function buildJobSettingsArea(isGuidedFTE) {
    const columnClass = isGuidedFTE ? "rounded-corners" : "rounded-corners-bottom"
    return (
      <div className="columns rounded-corners">
        <div className={`column dm-brand m-0 pt-0 ${columnClass}`}>
          <div className="box disabled-switch m-3 p-4">
            {
              STATE.animJobSettings.jobType === Enums.jobTypes.animation
              ?
              buildAnimationJobConfigScreen()
              :
              buildStaticPoseJobConfigScreen()
            }

            {/*** Create & Discard Action Buttons ***/}
            <div className="columns">
              { /* Do not show discard button for the guided FTE */
                location.pathname !== Enums.routes.Anim3dGuidedFTE
                &&
                <div className="column has-text-centered">  
                  <button type="button" className="button remove-btn btn-shadow" onClick={()=>history.push(Enums.routes.Anim3d)} style={{width:'100%'}} ><span className="no-side-margins">Discard Job</span></button> 
                </div>
              }
              <div className="column has-text-centered">
                { !STATE.silentUploadInProgress
                  ?
                  <button type="button" className="button action-btn glow-on-hover btn-shadow" onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.confirmNewAnimJob})} style={{width:'100%'}}><span className="no-side-margins"> {titleCreateAnim} </span></button> 
                  :
                  <button type="button" className="button create-anim-btn-disabled no-cursor" style={{width:'100%'}}>
                    <span className="logo-text-blink">
                      <span className="icon dm-brand-2-font ml-0 mr-1">
                        <i className="fas fa-exclamation-triangle">
                        </i>
                      </span> 
                      {textUploadingVideo} 
                    </span>
                  </button>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  function buildMotionClipSelectedArea() {

    let fileNameSize = (STATE.inputVideoData.fileName.length > Math.floor(Enums.MAX_FILENAME_LENGTH/2)) ? "is-6" : "is-5"
    let fileResolution = (STATE.inputVideoData.videoRes) ? (STATE.inputVideoData.videoRes.w + " x " + STATE.inputVideoData.videoRes.h) : "n/a"
    let fileFPS = (STATE.inputVideoData.fps) ? (Number.isInteger(STATE.inputVideoData.fps) ? STATE.inputVideoData.fps : STATE.inputVideoData.fps.toFixed(2)) : "n/a"

    // show progress border when background upload in progress
    let borderClass = STATE.silentUploadInProgress ? "bkgd-progress-bar meter-bkgd" : ""
    return (
      <div className="column box disabled-switch m-3 mt-3 pl-0 pr-0 pt-0">
        <div className="is-relative">
        <div>
          {/* conditionally show background upload progress bar */}
          <div className={borderClass} >
            <span style={{width:'100%'}}></span>
          </div>
        </div>

        {/*** DISPLAY SELECTED INPUT VIDEO & FILE INFO ***/}
        <div className="columns m-0 pl-4 pr-4 pt-4 pb-0">
          <div className="column">
            <div className="columns">
              <div className="column bottom-border">
                <span className={"title is-5 has-text-white " + fileNameSize}><span className="icon" style={{marginRight:'10px'}}><i className="far fa-play-circle"></i></span><span>{STATE.inputVideoData.fileName}</span></span>
              </div>
              <div className="column is-1 m-0 p-0 bottom-border">
                {
                  !STATE.silentUploadInProgress
                  &&
                  <DMToolTip
                    text={Enums.toolTipChangeVideo}
                    tipId="tip-change-video"
                    icon="fas fa-times-circle fa-lg"
                    iconColor="#fab03c"
                    cursor="cursor-pt"
                    onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.confirmInputMediaRemoval})}
                  />
                }
              </div>
            </div>
          </div>
        </div>
        <div className="columns m-0 pr-4 pl-4 pt-0 pb-0">
          <div className="column is-7 flex-center mt-0">
          {
            STATE.animJobSettings.jobType === Enums.jobTypes.staticPose && !Enums.isFileExtensionVideoFormat(STATE.inputVideoData.fileName)
            ?
            <figure className="image is-256x256">
              <img src={staticImgUrl} className="bShadow br-4" />
            </figure>
            :
            <div>
              <video id="anim-fadein" controls className="bShadow" style={{borderRadius:'6px', maxHeight:'33vh'}}>
                <source src={URL.createObjectURL(STATE.inputVideoData.selectedFile)} type="video/mp4"/>
                <source src={URL.createObjectURL(STATE.inputVideoData.selectedFile)} type="video/avi"/>
                <source src={URL.createObjectURL(STATE.inputVideoData.selectedFile)} type="video/mov"/>
                Your browser does not support HTML5 video.
              {/*** TODO: Create new placeholder image for when video not supported  ***/}
                <figure className="image is-256x256">
                  <img src={imgStandard}/>
                </figure>
              </video>
            </div>
          }

          </div>
          <div className="column mt-0">
            <div className="columns has-text-left">
              <div className="column">
                {
                  STATE.silentUploadInProgress
                  ?
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="far fa-clock"></i></span><span className="logo-text-blink">{textAnalyzing}</span></span>
                  :
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="far fa-clock"></i></span><span>{STATE.inputVideoData.fileLength ? (STATE.inputVideoData.fileLength.toFixed(2) + " sec") : "n/a"}</span></span>
                }
              </div>
            </div>
            <div className="columns has-text-left">  
              <div className="column">
                {
                  STATE.silentUploadInProgress
                  ?
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="far fa-hdd"></i></span><span className="logo-text-blink">{textAnalyzing}</span></span>
                  :
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="far fa-hdd"></i></span><span>{Enums.formatSizeUnits(STATE.inputVideoData.fileSize, 2)}</span></span>
                }
              </div>
            </div>
            <div className="columns has-text-left">  
              <div className="column">
                {
                  STATE.silentUploadInProgress
                  ?
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="fas fa-desktop"></i></span><span className="logo-text-blink">{textAnalyzing}</span></span>
                  :
                  <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="fas fa-desktop"></i></span><span>{fileResolution}</span></span>
                }
              </div>
            </div>
            {
              STATE.animJobSettings.jobType === Enums.jobTypes.animation
              &&
              <div className="columns has-text-left">  
                <div className="column">
                  {
                    STATE.silentUploadInProgress
                    ?
                    <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="fas fa-film"></i></span><span className="logo-text-blink">{textAnalyzing}</span></span>
                    :
                    <span className="subtitle is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="fas fa-film"></i></span><span>{fileFPS} FPS</span></span>
                  }
                </div>
              </div>
            }
          </div>
        </div>
        <div className="columns m-0 p-4">
          <div className="column pt-1 pb-1 has-text-right">
            { STATE.silentUploadInProgress && location.pathname != Enums.routes.Anim3dGuidedFTE
              &&
              <button type="button" className="button remove-btn" onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.confirmInputMediaRemoval})} >
                <span className="no-side-margins"> Stop Upload </span>
              </button>
            }
          </div>
        </div>
        </div>
      </div>
    )
  }

  function buildCharacterSettingsArea() {
    let customModelName = null
    let faceSupported = false
    let uiImage = null
    let descr = ""
    // let imgRatio = "is-256x256"
    let imgRatio = "is-16by9"

    // special case for Roblox model:
    if( STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id === Enums.robloxModelId ) {
      // otherwise standard cloud character set
      customModelName = STATE.animJobSettings.customModelInfo.name
      faceSupported = false
      uiImage = imgRobloxR15
      descr = customModelName
      // imgRatio = "is-16by9"
    }
    else {
      if( STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id ) {
        customModelName = STATE.animJobSettings.customModelInfo.name
        faceSupported = isFaceTrackingSupported(STATE.animJobSettings.customModelInfo.id)
        uiImage = (STATE.animJobSettings.customModelInfo.thumbImg instanceof Blob) ? URL.createObjectURL(STATE.animJobSettings.customModelInfo.thumbImg) : imgCustom
        descr = customModelName
        // imgRatio = "is-16by9"
      }
      else {
        // otherwise standard cloud character set
        customModelName = ""
        faceSupported = true
        uiImage = imgStandard
        descr = modelSectionDefault
        // imgRatio = "is-16by9"
      }
    }

    const glbSupported = STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id && STATE.animJobSettings.customModelInfo.id !== Enums.robloxModelId
    
    let customGlbToolTip = [
      <React.Fragment key="customGlbToolTip">
        <DMToolTip
          text={Enums.toolTipGlb}
          tipId="glb-output-tip"
          noMargins={true}
        />
      </React.Fragment>
    ]
    let faceSupportedToolTip = [
      <React.Fragment key="faceSupportedToolTip">
        <DMToolTip
          text={Enums.toolTipFaceTrack}
          tipId="face-supported-tip"
          noMargins={true}
        />
      </React.Fragment>
    ]

    return (
      <div className="column box disabled-switch m-3 mt-3 p-4">
        <div className="columns m-0">
          <div className="column has-text-left bottom-border">
            <span className="title is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className="fas fa-user"></i></span><span className="mr-1">3D Model: </span><span>{descr}</span></span>
          </div>
          <div className="column is-1 m-0 p-0 bottom-border">
              <DMToolTip
                text={`Change model`}
                tipId="tip-change-model"
                icon="fas fa-edit fa-lg"
                iconColor="#fab03c"
                cursor="cursor-pt"
                onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.customModelSelect})}
              />
          </div>
        </div>
        <div className="columns m-0">
          <div className="column mt-0 has-text-centered br-4" style={{height:'175px'}}>
            <figure id="anim-fadein" className={"image br-4 " + imgRatio} style={{minHeight:'125px', border:'2px solid #363636'}}>
              <img src={uiImage} className="bShadow m-0 has-background-light" alt="custom character" />
            </figure>
          </div>
          {
            STATE.animJobSettings.jobType === Enums.jobTypes.staticPose
            ?
            <div className="column has-text-left mt-0">
              <h3 className="subtitle is-5 mb-3 has-text-white"> {textPoseDescr} </h3>
            </div>
            :
            <div className="column has-text-left mt-0">
              {
                faceSupported
                ?
                <h3 className="subtitle is-5 mb-3 has-text-white">Face Tracking is <span className="has-text-success mr-1">supported</span>
                  {faceSupportedToolTip}
                </h3>
                :
                <h3 className="subtitle is-5 mb-3 has-text-white">Face Tracking is <span className="has-text-warning">disabled</span>
                  {faceSupportedToolTip}
                </h3>
              }
              {
                glbSupported
                ?
                <h3 className="subtitle is-5 has-text-white">GLB output is <span className="has-text-success mr-1">enabled</span>
                  {customGlbToolTip}
                </h3>
                :
                <h3 className="subtitle is-5 has-text-white">GLB output is <span className="has-text-warning">disabled</span>
                  {customGlbToolTip}
                </h3>
              }
            </div>
          }
        </div>
        <div className="columns m-0">
          <div className="column pt-1 pb-1">
            <h5 className="subtitle is-6 pt-0 mt-0" style={{color:'transparent'}}>.</h5>
          </div>
        </div>
        {/* 
        <div className="columns m-0 p-4">
          <div className="column pt-1 pb-1 has-text-right">
              <button type="button" className="button remove-btn" onClick={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.customModelSelect})} >
                <span className="no-side-margins"> Change Character </span>
              </button>
          </div>
        </div>
        */}
      </div>
    )
  }

  function buildAnimationSettingsArea() {

    const footLockOptions = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.footLocking)
    let footlockDropDownData = []
    Object.values(Enums.footLockMode).forEach((value, index)=> {
      footlockDropDownData.push({value: value, available: footLockOptions[index]})
    })

    const faceSupported = isFaceTrackingSupported(STATE.animJobSettings.customModelInfo.id)

    return (
      <div className="columns">
        <div className="column has-text-left">
          <div className="columns mt-1 mb-1">
            <div className="column">
              <YesNoSlider 
                label="FBX Output"
                onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'formats': createNewOutputFormatsObj('fbx', event.target.checked)} }} ) }
                tipText={Enums.toolTipFbx}
                tipId="fbx-output"
                isDisabled={((STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id) || STATE.animJobSettings.formats.mp4 || STATE.animJobSettings.trackFace)}
                value={((STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id) || STATE.animJobSettings.formats.mp4 || STATE.animJobSettings.trackFace ) ? true : STATE.animJobSettings.formats.fbx}
              />
            </div>
            <div className="column">

              <YesNoSlider 
                label="Root Joint at Origin"
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'rootJointAtOrigin': (event.target.checked ? 1 : 0 ) }}}) }
                tipText={Enums.toolTipRootJoint}
                tipId="root-joint-at-origin"
                isDisabled={STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id ? true : false}
                value={STATE.animJobSettings.rootJointAtOrigin}
              />

              {/* 
              <YesNoSlider 
                label="FBX Frame Reducer"
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'keyframeReducer': event.target.checked} }}) }
                tipText={Enums.toolTipFBXKeyFrame}
                tipId="fbx-frame-reduce"
                isDisabled={STATE.animJobSettings.formats["fbx"] ? false : true}
                value={STATE.animJobSettings.keyframeReducer}
              />
              */}

            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column">
              <YesNoSlider 
                label="Physics Filter"
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'physicsSim': event.target.checked} }}) }
                tipText={Enums.toolTipPhysSim}
                tipId="phys-filter"
                isDisabled={false}
                value={STATE.animJobSettings.physicsSim}
              />
            </div>
            <div className="column">
              <YesNoSlider 
                label="Face Tracking"
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'trackFace': (event.target.checked ? 1 : 0 ) }}}) }
                tipText={Enums.toolTipFaceTrack}
                tipId="face-track"
                isDisabled={!faceSupported}
                value={STATE.animJobSettings.trackFace}
              />
            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column max-col-height is-align-items-center is-flex">
              <div className="columns">
                <div className="column">
                  <DMDropDown
                    label={Enums.dropDownLabels.footLockMode}
                    value={STATE.animJobSettings.footLockMode}
                    onChange={ changeFootLockingSelect }
                    data={footlockDropDownData}
                    tipText={Enums.tooltipFootLocking}
                    isTipHtml={true}
                    isDisabled={false}
                  />
                </div>
              </div>
            </div>
            <div className="column">
              
            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column">
              {buildPoseFilteringSelect(STATE.animJobSettings)}
            </div>
            <div className="column">
              {buildSpeedMultiplierSelect(STATE.animJobSettings)}
            </div>
          </div>
        </div>
      </div>
    )
  }

  function buildVideoSettingsArea() {
    let cameraSettingsDropDownData = []
    Object.values(Enums.cameraMotionSettings).forEach((value, index)=> {
      cameraSettingsDropDownData.push({value: value, available: true})
    })
    const mp4BackDropDownData = createMp4BackOptions()

    return (
      <div className="columns">
        <div className="column has-text-left">
          <div className="columns mt-1 mb-1">
            <div className="column">
              <YesNoSlider 
                label={mp4EnableOutputTitle}
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'formats': createNewOutputFormatsObj('mp4', event.target.checked)} }}) }
                tipText={Enums.toolTipMp4}
                tipId="mp4-enable"
                isDisabled={!STATE.animJobSettings.formats.fbx}
                value={STATE.animJobSettings.formats.mp4}
              />
            </div>
            <div className="column">
              <YesNoSlider 
                label={mp4EnableShadowTitle}
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'shadow': event.target.checked} }}) }
                tipText={Enums.toolTipShadow}
                isDisabled={!STATE.animJobSettings.formats.mp4}
                value={STATE.animJobSettings.formats.mp4 ? STATE.animJobSettings.shadow : false}
              />
            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column">
              <YesNoSlider 
                label={STATE.animJobSettings.jobType === Enums.jobTypes.animation ? Enums.mp4EnableSplitTitle : Enums.mp4EnableSplitTitlePose }
                onChangeFunc={(event)=>changeMP4IncludeOriginalVideo(event.target.checked)}
                tipText={Enums.toolTipIncludeVid}
                isDisabled={!STATE.animJobSettings.formats.mp4}
                value={STATE.animJobSettings.formats.mp4 ? STATE.animJobSettings.sbs : false}
              />
            </div>
            <div className="column max-col-height has-text-left">
              <YesNoSlider 
                label={mp4EnableAudioTitle}
                value={(STATE.animJobSettings.formats.mp4 ? STATE.animJobSettings.includeAudio : false)}
                onChangeFunc={ (event) => DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'includeAudio': event.target.checked} }}) }
                tipText={Enums.toolTipIncludeAud}
                isDisabled={!STATE.animJobSettings.formats.mp4}
              />
            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column">
              <DMDropDown
                label={Enums.dropDownLabels.mp4BkgdOption}
                value={(STATE.animJobSettings.backdrop ? Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.studio) : (STATE.animJobSettings.greenScreen ? Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.solid) : Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.off)) )}
                onChange={changeCustomBkgdSelect}
                data={mp4BackDropDownData}
                tipText={Enums.toolTipMp4BackType}
                isTipHtml={true}
                isDisabled={ !STATE.animJobSettings.formats.mp4 || STATE.animJobSettings.sbs }
              />
            </div>
            <div className="column max-col-height is-align-items-center is-flex">
              {buildBackgroundColorSelect()}
            </div>
          </div>
          <div className="columns mt-1 mb-1">
            <div className="column">
              <DMDropDown
                label={Enums.dropDownLabels.cameraMotion}
                value={STATE.animJobSettings.camMode}
                onChange={changeMp4OutputCameraMotion}
                data={cameraSettingsDropDownData}
                tipText={Enums.tooltipMp4Camera}
                isDisabled={!STATE.animJobSettings.formats.mp4}
                isTipHtml={true}
              />
            </div>
          </div>
        </div> 
      </div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildAnimationJobConfigScreen() {
    return (
      <React.Fragment>
        <div className="buttons is-centered has-addons">
          <button onClick={()=>setActiveJobMenu(Enums.jobMenu.animSettings)} className={"button " + (activeJobMenu === Enums.jobMenu.animSettings ? "is-link is-selected" : "is-light")}>
            Animation Output
          </button>
          <button onClick={()=>setActiveJobMenu(Enums.jobMenu.videoSettings)} className={"button " + (activeJobMenu === Enums.jobMenu.videoSettings ? "is-link is-selected" : "is-light")}>
            Video Output
          </button>
        </div>
        {
          activeJobMenu === Enums.jobMenu.animSettings
          ?
          buildAnimationSettingsArea()
          :
          buildVideoSettingsArea()
        }
      </React.Fragment>
    )
  }
  /////////////////////////////////////////////////////////////////////
  function createMp4BackOptions() {
    let mp4BackDropDownData = []
    Object.values(Enums.mp4BkgdOption).forEach((value, index)=> {
      mp4BackDropDownData.push({value: value, available: true})
    })
    return mp4BackDropDownData
  }
  /////////////////////////////////////////////////////////////////////
  function buildStaticPoseJobConfigScreen() {
    const mp4BackDropDownData = createMp4BackOptions()
    const faceSupported = isFaceTrackingSupported(STATE.animJobSettings.customModelInfo.id)
    return (
      <div className="mb-2 pb-4">
        <div className="columns mt-1 mb-1">
          <div className="column">
            <YesNoSlider 
              label="JPG Output"
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'formats': createNewOutputFormatsObj('jpg', event.target.checked)} }} ) }
              tipText={Enums.toolTipJpg}
              tipId="jpg-output"
              isDisabled={false}
              value={STATE.animJobSettings.formats.jpg}
            />
          </div>
          <div className="column">
            <YesNoSlider 
              label="PNG Output"
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'formats': createNewOutputFormatsObj('png', event.target.checked)} }} ) }
              tipText={Enums.toolTipPng}
              tipId="png-output"
              isDisabled={false}
              value={STATE.animJobSettings.formats.png}
            />
          </div>
        </div>
        <div className="columns mt-1 mb-1">
          <div className="column">
            <YesNoSlider 
              label="Physics Filter"
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'physicsSim': event.target.checked} }} ) }
              tipText={Enums.toolTipPhysSim}
              tipId="phys-filter"
              isDisabled={false}
              value={STATE.animJobSettings.physicsSim}
            />
          </div>
          <div className="column">
            <YesNoSlider 
              label="Root Joint at Origin"
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'rootJointAtOrigin': (event.target.checked ? 1 : 0 ) } }} ) }
              tipText={Enums.toolTipRootJoint}
              tipId="root-joint-at-origin"
              isDisabled={STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id ? true : false}
              value={STATE.animJobSettings.rootJointAtOrigin}
            />
          </div>
        </div>

        <div className="columns mt-1 mb-1">
          <div className="column">
            <YesNoSlider 
              label={mp4EnableShadowTitle}
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'shadow': event.target.checked} }} ) }
              tipText={Enums.toolTipShadow}
              tipId="enable-mp4-shadow"
              isDisabled={false}
              value={STATE.animJobSettings.shadow}
            />
          </div>
          <div className="column">
            <YesNoSlider 
              label={STATE.animJobSettings.jobType === Enums.jobTypes.animation ? Enums.mp4EnableSplitTitle : Enums.mp4EnableSplitTitlePose}
              onChangeFunc={(event)=>changeMP4IncludeOriginalVideo(event.target.checked)}
              tipText={Enums.toolTipIncludeVid}
              tipId="include-original"
              isDisabled={false}
              value={STATE.animJobSettings.sbs}
            />
          </div>
        </div>

        <div className="columns mt-1 mb-1">
          <div className="column">
            <DMDropDown
              label={Enums.dropDownLabels.mp4BkgdOption}
              value={(STATE.animJobSettings.backdrop ? Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.studio) : (STATE.animJobSettings.greenScreen ? Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.solid) : Enums.capitalizeFirstLetter(Enums.mp4BkgdOption.off)) )}
              onChange={changeCustomBkgdSelect}
              data={mp4BackDropDownData}
              tipText={Enums.toolTipMp4BackType}
              isTipHtml={true}
              isDisabled={ STATE.animJobSettings.sbs }
            />
          </div>
          <div className="column max-col-height is-align-items-center is-flex">
            {buildBackgroundColorSelect()}
          </div>
        </div>
        <div className="columns mt-1 mb-1">
          <div className="column">
            <YesNoSlider 
              label="Face Tracking"
              onChangeFunc={(event)=> DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'trackFace': (event.target.checked ? 1 : 0 ) } }} ) }
              tipText={Enums.toolTipFaceTrack}
              tipId="face-track"
              isDisabled={!faceSupported}
              value={STATE.animJobSettings.trackFace}
            />
          </div>
          <div className="column">
          </div>
        </div>
      </div>
    )
  }
  /////////////////////////////////////////////////////////////////////
  // builds the Recommendations / Best Results info area
  /////////////////////////////////////////////////////////////////////
  function InformationArea() {
    return (
      <div className="columns rounded-corners has-background-warning-light info-area mx-4 mt-6" >
        <div className="column">
          <div className="columns">
            <div className="column has-text-centered">
              <h3 className="title is-4">For best results:</h3>
            </div>
          </div>
          <div className="columns">
            <div className="column has-text-left" style={{marginTop:'10px'}}>
              <div className="content">
                <p className="subtitle is_4"><strong>Camera</strong>: Should be stationary and parallel to your subject</p>
                <p className="subtitle is_4"><strong>Character Placement</strong>: The entire body or the upper body from head to waist should be visible and located 2-6 meters (6-20 feet) from the camera</p>
                <p className="subtitle is_4"><strong>Lighting</strong>: Neutral lighting with high contrast between the subject and the background is recommended</p>
              </div>
            </div>
            
            <div className="column has-text-left" style={{marginTop:'10px'}}>
              <div className="content">
                <p className="subtitle is_4"><strong>Occlusion</strong>: The subject should not be occluded by any objects and there should be a single subject in the motion clip</p>
                <p className="subtitle is_4"><strong>Clothing</strong>: Do not wear loose clothing or clothing that covers key joints like knees and elbows</p>
                <p className="subtitle is_4"><strong>Face Tracking</strong>: Face Tracking is supported for full body and half body tracking modes, though for better results half body is recommended</p>
              </div>
            </div>
          </div>
          <div className="is-divider" style={{width:'300px', height:'2px', color:'gray',marginBottom:'10px'}}></div>
          <div className="columns">
            <div className="column has-text-centered">
              <h3 className="subtitle is-5 mb-3 is-underlined">Need Help?</h3>
              <h4 className="subtitle is-5">If you have questions or need further support please contact <a href="mailto:support@deepmotion.com">support@deepmotion.com</a></h4>
            </div>
          </div> 
        </div>
      </div>
    )
  }

  function buildMp4ColorPickerDropDown() {
    let colorData = {
      r: STATE.animJobSettings.bgColor[0],
      g: STATE.animJobSettings.bgColor[1],
      b: STATE.animJobSettings.bgColor[2]
    }
    const colorStr = `rgb(${colorData.r}, ${colorData.g}, ${colorData.b})` 
    let includeMp4Check = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? false : !STATE.animJobSettings.formats.mp4
    return (
      <div className="columns">
        <div className="column">
          <DMDropDownColorPicker 
            label="Background Color"
            data={(!STATE.animJobSettings.bgColor || STATE.animJobSettings.bgColor === 'n/a') ? defaultGSColor : STATE.animJobSettings.bgColor }
            value={STATE.animJobSettings.bgColor}
            onChange={handleBkgdColorChange}
            isDisabled={ STATE.animJobSettings.sbs || includeMp4Check || (!STATE.animJobSettings.greenScreen && !STATE.animJobSettings.backdrop) }
            tipText={Enums.toolTipMp4BackColor}
            isTipHtml={true}
          />
        </div>
      </div>
    )
  }
  /////////////////////////////////////////////////////////////////////
  function buildBackgroundColorSelect() {
    let skipMp4Check = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose ? true : STATE.animJobSettings.formats.mp4
    return (
      <React.Fragment>
        { 
          skipMp4Check && (STATE.animJobSettings.greenScreen || STATE.animJobSettings.backdrop) && !STATE.animJobSettings.sbs
          ?
          buildMp4ColorPickerDropDown()
          :
          <div className="green-screen-box m-0 disabled-item no-cursor" ></div>
        }
      </React.Fragment>
    )
  }
  /////////////////////////////////////////////////////////////////////
  function isFaceTrackingSupported(modelId) {
    if( !modelId || modelId === 'standard' ) {
      // true for default characters
      return true
    }
    return (getModelDataById(modelId, true)).faceDataType
  }

  /////////////////////////////////////////////////////////////////////
  // Builds the drag & drop tile area for adding a motion clip
  /////////////////////////////////////////////////////////////////////
  function buildAddMotionClipArea() {

    const addIcon = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? iconMotionClip : iconImage
    const addText = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? textAddMotionClip : textAddImage
    const acceptedTypes = STATE.animJobSettings.jobType === Enums.jobTypes.animation ? Enums.textAcceptedClipTypes : Enums.textAcceptedImgTypes

    return (
      <div className="column box disabled-switch m-3 mt-3 p-4">
        <div className="columns m-0">
          <div className="column has-text-left bottom-border">
            <span className="title is-5 has-text-white"><span className="icon" style={{marginRight:'10px'}}><i className={addIcon}></i></span><span>{addText}</span></span>
          </div>
        </div>
        <div className="columns m-0">
          <div className="column mt-0" style={{minHeight:'175px'}}>
            <DragZone onFilesAdded={uploadOnchange} animJobSettings={STATE.animJobSettings} />
          </div>
        </div>
        <div className="columns m-0">
          <div className="column has-text-left pt-1 pb-1">
            <h5 className="subtitle is-6 pt-0 mt-0 has-text-white"> {acceptedTypes} </h5>
          </div>
        </div>
      </div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Displays users animation minutes balance/usage
  /////////////////////////////////////////////////////////////////////
  function buildRemainingMinutesMeter(percentage) {
    // convert usage percent to remaining
    const remainingPct = 100 - percentage
    let color = null
    if( STATE.accountTotals.remainingTimeInSeconds <= Enums.accountPlansInfo[0].minsInt*60 ) {
      color = '#fab03c'
    }
    else {
      color = setProductSkuColor()
    }

    let remainingTime = String(STATE.currentBillCycleInfo.remainingMonthlyTime)
    if( remainingTime.substring(0,3) === "00:" ) {
      // only show minutes and seconds if less than 1 hour of time
      remainingTime = remainingTime.substring(3, remainingTime.length)
    }
    return (
      <ProgressProvider valueStart={0} valueEnd={remainingPct}>
        {(value) =>
        <CircularProgressbarWithChildren className=""
          value={value}
          text={remainingPct+"%"}
          maxValue={100}
          minValue={0}
          strokeWidth={12}
          circleRatio={0.75}
          styles={buildStyles({
            strokeLinecap: 'butt',
            rotation: 1 / 2 + 1 / 8,
            textSize: '16px',
            pathTransitionDuration: 0.75,
            pathColor: (percentage >= 100) ? 
              '#f14668' : setProductSkuColor(),
            textColor: 'white',
            trailColor: '#d6d6d6',
            backgroundColor: '#fff'
          })}
        >
      </CircularProgressbarWithChildren>
      }
      </ProgressProvider>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Calculates the minutes meter path color based on percentage
  /////////////////////////////////////////////////////////////////////
  function setProductSkuColor() {
    let pathColor = Enums.accountPlansInfo[0].skuColor
    if( STATE.subscriptionInfo.status !== 'active' && STATE.subscriptionInfo.status !== 'past_due' ) {
      // default path color used for Freemium accounts:
      document.body.style.setProperty('--dm-progress-color', pathColor)
      return pathColor
    }
    else {
      for( let i = 0; i < Enums.accountPlansInfo.length; i++ ) {
        if( Enums.accountPlansInfo[i].name === STATE.subscriptionInfo.name ) {
          pathColor = Enums.accountPlansInfo[i].skuColor
        }
      }
      document.body.style.setProperty('--dm-progress-color', pathColor)
      return pathColor
    }
  }
  /////////////////////////////////////////////////////////////////////
  function getProductColorCSSClass() {
    let productSkuColorClass = 'dm-progress-bar-freemium'
    if( !STATE.subscriptionInfo || !STATE.subscriptionInfo.name ) {
      return productSkuColorClass
    }
    switch(STATE.subscriptionInfo.name) {
      case Enums.accountPlansInfo[1].name:
        productSkuColorClass = 'dm-progress-bar-starter'
        break
      case Enums.accountPlansInfo[2].name:
        productSkuColorClass = 'dm-progress-bar-innovator'
        break
      case Enums.accountPlansInfo[3].name:
        productSkuColorClass = 'dm-progress-bar-professional'
        break
      case Enums.accountPlansInfo[4].name:
        productSkuColorClass = 'dm-progress-bar-studio'
        break
    }
    return productSkuColorClass
  }

  /////////////////////////////////////////////////////////////////////
  function resetVideoUploadInfo(cb) {
    DISPATCH({
      silentUploadInProgress: false,
      videoFileName: null,
      videoStorageUrl: null,
    }, () => {
      if( cb ) {
        cb()
      }
    })
  }
  /////////////////////////////////////////////////////////////////////
  function checkJobDownloadHasCustomCharacter() {
    return ((STATE.animJobLinks && STATE.animJobLinks.downloadLinks) ? STATE.animJobLinks.downloadLinks.customMode : false)
  }
  /////////////////////////////////////////////////////////////////////
  function goToMotionClipSelectPage() {
    history.push(Enums.routes.Anim3dCreate)
  }
  /////////////////////////////////////////////////////////////////////
  function goToUploadCharacterPage() {
    history.push(Enums.routes.Anim3dModelUpload)
  }
  /////////////////////////////////////////////////////////////////////
  // redirects user to account closed page upon account de-activation
  /////////////////////////////////////////////////////////////////////
  function closedAccount() {
    console.log("Redirecting to account closed page.")
    DISPATCH({confirmDialogId: Enums.confirmDialog.none})
    removeLocalStorageData( () => {
      history.push(Enums.routes.ClosedAccount)
    })
  }

  ////////////////////////////////////////////////////////////////////
  // displayVideoValidationErrors() : Displays a user friendly dialog
  // that displays one or more errors re: input video validation or
  // various limits/features exceeded for the account/subscription. 
  // 
  // @param errorFlagsObj : An object of key/boolean fields indicating
  // which error(s) may have occurred.  For example, video is both 
  // too long (maxDuration: true) and it exceed FPS limit (maxFps: true)
  ////////////////////////////////////////////////////////////////////
  function displayVideoValidationErrors(errorFlagsObj) {
    let tmpObj = STATE.inputVideoData
    if( !tmpObj || tmpObj === undefined ) {
      tmpObj = {}
    }
    tmpObj.errorFlags = errorFlagsObj    
    DISPATCH({
      isJobInProgress: false,
      currWorkflowStep: Enums.uiStates.initial,
      inputVideoData: tmpObj,
      confirmDialogId: Enums.confirmDialog.VideoValidationFailed,
      silentUploadInProgress: false,
      videoStorageUrl: null,
      videoFileName: null
    })
  }

  ////////////////////////////////////////////////////////////
  // Opens the rerun screen from library and preview pages
  // 
  // @param rid : job id of the selected job to rerun
  ////////////////////////////////////////////////////////////
  function displayReRunModal(rid) {
    getUserPlanData(true)
    .then( res => updateUserPlanData({...res.data, ...{subcriptionInfo: STATE.subscriptionInfo}}) )
    .then( res => {
      const jobInfo = getJobDetailsById(rid)
      let initialRerunSettings = {...Enums.JOB_SETTINGS_TEMPLATE, ...jobInfo.settings}
      initialRerunSettings.customModelInfo = {id: jobInfo.customModel}
      DISPATCH({
        animJobId: rid,
        accountTotals: res.accountTotals,
        rerunSettings: initialRerunSettings,
        confirmDialogId: Enums.confirmDialog.reRunJobConfig
      })
    })
  }

  ////////////////////////////////////////////////////////////
  // Displays animation settings table for a given job. If
  // params.reRun is true then displays a third column for
  // changing the setting
  ////////////////////////////////////////////////////////////
  function buildAnimationSettingsTable(params) {
    const jobUsesCustomModel = (params.modelInfo !== 'standard')
    if( params.reRun ) {
      return buildAnimSettingsRerunView(params, jobUsesCustomModel)
    }
    else {
      return buildAnimSettingsSummaryView(params, jobUsesCustomModel)
    }
  }

  ////////////////////////////////////////////////////////////
  // Displays job video output settings
  ////////////////////////////////////////////////////////////
  function buildVideoSettingsTable(params) {
    if( params.reRun ) {
      return buildVideoSettingsRerunView(params)
    }
    else {
      return buildVideoSettingsSummaryView(params)
    }
  }

  ////////////////////////////////////////////////////////////
  // Builds section for animation output file formats
  ////////////////////////////////////////////////////////////
  function buildOutputFormatsTable(formats) {
    let formatsArray = formats.split(',')
    let contentList = []
    for( let i = 0; i < formatsArray.length; i++) {
      contentList.push(
        <div className="column m-1 py-5 fade-in has-text-centered has-text-weight-semibold" key={formatsArray[i]}>
          <span className="icon dm-brand-font is-large fa-4x">
            <i className="fas fa-file-video" aria-hidden="true"></i>
          </span>
          <div className="subtitle mt-2 dm-brand-font has-text-weight-semibold is-uppercase">{formatsArray[i]}</div>
        </div>
      )
    }
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan={formatsArray.length} >Output Formats</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="columns">
                <div className={`column ${(contentList.length === 2 ? "is-6" : "")}`}>
                  <div className="columns"> 
                    {contentList}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Builds dynamic actions dropdown for each job row of the library
  // @param rid : ID of the job we're building the dropdown for
  // @param jobData : job settings and metadata for a given job
  /////////////////////////////////////////////////////////////////////
  function buildActionsDropDown(rid, jobData) {
    return (
      <div className="dropdown is-hoverable btn-shadow br-4 is-right">
        <div className="dropdown-trigger">
          <button className="button preview-btn" onMouseDown={(e)=>e.preventDefault()} aria-haspopup="true" aria-controls="dropdown-menu">
            <span className="icon is-small"><i className="fas fa-ellipsis-v fa-lg"></i></span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content has-text-left">
            <a onClick={()=>handleDownloadJobClick(rid)} className="dropdown-item">
              Download Animations
            </a>
            <a onClick={()=>displayJobSettingsModal(rid)} className="dropdown-item">
              View Job Settings
            </a>
            <React.Fragment>
              <span>
                <a onClick={()=>displayReRunModal(rid)} className="dropdown-item" >
                  Rerun
                  <span className="dm-brand-font" style={{fontSize:'1rem'}}>
                  <i className="fas fa-info-circle ml-2"
                    data-for="tooltip-rerun"
                    data-border={true}
                    data-border-color="black"
                    data-tip
                    data-text-color="#2d4e77"
                    data-background-color="white">
                  </i>
                  </span>
                </a>
              </span>
              <ReactTooltip className="tip-max-w" id="tooltip-rerun" place="left" effect="solid">
                <div className="subtitle">
                  <div dangerouslySetInnerHTML={{__html: Enums.tooltipReRun}} />
                </div>
              </ReactTooltip>
            </React.Fragment>
            <hr className="mt-2 mb-2"/>
            <a onClick={()=>handleDeleteJobClick(rid, jobData.name, jobData.lengthRaw, jobData.size, jobData.dateRaw)} className="dropdown-item has-text-danger" >
              Delete
            </a>
          </div>
        </div>
      </div>
    )
  }

  ////////////////////////////////////////////////////////////
  function displayJobSettingsModal(rid) {
    DISPATCH({animJobId: rid, confirmDialogId: Enums.confirmDialog.libraryJobSettings})
  }

  ////////////////////////////////////////////////////////////
  // renders a simple column based meter that gives the user
  // a visual idea of how much time the new anim will take 
  // wrt to their remaining time for current cycle
  ////////////////////////////////////////////////////////////
  function buildTimeMeterForNewJob(settings) {
    // calculate percentage new anim will take out of user's remaining monthly time
    let newAnimTimePercent = 0
    if( (STATE.currentBillCycleInfo.remainingRounded - settings.inputVideoData.fileLength) < 1 ) {
      newAnimTimePercent = 100
    }
    else {
      newAnimTimePercent = Math.ceil(settings.inputVideoData.fileLength / STATE.currentBillCycleInfo.remainingRounded * 100)
    }
    return (
      <React.Fragment>
      <div className="columns m-0 my-2 px-2 py-4" style={{height:'48px'}}>
        <div className="column progress-time-remain">
          <div className={newAnimTimePercent >= 100 ? "progress-time-new-full" : "progress-time-new"} style={{width:newAnimTimePercent.toString()+"%"}} />
        </div>
      </div>
      <div className="columns m-0 pb-3">
        <div className="column p-1 has-text-centered">
          <div className="subtitle is-6">
            This animation will use <span className="dm-brand-font has-text-weight-semibold">{Math.ceil(STATE.inputVideoData.fileLength)} out of {STATE.currentBillCycleInfo.remainingRounded} </span> second(s) from your account balance
          </div>
        </div>
      </div>
      </React.Fragment>
    )
  }

  ////////////////////////////////////////////////////////////
  // used in new animation confirmation dialog & library job 
  // settings view
  //
  // @param rid : if rerun the rid of job to rerun
  // @param displayMinsMeter : displays anim time bank if true
  ////////////////////////////////////////////////////////////
  function buildJobSummaryInfoTables(rid, displayMinsMeter) {

    let jobDetails = null
    let settings = null
    let lengthRaw = null 
    let animName = null 
    let customModel = null

    // new anim job if rid is null or 0
    if( !rid ) {
      settings = JSON.parse(JSON.stringify(STATE.animJobSettings ))
      settings.inputVideoData = STATE.inputVideoData
      
      jobDetails = {}
      jobDetails.lengthRaw = STATE.inputVideoData.fileLength
      jobDetails.customModel = (STATE.animJobSettings.customModelInfo && STATE.animJobSettings.customModelInfo.id ? STATE.animJobSettings.customModelInfo.id : 'standard')
      jobDetails.name = STATE.inputVideoData.fileName
      jobDetails.date = "" // only valid for rerun jobs
      jobDetails.jobType = STATE.animJobSettings.jobType
    }
    // otherwise it's a rerun animation job
    else {
      jobDetails = getJobDetailsById(rid)
      for (const [key, value] of Object.entries(Enums.JOB_SETTINGS_TEMPLATE)) {
        if( !(key in jobDetails.settings) || jobDetails.settings[key] === undefined ) {
          jobDetails.settings[key] = value
        }
      }
      settings = jobDetails.settings
    }

    if( Object.keys(settings).length === 0 && settings.constructor === Object ) {
      return(
        <React.Fragment>
        <div className="columns">
          <div className="column">
            <div className="notification subtitle is-info is-light">
              Job settings are not available for animations created before July 25, 2021.
            </div>
          </div>
        </div>
        </React.Fragment>
      )
    }
    else {
      const settingFlags = []
      settingFlags.push(
        <span key="setting-on" className="icon is-medium"><i className="fas fa-check-circle has-text-success fa-lg"></i></span>
      )
      settingFlags.push(
        <span key="setting-off" className="icon is-medium"><i className="fas fa-times-circle has-text-danger fa-lg"></i></span>
      )
      let outputFormatsList = []
      for (const [key, value] of Object.entries(settings.formats)) {
        if(value === true) {
          outputFormatsList.push(key)
        }
      }
      outputFormatsList = outputFormatsList.join(', ')
      if( jobDetails.customModel !== 'standard' ) {
        // prepend glb format if custom character present
        outputFormatsList = "glb, " + outputFormatsList
      }

      let mp4Enabled = outputFormatsList.includes('mp4')

      // build parameters object for below functions: 
      const params = {
        settings: settings,
        duration: jobDetails.lengthRaw,
        animName: jobDetails.name,
        cDate: jobDetails.dateRaw,
        outputFormatsList: outputFormatsList,
        mp4Enabled: mp4Enabled,
        modelInfo: jobDetails.customModel,
        showFullDate: (!rid ? false : true),
        jobType: jobDetails.jobType
      }

      return (
        <div>

        {/*** Display summary info including animation name, date, length: ***/}
        {buildAnimationHeaderSection(params)}

        {
          displayMinsMeter
          &&
          buildTimeMeterForNewJob(settings)
        }

        {/*** Display Input Info, animation settings, and video settings tables: ***/}

        { 
          jobDetails.jobType === Enums.jobTypes.staticPose 
          ?
          <div className="p-5">
            {buildStaticPoseSettingsTable(params)}
            {buildOutputFormatsTable(outputFormatsList)}
          </div>
          :
          <div className="p-5">
            {buildAnimationSettingsTable(params)}
            {buildVideoSettingsTable(params)}
            {buildOutputFormatsTable(outputFormatsList)}
          </div>
        }

        </div>
      )
    }
  }

  ////////////////////////////////////////////////////////////
  function buildAnimationHeaderSection(params) {
    if( !params.animName ) {
      console.log(`warning - invalid params.animName passed to buildAnimationHeaderSection()`)
      return
    }
    let cDate = null
    let jobName = params.animName
    if( params.showFullDate ) {
      // display full date including time localized to user's region
      cDate = new Date(params.cDate).toLocaleString( Enums.getNavigatorLanguage() )
    }
    else {
      cDate = params.cDate
    }
    if( params.animName.includes('.') ) {
      jobName = params.animName.split('.').slice(0, -1).join('.')
    }

    return (
      <div className="columns m-0 p-0 fullwidth">
        <div className="column m-0 p-0 pl-5 pr-5 notification is-info is-light has-text-left">
          <div className="title is-5 pt-5"> {jobName} </div>
          <div className="subtitle is-6 pb-5"> {cDate} </div>
        </div>
        <div className="column is-4 left-border m-0 p-0 notification is-info is-light flex-vert-center has-text-centered">
          <div className="title pl-2 pr-2 is-5"> {params.duration.toFixed(2)} sec </div>
        </div>
      </div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  // Displays info on input motion video
  /////////////////////////////////////////////////////////////////////
  function buildInputInfoTable(params) {
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan="2" >Input Video</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-half">Name</td>
            <td className="has-text-weight-semibold">{params.settings.inputVideoData ? params.settings.inputVideoData.fileName : ""}</td>
          </tr>
          <tr>
            <td className="td-half">Length</td>
            <td className="has-text-weight-semibold">{params.settings.inputVideoData ? params.settings.inputVideoData.fileLength.toFixed(2) : ""}</td>
          </tr>
          <tr>
            <td className="td-half">Resolution</td>
            <td className="has-text-weight-semibold">{ params.settings.inputVideoData && params.settings.inputVideoData.videoRes ? (params.settings.inputVideoData.videoRes.w + " x " + params.settings.inputVideoData.videoRes.h) : "n/a" }</td>
          </tr>
          <tr>
            <td className="td-half">FPS</td>
            <td className="has-text-weight-semibold">{ params.settings.inputVideoData && params.settings.inputVideoData.fps ? params.settings.inputVideoData.fps : "n/a" }</td>
          </tr>
        </tbody>
      </table>
    )
  }

  ////////////////////////////////////////////////////////////
  function buildStaticPoseSettingsTable(params) {
    const jobUsesCustomModel = (params.modelInfo !== 'standard')
    if( params.reRun ) {
      return buildStaticPoseRerunView(params, jobUsesCustomModel)
    }
    else {
      return buildStaticPoseSummaryView(params, jobUsesCustomModel)
    }
  }
  ////////////////////////////////////////////////////////////
  function buildStaticPoseSummaryView(params, jobUsesCustomModel) {
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan="2" >{text3DPoseTitle}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-half">Root Joint at Origin</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Physics Filter</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Custom Character</td>
            <td className="td-col-center has-text-weight-semibold"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Enable Shadow</td>
            <td className="td-col-center has-text-weight-semibold">{(params.settings.shadow && params.settings.shadow !== 'n/a' ? settingFlags[0] : settingFlags[1])}</td>
          </tr>
          <tr>
            <td className="td-half"> {Enums.mp4EnableSplitTitle} </td>
            <td className="td-col-center has-text-weight-semibold">{ (params.settings.sbs && params.settings.sbs !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
          </tr>
          <tr>
            <td className="td-half">Custom Background</td>
            <td className={"td-col-center has-text-weight-semibold " + (params.mp4Enabled ? "is-capitalized" : "")}>
              { params.settings.backdrop === true ? Enums.mp4BkgdOption.studio : (params.settings.bgColor && params.settings.bgColor !== 'n/a' ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off) }
            </td>
          </tr>
          <tr>
            <td className="td-half">Background Color</td>
            <td className="td-col-center has-text-weight-semibold">
              <span className="is-inline-flex">                  
                {/* Only show color box if bgColor is valid */}
                {
                  params.settings.bgColor === 'n/a'
                  ?
                  "n/a"
                  :
                  <React.Fragment>
                    <button 
                      className="button dm-brand-font" 
                      style={{backgroundColor:`rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`, width:'5rem', cursor:'auto'}}
                      data-for="bkgd-color-tip" 
                      data-border={true}
                      data-border-color="black"
                      data-tip
                      data-text-color="#2d4e77"
                      data-background-color="white"
                    />
                    <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                      <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                        rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                      </p>
                    </ReactTooltip>
                  </React.Fragment>
                }
              </span>
            </td>
          </tr>
          <tr>
            <td className="td-half">Face Tracking</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
        </tbody>
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildAnimSettingsSummaryView(params, jobUsesCustomModel) {
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan="3" >Animation Settings</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-half">Face Tracking</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Root Joint at Origin</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Physics Filter</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          <tr>
            <td className="td-half">Foot Locking</td>
            <td className="td-col-center has-text-weight-semibold is-capitalized">{params.settings.footLockMode}</td>
          </tr>
          <tr>
            <td className="td-half">Speed Multiplier</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.videoSpeedMultiplier}x </td>
          </tr>
          <tr>
            <td className="td-half">Motion Smoothing</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.poseFilteringStrength <= 0 ? "0.0" : params.settings.poseFilteringStrength } </td>
          </tr>
          <tr>
            <td className="td-half">Custom Character</td>
            <td className="td-col-center has-text-weight-semibold"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          {/* 
          <tr>
            <td className="td-half">FBX Frame Reducer</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.keyframeReducer ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
          */}
        </tbody>
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildVideoSettingsSummaryView(params) {
    let camModeClass = ""
    if( params.mp4Enabled && params.settings.camMode !== "n/a" ) {
      camModeClass = "is-capitalized"
    }
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan="2" >Video Output Settings</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-half">MP4 Enabled</td>
            <td className="td-col-center has-text-weight-semibold">{params.mp4Enabled ? settingFlags[0] : settingFlags[1]}</td>
          </tr>
          <tr>
            <td className="td-half">Enable Shadow</td>
            <td className="td-col-center has-text-weight-semibold">{!params.mp4Enabled ? 'n/a' : (params.settings.shadow && params.settings.shadow !== 'n/a' ? settingFlags[0] : settingFlags[1])}</td>
          </tr>
          <tr>
            <td className="td-half">Enable Audio</td>
            <td className="td-col-center has-text-weight-semibold">{!params.mp4Enabled ? 'n/a' : (params.settings.includeAudio && params.settings.includeAudio !== 'n/a' ? settingFlags[0] : settingFlags[1])}</td>
          </tr>
          <tr>
            <td className="td-half">Camera Mode</td>
            <td className={`td-col-center has-text-weight-semibold ${camModeClass}`}>{ !params.mp4Enabled ? 'n/a' : params.settings.camMode }</td>
          </tr>
          <tr>
            <td className="td-half">Include Original Video</td>
            <td className="td-col-center has-text-weight-semibold">{ !params.mp4Enabled ? 'n/a' : (params.settings.sbs && params.settings.sbs !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
          </tr>
          <tr>
            <td className="td-half">Custom Background</td>
            <td className={"td-col-center has-text-weight-semibold " + (params.mp4Enabled ? "is-capitalized" : "")}>
              { !params.mp4Enabled ? 'n/a' : ( params.settings.backdrop === true ? Enums.mp4BkgdOption.studio : (params.settings.bgColor && params.settings.bgColor !== 'n/a' ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off) )}
            </td>
          </tr>
          <tr>
            <td className="td-half">Background Color</td>
            <td className="td-col-center has-text-weight-semibold">
              <span className="is-inline-flex">                  
                {/* Only show color box if bgColor is valid */}
                {
                  params.settings.bgColor === 'n/a' || !params.mp4Enabled
                  ?
                  "n/a"
                  :
                  <React.Fragment>
                    <button 
                      className="button dm-brand-font" 
                      style={{backgroundColor:`rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`, width:'5rem', cursor:'auto'}}
                      data-for="bkgd-color-tip" 
                      data-border={true}
                      data-border-color="black"
                      data-tip
                      data-text-color="#2d4e77"
                      data-background-color="white"
                    />
                    <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                      <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                        rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                      </p>
                    </ReactTooltip>
                  </React.Fragment>
                }
              </span>
            </td>
          </tr>
        </tbody>  
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildVideoSettingsRerunView(params) {
    let camModeClass = ""
    if( params.mp4Enabled && params.settings.camMode !== "n/a" ) {
      camModeClass = "is-capitalized"
    }
    const mp4BackDropDownData = getMp4BackDropDownData()
    const cameraSettingsDropDownData = getCameraSettingsDropDownData()
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md">Video Output Settings</th>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md">Current</th>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md">New</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-40">MP4 Enabled
              <DMToolTip
                text={Enums.toolTipMp4}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-format-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{params.mp4Enabled ? settingFlags[0] : settingFlags[1]}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'formats': createNewOutputFormatsObj('mp4', event.target.checked, true)}}})} 
                tipText={Enums.toolTipMp4}
                isDisabled={!STATE.rerunSettings.formats.fbx}
                value={ STATE.rerunSettings.formats.mp4 && (STATE.rerunSettings.formats.mp4 === true)}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Enable Shadow
              <DMToolTip
                text={Enums.toolTipShadow}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-shadow-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{!params.mp4Enabled ? 'n/a' : (params.settings.shadow && params.settings.shadow !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider 
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'shadow': event.target.checked}}})}
                tipText={Enums.toolTipShadow}
                isDisabled={!STATE.rerunSettings.formats.mp4}
                value={STATE.rerunSettings.formats.mp4 ? STATE.rerunSettings.shadow : false}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Enable Audio
              <DMToolTip
                text={Enums.toolTipIncludeAud}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-audio-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{ !params.mp4Enabled ? 'n/a' : (params.settings.includeAudio && params.settings.includeAudio !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider 
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'includeAudio': event.target.checked}}})}
                tipText={Enums.toolTipIncludeAud}
                isDisabled={!STATE.rerunSettings.formats.mp4}
                value={(STATE.rerunSettings.formats.mp4 ? STATE.rerunSettings.includeAudio : false)}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Include Original Video
              <DMToolTip
                text={Enums.toolTipIncludeVid}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-include-video-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{ !params.mp4Enabled ? 'n/a' : (params.settings.sbs && params.settings.sbs !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider 
                value={STATE.rerunSettings.formats.mp4 ? STATE.rerunSettings.sbs : false}
                onChangeFunc={(event)=>changeMP4IncludeOriginalVideoReRun(event.target.checked)}
                tipText={Enums.toolTipIncludeVid}
                isDisabled={!STATE.rerunSettings.formats.mp4}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Camera Mode
              <DMToolTip
                text={Enums.tooltipMp4Camera}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-cam-mode-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className={`td-30 td-col-center has-text-weight-semibold ${camModeClass}`}>{ !params.mp4Enabled ? 'n/a' : params.settings.camMode }</td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDown
                value={STATE.rerunSettings.camMode === 'n/a' ? Enums.cameraMotionSettings[0] : STATE.rerunSettings.camMode}
                onChange={(val)=> DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'camMode': val}}}) }
                data={cameraSettingsDropDownData}
                isDisabled={!STATE.rerunSettings.formats.mp4}
                rightAlign={true}
              />
            </td>
          </tr>
          
          <tr>
            <td className="td-40">Custom Background
              <DMToolTip
                text={Enums.toolTipMp4BackType}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-background-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className={"td-30 td-col-center has-text-weight-semibold " + (params.mp4Enabled ? "is-capitalized" : "")}>{ !params.mp4Enabled ? 'n/a' : (params.settings.backdrop === true ? Enums.mp4BkgdOption.studio : (params.settings.bgColor && params.settings.bgColor !== 'n/a' ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off) )}</td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDown
                value={(STATE.rerunSettings.backdrop === true ? Enums.mp4BkgdOption.studio : ((STATE.rerunSettings.greenScreen === true ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off)) )}
                onChange={changeCustomBkgdSelectReRun}
                data={mp4BackDropDownData}
                isDisabled={ !STATE.rerunSettings.formats.mp4 || STATE.rerunSettings.sbs }
                rightAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Background Color
              <DMToolTip
                text={Enums.toolTipMp4BackColor}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="mp4-background-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">
              <span className="is-inline-flex">                  
                {
                  params.settings.bgColor === 'n/a' || !params.mp4Enabled
                  ?
                  'n/a'
                  :
                  <React.Fragment>
                    <button 
                      className="button dm-brand-font" 
                      style={{backgroundColor:`rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`, width:'5rem', cursor:'auto'}}
                      data-for="bkgd-color-tip" 
                      data-border={true}
                      data-border-color="black"
                      data-tip
                      data-text-color="#2d4e77"
                      data-background-color="white"
                    />
                    <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                      <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                        rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                      </p>
                    </ReactTooltip>
                  </React.Fragment>
                }
              </span>
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">
              <DMDropDownColorPicker
                data={(!STATE.rerunSettings.bgColor || STATE.rerunSettings.bgColor === 'n/a') ? Enums.defaultGSColor : STATE.rerunSettings.bgColor }
                value={STATE.rerunSettings.bgColor}
                onChange={handleBkgdColorChangeReRun}
                isDisabled={ !STATE.rerunSettings.formats.mp4 || STATE.rerunSettings.sbs || (!STATE.rerunSettings.sbs && !STATE.rerunSettings.greenScreen && !STATE.rerunSettings.backdrop) }
                rightAlign={true}
              /> 
            </td>
          </tr>
        </tbody>  
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildAnimSettingsRerunView(params, jobUsesCustomModel) {
    const footLockOptions = checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.footLocking)
    let footlockDropDownData = []
    Object.values(Enums.footLockMode).forEach((value, index)=> {
      footlockDropDownData.push({value: value, available: footLockOptions[index]})
    })
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" >Animation Settings</th>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" >Current</th>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" >New</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-40">Face Tracking
              <DMToolTip
                text={Enums.toolTipFaceTrack}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="face-tracking-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.trackFace ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH( {rerunSettings:  {...STATE.rerunSettings, ...{isRerun:true,'trackFace': (event.target.checked ? 1 : 0 )}} } )}
                toolTip={Enums.toolTipFaceTrack}
                isDisabled={false}
                value={STATE.rerunSettings.trackFace}
                centerAlign={true}
                noMargins={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Root Joint at Origin
              <DMToolTip
                text={Enums.toolTipRootJoint}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="root-joint-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold"> 
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'rootJointAtOrigin': (event.target.checked ? 1 : 0 ) }}})}
                toolTip={Enums.toolTipRootJoint}
                isDisabled={jobUsesCustomModel ? true : false}
                value={STATE.rerunSettings.rootJointAtOrigin}
                centerAlign={true}
                noMargins={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Physics Filter
              <DMToolTip
                text={Enums.toolTipPhysSim}
                tipId="physics-filter-tip"
                iconColor="#2d4e77"
                iconSize=".75rem"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold"> 
              <YesNoSlider 
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'physicsSim': event.target.checked}}})}
                toolTip={Enums.toolTipPhysSim}
                isDisabled={false}
                value={STATE.rerunSettings.physicsSim}
                centerAlign={true}
                noMargins={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Foot Locking
              <DMToolTip
                text={Enums.tooltipFootLocking}
                tipId="footlocking-tip"
                iconColor="#2d4e77"
                iconSize=".75rem"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold is-capitalized">{params.settings.footLockMode}</td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDown
                data={footlockDropDownData}
                value={STATE.rerunSettings.footLockMode}
                onChange={changeFootLockingSelectReRun}
                rightAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Motion Smoothing
              <DMToolTip
                text={Enums.toolTipSmoothness}
                tipId="smoothness-tip"
                iconColor="#2d4e77"
                iconSize=".75rem"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.poseFilteringStrength <= 0 ? "0.0" : (params.settings.poseFilteringStrength == 1 ? "1.0" : params.settings.poseFilteringStrength) } </td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDownKnob
                value={STATE.rerunSettings.poseFilteringStrength }
                // convert from [0-100] int range to float [0.0-1.0]
                onChange={(val)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'poseFilteringStrength': Math.abs( (parseFloat(val) / 100).toFixed(1) )}}})}
                isDisabled={ !checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.peSmoothness) }
                buttonClass={"dm-brand-font is-light"}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Speed Multiplier
              <DMToolTip
                text={Enums.toolTipVideSpeedMult}
                tipId="video-speed-tip"
                iconColor="#2d4e77"
                iconSize=".75rem"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.videoSpeedMultiplier}x </td>
            <td className="td-col-center has-text-weight-semibold"> 
              <DMDropDownKnob
                value={STATE.rerunSettings.videoSpeedMultiplier}
                // convert from [0-100] int range to float [1.0-8.0]
                onChange={(val)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'videoSpeedMultiplier': (Math.abs( (val / 100.0 ) * 7.0) + 1).toFixed(1)}}})}
                isDisabled={ !checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.slowMotion) }
                buttonClass={"dm-brand-font is-light"}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">Custom Character</td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold no-cursor"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
          </tr>

          {/* 
          <tr>
            <td className="td-40">FBX Frame Reducer
              <DMToolTip
                text={Enums.toolTipFBXKeyFrame}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="fbx-keyframe-reduce-tip"
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold"> {params.settings.keyframeReducer ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH( {rerunSettings:  {...STATE.rerunSettings, ...{'keyframeReducer': event.target.checked}}} )}
                toolTip={Enums.toolTipFBXKeyFrame}
                isDisabled={STATE.rerunSettings.formats.fbx ? false : true}
                value={STATE.rerunSettings.keyframeReducer}
                centerAlign={true}
              />
            </td>
          </tr>
          */}

        </tbody>
      </table>
    )
  }

  /////////////////////////////////////////////////////////////////////
  function buildStaticPoseRerunView(params, jobUsesCustomModel) {
    const mp4BackDropDownData = getMp4BackDropDownData()
    return (
      <table className="table is-bordered is-striped is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th className="has-background-info-light dm-brand-font dm-brand-border-md" colSpan="3" > {text3DPoseTitle} </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="td-40">JPG Enabled
              <DMToolTip
                text={Enums.toolTipJpg}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="jpg-format-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{params.outputFormatsList.includes('jpg') ? settingFlags[0] : settingFlags[1]}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'formats': createNewOutputFormatsObj('jpg', event.target.checked, true)}} })}
                tipText={Enums.toolTipJpg}
                value={ STATE.rerunSettings.formats.jpg && (STATE.rerunSettings.formats.jpg === true)}
                centerAlign={true}
                noMargins={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-40">PNG Enabled
              <DMToolTip
                text={Enums.toolTipPng}
                iconColor="#2d4e77"
                iconSize=".75rem"
                tipId="png-format-tip"
                isTipHtml={true}
                noMargins={true}
              />
            </td>
            <td className="td-30 td-col-center has-text-weight-semibold">{params.outputFormatsList.includes('png') ? settingFlags[0] : settingFlags[1]}</td>
            <td className="td-col-center has-text-weight-semibold">
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'formats': createNewOutputFormatsObj('png', event.target.checked, true)}} })}
                tipText={Enums.toolTipPng}
                value={ STATE.rerunSettings.formats.png && (STATE.rerunSettings.formats.png === true)}
                centerAlign={true}
                noMargins={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Root Joint at Origin</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.rootJointAtOrigin ? settingFlags[0] : settingFlags[1] } </td>
            <td>
              <YesNoSlider
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'rootJointAtOrigin': (event.target.checked ? 1 : 0 )}}} )}
                toolTip={Enums.toolTipRootJoint}
                isDisabled={jobUsesCustomModel ? true : false}
                value={STATE.rerunSettings.rootJointAtOrigin}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Physics Filter</td>
            <td className="td-col-center has-text-weight-semibold"> {params.settings.physicsSim ? settingFlags[0] : settingFlags[1] } </td>
            <td>
              <YesNoSlider 
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'physicsSim': event.target.checked}}})}
                toolTip={Enums.toolTipPhysSim}
                isDisabled={false}
                value={STATE.rerunSettings.physicsSim}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Enable Shadow</td>
            <td className="td-col-center has-text-weight-semibold">{(params.settings.shadow && params.settings.shadow !== 'n/a' ? settingFlags[0] : settingFlags[1])}</td>
            <td>
              <YesNoSlider 
                onChangeFunc={(event)=>DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'shadow': event.target.checked}}})}
                tipText={Enums.toolTipShadow}
                isDisabled={false}
                value={STATE.rerunSettings.shadow}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Include Original Video</td>
            <td className="td-col-center has-text-weight-semibold">{ (params.settings.sbs && params.settings.sbs !== 'n/a' ? settingFlags[0] : settingFlags[1] )}</td>
            <td>
              <YesNoSlider 
                value={STATE.rerunSettings.sbs}
                onChangeFunc={(event)=>changeMP4IncludeOriginalVideoReRun(event.target.checked)}
                tipText={Enums.toolTipIncludeVid}
                tipId="include-original-vid"
                isDisabled={false}
                centerAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Custom Background</td>
            <td className={"td-col-center has-text-weight-semibold " + (params.mp4Enabled ? "is-capitalized" : "")}>
              { params.settings.backdrop === true ? Enums.mp4BkgdOption.studio : (params.settings.bgColor && params.settings.bgColor !== 'n/a' ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off) }
            </td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDown
                value={(STATE.rerunSettings.backdrop === true ? Enums.mp4BkgdOption.studio : ((STATE.rerunSettings.greenScreen === true ? Enums.mp4BkgdOption.solid : Enums.mp4BkgdOption.off)) )}
                onChange={changeCustomBkgdSelectReRun}
                data={mp4BackDropDownData}
                isDisabled={ STATE.rerunSettings.sbs }
                rightAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Background Color</td>
            <td className="td-col-center has-text-weight-semibold">
              <span className="is-inline-flex">                  
                {/* Only show color box if bgColor is valid */}
                {
                  params.settings.bgColor === 'n/a'
                  ?
                  "n/a"
                  :
                  <React.Fragment>
                    <button 
                      className="button dm-brand-font" 
                      style={{backgroundColor:`rgb(${params.settings.bgColor[0]},${params.settings.bgColor[1]},${params.settings.bgColor[2]})`, width:'5rem', cursor:'auto'}}
                      data-for="bkgd-color-tip" 
                      data-border={true}
                      data-border-color="black"
                      data-tip
                      data-text-color="#2d4e77"
                      data-background-color="white"
                    />
                    <ReactTooltip className="tip-max-w" id="bkgd-color-tip" place="right" effect="solid">
                      <p className="subtitle is-5 dm-brand-font has-text-weight-semibold">
                        rgb({params.settings.bgColor[0]},{params.settings.bgColor[1]},{params.settings.bgColor[2]})
                      </p>
                    </ReactTooltip>
                  </React.Fragment>
                }
              </span>
            </td>
            <td className="td-col-center has-text-weight-semibold">
              <DMDropDownColorPicker
                data={(!STATE.rerunSettings.bgColor || STATE.rerunSettings.bgColor === 'n/a') ? Enums.defaultGSColor : STATE.rerunSettings.bgColor }
                value={STATE.rerunSettings.bgColor}
                onChange={handleBkgdColorChangeReRun}
                isDisabled={ STATE.rerunSettings.sbs || (!STATE.rerunSettings.sbs && !STATE.rerunSettings.greenScreen && !STATE.rerunSettings.backdrop) }
                rightAlign={true}
              />
            </td>
          </tr>
          <tr>
            <td className="td-half">Custom Character</td>
            <td className="td-col-center has-text-weight-semibold"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
            <td className="td-col-center has-text-weight-semibold"> {jobUsesCustomModel ? settingFlags[0] : settingFlags[1] } </td>
          </tr>
        </tbody>
      </table>
    )
  }
  /////////////////////////////////////////////////////////////////////
  function changeMP4IncludeOriginalVideo(value) {
    if( value ) {
      // if enabling side by side (ie. include original video) also disable
      // background color since cannot set solid background at same time
      DISPATCH({ animJobSettings: {...STATE.animJobSettings, ...{'sbs': value,'greenScreen': false}} } )
    }
    else {
      DISPATCH({ animJobSettings: {...STATE.animJobSettings, ...{'sbs': value}} } )
    }
  }
  /////////////////////////////////////////////////////////////////////
  function changeMP4IncludeOriginalVideoReRun(value) {
    if( value ) {
      // if enabling side by side (ie. include original video) also disable
      // background color since cannot set solid background at same time
      DISPATCH({ rerunSettings: {...STATE.rerunSettings, ...{'sbs': value,'greenScreen': false}} } )
    }
    else {
      DISPATCH({ rerunSettings: {...STATE.rerunSettings, ...{'sbs': value}} } )
    }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeFootLockingSelect(val, cb) {
    DISPATCH( {animJobSettings: {...STATE.animJobSettings, ...{'footLockMode': val}}} )
    if( cb ) { cb() }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeFootLockingSelectReRun(val, cb) {
    DISPATCH( {rerunSettings: {...STATE.rerunSettings, ...{'footLockMode': val}}} )
    if( cb ) { cb() }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeMp4OutputCameraMotion(value, cb) {
    DISPATCH({ animJobSettings: {...STATE.animJobSettings, ...{'camMode': value}} } )
    if( cb ) { cb() }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeMp4OutputCameraMotionReRun(value, cb) {
    DISPATCH({ rerunSettings: {...STATE.rerunSettings, ...{'camMode': value}} } )
    if( cb ) { cb() }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeCustomBkgdSelect(value, cb) {
    let newAnimSettings = STATE.animJobSettings
    if( value === Enums.mp4BkgdOption.off ) {
      newAnimSettings.greenScreen = false
      newAnimSettings.backdrop = false
    }
    else if( value === Enums.mp4BkgdOption.solid ) {
      newAnimSettings.greenScreen = true
      newAnimSettings.backdrop = false
    }
    else if( value === Enums.mp4BkgdOption.studio ) {
      newAnimSettings.greenScreen = false
      newAnimSettings.backdrop = true
    }
    DISPATCH({ animJobSettings: newAnimSettings} )
    if( cb ) { cb() }
  }
  ////////////////////////////////////////////////////////////////////////
  function changeCustomBkgdSelectReRun(value, cb) {
    let newRerunSettings = STATE.rerunSettings
    if( value === Enums.mp4BkgdOption.off ) {
      newRerunSettings.greenScreen = false
      newRerunSettings.backdrop = false
    }
    else if( value === Enums.mp4BkgdOption.solid ) {
      newRerunSettings.greenScreen = true
      newRerunSettings.backdrop = false
    }
    else if( value === Enums.mp4BkgdOption.studio ) {
      newRerunSettings.greenScreen = false
      newRerunSettings.backdrop = true
    }
    DISPATCH({ rerunSettings: newRerunSettings} )
    if( cb ) { cb() }
  }

  /////////////////////////////////////////////////////////////////////
  // Helper functions for setting animation output |formats| which 
  // is a property (ie key) of job state object
  /////////////////////////////////////////////////////////////////////
  function createNewOutputFormatsObj(format, val, isRerun) {
    let formatsObj = isRerun 
      ? JSON.parse(JSON.stringify(STATE.rerunSettings.formats))
      : JSON.parse(JSON.stringify(STATE.animJobSettings.formats))
    formatsObj[format] = (typeof(val) === undefined) ? false : val
    return formatsObj
  }

  /////////////////////////////////////////////////////////////////////
  function getMp4BackDropDownData() {
    let mp4BackDropDownData = []
    Object.values(Enums.mp4BkgdOption).forEach((value, index)=> {
      mp4BackDropDownData.push({value: value, available: true})
    })
    return mp4BackDropDownData
  }
  /////////////////////////////////////////////////////////////////////
  function getCameraSettingsDropDownData() {
    let cameraSettingsDropDownData = []
    Enums.cameraMotionSettings.forEach((value)=> {
      cameraSettingsDropDownData.push({value: value, available: true})
    })
    return cameraSettingsDropDownData
  }

  /////////////////////////////////////////////////////////////////////
  function handleBkgdColorChange(color) {
    // only 100% alpha allow currently
    DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'bgColor': [color.rgb.r, color.rgb.g, color.rgb.b, 1]} } })
  }
  /////////////////////////////////////////////////////////////////////
  function handleBkgdColorChangeReRun(color) {
    // only 100% alpha allow currently
    DISPATCH({rerunSettings: {...STATE.rerunSettings, ...{'bgColor': [color.rgb.r, color.rgb.g, color.rgb.b, 1]}} })
  }
  /////////////////////////////////////////////////////////////////////
  function resetSelectedInputVideo(newJobType) {
    if( STATE.silentUploadInProgress ) {
      setUploadCancelled(true)
    }
    const resetObj = {
      selectedFile: null,
      fileName: null,
      fileLength: null,
      fileSize: null,
      videoRes: null,
      fps: null,
      codec: null
    }
    let dispatchObject = {
      currWorkflowStep: Enums.uiStates.initial,
      videoStorageUrl: null,
      videoFileName: null,
      inputVideoData: resetObj,
      animJobSettings: {...STATE.animJobSettings, ...{jobType: newJobType ? 1 : 0}},
      confirmDialogId: Enums.confirmDialog.none
    }
    DISPATCH(dispatchObject)
  }

  /******************************************************************
   * performBackgroundVideoUpload() - called once input video is selected,
   *  starts uploading input video asynchoronously in the background
   *  while user continues job configuration. Once done we call video
   *  analyzer API to retrieve metadata including length and size.
   *
   * @param retry : if we should retry on auth error encountered
   ******************************************************************/
  function performBackgroundVideoUpload(retry) {
    return new Promise((resolve, reject) => {
      let storageUrl = null
      uploadJobDataToBackend(
        STATE.videoFileName, 
        STATE.inputVideoData.selectedFile,
        retry
      ).then( res => {
        if( uploadCancelled ) {
          setUploadCancelled(true)
          return resolve('upload cancelled by user.')
        }
        storageUrl = res.videoStorageUrl
        analyzeVideoInputData(res.videoStorageUrl, true)
        .then( res => {
          let newStateData = {}
          let inputData = STATE.inputVideoData
          if( !res.data || Object.keys(res.data).length === 0 || !res.data.duration || !res.data.width || !res.data.fps ) {
            // abort job if problem with request or could not read input
            // video time, resolution, or fps
            inputData.errorFlags = {videoReadFailure: true}
            newStateData = {...newStateData, ...{inputVideoData: inputData} }
            newStateData = {...newStateData, ...{videoStorageUrl: null} }
            newStateData = {...newStateData, ...{videoFileName: null} }
            newStateData = {...newStateData, ...{silentUploadInProgress: false} }
            reject('Video validation failed!')
          }
          else {
            let duration = res.data.duration
            if( typeof(res.data.duration) === 'string' ) {
              duration = parseFloat(res.data.duration)
            }
            inputData.fileLength = duration
            inputData.fileSize = res.data.size
            inputData.videoRes = {w: res.data.width, h: res.data.height}
            inputData.fps = res.data.fps
            inputData.codec = res.data.codec
            newStateData = {
              ...newStateData, 
              ...{inputVideoData: inputData}, 
              ...{videoStorageUrl: storageUrl} 
            }
            DISPATCH(newStateData)
            resolve('ok')
          }
        })
        .catch( (error) => {
          console.error(`Error detected during background upload process: ${error}`)
          reject(error)
        })
      })
      .catch( (error) => {
        console.error(`Error detected attempting to upload job input in background: ${error}`)
        reject(error)
      })
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Handle local file selection from the user
  /////////////////////////////////////////////////////////////////////
  function uploadOnchange(array) {
    const name = array[0].name
    const size = array[0].size

    // limit filename characters to standard Unicode UTF-8
    const santized = Enums.removeIllegalOrReservedCharacters(name)
    if( Enums.containsNonAsciiCharacters(name) || (santized.length !== name.length) ) {
      console.error(`Error: File name "${name}" contains invalid characters. Please rename your file and try again.`)
      DISPATCH({
        dialogInfo: {videoFileName: name},
        confirmDialogId: Enums.confirmDialog.inputFileNameInvalid
      })
      return
    }
    else if( name.length > Enums.MAX_FILENAME_LENGTH ) {
      console.error(`Error: File name "${name}" exceeds max length of ${Enums.MAX_FILENAME_LENGTH} characters. Please rename your file and try again.`)
      DISPATCH({
        dialogInfo: {videoFileName: name},
        confirmDialogId: Enums.confirmDialog.inputMaxLengthExceeded
      })
      return
    }

    // reject unwanted filetypes
    const splitExt = name.split('.')
    const ext = splitExt[splitExt.length-1].toLowerCase()
    let formatAccepted = false
    let acceptedFormatsListBasedOnJob = null
    if( STATE.animJobSettings.jobType === Enums.jobTypes.animation ) {
      acceptedFormatsListBasedOnJob = Enums.acceptedVideoFormats
    }
    else {
      acceptedFormatsListBasedOnJob = Enums.acceptedStaticPoseFormats
    }
    for( let i = 0; i < acceptedFormatsListBasedOnJob.length; i++ ){
      if( ext === acceptedFormatsListBasedOnJob[i] ){
        formatAccepted = true
        break
      }
    }
    if( !formatAccepted ) {
      console.error(`Error: File "${name}" is of invalid type. Accepted formats are ${JSON.stringify(acceptedFormatsListBasedOnJob)}`)
      DISPATCH({confirmDialogId: Enums.confirmDialog.inputFileTypeWrong, videoFileName: name})
      return
    }
    if( size > getMaxInputClipSize() ) {
      tmpSize = Enums.formatSizeUnits(size, 2)
      console.error(`Error: Max input clip size of ${Enums.formatSizeUnits(getMaxInputClipSize(), 2)} exceeded. Input clip size was `, tmpSize, ".\nAborting job request...")
      DISPATCH({dialogInfo: {size: tmpSize}, confirmDialogId: Enums.confirmDialog.inputFileTooBig})
      return
    }

    const video = document.createElement('video')
    video.src = URL.createObjectURL(array[0])
    let duration
    var wait = setTimeout( () => {
      let tmpObj = STATE.inputVideoData
      if( !tmpObj || tmpObj === 'undefined' ) {
        tmpObj = {}
      }
      tmpObj.selectedFile = array[0]
      tmpObj.fileName = name
      DISPATCH({
        currWorkflowStep: Enums.uiStates.fileSelected,
        inputVideoData: tmpObj,
        videoFileName: name,
        silentUploadInProgress: true
      })
    } , 100)
  }

  /////////////////////////////////////////////////////////////////////
  // resetDialogsData() - called when a job is cancelled or fails and also
  // when this component unmounts...
  /////////////////////////////////////////////////////////////////////
  function resetDialogsData(error, saveDuration) {
    // reset some local and global state hooks
    setActiveJobMenu(Enums.jobMenu.animSettings)
    setShowColorPickerDialog(false)
    // preserve video duration and name for dialogs if saveDuration = true
    const duration = saveDuration ? STATE.inputVideoData.fileLength  : null
    const fName = saveDuration ? STATE.inputVideoData.fileName  : null
    const resetVideoData = {
      selectedFile: null,       
      fileName: fName,
      fileLength: duration,
      fileSize: null,
      // save videoRes data for use in error dialogs if errorFlags present 
      videoRes: (STATE.inputVideoData && STATE.inputVideoData.errorFlags) ? STATE.inputVideoData.videoRes : null,
      fps: null,
      codec: null,
      // save temporary errorFlags if present
      errorFlags: (STATE.inputVideoData && STATE.inputVideoData.errorFlags) ? STATE.inputVideoData.errorFlags : null
    }
    DISPATCH({
      confirmDialogId: Enums.confirmDialog.none,
      currWorkflowStep: Enums.uiStates.initial,
      dialogInfo: {},
      animationJobProgress: 0,
      isJobInProgress: false,
      silentUploadInProgress: false
    })
    if(error) {
      // if error passed in propogate up to handleHttpError() in dashboard
      handleHttpError(error, "")
    }
    return true
  }

  /////////////////////////////////////////////////////////////////////
  // Removes user's application(s) access to any DM apps,
  // de-activates their account, and logs the user out
  /////////////////////////////////////////////////////////////////////
  async function closeUserAccount() {

    // first get idToken
    const token = await oktaAuth.tokenManager.get('idToken')
    console.log(`id token: ${token}`)

    console.log(`Attempting to close user account`)
    let reqBody = {
      idToken: token,
      uid: STATE.uid,
      email: STATE.email
    }
    axios.put(`${process.env.REACT_APP_API_URL}/closeUserAccount`, reqBody, {
      withCredentials: true
    })
    .then((res) => {
      // TODO: Re-direct to "Account Closed" landing page when done!
      console.log(`User account has been closed.\n${res}`)
      closedAccount()
    })
    .catch((error) => {
      //TODO: throw an error dialog 
      console.error(error)
    })
  }

  /////////////////////////////////////////////////////////////////////
  // Dynamically retrieves output animation types for a given job and
  // returns as an array of sorted strings per extension
  /////////////////////////////////////////////////////////////////////
  function retrieveJobOutputFileTypes() {
    if( !STATE.animJobId || STATE.animJobId === "" ) {
      return null
    }
    const jobDetails = getJobDetailsById( STATE.animJobId )
    const jobUsesRobloxModel = jobDetails.customModel === Enums.robloxModelId ? true : false
    let outputFileTypesList = []
    if( STATE.currDownloadLinks ) {
      let sortedLinks = Enums.sortObject(STATE.currDownloadLinks)
      for( const [key, value] of Object.entries(sortedLinks) ) {
        if( key.includes(Enums.animFileType.bvh) && !jobUsesRobloxModel ) {
          outputFileTypesList.push(Enums.animFileType.bvh.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.fbx) ) { 
          outputFileTypesList.push(Enums.animFileType.fbx.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.glb) && !jobUsesRobloxModel ) {
          outputFileTypesList.push(Enums.animFileType.glb.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.mp4) ) { 
          outputFileTypesList.push(Enums.animFileType.mp4.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.gif) ) { 
          outputFileTypesList.push(Enums.animFileType.gif.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.dmpe) ) { 
          outputFileTypesList.push(Enums.animFileType.dmpe.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.jpg) ) { 
          outputFileTypesList.push(Enums.animFileType.jpg.toUpperCase())
        }
        else if( key.includes(Enums.animFileType.png) ) { 
          outputFileTypesList.push(Enums.animFileType.png.toUpperCase())
        }
      }
      return outputFileTypesList
    }
  }

  ////////////////////////////////////////////////////////////////////
  // General error handling function for http errors
  // TODO: Refactor params to only use dialogInfo object
  ////////////////////////////////////////////////////////////////////
  function handleHttpError(error, title, dialogInfo) {
    if( dialogInfo.error && dialogInfo.message ) {
      setErrorDialogInfo(true, dialogInfo.message, dialogInfo.error)
    }

    // 400 - Bad request (usually a problematic model or video upload from user)
    if(error.response && error.response.status === Enums.eCodes.BadRequest) {
      console.log("There was a problem with your request, please try again. If the problem continues please contact service@deepmotion.com.")
      setErrorDialogInfo(true, Enums.eCodes.BadRequest, title)
    }
    // 401 - User unauthorized (token expired, deleted, or corrupted)
    else if(error.response && error.response.status === Enums.eCodes.Unauthorized) {
      console.log("User un-authenticated (token has expired or been removed), please sign in again.")
      logoutUser()
    }
    // 403 - Forbidden
    else if(error.response && error.response.status === Enums.eCodes.Forbidden) {
      console.log("403 - Access Forbidden.")
      setErrorDialogInfo(true, Enums.eCodes.Forbidden, title)
      return
    }
    // 404 - Not Found
    else if(error.response && error.response.status === Enums.eCodes.NotFound) {
      console.log("404 - Not Found")
      setErrorDialogInfo(true, Enums.eCodes.NotFound, title)
      return
    }
    // 408 - Request Timeout 
    else if(error.response && error.response.status === Enums.eCodes.RequestTimeout) {
      console.log("The request timed out, this could be due to networking/internet issues or possibly a server side problem. Please try your request again, if the problem continues contact service@deepmotion.com.")
      setErrorDialogInfo(true, Enums.eCodes.RequestTimeout, title)
    }
    // 444 - Closed No Response
    else if(error.response && error.response.status === Enums.eCodes.ClosedNoResponse) {
      console.log("The request was closed without a response, if this problem continues please contact service@deepmotion.com.")
      //TODO - pop modal
    }
    // 499 - Client Closed Request
    else if(error.response && error.response.status === Enums.eCodes.ClientClosedRequest) {
      console.log("The request was closed by the client, please try again. If the problem continues please contact service@deepmotion.com.")
      //TODO - pop modal
    }
    /*********************************************
      Server Side HTTP Errors
    **********************************************/
    // 500 - Internal Server Error
    else if(error.response && error.response.status === Enums.eCodes.InternalServerError) {
      console.log("Sorry there was an internal server error. Please try again, if the problem continues please contact service@deepmotion.com.")
      setErrorDialogInfo(
        true, 
        Enums.eCodes.InternalServerError, 
        Enums.customErrors[error.response.data.error], 
        error.response.data.message, () => {
          return
      })
    }
    // 502 - Bad Gateway
    else if(error.response && error.response.status === Enums.eCodes.BadGateway) {
      console.log("Sorry we encountered a networking error - bad gateway. Please try again, if the problem continues please contact service@deepmotion.com.")
      setErrorDialogInfo(true, Enums.eCodes.BadGateway, title)
    }
    // 503 - Service Unavailable
    else if(error.response && error.response.status === Enums.eCodes.ServiceUnavailable) {
      console.log("Sorry the service is currently unavailble, exiting application. If the problem continues please contact service@deepmotion.com.")
      logoutUser()
    }
    // 504 - Gateway Timeout
    else if(error.response && error.response.status === Enums.eCodes.GatewayTimeout) {
      console.log("Sorry we encountered a networking error - the gateway timed out. Please try again, if the problem continues please contact service@deepmotion.com.")
      //TODO - pop modal
    }
    // 507 - Insufficient Storage
    else if(error.response && error.response.status === Enums.eCodes.InsufficientStorage) {
      console.log("Sorry there was a problem, the server is reporting insufficient storage space. Please contact DeepMotion at service@deepmotion.com to resolve.")
      //TODO - pop modal
    }
    // Unknown error - i.e. status and error fields not present in response
    // (can occur due to CORS errors for instance)
    else {
      setErrorDialogInfo(true, Enums.eCodes.OtherError, title)
    }
  }

  /////////////////////////////////////////////////////////////////////
  // Error dialog setter 
  /////////////////////////////////////////////////////////////////////
  function setErrorDialogInfo(show, id, title, msg, cb) {
    let tmpObj = {show: show, id: id, title: !id ? "" : title, msg: !id ? "" : msg}
    DISPATCH({errorDialogInfo: tmpObj})
  }

  /////////////////////////////////////////////////////////////////////
  // logs the user out by redirecting to the login page
  /////////////////////////////////////////////////////////////////////
  async function logoutUser() {
    const signout = await oktaAuth.signOut()
    return
  }

  /////////////////////////////////////////////////////////////////////
  // Builds the side bar menu on left hand of screen
  /////////////////////////////////////////////////////////////////////
  function buildSideBarMenu() {
    return (
      <div/>
    )
  }

  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////
  // --- Custom Dialogs & related functions ---
  /////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////

  ///--------------------------------------------------------------------
  /// Modal for downloading animations using our standard cloud 
  /// character set (ie various female, male, and child models)
  ///
  /// @param mode : if true stops current preview and resets 
  ///   download links when closing the modal
  ///--------------------------------------------------------------------
  function DownloadDefaultCharacterModal(mode) {
    if( Object.keys(STATE.currDownloadLinks).length === 0 ) {
      console.log(`Warning: Animation download dialog invoked with invalid job download links: ${STATE.currDownloadLinks}`)
      return
    }
    if( !STATE.animJobId ) {
      console.log(`Warning: Animation download dialog invoked with invalid animJobId: ${STATE.animJobId}`)
      return
    }
    const jobDetails = getJobDetailsById( STATE.animJobId )
    const animName = jobDetails.name
    const duration = jobDetails.length
    const cDate = new Date(jobDetails.dateRaw).toLocaleString( Enums.getNavigatorLanguage() )
    const params = {
      animName: animName,
      duration: duration,
      cDate: cDate
    }
    
    return (
      <div id="modal-ter" className="modal is-active" >
        <div className="modal-background"></div>
        <div className="modal-card" id="anim-fadein" >
          <header className="modal-card-head m-0">
            <p className="modal-card-title"> Download Animations </p>
            <button className="delete" onClick={()=>closeModal()} aria-label="close"></button>
          </header>
          <section className={`modal-card-body m-0 p-0`} style={{overflowX:'hidden'}}>
            <div className="content">
              <div className="columns m-0">
                <div className="column p-0">
                  
                  {buildAnimationHeaderSection(params)}
                  <div className="columns m-0 fullwidth">

                    {/*** Build default character selector ***/}
                    <div className="column">
                      <div className="columns m-0">
                        <div className="column m-0 has-text-centered">
                          <CharacterSwiperDefault DISPATCH={DISPATCH} setCurrDownloadModel={setCurrDownloadModel}/>
                        </div>
                      </div>
                    </div>

                    {/*** Build Output File Type Dropdown ***/}
                    <div className="column">
                      <div className="columns m-0">
                        <div className="column has-text-centered">
                          {buildOutputFileTypeDropDown()}
                        </div>
                      </div>

                    {/*** Build Download Button ***/}
                      <div className="column">
                        <div className="columns m-0">
                          {buildAnimationDownloadButton()}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns m-0 fullwidth">
              <div className="column p-0">
                <div className="buttons is-right">
                  <button className="button is-white dm-brand-font" tabIndex="0" onClick={()=>closeModal()}> {textDialogClose} </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )

  }

  ///--------------------------------------------------------------------
  /// Modal for downloading animations using our standard cloud 
  /// character set (ie various female, male, and child models)
  ///
  /// @param mode : if true stops current preview and resets 
  ///   download links when closing the modal
  ///--------------------------------------------------------------------
  function DownloadCustomCharacterModal(mode) {
    const jobDetails = getJobDetailsById( STATE.animJobId )
    const textCustom = "(Custom)"
    const animName = jobDetails.name
    const duration = jobDetails.length
    const cDate = new Date(jobDetails.dateRaw).toLocaleString( Enums.getNavigatorLanguage() )
    const imgData = getModelDataById(jobDetails.customModel)
    let imgName = null
    let thumbImg = null
    // special case for face tracking, display our female face tracking model
    if( jobDetails.customModel === Enums.robloxModelId ) {
      imgName = "Roblox R15"
      thumbImg = imgRobloxR15
    }
    else if( jobDetails.settings.trackFace && jobDetails.customModel === "standard" ) {
      imgName = "Default Face Model"
      thumbImg = imgFaceModel
    }
    else {
      imgName = (imgData && imgData.name) ? imgData.name : textCustom
      thumbImg = (imgData && imgData.thumbImg instanceof Blob) ? URL.createObjectURL(imgData.thumbImg) : imgCustom
    }
    const params = {
      animName: animName,
      duration: duration,
      cDate: cDate
    }
    
    return(
      <div id="modal-ter" className="modal is-active" >
        <div className="modal-background"></div>
        <div className="modal-card" id="anim-fadein" >
          <header className="modal-card-head m-0">
            <p className="modal-card-title"> Download Animations </p>
            <button className="delete" onClick={()=>closeModal()} aria-label="close"></button>
          </header>
          <section className={`modal-card-body m-0 p-0`} style={{overflowX:'hidden'}}>
            <div className="content">
              <div className="columns m-0">
                <div className="column p-0">
                  {buildAnimationHeaderSection(params)}
                  <div className="columns m-0 fullwidth">

                    {/*** Show custom character image ***/}
                    <div className="column">
                      <div className="columns m-0">
                        <div className="column m-0">
                          <div className="card dm-brand-border-lg">
                            <div className="card-image">
                              <figure className="image is-16by9 m-0" style={{borderRadius:'4px'}}>
                                <img src={thumbImg} alt={`image-${imgName}`} />
                              </figure>
                              {
                                thumbImg === imgCustom
                                &&
                                <div className="thumbnail-unavail has-text-centered">
                                  <div className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                                    {textNotAvailable}
                                  </div>
                                </div>
                              }
                            </div>
                            <div className="card-content bShadow dm-brand has-text-centered">
                              <div className="media">
                                <div className="media-content">
                                  <p className="title is-5 mb-4 has-text-white">{imgName}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/*** Build Output File Type Dropdown ***/}
                    <div className="column">
                      <div className="columns m-0">
                        <div className="column has-text-centered">
                          {buildOutputFileTypeDropDown()}
                        </div>
                      </div>

                    {/*** Build Download Button ***/}
                      <div className="column">
                        <div className="columns m-0">
                          {buildAnimationDownloadButton()}
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns m-0 fullwidth">
              <div className="column p-0">
                <div className="buttons is-right">
                  <button className="button is-white dm-brand-font" tabIndex="0" onClick={()=>closeModal()}> {textDialogClose} </button>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>  
    )
  }

  ///--------------------------------------------------------------------
  /// Confirm deletion of custom character model
  ///--------------------------------------------------------------------
  function DeleteCustomCharacterModal() {
    let image = (STATE.dialogInfo.thumbImg instanceof Blob) ? URL.createObjectURL(STATE.dialogInfo.thumbImg) : imgCustom
    let cDate = new Date(STATE.dialogInfo.date).toLocaleString( Enums.getNavigatorLanguage() )
    const dialogBody = [
      <div key='remove-model-confirm'>
        <div className="columns m-0 pl-3 pr-3 fullwidth download-modal-bkgd">
          <div className="column is-1 mr-5">
            <span className="icon is-large">
              <i className="fas fa-trash-alt fa-3x has-text-white"></i>
            </span>
          </div>
          <div className="column p-0">
            <div className="columns m-0">
              <div className="column is-2 pb-1 m-0 has-text-right">
                <h1 className="subtitle is-6 has-text-white"> Name: </h1>
              </div>
              <div className="column pb-1 m-0 has-text-left">
                <h1 className="subtitle is-6 dm-brand-2-font"> {STATE.dialogInfo.name} </h1>
              </div>
            </div>
            <div className="columns m-0">
              <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                <h1 className="subtitle is-6 has-text-white">Created:</h1>
              </div>
              <div className="column pb-1 pt-1 m-0 has-text-left">
                <h1 className="subtitle is-6 dm-brand-2-font"> {cDate} </h1>
              </div>
            </div>
          </div>
        </div>
        <div className="columns m-4">
          <div className="column has-text-centered is-vcentered">
            <div className="is-relative" >
              <figure className="image bShadow is-16by9">
                <img src={image} alt={STATE.dialogInfo.name} className="img-outline" />
                {
                  !(STATE.dialogInfo.thumbImg instanceof Blob)
                  &&
                  <div className="thumbnail-unavail has-text-centered">
                    <div className="thumbnail-unavail-content dash-prod-btn subtitle is-4">
                      Thumbnail Not Available
                    </div>
                  </div>
                }
              </figure>
            </div>
          </div>
          <div className="column has-text-left">
            <p className="subtitle is_3">
              Are you sure you would like to delete this custom character? Once deleted the character can not be retrieved.
            </p>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Delete Character"
        content={dialogBody}
        msgFormat="html"
        label1={textDialogCancel}
        action1={ ()=>closeModal() }
        label2={textDialogDelete}
        action2={()=>deleteCustomModel(STATE.dialogInfo.modelId, STATE.dialogInfo.clbFunc, true)}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Not enough animation minutes modal:
  ///--------------------------------------------------------------------
  function buildNotEnoughMinutesModal() {
    let msg = `You do not have enough animation seconds left in your current billing cycle to create this animation.`
    const dialogBody = [
      <div key='not-enough-time-modal'>
        <div className="columns has-text-centered">
          <div className="column">
            <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
        </div>
        <div className="columns has-text-centered">
          <div className="column">
            <h2 className="subtitle is-5">{msg}</h2>
            <table className="table is-bordered is-striped is-fullwidth">
              <thead>
                <tr>
                  <th className="has-background-info-light" style={{width:'50%'}}>Input Video Length</th>
                  <th className="has-background-info-light">Remaining Animation Time</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{STATE.inputVideoData.fileLength}</td>
                  <td className="has-text-danger">{STATE.currentBillCycleInfo.remainingRounded + " sec"}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Not Enough Time Remaining"
        content={dialogBody}
        msgFormat="html"
        label1={textDialogUpgrade}
        action1={ ()=>closeModal() }
        custom1="showUpgradeButton"
        label2={textDialogOk}
        action2={()=>closeModal()}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Video validation failed dialog - can display multiple error(s)
  ///--------------------------------------------------------------------
  function buildVideoValidationFailed() {
    
    let msg = []
    let errorMsgs = [
      STATE.inputVideoData.fileLength ? `Not enough animation time available, video is ${STATE.inputVideoData.fileLength} seconds long but only ${STATE.currentBillCycleInfo.remainingRounded} second(s) of animation time remaining.` : "",
      STATE.inputVideoData.fileLength ? `Video length is ${STATE.inputVideoData.fileLength} seconds, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxDuration)} seconds.` : "",
      STATE.inputVideoData.fps ? `Video frames per second is ${STATE.inputVideoData.fps}, maximum allowed under your current plan is ${checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxFps)} FPS.` : "",
      STATE.inputVideoData.videoRes ? `Video resolution is ${STATE.inputVideoData.videoRes.w} x ${STATE.inputVideoData.videoRes.h}, maximum allowed under your current plan is ${(checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)).w} x ${(checkFeatureAvailability(STATE.subscriptionInfo.name, Enums.featureLockNames.maxResolution)).h}.` : "",
      `Could not read video data, it may be using an un-supported codec or the file may be corrupt. Please fix the video or use a different motion clip.`
    ]
    // find corresponding feature data for feature in question
    for (const [key, value] of Object.entries(STATE.inputVideoData.errorFlags)) {
      if( key === 'minsBalanceTooLow' && value === true ) {
        msg.push(
          <div className="notification is-danger is-light" key={errorMsgs[0]}>
            <div className="columns">
              <div className="column is-1 p-1 m-1">
                <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
              </div>
              <div className="column p-1 m-1">
                <div className="subtitle is-5">{errorMsgs[0]}</div>
              </div>
            </div>
          </div>
        )
      }
      if( key === 'maxDuration' && value === true ) {
        msg.push(
          <div className="notification is-danger is-light" key={errorMsgs[1]}>
            <div className="columns">
              <div className="column is-1 p-1 m-1">
                <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
              </div>
              <div className="column p-1 m-1">
                <div className="subtitle is-5">{errorMsgs[1]}</div>
              </div>
            </div>
          </div>
        )
      }
      if( key === 'maxFps' && value === true ) {
        msg.push(
          <div className="notification is-danger is-light" key={errorMsgs[2]}>
            <div className="columns">
              <div className="column is-1 p-1 m-1">
                <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
              </div>
              <div className="column p-1 m-1">
                <div className="subtitle is-5">{errorMsgs[2]}</div>
              </div>
            </div>
          </div>
        )
      }
      if( key === 'maxResolution' && value === true ) {
        msg.push(
          <div className="notification is-danger is-light" key={errorMsgs[3]}>
            <div className="columns">
              <div className="column is-1 p-1 m-1">
                <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
              </div>
              <div className="column p-1 m-1">
                <div className="subtitle is-5">{errorMsgs[3]}</div>
              </div>
            </div>
          </div>
        )
      }
      if( key === 'videoReadFailure' && value === true ) {
        msg.push(
          <div className="notification is-danger is-light" key={errorMsgs[4]}>
            <div className="columns">
              <div className="column is-1 p-1 m-1">
                <span className="icon is-danger has-text-danger is-medium"><i className="fas fa-exclamation-triangle fa-lg"></i></span>
              </div>
              <div className="column p-1 m-1">
                <div className="subtitle is-5">{errorMsgs[4]}</div>
              </div>
            </div>
          </div>
        )
      }
    }
    const dialogBody = [
      <div key="problems-detected" className="columns has-text-left">
        <div className="column">
          <p className="subtitle is-5">The following problem(s) were detected:</p>
          {msg}
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Unable to Create Animation"
        content={dialogBody}
        msgFormat="html"
        label1={textDialogUpgrade}
        action1={ ()=>closeModal() }
        custom1={STATE.subscriptionInfo.name !== "Studio" ? "showUpgradeButton" : ""} 
        label2={textDialogOk}
        action2={()=>closeModal()}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Animation job failed modal
  ///--------------------------------------------------------------------
  function buildJobProcessingFailedModal() {
    const msg = "Sorry something went wrong processing this job, no animation time will be deducted from your account. If the problem continues please contact DeepMotion Support."
    const dialogBody = [
      <div key="job-failed-dialog">
        <div className="columns has-text-centered">
          <div className="column">
            <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
        </div> 
        <div className="columns has-text-centered">
          <div className="column notification is-danger is-light">
            <h2 className="subtitle is-5">{msg}</h2>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Animation Job Failed"
        content={dialogBody}
        msgFormat="html"
        label1={textDialogUpgrade}
        action1={ ()=>closeModal() }
        custom1={STATE.subscriptionInfo.name !== "Studio" ? "showUpgradeButton" : ""} 
        label2={textDialogOk}
        action2={()=>closeModal()}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Video copy or model copy to GCP error 
  ///--------------------------------------------------------------------
  function buildVideoOrModelCopyError() {
    let msg = `Our servers reported an error while trying to process this animation job, no animation time will be deducted from your account. If this problem continues to happen please contact DeepMotion Support.`
    const dialogBody = [
      <div key='video-or-model-copy-error'>
        <div className="columns has-text-centered" key='video-or-model-copy-error'>
          <div className="column">
            <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
        </div> 
        <div className="columns has-text-centered">
          <div className="column">
            <h2 className="subtitle is-5">{msg}</h2>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title={textGenericErrorTitle}
        content={dialogBody}
        msgFormat="html"
        label1={textDialogClose}
        action1={()=>closeModalAndResetWorkflow(closeModalFlags.routeToA3dHome)}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Invalid or un-supported input video codec:
  ///--------------------------------------------------------------------
  function buildInvalidCodecError() {
    let msg = `The input video format is not supported. It is recommended to encode the input video with H.264 codec and use .mp4 as the container file format.`
    const dialogBody = [
      <div key="invalid-video-codec-error">
        <div className="columns has-text-centered">
          <div className="column">
            <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
        </div> 
        <div className="columns has-text-centered">
          <div className="column">
            <h2 className="subtitle is-5">{msg}</h2>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title={textInputVideoErrorTitle}
        content={dialogBody}
        msgFormat="html"
        label1={textDialogClose}
        action1={()=>closeModal()}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Modal for selecting custom characters from users account
  ///--------------------------------------------------------------------
  function buildCharacterSelectionDialog() {
    const title = "Account Characters"
    const actionBtnLabel = "Close"
    const actionBtnClickFcn = STATE.accountTotals.charactersList.length ? ()=>updateModelInfoandNavigateUser(false, true) : ()=>updateModelInfoandNavigateUser(false, false)
    const addDate = STATE.animJobSettings.customModelInfo.ctime
      ? Enums.convertUnixDateToStandardFormat(STATE.animJobSettings.customModelInfo.ctime)
      : ""

    const canCreateNewModel = (STATE.accountTotals.characterLimit - STATE.accountTotals.charactersList.length > 0) ? true : false
    return (
      <div id="modal-ter" className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card" style={{width:'66vw'}}>
          <header className="modal-card-head m-0">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={()=>closeModal()} aria-label="close"></button>
          </header>
          <section className="modal-card-body m-0 p-0">
            <div className="content">

              {buildModelThumbnailsSection()}

            </div>
          </section>
          <section className="m-0 p-0">
            <div className="columns m-0 has-background-link-light">
              <div className={"column p-4 has-text-centered"}>
                <div className="title is-5 dm-brand-font">{STATE.animJobSettings.customModelInfo.name}</div>
                <div className="subtitle is-6 dm-brand-font">
                {
                  !STATE.animJobSettings.customModelInfo.id || STATE.animJobSettings.customModelInfo.id === Enums.robloxModelId
                  ?
                  <div className="subtitle is-6 dm-brand-font"> (System Provided) </div>
                  :
                  <div className="subtitle is-6 dm-brand-font"> Added: {addDate} </div>
                }
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns m-0 fullwidth">
              {/* 
                canCreateNewModel 
                &&
                <div className="column p-0">
                  <div className="buttons is-left">
                    <div className="button ml-4" onClick={()=>closeDialogAndOpenModelsPage()}> 
                      <span> {textManageModels} </span>
                    </div>
                  </div>
                </div>
              */}
              <div className="column p-0">
                <div className="buttons is-right">
                  <div className="button action-btn-dark" tabIndex="0" onClick={actionBtnClickFcn}> {actionBtnLabel} </div>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }

  ///--------------------------------------------------------------------
  /// Modal for warning user they will lose upload data on job type switch
  ///--------------------------------------------------------------------
  function buildWarnUploadDataLoseModal(mode) {
    const cancelBtnLabel = "No"
    const actionBtnLabel = mode ? "Yes, Change to Pose" : "Yes, Change to Animation"
    const msg = STATE.silentUploadInProgress ? "The input file currently being uploaded will be lost if you change the job type, continue?" : "Your uploaded input file will be lost if you change job type, continue?"
    const dialogBody = [
      <div key="warn-lose-upload">
        <div className="columns has-text-left">
          <div className="column is-2 has-text-centered">
            <span className="icon is-warning has-text-warning is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
          <div className="column">
            <h2 className="subtitle is-5">{msg}</h2>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Warning"
        content={dialogBody}
        label1={cancelBtnLabel}
        action1={() => closeModal()}
        label2={actionBtnLabel}
        action2={ () => resetSelectedInputVideo(mode) }
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Confrim removal of currently uploading or fully uploaded input media
  ///--------------------------------------------------------------------
  function buildConfirmInputMediaRemoval(mode) {
    const cancelBtnLabel = "No"
    const actionBtnLabel = STATE.silentUploadInProgress ? "Yes, Stop Upload" : "Yes, Remove"
    const msg = STATE.silentUploadInProgress ? "Stop the current upload that's in progress?" : "Remove the currently uploaded input file?"
    const dialogBody = [
      <div key="confirm-remove-input">
        <div className="columns has-text-left">
          <div className="column is-2 has-text-centered">
            <span className="icon is-warning has-text-warning is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
          </div>
          <div className="column">
            <h2 className="subtitle is-5">{msg}</h2>
          </div>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Warning"
        content={dialogBody}
        label1={cancelBtnLabel}
        action1={() => closeModal()}
        label2={actionBtnLabel}
        action2={ () => resetSelectedInputVideo(mode) }
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Confirm deletetion of an existing animation job including all
  /// its associated files (ie FBX, BVH, JPG, etc)
  ///--------------------------------------------------------------------
  function buildAnimationDeleteModal() {
    const rid = STATE.confirmDialogId
    let cDate = new Date(STATE.dialogInfo.date).toLocaleString( Enums.getNavigatorLanguage() )
    let msg = "Confirm deletion by typing the job name (case sensitive) below"
    return (
      <div id="modal-ter" className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head m-0">
            <p className="modal-card-title">Delete Animation</p>
            <button className="delete" onClick={()=>closeDeleteAnimJobModal()} aria-label="close"></button>
          </header>
          <section className="modal-card-body m-0 p-0">
            <div className="content">
              <div className="columns m-0 pl-3 pr-3 fullwidth has-background-info-light">
                <div className="column is-1 mr-5">
                  <span className="icon is-large">
                    <i className="fas fa-trash-alt fa-3x has-text-danger"></i>
                  </span>
                </div>
                <div className="column p-0">
                  <div className="columns m-0">
                    <div className="column is-2 pb-1 m-0 has-text-right">
                      <h1 className="subtitle is-6 dm-brand-font"> Name: </h1>
                    </div>
                    <div className="column pb-1 m-0 has-text-left">
                      <h1 className="subtitle is-6 has-text-black"> {STATE.dialogInfo.name} </h1>
                    </div>
                  </div>
                  <div className="columns m-0">
                    <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                      <h1 className="subtitle is-6 dm-brand-font">Length:</h1>
                    </div>
                    <div className="column pb-1 pt-1 m-0 has-text-left">
                      <h1 className="subtitle is-6 has-text-black">{STATE.dialogInfo.length.toFixed(2)} seconds</h1>
                    </div>
                  </div>
                  <div className="columns m-0">
                    <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                      <h1 className="subtitle is-6 dm-brand-font">Created:</h1>
                    </div>
                    <div className="column pb-1 pt-1 m-0 has-text-left">
                      <h1 className="subtitle is-6 has-text-black"> {cDate} </h1>
                    </div>
                  </div>
                  <div className="columns m-0">
                    <div className="column is-2 pb-1 pt-1 m-0 has-text-right">
                      <h1 className="subtitle is-6 dm-brand-font">Size:</h1>
                    </div>
                    <div className="column pb-1 pt-1 m-0 has-text-left">
                      <h1 className="subtitle is-6 has-text-black"> {STATE.dialogInfo.size} </h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns m-0 fullwidth">
                <div className="column">
                  <div className="block p-4">
                    <p className="subtitle is-5">
                      Deleting this animation will permanently remove it from your Animate 3D Library, if you need a backup copy make sure you download the animation before deleting it.
                    </p>
                    <div className="subtitle is-5 notification is-info is-light">
                      Note: Deleting animations does <span className="has-text-weight-semibold">not</span> add back any animation time to your account.
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns m-0 fullwidth">
                <div className="column px-5">
                  <label className="label">{msg}</label>
                  <input className="input" onChange={validateJobDeleteInput} type="text" name="jobName" id="job-input" placeholder="Job Name" required />
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns m-0 fullwidth">
              <div className="column p-0">
                <div className="buttons is-right">
                  <div className="button is-white dm-brand-font" tabIndex="0" onClick={closeDeleteAnimJobModal}>Cancel</div>
                  {
                    deleteJobButtonEnabled 
                    ?
                    <div className="button is-danger" tabIndex="1" onClick={()=>deleteAnimJobAndClearLocalState(rid)}>Delete</div>
                    :
                    <div className="button is-danger" disabled>Delete</div>
                  }
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    )
  }

  ///--------------------------------------------------------------------
  /// Close and de-activate user account modal:
  ///--------------------------------------------------------------------
  function buildCloseAccountModal() {
    let title = "Close Account"
    let msg = "WARNING: This action will deactivate your account and you will lose access to Animate 3D including all of your data. To close your account enter your email into the field below. Once complete you will be logged out of the service."

    return (
      <div id="modal-ter" className="modal is-active">
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head m-0">
            <p className="modal-card-title">{title}</p>
            <button className="delete" onClick={()=>closeModalAndResetWorkflow()} aria-label="close"></button>
          </header>
          <section className="modal-card-body m-0">
            <div className="content">
              <div className="columns has-text-centered">
                <div className="column">
                  <span className="icon is-danger has-text-danger is-large"><i className="fas fa-exclamation-triangle fa-3x"></i></span>
                </div>
              </div> 
              <div className="columns has-text-centered">
                <div className="column">
                  <h2 className="subtitle is-5">{msg}</h2>
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <input className="input" onChange={validateCloseAccountInput} type="email" name="email" id="email-input" placeholder="email address" required />
                </div>
              </div>
            </div>
          </section>
          <footer className="modal-card-foot m-0">
            <div className="columns fullwidth">
                <div className="column">
                  <div className="button" onClick={()=>closeModalAndResetWorkflow()}> {textDialogCancel} </div>
                </div>
                <div className="column">
                  {
                    closeAccountButtonEnabled
                    ?
                    <div className="button is-danger" onClick={()=>closeUserAccount()}> Close Account </div>
                    :
                    <div className="button is-danger" disabled > Close Account </div>
                  }
                </div>
              </div>
          </footer>    
        </div>
      </div>
    )
  }

  ///--------------------------------------------------------------------
  /// Shows job summary information from library page
  ///--------------------------------------------------------------------
  function buildJobSettingsModal() {
    const dialogBody = [
      <div key="job-settings-info">
        {buildJobSummaryInfoTables(STATE.animJobId, false)}
      </div>
    ]
    return (
      <DMDialog
        title="Job Settings"
        content={dialogBody}
        msgFormat="html"
        label1={textDialogClose}
        action1={()=>closeModalAndResetWorkflow()}
        noPadding={true}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Builds the confirm new animation modal
  ///--------------------------------------------------------------------
  function buildConfirmNewJobModal() {
    const dialogTitle = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose
      ? "Confirm New Static Pose" : "Confirm New Animation"
    const dialogBody = [buildJobSummaryInfoTables(0, true)]
    return (
      <DMDialog 
        title={dialogTitle}
        content={dialogBody}
        msgFormat="html"
        noPadding={true}
        label1="Back to Settings"
        action1={()=>DISPATCH({confirmDialogId: Enums.confirmDialog.none})}
        label2="Start Job"
        action2={()=>initNewAnimationJob({retry: true})}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// confirmation dialog for max input clip length reached 
  ///--------------------------------------------------------------------
  function buildInputFileTooLongModal() {
    let customMsg = `The maximum length for input motion clips is ${process.env.REACT_APP_MAX_CLIP_LENGTH} seconds, current file is ${STATE.inputVideoData.fileLength} seconds. Please try using a shorter motion clip.`
    return (
      <InfoDialog 
        title="Input Video Too Long"
        msg={customMsg}
        isDanger={true}
        label1="OK"
        action1={closeModal}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// confirmation dialog for max input size length reached 
  ///--------------------------------------------------------------------
  function buildInputFileTooBigModal() {
    let customMsg = `The maximum input size allowed is ${Enums.formatSizeUnits(getMaxInputClipSize(), 0)}, your file is ${STATE.dialogInfo.size}. Please try using a smaller sized motion clip.`
    return (
      <InfoDialog 
        title="Size Limit Reached"
        msg={customMsg}
        isDanger={true}
        label1="OK"
        action1={resetDialogsData}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// 
  ///--------------------------------------------------------------------
  function buildInputFileNameInvalidModal() {
    const message = `File name: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> contains invalid characters. Please rename your file and try again.`
    return (
      <InfoDialog 
        title="File Name Invalid"
        msg={message}
        msgFormat="html"
        isDanger={true}
        label1="OK"
        action1={resetDialogsData}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// 
  ///--------------------------------------------------------------------
  function buildInputMaxLengthExceededModal() {
    const message = `File name: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> exceeds max length of <strong>${Enums.MAX_FILENAME_LENGTH}</strong> characters. Please shorten the file name and try again.`
    return (
      <InfoDialog 
        title="File Name Too Long"
        msg={message}
        msgFormat="html"
        isDanger={true}
        label1="OK"
        action1={resetDialogsData}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// 
  ///--------------------------------------------------------------------
  function buildInputInvalidOrCorruptModal() {
    const message = `The input video file supplied: <br/><br/><strong>${STATE.dialogInfo.videoFileName}</strong><br/><br/> does not have any video information or is corrupted. Please try using a different file.`
    return (
      <InfoDialog 
        title="Input Media Not Valid"
        msg={message}
        msgFormat="html"
        isDanger={true}
        label1="OK"
        action1={resetDialogsData}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// 
  ///--------------------------------------------------------------------
  function buildInputFileTypeWrongModal() {
    const fileTypesString = STATE.animJobSettings.jobType === Enums.jobTypes.staticPose 
      ? Enums.textAcceptedImgTypes 
      : Enums.textAcceptedClipTypes
    const message = `File "${STATE.videoFileName}" is using an unsupported file extension. ${fileTypesString}`
    return (
      <InfoDialog 
        title="File Type Invalid"
        msg={message}
        msgFormat="html"
        isDanger={true}
        label1="OK"
        action1={resetDialogsData}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// 
  ///--------------------------------------------------------------------
  function buildCancelInProgressJobModal() {
    return (
      <InfoDialog 
        title="Confirm Job Cancel"
        msg="Are you sure you would like to cancel the current job?"
        label1="No"
        label2="Yes, Cancel Job"
        action1={closeModal}
        action2={()=>stopCurrentHandler(true)}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Builds the rerun animation/pose job modal:
  ///--------------------------------------------------------------------
  function buildJobReRunModal() {
    if( !STATE.animJobId ) {
      console.log(`Warning: null or invalid jobId found while opening rerun modal, closing dialog!`)
      closeModalAndResetWorkflow()
      return <div />
    }
    const jobDetails = getJobDetailsById( STATE.animJobId )
    if( !jobDetails ) {
      return <div />
    }
    const modelInfo = getModelDataById(jobDetails.customModel, true)
    let faceDataType = 0
    if( !modelInfo || modelInfo.faceDataType ) {
      // face tracking available for default models and custom models with faceDataType=1
      faceDataType = 1
    }
    const animName = jobDetails.name
    const duration = jobDetails.length
    const cDate = new Date(jobDetails.dateRaw).toLocaleString( Enums.getNavigatorLanguage() )
    const dialogTitle = jobDetails.jobType ? "Rerun 3D Pose" : "Rerun Animation"
    const jobId = jobDetails.rid
    const settings = {
      ...Enums.JOB_SETTINGS_TEMPLATE,
      ...jobDetails.settings
    }

    if( Object.keys(settings).length === 0 ) {
      return(
        <React.Fragment>
          <div className="columns">
            <div className="column">
              <div className="notification subtitle is-info is-light">
                Unable to rerun animation jobs created before July 25, 2021.
              </div>
            </div>
          </div>
        </React.Fragment>
      )
    }
    else {
      const settingFlags = []
      settingFlags.push(
        <span key="setting-on" className="icon is-small"><i className="fas fa-check-circle has-text-success fa-sm"></i></span>
      )
      settingFlags.push(
        <span key="setting-off" className="icon is-small"><i className="fas fa-times-circle has-text-danger fa-sm"></i></span>
      )

      let outputFormatsList = []
      let rerunFormatsList = []
      for (const [key, value] of Object.entries(settings.formats)) {
        if(value === true) {
          outputFormatsList.push(key)
        }
      }
      for (const [key, value] of Object.entries(STATE.rerunSettings.formats)) {
        if(value === true) {
          rerunFormatsList.push(key)
        }
      }
      outputFormatsList = outputFormatsList.join(', ')
      rerunFormatsList = rerunFormatsList.join(', ')
      if( modelInfo && modelInfo.id !== 'standard' ) {
        // prepend glb format if custom character present
        outputFormatsList = "glb, " + outputFormatsList
        rerunFormatsList = "glb, " + rerunFormatsList
      }

      let rerunIsAvailable = STATE.accountTotals.max_rerun === -1 ? true : ((STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count) > 0)
      let rerunCountStr = STATE.accountTotals.max_rerun === -1 ? "Unlimited" : `${STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count}`
      let rerunRemaininStr = STATE.accountTotals.max_rerun === -1 ? "Unlimited" : `${STATE.accountTotals.max_rerun - STATE.accountTotals.rerun_count} / ${STATE.accountTotals.max_rerun}`

      const params = {
        settings: settings,
        outputFormatsList: outputFormatsList,
        animName: animName,
        duration: duration,
        cDate: cDate,
        faceDataType: faceDataType,
        modelInfo: modelInfo === null ? 'standard' : modelInfo,
        // set reRun=true for rerun view of each table
        reRun: true
      }
      let displayScreen = null
      if( jobDetails.jobType ) {
        displayScreen = buildStaticPoseSettingsTable(params)
      }
      else {
        switch(rerunViewState) {
          default:
          break
          case Enums.pageState.rerunAnimSettings:
            displayScreen = buildAnimationSettingsTable(params)
            break
          case Enums.pageState.rerunVideoSettings:
            displayScreen = buildVideoSettingsTable(params)
            break
        }
      }

      return (
        <React.Fragment>
          <div id="modal-ter" className="modal is-active">
            <div className="modal-background"></div>
            <div className="modal-card" style={{height:'100%'}}>
              <header className="modal-card-head m-0">
                <p className="modal-card-title">{dialogTitle}</p>
                <button className="delete" onClick={() => closeModalAndResetWorkflow()} aria-label="close"></button>
              </header>
              <section className="modal-card-body m-0 p-0">
                <div className="content">
                  {buildAnimationHeaderSection(params)}
                  <div className="columns m-1 p-1">
                    <div className="column has-text-left">

                      <div className="columns is-justify-content-center">
                        {
                          rerunIsAvailable
                          ?
                          <div className="column notification is-warning is-light p-4 m-5 has-text-centered">
                            <span className="icon is-medium">
                              <i className="fas fa-info-circle" aria-hidden="true"></i>
                            </span>
                            <span className="has-text-weight-semibold">
                              You have {rerunCountStr} reruns remaining for the current month
                            </span>
                          </div>
                          :
                          <div className="column notification is-danger is-light p-4 ml-5 mr-5 mb-3 mt-3 has-text-centered">
                            <span className="icon is-medium">
                              <i className="fas fa-exclamation-triangle" aria-hidden="true"></i>
                            </span>
                            {
                              STATE.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                              ?
                              <span className="block has-text-weight-semibold">
                                Reruns are not available for {Enums.accountPlansInfo[0].name} accounts
                              </span>
                              :
                              <React.Fragment>
                              <span className="block has-text-weight-semibold">
                                You have used all of your animation reruns for the current month
                              </span>
                              <div className="has-text-weight-semibold">
                                Reruns Remaining: {rerunRemaininStr}
                              </div>
                              </React.Fragment>
                            }
                          </div>
                        }
                      </div>

                      {/* Only show screen toggle button for regular animation jobs (i.e not for static poses) */}
                      {
                        !jobDetails.jobType
                        &&
                        <div className="columns">
                          <div className="column">
                            <div className="buttons is-centered has-addons">
                              <button onClick={() => setRerunViewState(Enums.pageState.rerunAnimSettings)} className={"button " + (rerunViewState === Enums.pageState.rerunAnimSettings ? "is-link is-selected" : "is-light")}>Animation Settings</button>
                              <button onClick={() => setRerunViewState(Enums.pageState.rerunVideoSettings)} className={"button " + (rerunViewState === Enums.pageState.rerunVideoSettings ? "is-link is-selected" : "is-light")}>Video Settings</button>
                            </div>
                          </div>
                        </div>
                      }
                      <div className="columns">
                        <div className="column">
                          {displayScreen}
                        </div>
                      </div>

                      <div className="columns">
                        <div className="column">
                          {buildOutputFormatsTable(rerunFormatsList)}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </section>
              <footer className="modal-card-foot m-0">
                <div className="columns m-0 fullwidth">
                  <div className="column p-0">
                    <div className="buttons is-right">
                      <button className="button is-white dm-brand-font" tabIndex="0" onClick={() => closeModalAndResetWorkflow()}>Close</button>
                      {
                        rerunIsAvailable
                        ?
                        <div className="button action-btn" tabIndex="1" onClick={()=>setRerunConfirmInfo({showModal: true, jobId: jobId})}> Rerun </div>
                        :
                        <React.Fragment>
                          {
                            STATE.subscriptionInfo.name === Enums.accountPlansInfo[0].name
                            ?
                            <div disabled={true} className="button is-danger is-light no-cursor" tabIndex="1"> <span className="no-side-margins"> Unavailable </span></div>
                            :
                            <div disabled={true} className="button is-danger is-light no-cursor" tabIndex="1"> <span className="no-side-margins">Rerun Limit Reached </span></div>
                          }
                        </React.Fragment>
                      }
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  ///--------------------------------------------------------------------
  function buildRemoveCharacterConfirmModal() {
    let dialogTitle = "Discard Custom Character?"
    let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready to be used in your next animation, selecting this option will remove it and use standard characters instead.`
    return (
      <InfoDialog 
        title={dialogTitle}
        msg={msg}
        label1="No, Keep Character"
        action1={() => updateModelInfoandNavigateUser(false, true)}
        label2="Yes, Discard Character"
        action2={() => updateModelInfoandNavigateUser(true, true)}
      />
    )
  }
  ///--------------------------------------------------------------------
  function buildUploadCharacterConfirmModal() {
    let dialogTitle = "Upload New Character?"
    let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready for your next animation, uploading a new character will replace the current one.`
    return (
      <InfoDialog 
        title={dialogTitle}
        msg={msg}
        label1="No, Keep Character"
        action1={() => updateModelInfoandNavigateUser(false, true)}
        label2="Yes, Upload New Character"
        action2={() => updateModelInfoandNavigateUser(true, false)}
      />
    )
  }

  ///--------------------------------------------------------------------
  /// Logout confirmation modal:
  ///--------------------------------------------------------------------
  function buildExitApplicationModal() {
    return (
      <DMDialog
        title="Confirm Sign Out"
        content="Are you sure you want to sign out of DeepMotion?"
        noPadding={true}
        label1="Cancel"
        label2="Sign Out"
        action1={ () => DISPATCH({confirmDialogId: null}) }
        action2={ () => logoutUser() }
      />
    )
  }

  //////////////////////////////////////////////////////////////////////
  // helper function for custom character workflow
  //////////////////////////////////////////////////////////////////////
  function updateModelInfoandNavigateUser(discardModel, pageFlag) {
    // close the dialog, update custom model info (if needed), and
    // navigate user to the appropriate page based on flag
    closeModal()
    if( discardModel ) {
      DISPATCH({animJobSettings: {...STATE.animJobSettings, ...{'customModelInfo': Enums.customModelObj}}})
    }
  }

  ///--------------------------------------------------------------------
  /// Confirmation dialog for starting a new re-run job
  ///--------------------------------------------------------------------
  function RerunConfirmationModal(rid) {
    const dialogBody = [
      <div key="rerun-confirm" className="columns">
        <div className="column has-text-left">
          <p className="subtitle is_3">Are you sure you would like to rerun this animation?</p>
        </div>
      </div>
    ]
    return (
      <DMDialog
        title="Confirm Rerun"
        content={dialogBody}
        msgFormat="html"
        label1="Cancel"
        action1={()=>setRerunConfirmInfo({showModal:false, jobId: 0})}
        label2="Yes, Rerun"
        action2={ ()=>startJobRerun(rid) }
      />
    )
  }

  //////////////////////////////////////////////////////////////////////
  // helper function for starting a rerun job
  //////////////////////////////////////////////////////////////////////
  function startJobRerun(jobId) {
    setRerunConfirmInfo({showModal: false, jobId: 0})
    DISPATCH({confirmDialogId: null})
    beginAnimationJob({rerun: true, rid: jobId, retry: true})
  }

  ///--------------------------------------------------------------------
  /// Builds the model dropdown selector for downloading
  /// standard characters
  ///--------------------------------------------------------------------
  function buildOutputFileTypeDropDown() {
    const fileTypesList = retrieveJobOutputFileTypes()
    if( !fileTypesList || !fileTypesList.length ) {
      return
    }
    let dd_data = []
    fileTypesList.forEach( (fileType) => {
      dd_data.push({value: fileType, available: true})
    })
    // default anim file type is FBX, but if user disabled FBX then select first file type
    if( selectedFileType === Enums.animFileType.FBX && !fileTypesList.includes(Enums.animFileType.FBX) ) {
      if( dd_data[0] ) {
        setSelectedFileType(dd_data[0].value)
      }
    }
    return (
      <DMDropDown
        data={dd_data}
        value={selectedFileType}
        onChange={onFileTypeChange}
        isUp={true}
        isStyled={true}
        noMargins={true}
      />
    )
  }

  // wrapper function for updating selected file type 
  function onFileTypeChange(value, cb) {
    setSelectedFileType(value)
    if( cb ) {
      cb()
    }
  }

  ///--------------------------------------------------------------------
  /// Builds a custom branded animations download button
  ///--------------------------------------------------------------------
  function buildAnimationDownloadButton() {
    if( !STATE.currDownloadLinks ) {
      return
    }
    // loop through STATE.currDownloadLinks
    for (const [key, link] of Object.entries(STATE.currDownloadLinks)) {
      if( key.toLowerCase().includes(selectedFileType.toLowerCase()) ) {
        return (
          <div><a href={link} className="button action-btn fade-in"><span className="no-side-margins">{titleDownload}</span></a></div>
        )
      }
    }
    // otherwise default to BVH file download since always available
    return (
      <div><a href={STATE.currDownloadLinks.bvhLink} className="button action-btn fade-in"><span className="no-side-margins">{titleDownload}</span></a></div>
    )
  }

  /////////////////////////////////////////////////////////////////////
  /// Seperate function for checking info or error Dialogs instead of 
  /// Modals which may have various functionality and unique designs
  /////////////////////////////////////////////////////////////////////
  function checkForMessageDialogs() {
    // check for info/confirmation dialogs 
    if( STATE.confirmDialogId === Enums.confirmDialog.customModelExists1 ) {
      let dialogTitle = "Discard Custom Character?"
      let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready to be used in your next animation, selecting this option will remove it and use standard characters instead.`
      return (
        <InfoDialog 
          title={dialogTitle}
          msg={msg}
          label1="No, Keep Character"
          action1={() => updateModelInfoandNavigateUser(false, true)}
          label2="Yes, Discard Character"
          action2={() => updateModelInfoandNavigateUser(true, true)}
        />
      )
    }
    else if( STATE.confirmDialogId === Enums.confirmDialog.customModelExists2 ) {
      let dialogTitle = "Upload New Character?"
      let msg = `We found a custom character (${STATE.animJobSettings.customModelInfo.name}) ready for your next animation, uploading a new character will replace the current one.`
      return (
        <InfoDialog 
          title={dialogTitle}
          msg={msg}
          label1="No, Keep Character"
          action1={() => updateModelInfoandNavigateUser(false, true)}
          label2="Yes, Upload New Character"
          action2={() => updateModelInfoandNavigateUser(true, false)}
        />
      )
    }

    // next check for potential error dialogs 
    if( STATE.errorDialogInfo ) {
      if( STATE.errorDialogInfo.show ) {
        let dialogTitle = ""
        let msg = ""
        switch(STATE.errorDialogInfo.id) {
          case Enums.eCodes.BadRequest:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Bad Request" : STATE.errorDialogInfo.title) 
            msg = "Sorry we could not process your request, if the problem continues please contact DeepMotion support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            )
          break
          case Enums.eCodes.Unauthorized:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Unauthorized" : STATE.errorDialogInfo.title) 
            msg = "Your session has expired due to inactivity, please sign in again."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => logoutUser()}
              />
            )
          break
          case Enums.eCodes.Forbidden:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Access Denied" : STATE.errorDialogInfo.title) 
            msg = "You do not have access to the requested resource (404 - Forbidden), you have been logged out."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => logoutUser()}
              />
            )
          break
          case Enums.eCodes.RequestTimeout:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Request Timed Out" : STATE.errorDialogInfo.title) 
            msg = "Sorry your request has timed out, the service might be down due to an upgrade in progress. Please wait 10 minutes and try again, if the problem continues contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            ) 
          break
          case Enums.eCodes.NotFound:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Not Found" : STATE.errorDialogInfo.title) 
            msg = "The requested resource was not found (404) - the service might be down due to an upgrade in progress. Please try your request again, if the problem continues contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            ) 
          break
          case Enums.eCodes.OtherError:
            dialogTitle = (STATE.errorDialogInfo.title === "" ? "Something went wrong" : STATE.errorDialogInfo.title) 
            msg = "Sorry there was a problem with the request, please try again. If the problem continues please contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            ) 
          break
          //--------------------
          // SERVER SIDE ERRORS:
          //-------------------- 

          case Enums.eCodes.BadGateway:
            dialogTitle = "Bad Gateway"
            msg = "Sorry the server returned a 502 (Bad Gateway) error. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            )
          break

          case Enums.eCodes.InternalServerError:
            dialogTitle = STATE.errorDialogInfo.title !== "" ? STATE.errorDialogInfo.title : "Sorry, something went wrong"
            msg = STATE.errorDialogInfo.msg !== "" ? STATE.errorDialogInfo.msg : "The request could not be completed, this might be a network connectivity issue. If the problem continues please contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => DISPATCH({dispatchType: 'resetModelsPageAndDialogInfo', payload: {} }) }
              />
            )
          break

          case Enums.eCodes.ServiceUnavailable:
          case Enums.eCodes.GatewayTimeout:
          case Enums.eCodes.InsufficientStorage:
            dialogTitle = "Insufficient Storage"
            msg = "Sorry, the server reported a problem with your request. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            )
          break  

          default:
            dialogTitle = "Server Error"
            msg = "Sorry, the server reported a problem with your request. This may be due to an upgrade in progress, please wait 5-10 mins and try your request again. If the problem continues please contact DeepMotion Support."
            return (
              <InfoDialog 
                title={dialogTitle}
                msg={msg}
                label1="OK"
                action1={() => setErrorDialogInfo(false, null)}
              />
            )
          break
        }
      }
    }
    return null
  }

  let checkForDialogs = ""
  if( STATE.confirmDialogId ) {
    // TODO: merge rerun dialog into broader Enums dialogIds list
    if( rerunConfirmInfo.showModal ) {
      checkForDialogs = RerunConfirmationModal(rerunConfirmInfo.jobId)
    }
    else {
      if( STATE.confirmDialogId.length >= Enums.minJobIdLength ) {
        // DialogId=(job rid) used for deleting specific account job and its animations
        checkForDialogs = buildAnimationDeleteModal()
      }
      else {
        switch( STATE.confirmDialogId ) {
          case Enums.confirmDialog.standardDownload: 
            checkForDialogs = DownloadDefaultCharacterModal()
          break
          case Enums.confirmDialog.customDownload: 
            checkForDialogs = DownloadCustomCharacterModal()
          break
          case Enums.confirmDialog.customModelSelect: 
            checkForDialogs = buildCharacterSelectionDialog()
          break
          case Enums.confirmDialog.removeCustChar: 
            checkForDialogs = DeleteCustomCharacterModal()
          break
          case Enums.confirmDialog.MinutesBalanceTooLow: 
            checkForDialogs = buildNotEnoughMinutesModal()
          break
          case Enums.confirmDialog.VideoOrModelCopyError: 
            checkForDialogs = buildVideoOrModelCopyError()
          break
          case Enums.confirmDialog.InvalidVideoCodecError: 
            checkForDialogs = buildInvalidCodecError()
          break
          case Enums.confirmDialog.VideoValidationFailed: 
            checkForDialogs = buildVideoValidationFailed()
          break
          case Enums.confirmDialog.Video2AnimFailure: 
            checkForDialogs = buildJobProcessingFailedModal()
          break
          case Enums.confirmDialog.exitApplication: 
            checkForDialogs = buildExitApplicationModal()
          break
          case Enums.confirmDialog.closeUserAccount: 
            checkForDialogs = buildCloseAccountModal()
          break
          case Enums.confirmDialog.libraryJobSettings: 
            checkForDialogs = buildJobSettingsModal()
          break
          case Enums.confirmDialog.confirmNewAnimJob: 
            checkForDialogs = buildConfirmNewJobModal()
          break
          case Enums.confirmDialog.inputFileTooLong: 
            checkForDialogs = buildInputFileTooLongModal()
          break
          case Enums.confirmDialog.inputFileTooBig: 
            checkForDialogs = buildInputFileTooBigModal()
          break          
          case Enums.confirmDialog.inputMaxLengthExceeded: 
            checkForDialogs = buildInputMaxLengthExceededModal()
          break
          case Enums.confirmDialog.inputInvalidOrCorrupt: 
            checkForDialogs = buildInputInvalidOrCorruptModal()
          break
          case Enums.confirmDialog.inputFileNameInvalid: 
            checkForDialogs = buildInputFileNameInvalidModal()
          break
          case Enums.confirmDialog.inputFileTypeWrong: 
            checkForDialogs = buildInputFileTypeWrongModal()
          break
          case Enums.confirmDialog.cancelInProgressJob: 
            checkForDialogs = buildCancelInProgressJobModal()
          break
          case Enums.confirmDialog.reRunJobConfig: 
            checkForDialogs = buildJobReRunModal()
          break
          case Enums.confirmDialog.customModelExists1: 
            checkForDialogs = buildRemoveCharacterConfirmModal()
          break
          case Enums.confirmDialog.customModelExists2: 
            checkForDialogs = buildUploadCharacterConfirmModal()
          break
          case Enums.confirmDialog.confirmLoseUploadData: 
            checkForDialogs = buildWarnUploadDataLoseModal(!STATE.animJobSettings.jobType)
          break
          case Enums.confirmDialog.confirmInputMediaRemoval:
            checkForDialogs = buildConfirmInputMediaRemoval(STATE.animJobSettings.jobType)
          break

          ////////////////////////////////////////////////////////////////////////
          // check for known job processing error codes returned from the backend
          ////////////////////////////////////////////////////////////////////////
          case Enums.confirmDialog.InternalCliPipelineError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.InternalCliPipelineError)
          break
          case Enums.confirmDialog.FailedToParseArgsError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToParseArgsError)
          break
          case Enums.confirmDialog.FailedToLoadDataError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToLoadDataError)
          break
          case Enums.confirmDialog.ExplosionDetectedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.ExplosionDetectedError)
          break
          case Enums.confirmDialog.FailedToCreatePoseEstimatorError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToCreatePoseEstimatorError)
          break
          case Enums.confirmDialog.FailedToCreateBodyTrackerError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToCreateBodyTrackerError)
          break
          case Enums.confirmDialog.PoseEstimationTrackingError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.PoseEstimationTrackingError)
          break
          case Enums.confirmDialog.FailedToLoadConfigError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToLoadConfigError)
          break
          case Enums.confirmDialog.FailedToOpenFileForWritingError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToOpenFileForWritingError)
          break
          case Enums.confirmDialog.InterruptedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.InterruptedError)
          break
          case Enums.confirmDialog.InternalFaceTrackingError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.InternalFaceTrackingError)
          break
          case Enums.confirmDialog.LoadMeshFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.LoadMeshFailedError)
          break
          case Enums.confirmDialog.LoadBvhFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.LoadBvhFailedError)
          break
          case Enums.confirmDialog.CopyAnimFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.CopyAnimFailedError)
          break
          case Enums.confirmDialog.ExportFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.ExportFailedError)
          break
          case Enums.confirmDialog.MeshNotProvidedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.MeshNotProvidedError)
          break
          case Enums.confirmDialog.BlendShapesLessThanHalfError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.BlendShapesLessThanHalfError)
          break
          case Enums.confirmDialog.LoadFaceDefinitionFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.LoadFaceDefinitionFailedError)
          break
          case Enums.confirmDialog.LoadDMFTDataFailedError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.LoadDMFTDataFailedError)
          break
          case Enums.confirmDialog.LoadHumanoidMapError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.LoadHumanoidMapError)
          break
          case Enums.confirmDialog.RenderCliError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.RenderCliError)
          break
          case Enums.confirmDialog.InvalidInputParameterError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.InvalidInputParameterError)
          break
          case Enums.confirmDialog.FailedToLoadOrPlayInputVideoError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToLoadOrPlayInputVideoError)
          break
          case Enums.confirmDialog.FailedToLoadInputBvhError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToLoadInputBvhError)
          break
          case Enums.confirmDialog.FailedToLoadInputCharacterError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToLoadInputCharacterError)
          break
          case Enums.confirmDialog.FailedToAttachAnimToCharacterError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToAttachAnimToCharacterError)
          break
          case Enums.confirmDialog.FailedToConfigureBackDropError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToConfigureBackDropError)
          break
          case Enums.confirmDialog.FailedToCreateGifError:
            checkForDialogs = buildCustomJobFailedDialog(Enums.confirmDialogMsgs.FailedToCreateGifError)
          break
          ////////
          default:
            console.error(`Warning: Invalid confirmDialogId encountered on current render: ${STATE.confirmDialogId}`)
          break
        }
      }
    }
  }
  else {
    checkForDialogs = checkForMessageDialogs()
  }

  // simple css style to not display content when loading
  const loadingStyle = LOADING.show ? {display: 'none'} : null

  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // ----- Render Functions -----
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  //++++++++++++++++++++++++++++++++++++++++++++++++++++++++++
  return (
    <React.Fragment>

      <Security
        oktaAuth={oktaAuth}
        onAuthRequired={customAuthHandler}
        restoreOriginalUri={restoreOriginalUri}
      >
      <AppStateContext.Provider value={{state: STATE, dispatch: DISPATCH}}>
        {checkForDialogs}
        
        {/* Temporarily removing: <ParticlesBackground /> */}

        <Switch>
          
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3d} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} pageTitle={pageTitleA3DHome} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen msg={LOADING.msg} />
                }
                <div style={loadingStyle}>
                  <Anim3DHome {...routeProps}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                    getProductColorCSSClass={getProductColorCSSClass}
                    buildRemainingMinutesMeter={buildRemainingMinutesMeter}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3dGuidedFTE} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen msg={LOADING.msg} />
                }
                <div style={loadingStyle}>
                  <GuidedFTE {...routeProps}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    pageTitle={titleCreateAnim}
                    initializeA3DService={initializeA3DService}
                    getProductColorCSSClass={getProductColorCSSClass}
                    buildRemainingMinutesMeter={buildRemainingMinutesMeter}
                    uploadOnchange={uploadOnchange}
                    uploadInputVideo={uploadInputVideo}
                    getMaxInputClipSize={getMaxInputClipSize}
                    getMaxInputClipLength={getMaxInputClipLength}
                    buildFileSelectedScreen={buildFileSelectedScreen}
                    buildMotionClipSelectedArea={buildMotionClipSelectedArea}
                    buildJobSettingsArea={buildJobSettingsArea}
                    activeJobMenu={activeJobMenu}
                    closeModal={closeModal}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3dCreate} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen msg={LOADING.msg} />
                }
                <div style={loadingStyle}>
                  
                  <NewJobConfig {...routeProps}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                    checkStatus={checkStatus}
                    uploadOnchange={uploadOnchange}
                    performBackgroundVideoUpload={performBackgroundVideoUpload}
                    uploadInputVideo={uploadInputVideo}
                    buildFileSelectedScreen={buildFileSelectedScreen}
                    beginAnimationJob={beginAnimationJob}
                    InformationArea={InformationArea}
                    buildJobSummaryInfoTables={buildJobSummaryInfoTables}
                    buildPoseFilteringSelect={buildPoseFilteringSelect}
                    buildBackgroundColorSelect={buildBackgroundColorSelect}
                    buildSpeedMultiplierSelect={buildSpeedMultiplierSelect}
                    buildAddMotionClipArea={buildAddMotionClipArea}
                    buildCharacterSettingsArea={buildCharacterSettingsArea}
                    buildVideoSettingsArea={buildVideoSettingsArea}
                    buildAnimationJobConfigScreen={buildAnimationJobConfigScreen}
                    buildStaticPoseJobConfigScreen={buildStaticPoseJobConfigScreen}
                    buildJobTypeSelectionRow={buildJobTypeSelectionRow}

                    isProfessionalOrHigherPlan={isProfessionalOrHigherPlan}
                    activeJobMenu={activeJobMenu}
                    setActiveJobMenu={setActiveJobMenu}
                    checkFeatureAvailability={checkFeatureAvailability}
                    resetSelectedInputVideo={resetSelectedInputVideo}
                    uploadCancelled={uploadCancelled}
                    setUploadCancelled={setUploadCancelled}
                    resetDialogsData={resetDialogsData}
                    createNewOutputFormatsObj={createNewOutputFormatsObj}
                    getModelDataById={getModelDataById}
                    changeMP4IncludeOriginalVideo={changeMP4IncludeOriginalVideo}
                    changeMP4IncludeOriginalVideoReRun={changeMP4IncludeOriginalVideoReRun}
                    changeMp4OutputCameraMotion={changeMp4OutputCameraMotion}
                    changeMp4OutputCameraMotionReRun={changeMp4OutputCameraMotionReRun}
                    changeCustomBkgdSelect={changeCustomBkgdSelect}
                    changeCustomBkgdSelectReRun={changeCustomBkgdSelectReRun}
                    changeFootLockingSelect={changeFootLockingSelect}
                    changeFootLockingSelectReRun={changeFootLockingSelectReRun}
                    getMaxInputClipSize={getMaxInputClipSize}
                    getProductColorCSSClass={getProductColorCSSClass}
                    handleBkgdColorChange={handleBkgdColorChange}
                    closeModal={closeModal}
                    handleHttpError={handleHttpError}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3dPreview} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <Previewer {...routeProps}
                    STATE={STATE}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                    openAnimationPreviewer={openAnimationPreviewer}
                    pageTitle={()=>getJobDetailsById(STATE.animJobId).name}
                    displayReRunModal={displayReRunModal}
                    jobContainsGLBDownloadLinks={jobContainsGLBDownloadLinks}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3dLibrary} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <Library {...routeProps}
                    STATE={STATE}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    DownloadDefaultCharacterModal={DownloadDefaultCharacterModal}
                    initializeLibrary={initializeLibrary}
                    sortLibraryByColumn={sortLibraryByColumn}
                    buildActionsDropDown={buildActionsDropDown}
                    getModelDataById={getModelDataById}
                    openAnimationPreviewer={openAnimationPreviewer}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Anim3dModelManage} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <CharacterManagePage {...routeProps}
                    STATE={STATE}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeCharacterManagePage={initializeCharacterManagePage}
                    processCustomCharacterData={processCustomCharacterData}
                    getProductColorCSSClass={getProductColorCSSClass}
                    handleHttpError={handleHttpError}
                    getModelDataById={getModelDataById}
                    setErrorDialogInfo={setErrorDialogInfo}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={[Enums.routes.Anim3dProfilePage, `${Enums.routes.Anim3dProfilePage}?return=true`]} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} logout={logoutUser} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <ProfilePage {...routeProps}
                    STATE={STATE}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                    updateUserPlanData={updateUserPlanData}
                    handleHttpError={handleHttpError}
                    setErrorDialogInfo={setErrorDialogInfo}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Contact} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <ContactUs {...routeProps}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.SupportPage} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <Support {...routeProps}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.FeedbackPage} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <FeedbackForm {...routeProps}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.DMBTSdk} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <DMBTSdkPage {...routeProps}
                    pageTitle={pageTitleDashboard}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.VRSdk} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <VRSdkPage {...routeProps}
                    pageTitle={pageTitleDashboard}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Admin} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <AdminAPIApp {...routeProps}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.ActivityPage} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <ActivityPage {...routeProps}
                    STATE={STATE}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    logoutUser={logoutUser}
                  />
                </div>
              </PageTemplate>
            )
          }}/>

          {/***************************************************************************/}
          <SecureRoute exact path={Enums.routes.Dash} render={(routeProps) => {
            return (
              <PageTemplate {...routeProps} STATE={STATE} DISPATCH={DISPATCH} >
                {
                  LOADING.show
                  &&
                  <LoadingScreen />
                }
                <div style={loadingStyle}>
                  <DashboardPage {...routeProps}
                    pageTitle={pageTitleDashboard}
                    DISPATCH={DISPATCH}
                    LOADING={LOADING}
                    setLOADING={setLOADING}
                    initializeA3DService={initializeA3DService}
                  />
                </div>
              </PageTemplate>
            )
          }}/>
          {/***************************************************************************/}
          <Route path={Enums.routes.ForgotPwdPage} render={(props) => <ForgotPwdPage {...props} />} />
          <Route path={Enums.routes.CreateAccount} render={(props) => <Anim3DSignUpPage {...props} />} />
          <Route path={Enums.routes.ClosedAccount} render={(props) => <AccountClosedPage {...props} email={STATE.email}/>} />
          <Route path={Enums.routes.LoginCallback} component={LoginCallback} />
          <Route path={Enums.routes.SignIn} removeLocalStorageData={removeLocalStorageData} render={() => <Login config={oktaSignInConfig} />} />

          {/*TODO ? <Route path={Enums.routes.ActivityPage} render={(props) => <ActivityPage {...props} />} /> */}
          {/***************************************************************************/}
        </Switch>
        </AppStateContext.Provider>
      </Security>
    </React.Fragment>
  )
}