import React, { useRef } from 'react'
import Header from './navigation/Header'
import SideBarMenu from './navigation/sideBarMenu'
// import ParticlesBackground from './common/ParticlesBackground'
import * as Enums from './common/enums'

export default function PageTemplate(props) {

  /////////////////////////////////////////////////////////////////////
  // UI Page template for each route which includes sidebar and header
  /////////////////////////////////////////////////////////////////////
  return (
    <React.Fragment>
    <Header
      STATE={props.STATE}
      DISPATCH={props.DISPATCH}
      location={props.location} history={props.history}
    />
    <div className="section m-0 p-0" key="page-content">
      <div className="columns m-0">
        
        {/*** Render the sidemenu bar for each route: ***/}
        <SideBarMenu STATE={props.STATE} DISPATCH={props.DISPATCH} />
        
        {/*** Render main page content: ***/}
        <div className="column m-0">  
          {props.children}
        </div>

      </div>
    </div>
    </React.Fragment>
  )
}
  