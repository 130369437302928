import React from 'react'
import axios from 'axios'
import '@fortawesome/fontawesome-free/css/all.min.css'
import { browserHistory } from 'react-router'
import { relative } from 'path'
import 'mdbreact/dist/css/mdb.css'
import { MDBDataTable } from 'mdbreact'
import LoadingScreen from '../common/LoadingScreen'
import '../../styles/activities.css'

const pageTitle = 'User Activity Logs'

/*******************************************************************
 * Displays DRM Activity logs for customers in production
 *******************************************************************/
export default class ActivityPage extends React.Component {
  constructor() {
    super()
    this.state = {
      userlogs: {}
    }
  }

  // upon component mount we make an async call to retrieve the 
  // DRM logs data
  componentDidMount() {
    // TODO: Refactor from React Class to a Functional Component
    this.props.setLOADING({ ...this.props.LOADING, ...{show: true} })
    const reqBody = {userGroups: this.props.STATE.groups}
    axios.post(`${process.env.REACT_APP_API_URL}/license`, reqBody, {
      withCredentials: true
    })
    .then((res) => {
      const info = res.data;
      let logs = []
      info.forEach(item => {
        logs.push({
          email : item.Email,
          action : item.Message,
          computerName : item.ComputerName,
          time : item.Time
        })
      })
      const data = {
        columns: [
          {
            label: 'Email',
            field: 'email',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Action',
            field: 'action',
            sort: 'asc',
            width: 150
          },
          {
            label: 'ComputerName',
            field: 'computerName',
            sort: 'asc',
            width: 150
          },
          {
            label: 'Time',
            field: 'time',
            sort: 'asc',
            width: 150
          }
        ],
        rows: logs
      }

      // set state data once async call returns and update UI  
      this.setState({ userlogs: data }, () => {
        this.props.setLOADING({ ...this.props.LOADING, ...{show: false} })
      })

    }).catch((error) => {
      console.log(`*** ERROR! *** ${JSON.stringify(error)}`)
      console.log(error)
      this.props.setLOADING({ ...this.props.LOADING, ...{show: false} })

      if(error.response && (error.response.status === 401 || error.response.status === 403)) 
          this.props.logoutUser()
    })
  }
  
  // clean up state data when component unmounts
  componentWillUnmount() {
    this.setState({userlogs: {}}, () => { return } )
  }

  //////////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////////
	render () {

    // display loading bar until jobs data is received
    if( this.props.LOADING.show ) {
      return (
        <div className="column is-one-third has-text-centered is-vcentered" style={{marginTop:'100px'}}>
          <LoadingScreen />
        </div>
      )
    }

    // render the DRM activity logs
    else {
      return (
        <div className="page-fade-in">
          <div className="column has-text-centered" style={{marginTop:'50px'}}>
            <div className="columns has-text-centered">
              <div className="column is-three-quarters">
                <h1 className="title is-4 dm-brand-font">{pageTitle}</h1>
              </div>
            </div>
            <div style={{margin: '2%'}}>
              <MDBDataTable striped bordered hover data={this.state.userlogs} />
            </div>
          </div>
        </div>  
      )
    }
  }
}